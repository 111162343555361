/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
export const SITEURL =
  process.env.NODE_ENV === 'development'
    ? 'http://www.localhost.com:3000/'
    : 'https://www.amightygoodtime.com/';

export const EMAIL = 'hello@amightygoodtime.com';
export const SUPPORT_EMAIL = 'support@amightygoodtime.com';
export const PARTNERS_EMAIL = 'partners@amightygoodtime.com';
export const MARKETING_EMAIL = 'marketing@amightygoodtime.com';
export const PHONE = '5122229855';
export const FACEBOOK = 'https://www.facebook.com/amightygoodtime';
export const INSTAGRAM = 'https://www.instagram.com/amightygoodtime/';
export const TWITTER = 'https://twitter.com/amightygoodtime';
export const LINKEDIN = 'https://www.linkedin.com/company/a-mighty-good-time/';
export const MAX_PAGE_WIDTH = 1280;

export const SEARCH_CACHE_KEY = 'SearchCache';
export const CLOUDINARY_CLOUDNAME_HASC = 'ds6rxxvr3';
export const CLOUDINARY_CLOUDNAME_AMGT = 'a-mighty-good-time';
export const CLOUDINARY = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME_HASC}/image/upload/f_auto,q_auto/`;
export const CLOUDINARY_EDIT = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME_HASC}/image/upload/`;
export const CLOUDINARY_EVENTS = 'sona/events/';
export const CLOUDINARY_ORGANIZERS = 'sona/organizers/';
export const CLOUDINARY_UPLOAD = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME_HASC}/upload/`;
// TODO: Hide presets from all non-members
export const CLOUDINARY_PRESET = 'as8glwao';
export const CLOUDINARY_ORGANIZER_PRESET = 'rpddpsxx';
export const IMAGESTORAGEURL =
  'https://firebasestorage.googleapis.com/v0/b/stay-out-and-about.appspot.com/o/';
export const BRAINGAMES_URL =
  'https://mcusercontent.com/b348d53e957e0055a86291e15/files/5e21109a-daed-4a14-b584-15a08e616ae0/Stay_Smart_Brain_Games.pdf';

export const NAME_CHAR_MIN = 2;
export const NAME_CHAR_MAX = 100;
export const PASS_CHAR_MAX = 50;
export const DESC_CHAR_MIN = 50;
export const DESC_CHAR_MAX = 3000;
export const ORGANIZER_DESC_CHAR_MAX = 500;
export const IMG_SIZE_MB_MAX = 30;
export const PRICE_CHAR_MAX = 50;
export const URL_CHAR_MAX = 300;
export const EMAIL_CHAR_MAX = 200;
export const LOCATION_DETAILS_CHAR_MAX = 500;

const FULL_CATEGORY_LIST = {
  Advocacy: 'adv',
  'Alz/Dementia Programs': 'alz',
  Animals: 'ani',
  'Arts & Crafts': 'art',
  'Brain Health': 'brh',
  Caregiving: 'car',
  'Charity & Causes': 'cha',
  Comedy: 'com',
  Creative: 'cre',
  Dance: 'dan',
  Employment: 'emp',
  Entertainment: 'ent',
  'Film & Media': 'fil',
  Finance: 'fin',
  Fitness: 'fit',
  'Food & Drink': 'foo',
  'Health & Wellness': 'wel',
  'Home & Lifestyle': 'hom',
  Intergenerational: 'int',
  LGBTQIA: 'lgb',
  'Lifelong Learning': 'lif',
  'Mind & Body': 'min',
  Music: 'mus',
  'Performing & Visual Arts': 'per',
  'Photography & Video': 'pho',
  Podcast: 'pod',
  Relationships: 'rel',
  'Self-Improvement': 'sel',
  Social: 'soc',
  Speakers: 'spe',
  Spiritual: 'spi',
  Sports: 'spo',
  'Support Groups & Therapy': 'sup',
  Technology: 'tec',
  'Travel & Outdoor': 'tra',
  'Volunteer Opportunity': 'vol',
};
export const CATEGORYLIST = Object.keys(FULL_CATEGORY_LIST);

export const HELPCATEGORYLIST = [
  'Creative',
  'Entertainment',
  'Social',
  'Health & Wellness',
  'Travel & Outdoor',
  'Volunteer Opportunity',
];

export const FULL_OTHERFILTERS_LIST = {
  'Age 50+ only': '_50',
  'Age 55+ only': '_55',
  'Age 60+ only': '_60',
  'Wheelchair accessible': '_wh',
  'ASL available': '_as',
  'Closed Captioning': '_cl',
  'French / Français': '_fr',
  'Spanish / Español': '_sp',
};
export const OTHERFILTERSLIST = Object.keys(FULL_OTHERFILTERS_LIST);

export const getTagShortList = (longList) => {
  const shortList = [];
  longList.forEach((item) => {
    let shortItem = FULL_CATEGORY_LIST[item];
    if (!shortItem) {
      shortItem = FULL_OTHERFILTERS_LIST[item];
    }
    shortList.push(shortItem);
  });
  return shortList;
};

export const PRICELIST = [
  { key: 'free', name: 'Free' },
  { key: 'paid', name: 'Paid' },
  { key: 'any', name: 'Any Price' },
];

export const NON_PROFIT = {
  NONE: 0,
  PENDING_YES: 1,
  PENDING_NO: 2,
  YES: 3,
  NO: 4,
};

export const MODE = {
  INIT: 0,
  LIST: 1,
  CREATE: 2,
  VIEW: 3,
  EDIT: 4,
};

export const DATA_STATE = {
  DRAFT: 0,
  PENDING: 1,
  APPROVED: 2,
  DECLINED: 3,
  ACTIVE: 4,
  HIDDEN: 5,
  EXPIRED: 6,
  DELETED: 99,
};
export const dataStateLabel = (state) => {
  let stateLabel;
  switch (state) {
    case DATA_STATE.PENDING:
      stateLabel = 'Pending';
      break;
    case DATA_STATE.APPROVED:
      stateLabel = 'Approved';
      break;
    case DATA_STATE.DECLINED:
      stateLabel = 'Declined';
      break;
    case DATA_STATE.ACTIVE:
      stateLabel = 'Active';
      break;
    case DATA_STATE.HIDDEN:
      stateLabel = 'Hidden';
      break;
    case DATA_STATE.DRAFT:
      stateLabel = 'Draft';
      break;
    case DATA_STATE.EXPIRED:
      stateLabel = 'Expired';
      break;
    case DATA_STATE.DELETED:
      stateLabel = 'Deleted';
      break;
    default:
      stateLabel = 'UNKNOWN';
  }
  return stateLabel;
};

export const DEFAULT_ORGANIZER = {
  id: '',
  name: '',
  pid: '',
  url: '',
  phone: '',
  email: '',
  address: {
    name: '',
    street: '',
    city: '',
    lat: '',
    lng: '',
    placeId: '',
  },
  desc: '',
  social: [],
  status: 0,
  nonProfitStatus: NON_PROFIT.NONE,
};

export const DEFAULT_ORGANIZER_ERRORS = {
  name: '',
  pid: '',
  url: '',
  phone: '',
  email: '',
  address: '',
  desc: '',
  social: '',
  nonProfit: '',
};

export const DEFAULT_EVENT = {
  eid: '',
  name: '',
  featuredPhoto: {
    pid: '',
  },
  date: null,
  location: {
    inperson: null,
    online: null,
    phone: null,
  },
  priceType: 'paid',
  price: '',
  register: 'unknown',
  desc: {
    raw: '',
    search: '',
    length: 0,
  },
  tags: [],
  social: [],
  organizer: DEFAULT_ORGANIZER,
  status: DATA_STATE.DRAFT,
};

export const DEFAULT_EVENT_ERRORS = {
  name: '',
  date: '',
  organizer: '',
  img: '',
  price: '',
  desc: '',
  tags: '',
  social: '',
  location: '',
  register: '',
};

export const INITIAL_SEARCH_RADIUS_IN_METERS = 80000; // 80 km = 50 miles
export const COMMON_SEARCH_PARAMS = {
  getRankingInfo: true,
  page: 0,
  hitsPerPage: 20,
  restrictSearchableAttributes: [
    'startDate',
    'endDate',
    'name',
    'orgName',
    'desc',
    '_tags',
    'venue',
    'demo',
  ],
  distinct: 0,
};

export const getEmailRegex = () =>
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
