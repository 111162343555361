/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */

// Public Pages (permanent)
export const LANDING_URI = '/';
export const HOME_URI = '/home';
export const TOS_URI = '/tos';
export const PRIVACY_URI = '/privacy';
export const ABOUTUS_URI = '/aboutus';
export const CONTACTUS_URI = '/contactus';
export const HOWITWORKS_URI = '/howitworks';
export const EVENT_ORGANIZERS_URI = '/eventorganizers';
export const SEARCH_URI = '/search';
export const LOGIN_URI = '/login';
export const SIGNUP_URI = '/signup';
export const PLANUP_URI = '/planup';
export const FORGOT_URI = '/forgot';
export const EVENT_URI = '/event';
export const THANKYOU_URI = '/thankyou';
export const INPERSONNEEDS_URI = '/inpersonrequest';
export const PREFERENCES_URI = '/preferences';
export const PLUGINS_URI = '/plugins';
// Secure Pages
export const DASHBOARD_URI = '/dashboard';
export const EVENTS_URI = '/dashboard/events';
export const ORGANIZERS_URI = '/dashboard/organizers';
export const FAVORITES_URI = '/dashboard/favorites';
export const ACCOUNT_URI = '/dashboard/account';
export const ACCOUNT_EDIT_URI = '/dashboard/account/edit';
export const ACCOUNT_EMAIL_URI = '/dashboard/account/email';
export const ACCOUNT_PW_URI = '/dashboard/account/pw';
export const ACCOUNT_MEMBERSHIPS_URI = '/dashboard/account/membership';
export const ACCOUNT_BILLING_URI = '/dashboard/account/payment';
export const ACCOUNT_DELETE_URI = '/dashboard/account/delete';
export const APPLICATIONS_URI = '/dashboard/applications';
// Page Anchors
export const LOCATION_DETAILS_ANCHOR = 'location';
export const ORGANIZER_ANCHOR = 'organizer';
