/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';

import {
  MuiThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import { blue, grey, teal } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// light 300
// main  500
// dark  700
// text  500

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: (factor) => `${0.625 * factor}rem`,
  typography: {
    fontsize: 16,
    htmlFontSize: 16,
    fontWeight: 400,
    // fontFamily: ['sans-serif'].join(','),
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    textRendering: 'optimizeLegibility',
    h1: {
      fontSize: 42,
      fontWeight: 600,
    },
    h2: {
      fontSize: 36,
      fontWeight: 600,
    },
    h3: {
      fontSize: 31,
      fontWeight: 600,
    },
    h4: {
      fontSize: 27,
      fontWeight: 600,
    },
    h5: {
      fontSize: 23,
      fontWeight: 400,
    },
    h6: {
      fontSize: 21,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 400,
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
    button: {
      fontSize: 16,
      textTransform: 'inherit',
    },
    caption: {
      fontSize: 14,
    },
    overline: {
      fontSize: 12,
      textTransform: 'inherit',
    },
  },
  palette: {
    primary: {
      light: teal[400], // #80cbc4
      // main: teal[400], // #26a69a
      main: '#098076',
      dark: teal[900], // #00897b
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#e65100',
      contrastText: '#fff',
    },
    companyPrimary: {
      // #9C27B0 Orchid
      100: teal[100],
      200: teal.A100,
      300: teal.A200,
      400: teal.A400,
      light: teal[400], // #80cbc4
      // main: teal[400], // #26a69a (38, 166, 154)
      main: '#098076',
      dark: teal[900], // #00897b
      contrastText: '#fff',
    },
    companySecondary: {
      // #9c27b0 Teal
      100: teal[100],
      200: teal.A100,
      300: teal.A200,
      400: teal.A400,
      light: '#ffa726', // deepOrange[500], // #00BFA5
      // main: teal[600], // #00897B
      main: '#f57c00', // #f03113
      dark: '#e65100', // deepOrange[900], // #00695C
      contrastText: '#fff',
    },
    companyTertiary: {
      // #1976D2 Blue
      100: blue[100], // #e0f4ff
      200: blue.A100, // #cbebff
      300: blue.A200, // #b7e3ff
      400: blue.A400,
      light: '#c3b322',
      main: '#827717',
      dark: '#57500f',
      contrastText: '#fff',
    },
    company: {
      danger: '#f44336',
      warning: '#ff9800',
      info: '#00acc1',
      grey: grey[600],
      lightgrey: grey[300],
      success: '#4caf50',
    },
  },
});
theme = responsiveFontSizes(theme);

const withTheme = (Component) => (props) =>
  (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Component {...props} />
    </MuiThemeProvider>
  );

export default withTheme;
