/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { CLOUDINARY_EDIT, MAX_PAGE_WIDTH } from 'constants/general';
import { SEARCH_URI } from 'constants/routes';

const one = `${CLOUDINARY_EDIT}/v1613857780/sona/svg/one.svg`;
const two = `${CLOUDINARY_EDIT}/v1613857784/sona/svg/two.svg`;
const three = `${CLOUDINARY_EDIT}/v1613857789/sona/svg/three.svg`;
const searchSvg = `${CLOUDINARY_EDIT}/v1613857789/sona/svg/hiw.search.orange.svg`;
const registerSvg = `${CLOUDINARY_EDIT}/v1613857789/sona/img/hiw-register-v1.png`;
const likeSvg = `${CLOUDINARY_EDIT}/v1613857789/sona/svg/hiw.like.orange.svg`;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
    },
  },
  gridColumnContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '50px',
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      gridGap: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'unset',
      gridColumnGap: 'unset',
      gridTemplateRows: 'repeat(3, auto)',
      gridGap: '30px',
    },
  },
  gridColumn: {
    display: 'flex',
    height: 'fit-content',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  gridRowContainer: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    maxWidth: 476,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 576,
    },
  },
  gridRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  companyPrimary: {
    color: theme.palette.companyPrimary.main,
  },
  images: {
    position: 'relative',
    margin: '0px 15px',
  },
  numbers: {
    width: 50,
    height: 50,
    maxWidth: '100%',
  },
  searchImg: {
    width: 80,
    height: 80,
    marginTop: 0,
    marginLeft: 15,
    marginBottom: 3,
    transform: 'rotate(340deg)',
    [theme.breakpoints.only('sm')]: {
      width: 80 * 0.75,
      height: 80 * 0.75,
    },
  },
  registerImg: {
    width: 200,
    height: 80,
    marginLeft: -20,
    marginTop: 3,
    [theme.breakpoints.only('sm')]: {
      width: 200 * 0.75,
      height: 80 * 0.75,
    },
  },
  likeImg: {
    width: 70,
    height: 70,
    marginTop: -10,
    marginLeft: 20,
    marginBottom: 23,
    [theme.breakpoints.only('sm')]: {
      marginTop: -7,
      marginLeft: 15,
      width: 70 * 0.75,
      height: 70 * 0.75,
    },
  },
  searchBtn: {
    borderRadius: 35,
    textTransform: 'inherit',
    fontSize: 22,
    width: '100%',
    marginTop: 10,
    padding: '4px 10px',
    [theme.breakpoints.up('md')]: {
      width: 180,
      marginLeft: 30,
    },
    backgroundColor: (props) => {
      if (props.csid === 'j619cykdqr') {
        return '#31c800';
      }
    },
  },
  link: {
    color: theme.palette.companyPrimary.main,
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': {
      color: theme.palette.companyPrimary.dark,
    },
  },
}));

const HowToEvents = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  return (
    <div id={id} ref={ref} className={classes.root}>
      <div className={classes.gridColumnContainer}>
        <div className={classes.gridColumn}>
          <div className={classes.gridRowContainer}>
            <div className={ClassNames(classes.gridRow, classes.images)}>
              <img src={one} alt='one' className={classes.numbers} />
              <img
                src={searchSvg}
                alt='magnifying glass'
                className={classes.searchImg}
              />
            </div>
            <div style={{ marginLeft: 12 }}>
              <Typography
                variant='body1'
                className={classes.text}
                align='left'
                color='inherit'
              >
                <Link to={SEARCH_URI} className={classes.link}>
                  <strong style={{ whiteSpace: 'nowrap' }}>
                    Browse the calendar
                  </strong>
                </Link>{' '}
                for upcoming events and activities.
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.gridColumn}>
          <div className={classes.gridRowContainer}>
            <div className={ClassNames(classes.gridRow, classes.images)}>
              <img src={two} alt='two' className={classes.numbers} />
              <img
                src={registerSvg}
                alt='register button'
                className={classes.registerImg}
              />
            </div>
            <div className={classes.gridRow}>
              <Typography
                variant='body1'
                className={classes.text}
                align='left'
                color='inherit'
                style={{ marginLeft: 12 }}
              >
                Mark events as a favorite, share them on social media, or
                register right from the page.
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.gridColumn}>
          <div className={classes.gridRowContainer}>
            <div className={ClassNames(classes.gridRow, classes.images)}>
              <img src={three} alt='three' className={classes.numbers} />
              <img src={likeSvg} alt='thumbs up' className={classes.likeImg} />
            </div>
            <div className={classes.gridRow}>
              <Typography
                variant='body1'
                className={classes.text}
                align='left'
                color='inherit'
                style={{ marginLeft: 12 }}
              >
                Enjoy! Tell your friends about it and invite them to join you
                next time!
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

HowToEvents.propTypes = {
  id: PropTypes.string,
};

HowToEvents.defaultProps = {
  id: '',
};

export default HowToEvents;
