/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Rings as Loader } from 'react-loader-spinner';

import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    zIndex: 0,
    display: 'inline',
  },
  smallInput: {
    padding: '10px 12px',
  },
  padding: {
    paddingRight: 40,
  },
  label: {
    width: 'max-content',
    color: theme.palette.companyPrimary.main,
    '&$focused': {
      color: theme.palette.companyPrimary.dark,
    },
  },
  error: {
    color: theme.palette.company.danger,
    '&$focused': {
      color: theme.palette.company.danger,
    },
  },
  focused: {
    outline: 'none',
    borderColor: theme.palette.companyPrimary.main,
  },
  disabled: {},
  closeGap: {
    '& > fieldset > legend > span': {
      padding: 0,
    },
  },
  outlinedInput: {
    '& $notchedOutline': {
      color: theme.palette.companyPrimary.main,
      borderColor: theme.palette.companyPrimary.main,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.companyPrimary.main,
      borderWidth: 2,
    },
    '&$focused $notchedOutline': {
      color: theme.palette.companyPrimary.main,
      borderColor: theme.palette.companyPrimary.main,
    },
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  errorOutline: {
    '& $notchedOutline': {
      color: theme.palette.companyPrimary.main,
      borderColor: theme.palette.company.danger,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.company.danger,
      borderWidth: 2,
    },
    '&$focused $notchedOutline': {
      color: theme.palette.companyPrimary.main,
      borderColor: theme.palette.company.danger,
    },
  },
  notchedOutline: {
    color: theme.palette.companyPrimary.main,
    borderColor: theme.palette.companyPrimary.main,
  },
  iconButton: {
    position: 'absolute',
    top: 19,
    right: 0,
  },
  iconButtonMulti: {
    top: 15,
  },
  iconButtonLoc: {
    top: 5,
  },
  iconButtonLoadingTop: {
    top: 18,
    padding: '12px 10px 12px 0',
  },
  iconButtonLoadingRight: {
    right: 10,
  },
  iconButtonSm: {
    top: 13,
    padding: 8,
  },
  iconButtonSmOutlined: {
    top: 16,
  },
  iconButtonStandard: {
    top: '-7px',
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0,
    },
  },
  icon: {
    color: grey[400],
    fontSize: 26,
    transition: 'opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  helperText: {
    color: grey[600],
    fontSize: 14,
    margin: '5px 12px 0',
    '&$focused': {
      color: theme.palette.companyPrimary.dark,
    },
  },
  helperError: {
    color: '#3D4542 !important',
    backgroundColor: '#FDD5CE',
    padding: '8px 12px 5px',
    margin: '-2px 0 0 0',
    borderRadius: '0 0 5px 5px',
    transition: 'all 0.2s cubic-bezier(0.5, 0, 0, 1)',
  },
  multiline: {
    padding: '18.5px 14px 18.5px 15px',
  },
}));

const CustomTextField = React.forwardRef(
  (
    {
      id,
      defaultValue,
      className,
      disabled,
      label,
      helperText,
      hideOptional,
      inputProps,
      InputProps,
      location,
      maxLength,
      multiline,
      onChange,
      showCancel,
      required,
      size,
      showLoading,
      variant,
      ...other
    },
    ref
  ) => {
    const classes = useStyles();
    const [helperValue, setHelperValue] = useState(
      helperText.replace('__TEXT_LENGTH__', defaultValue.length)
    );
    const [length, setLength] = useState(defaultValue.length || 0);

    const textInput = useRef();

    const handleCancel = (e) => {
      if (e) e.preventDefault();
      textInput.current.value = '';
      setLength(0);
      textInput.current.focus();
      if (onChange) {
        onChange(e);
      }
    };

    useEffect(() => {
      setHelperValue(
        helperText.replace('__TEXT_LENGTH__', defaultValue.length)
      );
      // eslint-disable-next-line
    }, [helperText]);

    const handleChange = (e) => {
      if (e) e.preventDefault();
      const newLength = e.target.value.length;
      setHelperValue(helperText.replace('__TEXT_LENGTH__', newLength));
      setLength(newLength);
      if (onChange) {
        onChange(e);
      }
    };

    const labelValue =
      label === '' || variant !== 'outlined' || required ? (
        label
      ) : (
        <span>
          {label}
          {hideOptional ? '' : <small> (optional)</small>}
        </span>
      );

    useEffect(() => {
      if (defaultValue === '' && textInput.current.value !== '') {
        textInput.current.value = '';
        setLength(0);
        textInput.current.focus();
      }
    }, [defaultValue]);

    return (
      <div className={className}>
        <div className={classes.root}>
          <TextField
            {...other}
            variant={variant}
            id={id}
            inputRef={ref}
            label={labelValue}
            defaultValue={defaultValue}
            onChange={handleChange}
            helperText={helperValue}
            required={required}
            disabled={disabled}
            // error={error}
            margin='normal'
            multiline={multiline}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.label,
                focused: classes.focused,
                error: classes.error,
              },
            }}
            InputProps={{
              ...InputProps,
              classes: {
                root: ClassNames({
                  [classes.outlinedInput]: !disabled,
                  [classes.closeGap]: labelValue === '',
                }),
                multiline: classes.multiline,
                input: ClassNames({
                  [classes.padding]: showCancel,
                  [classes.smallInput]: size === 'sm',
                }),
                focused: classes.focused,
                notchedOutline: ClassNames({
                  [classes.notchedOutline]: variant !== 'standard',
                }),
                error: classes.errorOutline,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
                focused: classes.focused,
                error: classes.helperError,
              },
            }}
            // eslint-disable-next-line
            inputProps={{
              ...inputProps,
              ref: textInput,
              maxLength,
            }}
          />
          {showLoading && (
            <Loader
              arialLabel='loading'
              color={grey[400]}
              height={26}
              width={26}
              wrapperClass={ClassNames(classes.iconButton, {
                [classes.iconButtonLoc]: location,
                [classes.iconButtonLoadingRight]: location,
                [classes.iconButtonLoadingTop]: variant === 'outlined',
              })}
            />
          )}
          {!showLoading && showCancel && (
            <IconButton
              onClick={handleCancel}
              disabled={disabled}
              classes={{
                root: ClassNames(classes.iconButton, {
                  [classes.iconButtonLoc]: location,
                  [classes.iconButtonSm]: size === 'sm',
                  [classes.iconButtonSmOutlined]:
                    size === 'sm' && variant === 'outlined' && label.length > 0,
                  [classes.iconButtonStandard]: variant === 'standard',
                  [classes.iconButtonMulti]: multiline,
                  [classes.iconButtonHidden]: length === 0,
                }),
              }}
              tabIndex='-1'
            >
              <ClearIcon className={classes.icon} />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
);

CustomTextField.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  hideOptional: PropTypes.bool,
  id: PropTypes.string,
  InputProps: PropTypes.instanceOf(Object),
  inputProps: PropTypes.instanceOf(Object),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  location: PropTypes.bool,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  showCancel: PropTypes.bool,
  required: PropTypes.bool,
  showLoading: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};

CustomTextField.defaultProps = {
  className: '',
  defaultValue: '',
  disabled: false,
  error: false,
  helperText: '',
  hideOptional: false,
  id: 'custom-text-input',
  InputProps: null,
  inputProps: null,
  label: '',
  location: false,
  maxLength: 200,
  multiline: false,
  onChange: null,
  showCancel: false,
  required: false,
  showLoading: false,
  size: 'lg',
  variant: 'standard',
};

export default CustomTextField;
