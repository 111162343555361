/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import algoliasearch from 'algoliasearch/lite';
import { AuthMode } from '@algolia/client-common';

const algoliaSearch = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  {
    authMode: AuthMode.WithinHeaders,
  }
);

const indexEvents = algoliaSearch.initIndex('events');

export { indexEvents };
