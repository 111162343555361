/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CategoryChips from 'components/Selects/CategoryChips';
import CustomTextField from 'components/Inputs/CustomTextField';
import CustomTextField2 from 'components/Inputs/CustomTextField2';

import { EMAIL, HELPCATEGORYLIST, getEmailRegex } from 'constants/general';
import { doGatherInPersonNeeds } from 'vendors/firebase/firestore';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 40,
    [theme.breakpoints.up('md')]: {
      paddingTop: 60,
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  spaceRight: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 10,
    },
  },
  spaceLeft: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 10,
    },
  },
  helpInputsDiv: {
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 15,
  },
  helpBtn: {
    borderRadius: 35,
    textTransform: 'inherit',
    fontSize: 22,
    width: 'fit-content',
    padding: '5px 25px',
  },
  link: {
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  error: {
    display: 'flex',
    color: theme.palette.company.danger,
    margin: '5px 0',
  },
  errorIcon: {
    marginRight: 6,
  },
}));

const GatherInPersonNeeds = React.forwardRef(
  ({ displayName, email, id, queryString, uid }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [helpTags, setHelpTags] = useState([]);
    const [helpCityState, setHelpCityState] = useState('');
    const [helpEmail, setHelpEmail] = useState('');
    const [helpComment, setHelpComment] = useState('');
    const [helpSending, setHelpSending] = useState(false);
    const [helpSubmitted, setHelpSubmitted] = useState(false);
    const [helpError, setHelpError] = useState('');

    useEffect(() => {
      setHelpEmail(email);
    }, [email]);

    useEffect(() => {
      if (queryString) {
        const queryParams = JSON.parse(queryString);
        if (
          queryParams.searchLocation &&
          queryParams.searchLocation.city &&
          queryParams.searchLocation.state
        ) {
          setHelpCityState(
            `${queryParams.searchLocation.city}, ${queryParams.searchLocation.state}`
          );
        } else {
          setHelpCityState('');
        }
      }
      // eslint-disable-next-line
    }, [queryString]);

    const validateHelpInputs = (city, email) => {
      const REGEX_EMAIL = getEmailRegex();
      let foundError;
      if (city === '') {
        foundError = 'Please enter your city and state';
      } else if (email.length > 0 && !REGEX_EMAIL.test(email)) {
        foundError = 'Please enter a valid email address';
      }
      if (foundError) {
        setHelpError(foundError);
        return false;
      } else {
        setHelpError('');
        return true;
      }
    };

    const onHelpSubmitted = () => {
      const REGEX = /\s+/gi;
      const cityStateCleaned = helpCityState.trim().replace(REGEX, ' ');
      const emailCleaned = helpEmail.trim();
      const commentCleaned = helpComment.trim();
      if (validateHelpInputs(cityStateCleaned, emailCleaned)) {
        setHelpSending(true);
        doGatherInPersonNeeds(
          emailCleaned,
          cityStateCleaned,
          helpTags.join(', '),
          commentCleaned,
          queryString
        )
          .then(() => {
            setHelpError('');
            setHelpSending(false);
            setHelpSubmitted(true);
          })
          .catch(() => {
            setHelpError(
              'Sending of your feedback failed. Please wait a moment and try again.'
            );
            setHelpSending(false);
          });
      }
    };

    return (
      <div id={id} ref={ref} className={classes.helpInputsDiv}>
        <Typography
          variant='h4'
          align='center'
          style={{ paddingTop: 15, paddingBottom: 10 }}
        >
          <strong>
            {helpSubmitted
              ? 'Thank you for your feedback!'
              : 'What can we help you find?'}
          </strong>
        </Typography>
        <Typography
          variant='body1'
          align={helpSubmitted ? 'center' : 'left'}
          style={{ paddingBottom: 10 }}
        >
          {helpSubmitted ? (
            <span>
              We will do our best to curate activities in your area. If you have
              any questions, please contact us at{' '}
              <a
                href={`mailto:${EMAIL}`}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.link}
              >
                {EMAIL}
              </a>
            </span>
          ) : (
            'Let us know what type of local, in-person events you are interested in and we will reach out to activity organizers in your area.'
          )}
        </Typography>
        <Grid
          container
          alignItems='center'
          direction='row'
          justifyContent='center'
        >
          {helpSubmitted && (
            <Grid item xs={12} style={{ marginTop: 10, textAlign: 'center' }}>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => setHelpSubmitted(false)}
                className={classes.helpBtn}
              >
                Submit additional feedback
              </Button>
            </Grid>
          )}
          {!helpSubmitted && (
            <>
              <Grid item xs={12} style={{ marginTop: 0 }}>
                <CategoryChips
                  id='help-tags'
                  // size='sm'
                  maxColumns={3}
                  fullWidth
                  hideIcon
                  tagList={HELPCATEGORYLIST}
                  initTags={helpTags}
                  onChange={(newTags) => setHelpTags(newTags)}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.spaceRight}>
                <CustomTextField
                  variant='outlined'
                  id='helpCityState'
                  label='Location'
                  placeholder='E.g. City, State, Country'
                  required
                  fullWidth
                  value={helpCityState}
                  onChange={(e) => setHelpCityState(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.spaceLeft}>
                <CustomTextField
                  variant='outlined'
                  id='helpEmail'
                  label='Your email address'
                  placeholder='Your email address'
                  fullWidth
                  value={helpEmail}
                  onChange={(e) => setHelpEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField2
                  variant='outlined'
                  id='helpComment'
                  label={
                    xsScreen
                      ? 'What else are you looking for?'
                      : 'Tell us more about what you are you looking for?'
                  }
                  defaultValue={helpComment}
                  error={helpError.comment}
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={100}
                  onChange={(e) => setHelpComment(e.target.value)}
                />
              </Grid>
              {helpError && (
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    color='error'
                    className={classes.error}
                  >
                    <ErrorIcon className={classes.errorIcon} /> {helpError}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Button
                  variant='contained'
                  color='secondary'
                  endIcon={
                    helpSending ? (
                      <CircularProgress
                        color='inherit'
                        size={30}
                        className={classes.progress}
                      />
                    ) : null
                  }
                  onClick={onHelpSubmitted}
                  className={classes.helpBtn}
                  style={{ paddingRight: helpSending ? 15 : 25 }}
                >
                  Submit feedback
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
);

GatherInPersonNeeds.propTypes = {
  displayName: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  queryString: PropTypes.string,
  uid: PropTypes.string,
};

GatherInPersonNeeds.defaultProps = {
  displayName: '',
  email: '',
  id: '',
  queryString: '',
  uid: '',
};

export default GatherInPersonNeeds;
