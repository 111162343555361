/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Hidden from '@material-ui/core/Hidden';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

import CustomDropdown from 'components/Inputs/CustomDropdown';
import CategoryChips from 'components/Selects/CategoryChips';
import CategoryList from 'components/Selects/CategoryList';
import LocationInput from 'components/Inputs/LocationInput';
import RadioList from 'components/Selects/RadioList';
import CheckboxList from 'components/Selects/CheckboxList';
import DateSelector from 'components/Selects/DateSelector';

import { SEARCH_URI } from 'constants/routes';
import { CATEGORYLIST, PRICELIST, OTHERFILTERSLIST } from 'constants/general';
import { getDayInMinutes, getDayTypeId, verifyDayType } from 'utilities/utils';
import { useLocalState } from 'hooks/useStorageState';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 8px)',
    maxWidth: 1024,
    marginBottom: 20,
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(0)}`,
    borderRadius: 5,
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 20px)',
      padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(0)}`,
      marginLeft: 10,
      marginRight: 10,
    },
  },
  gridColumnContainer: {
    display: 'grid',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  gridColumnTop: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '20px',
    maxHeight: 88,
  },
  gridColumnBottom: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridColumnGap: 0,
  },
  gridColumn: {
    justifyContent: 'left',
  },
  gridColumnFilters: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr auto',
    paddingTop: 5,
    minHeight: 15,
  },
  gridSpacing: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
    },
  },
  gridFilters: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.57)',
    paddingBottom: 0,
  },
  datePicker: {
    marginTop: -2,
    width: '100%',
    '& > div': {
      font: '600 1.29rem sans-serif',
      color: theme.palette.companyPrimary.dark,
      '&::before': {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: `${theme.palette.companyPrimary.dark} !important`,
      },
    },
  },
  searchInput: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      minWidth: 400,
    },
    '& > input': {
      font: '600 1.29rem sans-serif',
      color: theme.palette.companyPrimary.dark,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: `${theme.palette.companyPrimary.dark} !important`,
      '&:hover': {
        borderBottomWidth: 2,
      },
    },
  },
  searchBtn: {
    borderRadius: 35,
    textTransform: 'inherit',
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    border: '1px solid white',
    padding: '12px 16px',
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.up('md')]: {
      width: 215,
      marginLeft: 20,
      marginTop: -9,
    },
    backgroundColor: (props) => {
      if (props.csid === 'j619cykdqr') {
        return '#31c800';
      }
    },
  },
  icon: {
    marginRight: 5,
  },
  paddingBottom: {
    paddingBottom: 20,
  },
  formControl: {
    minWidth: 298,
    marginTop: -2,
    marginBottom: 5,
  },
  formControlRoot: {
    minWidth: 87,
    marginLeft: -8,
    marginRight: 10,
  },
  formControlLabel: {
    fontWeight: '700',
    color: 'rgba(0, 0, 0, 0.57)',
    fontSize: 14,
    lineHeight: '1',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  radioBtn: {
    padding: 5,
    color: (props) => {
      if (props.csid === 'j619cykdqr') {
        return '#3a3a3a';
      } else {
        return theme.palette.companyPrimary.main;
      }
    },
  },
  chip: {
    backgroundColor: theme.palette.companyPrimary.light,
    color: theme.palette.common.white,
    margin: 5,
    height: 24,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    '& svg': {
      fontSize: 18,
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.company.lightgrey,
      },
    },
  },
  navLink: {
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    textDecoration: 'underline',
    textTransform: 'none',
    width: 'fit-content',
    justifyContent: 'flex-start',
    color: theme.palette.company.grey,
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
  },
  filtersPadding: {
    paddingBottom: 5,
  },
}));

const arrayRemove = (arr, value) => arr.filter((ele) => ele !== value);

const OTHERFILTERSLIST_FULL = OTHERFILTERSLIST.map((ele) => ({
  key: ele,
  name: ele,
}));

const AdvancedSearchView = React.forwardRef(
  (
    {
      id,
      className,
      customSearchId,
      history,
      date,
      dayType,
      venue,
      keywords: searchKeywords,
      price: searchPrice,
      tags: searchTags,
      otherFilters: searchOtherFilters,
    },
    ref
  ) => {
    const classes = useStyles({ csid: customSearchId });
    const [locInperson, setLocInperson] = useState(false);
    const [locOnline, setLocOnline] = useState(true);
    const [locPhone, setLocPhone] = useState(true);
    const [searchTypes, setSearchTypes] = useLocalState(
      {
        inperson: false,
        online: true,
        phone: true,
      },
      'searchType'
    );
    const [address, setAddress] = useLocalState(
      {
        formattedAddress: '',
        city: '',
        state: '',
        country: '',
        lat: '',
        lng: '',
        placeId: '',
      },
      'location',
      {
        formattedAddress: venue.formattedAddress,
        city: venue.city,
        state: venue.state,
        country: venue.country,
        lat: venue.lat,
        lng: venue.lng,
        placeId: venue.placeId,
      }
    );
    const [errors, setErrors] = useState({
      address: '',
    });
    // eslint-disable-next-line no-unused-vars
    const [_errorChange, setErrorChange] = useState(false);
    const [selectedDay, setSelectedDay] = useState({
      type: 'today',
      date: new Date(),
    });

    const [tags, setTags] = useState(searchTags);
    const [price, setPrice] = useState(
      PRICELIST.filter((p) => p.key === searchPrice)[0]
    );
    const [otherFilters, setOtherFilters] = useState(searchOtherFilters);
    const [manKeywords, setManKeywords] = useState(searchKeywords);

    const keywordsRef = useRef();

    useEffect(() => {
      setTags(searchTags);
      // eslint-disable-next-line
    }, [searchTags]);

    useEffect(() => {
      setPrice(PRICELIST.filter((p) => p.key === searchPrice)[0]);
      // eslint-disable-next-line
    }, [searchPrice]);

    useEffect(() => {
      setOtherFilters(searchOtherFilters);
      // eslint-disable-next-line
    }, [searchOtherFilters]);

    useEffect(() => {
      setManKeywords(searchKeywords);
      // eslint-disable-next-line
    }, [searchKeywords]);

    useEffect(() => {
      const type = verifyDayType(date, dayType);
      setSelectedDay({
        type,
        date: new Date(date * 60000),
      });
      // eslint-disable-next-line
    }, [date, dayType]);

    const onCategoryChange = (newTags) => {
      setTags(newTags);
    };
    const onCategoryDelete = (tag) => {
      const newTags = arrayRemove(tags, tag);
      setTags(newTags);
      onSearch(null, newTags, price.key, otherFilters);
    };

    const onPriceChange = (newPrice) => {
      setPrice(PRICELIST.filter((p) => p.key === newPrice)[0]);
    };
    const onPriceDelete = () => {
      setPrice(PRICELIST.filter((p) => p.key === 'any')[0]);
      onSearch(null, tags, 'any', otherFilters);
    };

    const onOtherFilterChange = (newOtherFilter, checked) => {
      if (otherFilters.includes(newOtherFilter)) {
        setOtherFilters((prev) => arrayRemove(prev, newOtherFilter));
      } else {
        setOtherFilters((prev) => prev.concat(newOtherFilter));
      }
    };
    const onOtherFiltersDelete = (otherFilter) => {
      const newOtherFilters = arrayRemove(otherFilters, otherFilter);
      setOtherFilters(newOtherFilters);
      onSearch(null, tags, price.key, newOtherFilters);
    };

    const validateLocation = (location) => {
      const newErrors = {};
      newErrors.address =
        // eslint-disable-next-line no-nested-ternary
        location === null || location.city === ''
          ? 'Begin entering city or zip code and then select your city, state from provided list once it appears.'
          : !location.lat || !location.lng
          ? 'Please check your city or zip code and try again.'
          : '';

      const hasErrors = Object.values(newErrors).some(
        (error) => error.length > 0
      );
      setErrors(newErrors);
      if (hasErrors) {
        setErrorChange((prev) => !prev);
      }
      return !hasErrors;
    };

    const onSearch = (
      filterKeywords,
      filterTags,
      filterPrice,
      filterOthers
    ) => {
      if (locInperson && !validateLocation(address)) {
        return;
      } else {
        setErrors({ address: '' });
      }
      const selectedDayInMins = getDayInMinutes(selectedDay.date);
      let queryString = `?l=${locInperson ? 'i' : ''}${locOnline ? 'o' : ''}${
        locPhone ? 'p' : ''
      }&d=${selectedDayInMins}`;
      const type = getDayTypeId(selectedDay.type);
      if (type) {
        queryString = `${queryString}&n=${type}`;
      }
      if (locInperson) {
        queryString = `${queryString}&g=${encodeURIComponent(address.placeId)}`;
      }
      const keywords =
        filterKeywords !== null ? filterKeywords : keywordsRef.current.value;
      if (keywords !== '') {
        queryString = `${queryString}&q=${encodeURIComponent(keywords)}`;
      }
      if (
        (filterTags && filterTags.length > 0) ||
        (filterOthers && filterOthers.length > 0)
      ) {
        queryString = `${queryString}&t=${encodeURIComponent(
          filterTags.concat(filterOthers).toString()
        )}`;
      }
      if (filterPrice !== 'any') {
        queryString = `${queryString}&p=${filterPrice === 'free' ? '1' : '0'}`;
      }
      if (customSearchId) {
        queryString = `${queryString}&csid=${customSearchId}`;
      }
      history.push({
        pathname: SEARCH_URI,
        search: queryString,
      });
    };

    useEffect(() => {
      if (venue && venue.placeId && venue.placeId !== address.placeId) {
        setAddress({
          formattedAddress: venue.formattedAddress,
          city: venue.city,
          state: venue.state,
          country: venue.country,
          lat: venue.lat,
          lng: venue.lng,
          placeId: venue.placeId,
        });
      }
      if (venue.inperson || venue.online || venue.phone) {
        setSearchTypes({
          inperson: venue.inperson,
          online: venue.online,
          phone: venue.phone,
        });
        setLocInperson(venue.inperson);
        setLocOnline(venue.online);
        setLocPhone(venue.phone);
      } else if (searchTypes) {
        setLocInperson(searchTypes.inperson || false);
        setLocOnline(searchTypes.online || false);
        setLocPhone(searchTypes.phone || false);
      } else {
        setLocInperson(false);
        setLocOnline(true);
        setLocPhone(true);
      }
      // eslint-disable-next-line
    }, [
      venue,
      venue.inperson,
      venue.online,
      venue.phone,
      venue.formattedAddress,
      venue.city,
      venue.state,
      venue.country,
      venue.lat,
      venue.lng,
      venue.placeId,
    ]);

    const onLocationTypeChange = (newType) => {
      let newLocInperson;
      let newLocOnline;
      let newLocPhone;
      if (newType === 'inperson') {
        newLocInperson = true;
        newLocOnline = false;
        newLocPhone = false;
      } else if (newType === 'online') {
        newLocInperson = false;
        newLocOnline = true;
        if (locInperson) {
          newLocPhone = false;
        } else if (locOnline) {
          newLocPhone = false;
        } else {
          newLocPhone = true;
        }
      } else if (newType === 'phone') {
        newLocInperson = false;
        if (locInperson) {
          newLocOnline = false;
        } else if (locPhone) {
          newLocOnline = false;
        } else {
          newLocOnline = true;
        }
        newLocPhone = true;
      }
      setLocInperson(newLocInperson);
      setLocOnline(newLocOnline);
      setLocPhone(newLocPhone);
      setSearchTypes({
        inperson: newLocInperson,
        online: newLocOnline,
        phone: newLocPhone,
      });
      if (newType !== 'inperson') {
        if (address.city === '' || address.lat === '' || address.lng === '') {
          setErrors({ address: '' });
          setErrorChange((prev) => !prev);
          setAddress({
            formattedAddress: '',
            city: '',
            state: '',
            country: '',
            lat: '',
            lng: '',
            placeId: '',
          });
        }
      }
    };

    const onLocationChange = (
      location = {
        formattedAddress: '',
        city: '',
        state: '',
        country: '',
        lat: '',
        lng: '',
        placeId: '',
      },
      search = false
    ) => {
      const newLocation = {
        formattedAddress: location.formattedAddress,
        city: location.city,
        state: location.state,
        country: location.country,
        lat: location.lat,
        lng: location.lng,
        placeId: location.placeId,
      };
      setAddress(newLocation);
      if (newLocation.lat && newLocation.lng) {
        validateLocation(newLocation);
      }
    };

    const handleKeyUp = (e) => {
      if (e) e.preventDefault();
      if (e.charCode === 13 || e.key === 'Enter') {
        onEnter();
      }
    };

    const handleDateChange = (newDay) => {
      setSelectedDay(newDay);
    };

    const enableLocationType = () => onLocationTypeChange('inperson');

    const clearFilters = () => {
      // setKeywords('');
      keywordsRef.current.value = '';
      setTags([]);
      setPrice(PRICELIST.filter((p) => p.key === 'any')[0]);
      setOtherFilters([]);
      onSearch('', [], 'any', []);
    };

    const onEnter = () => {
      onSearch(null, tags, price.key, otherFilters);
    };

    return (
      <Paper
        id={id}
        ref={ref}
        className={ClassNames(
          classes.root,
          {
            [classes.filtersPadding]:
              tags.length || price.key !== 'any' || otherFilters.length,
          },
          className
        )}
        elevation={12}
      >
        <div
          className={ClassNames(
            classes.gridColumnContainer,
            classes.gridColumnTop,
            classes.paddingBottom
          )}
        >
          <div className={classes.gridColumn}>
            <Typography
              variant='body1'
              display='block'
              align='center'
              className={classes.header}
            >
              <strong>I want to do something</strong>
            </Typography>
            <DateSelector value={selectedDay} onChange={handleDateChange} />
          </div>
          <div className={ClassNames(classes.gridColumn, classes.gridSpacing)}>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormGroup
                row
                aria-label='location type'
                name='locationType'
                style={{ flexWrap: 'unset', flexDirection: 'row' }}
                onChange={(e) => onLocationTypeChange(e.target.value)}
              >
                <FormControlLabel
                  value='inperson'
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      color='default'
                      checked={locInperson}
                      classes={{
                        root: classes.radioBtn,
                      }}
                    />
                  }
                  label='In Person'
                  classes={{
                    root: classes.formControlRoot,
                    label: classes.formControlLabel,
                  }}
                />
                <FormControlLabel
                  value='online'
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      color='default'
                      checked={locOnline}
                      classes={{
                        root: classes.radioBtn,
                      }}
                    />
                  }
                  label='Online'
                  classes={{
                    root: classes.formControlRoot,
                    label: classes.formControlLabel,
                  }}
                />
                <FormControlLabel
                  value='phone'
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      color='default'
                      checked={locPhone}
                      classes={{
                        root: classes.radioBtn,
                      }}
                    />
                  }
                  label='By Phone'
                  classes={{
                    root: classes.formControlRoot,
                    label: classes.formControlLabel,
                  }}
                  style={{ marginRight: 0 }}
                />
              </FormGroup>
            </FormControl>
            {address && (
              <LocationInput
                id='advanced-search-location'
                variant='standard'
                required
                initValue={{
                  street: '',
                  formattedAddress: address.formattedAddress,
                  city: address.city,
                  state: address.state,
                  country: address.country,
                  lat: address.lat,
                  lng: address.lng,
                  placeId: address.placeId,
                }}
                disabled={!locInperson}
                helperText={`${errors.address !== '' ? errors.address : ''}`}
                error={errors.address.length > 0}
                className={classes.datePicker}
                onChange={onLocationChange}
                onCancel={onLocationChange}
                onClick={enableLocationType}
                onEnter={onEnter}
                fullWidth
              />
            )}
          </div>
          <div className={ClassNames(classes.gridColumn, classes.gridSpacing)}>
            <Typography
              variant='body1'
              display='block'
              align='center'
              className={classes.header}
            >
              <strong>I'm interested in</strong>
            </Typography>
            <div
              style={{
                display: 'inline-flex',
                marginTop: 5,
                width: '100%',
              }}
            >
              <InputBase
                className={classes.searchInput}
                placeholder='Optional keywords...'
                value={manKeywords}
                onChange={(event) => {
                  setManKeywords(event.target.value);
                }}
                onKeyUp={handleKeyUp}
                inputProps={{
                  ref: keywordsRef,
                  'aria-label': 'keyword search',
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={ClassNames(
            classes.gridColumnContainer,
            classes.gridColumnBottom,
            classes.gridSpacing
          )}
        >
          <div className={classes.gridColumn}>
            <Hidden smDown>
              <CustomDropdown
                buttonText='Categories'
                groupPos='left'
                dropPlacement='bottom-start'
                width='700px'
                onApply={onEnter}
                dropdownNode={
                  <CategoryChips
                    id='search-tags'
                    size='sm'
                    maxColumns={3}
                    fullWidth
                    hideIcon
                    tagList={CATEGORYLIST}
                    initTags={tags}
                    onChange={onCategoryChange}
                  />
                }
              />
            </Hidden>
            <Hidden mdUp>
              <CustomDropdown
                buttonText='Categories'
                useDrawer
                dropPlacement='bottom-start'
                onApply={onEnter}
                dropdownHeader='Categories'
                dropdownNode={
                  <CategoryList
                    id='search-tags'
                    color='default'
                    size='sm'
                    fullWidth
                    hideIcon
                    value={tags}
                    onChange={onCategoryChange}
                    // tabIndex={TABINDEX.CAT}
                  />
                }
              />
            </Hidden>
          </div>
          <div className={classes.gridColumn}>
            <Hidden smDown>
              <CustomDropdown
                buttonText='Price'
                groupPos='middle'
                dropPlacement='bottom-start'
                onApply={onEnter}
                dropdownNode={
                  <RadioList
                    id='search-price'
                    listItems={PRICELIST}
                    initKey={price}
                    onChange={onPriceChange}
                  />
                }
              />
            </Hidden>
            <Hidden mdUp>
              <CustomDropdown
                buttonText='Price'
                useDrawer
                dropPlacement='bottom-start'
                onApply={onEnter}
                dropdownHeader='Price'
                dropdownNode={
                  <RadioList
                    id='search-price'
                    listItems={PRICELIST}
                    initKey={price}
                    onChange={onPriceChange}
                  />
                }
              />
            </Hidden>
          </div>
          <div className={classes.gridColumn}>
            <Hidden smDown>
              <CustomDropdown
                buttonText='Other'
                groupPos='right'
                dropPlacement='bottom-start'
                onApply={onEnter}
                dropdownNode={
                  <CheckboxList
                    id='search-price'
                    listItems={OTHERFILTERSLIST_FULL}
                    initSelectedItems={otherFilters}
                    onChange={onOtherFilterChange}
                  />
                }
              />
            </Hidden>
            <Hidden mdUp>
              <CustomDropdown
                buttonText='Other'
                useDrawer
                dropPlacement='bottom-start'
                onApply={onEnter}
                dropdownHeader='Other'
                dropdownNode={
                  <CheckboxList
                    id='search-price'
                    listItems={OTHERFILTERSLIST_FULL}
                    initSelectedItems={otherFilters}
                    onChange={onOtherFilterChange}
                  />
                }
              />
            </Hidden>
          </div>
          <div className={classes.gridColumn} style={{ textAlign: 'end' }}>
            <Button
              variant='contained'
              color='secondary'
              onClick={onEnter}
              className={classes.searchBtn}
            >
              <SearchIcon className={classes.icon} />
              Search
            </Button>
          </div>
        </div>
        <div className={classes.gridColumnFilters}>
          {tags.length === 0 &&
          price.key === 'any' &&
          otherFilters.length === 0 &&
          manKeywords.length === 0 ? (
            <div></div>
          ) : (
            <>
              <div className={classes.gridFilters}>
                <div style={{ textAlign: 'left' }}>
                  {tags.map((cat) => (
                    <Chip
                      key={cat}
                      color='default'
                      label={cat}
                      className={classes.chip}
                      deleteIcon={<ClearIcon />}
                      onDelete={() => onCategoryDelete(cat)}
                    />
                  ))}
                  {price.key !== 'any' && (
                    <Chip
                      key={price.key}
                      color='default'
                      label={price.name}
                      className={classes.chip}
                      deleteIcon={<ClearIcon />}
                      onDelete={onPriceDelete}
                    />
                  )}
                  {otherFilters.map((cat) => (
                    <Chip
                      key={cat}
                      color='default'
                      label={cat}
                      className={classes.chip}
                      deleteIcon={<ClearIcon />}
                      onDelete={() => onOtherFiltersDelete(cat)}
                    />
                  ))}
                </div>
              </div>
              <div
                className={classes.gridFilters}
                style={{ alignItems: 'flex-start' }}
              >
                <Button
                  color='default'
                  onClick={clearFilters}
                  className={classes.navLink}
                >
                  Clear filters
                </Button>
              </div>
            </>
          )}
        </div>
      </Paper>
    );
  }
);

AdvancedSearchView.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  customSearchId: PropTypes.string,
  date: PropTypes.string,
  dayType: PropTypes.string,
  id: PropTypes.string,
  keywords: PropTypes.string,
  venue: PropTypes.instanceOf(Object),
  price: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  otherFilters: PropTypes.arrayOf(PropTypes.string),
};

AdvancedSearchView.defaultProps = {
  className: '',
  customSearchId: '',
  date: null,
  dayType: '',
  id: '',
  keywords: '',
  venue: {
    inperson: undefined,
    online: undefined,
    phone: undefined,
    formattedAddress: '',
    city: '',
    state: '',
    country: '',
    lat: '',
    lng: '',
    placeId: '',
  },
  price: 'any',
  tags: [],
  otherFilters: [],
};

export default withRouter(AdvancedSearchView);
