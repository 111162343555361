/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { dropByCacheKey } from 'react-router-cache-route';

import { SEARCH_CACHE_KEY } from 'constants/general';

const ScrollToTop = ({ children }) => {
  useEffect(() => {
    dropByCacheKey(SEARCH_CACHE_KEY);
    window.scrollTo(0, 0);
  }, []);

  return children;
};

ScrollToTop.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollToTop;
