/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Footer from 'components/Footer';
import Header from 'components/Header';
import { SITEURL } from 'constants/general';
import { CONTACTUS_URI, EVENT_ORGANIZERS_URI } from 'constants/routes';

import HowToAddEvent from 'components/HowToAddEvent';
import HowToMarketing from 'components/HowToMarketing';
import HowToFAQ from 'components/HowToFAQ';
import OrganizerFreeTips from 'components/OrganizerFreeTips';
// import PricingCards from 'components/Pricing/PricingCards';
import SubscribeBanner from 'components/SubscribeBanner';
import SupportTutorials from 'components/SupportTutorials';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
  },
  content: {
    flex: 1,
    maxWidth: 1280,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'visible',
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    },
  },
  link: {
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
}));

const EventOrganizers = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>
          List older adult classes- senior activities: A Mighty Good Time
        </title>
        <link rel='canonical' href={SITEURL.concat(EVENT_ORGANIZERS_URI)} />
        <meta
          name='description'
          content='How to list events, senior activities and interactive experiences for older adults in your area.'
        />
      </Helmet>
      <main id={id} ref={ref} className={classes.root}>
        <Header />
        <SubscribeBanner />
        <div className={ClassNames(classes.content, 'page-transition fade-in')}>
          <Grid container>
            <Grid
              item
              xs={10}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
              <Typography
                variant='h2'
                align='center'
                color='secondary'
                style={{ paddingBottom: 20 }}
              >
                <strong>Grow your events. Grow your community.</strong>
              </Typography>
              <div
                style={{
                  maxWidth: 730,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Typography
                  variant='h6'
                  align='center'
                  style={{ paddingBottom: 30 }}
                >
                  <strong>
                    Do you provide life-enriching events, classes, or programs
                    to older adults? Add them to the calendar for FREE and we'll
                    spread the word!
                  </strong>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              {/* <PricingCards /> */}
              <div
                style={{
                  maxWidth: 800,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Typography
                  variant='body1'
                  align='center'
                  style={{ paddingBottom: 30 }}
                >
                  We also offer customized software solutions for agencies and
                  organizations that want to foster healthy and happy aging
                  within their community. For partnership inquiries, please{' '}
                  <Link
                    to={{
                      pathname: CONTACTUS_URI,
                      state: {
                        message: 'Partnerships.',
                      },
                    }}
                    className={classes.link}
                  >
                    contact us
                  </Link>
                  .
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <HowToAddEvent />
            </Grid>
            <Grid item xs={12}>
              <HowToMarketing />
            </Grid>
            <Grid item xs={12}>
              <OrganizerFreeTips />
            </Grid>
            <Grid item xs={12}>
              <HowToFAQ />
            </Grid>
            <Grid item xs={12}>
              <SupportTutorials />
            </Grid>
          </Grid>
        </div>
        <Footer />
      </main>
    </>
  );
});

EventOrganizers.propTypes = {
  id: PropTypes.string,
};

EventOrganizers.defaultProps = {
  id: '',
};

export default EventOrganizers;
