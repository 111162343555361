/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
const bandedDomains = [
  '23minutemail.it',
  '247web.net',
  'armspy.com',
  'cuvox.de',
  'dayrep.com',
  'dispostable.com',
  'einrot.com',
  'emltmp.com',
  'fleckens.hu',
  'getnada.com',
  'grr.la',
  'guerrillamail.biz',
  'guerrillamail.com',
  'guerrillamail.de',
  'guerrillamail.info',
  'guerrillamail.net',
  'guerrillamail.org',
  'guerrillamailblock.com',
  'gustr.com',
  'hugedomains.com',
  'itfast.net',
  'jourrapide.com',
  'maildrop.cc',
  'mailinator.com',
  'pokemail.net',
  'rhyta.com',
  'sharklasers.com',
  'sillver.us',
  'spam4.me',
  'superrito.com',
  'teleworm.us',
  'urhen.com',
  'wimsg.com',
];

export default function isBannedEmail(email) {
  const ind = email.indexOf('@');
  const emailDomain = email.substr(ind + 1);
  return bandedDomains.includes(emailDomain);
}
