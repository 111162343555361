/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'react-datepicker2';
import moment from 'moment-timezone';
import { rrulestr } from 'rrule';
import { DateTime } from 'luxon';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';

import { defaultTimezone } from 'utilities/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#F2F5F4',
  },
  content: {
    [theme.breakpoints.only('xs')]: {
      padding: '16px 0',
    },
  },
  openBtn: {
    height: 20,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    padding: '0 6px',
    textTransform: 'inherit',
    verticalAlign: 'text-bottom',
    marginLeft: 8,
    '& > span': {
      height: 18,
    },
  },
  linkBtn: {
    textTransform: 'inherit',
    textDecoration: 'underline',
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    padding: '0 10px',
    [theme.breakpoints.only('sm')]: {
      padding: '0 0 0 10px',
    },
    color: theme.palette.companyPrimary.light,
    '&:hover': {
      color: theme.palette.companyPrimary.main,
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  calendar: {
    boxShadow: 'none',
    width: '100%',
    maxWidth: 400,
    paddingTop: 0,
    '& * .highLightDot-container': {
      bottom: 'unset',
      top: 0,
    },
    '& * .selected button': {
      backgroundColor: theme.palette.companySecondary.light,
      color: theme.palette.common.black,
      border: `4px solid #222 !important`,
    },
    '& * .dayWrapper button': {
      position: 'inherit',
      background: 'transparent',
      zIndex: '1',
      pointerEvents: 'none',
      borderRadius: 5,
    },
    '& .highLightDot': {
      pointerEvents: 'auto',
      display: 'inline-block',
      position: 'relative',
      height: 40,
      width: 40,
      borderRadius: 5,
    },
  },
  legend: {
    paddingLeft: 20,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 50,
    },
  },
}));

const AltDatesDialog = React.forwardRef(
  ({ id, currDate, dashboard, onChange, favoriteDates }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const currLocal = DateTime.local().set({ hour: 0, minute: 0, second: 0 });
    const currEventDate = DateTime.fromMillis(
      currDate.startDate * 60000
    ).setZone(currDate.timezone);
    const currLocalDate = DateTime.local(
      currLocal.year,
      currLocal.month,
      1,
      0,
      0,
      0
    );
    const endTime = currLocalDate.plus({ month: 7 });
    const [open, setOpen] = useState(false);
    const [eventDates, setEventDates] = useState([]);
    const [availableDateTimeLookup, setAvailableDateTimeLookup] = useState([]);

    const onOpen = () => {
      const eventDayTimes = [];
      const { dates, timezone, recurrance, recurranceExceptions } = currDate;
      let lastDate = DateTime.fromMillis(dates[0].start * 60000).setZone(
        timezone
      );
      lastDate = lastDate.set({ hour: 0, minute: 0, second: 0 });
      let actualDate;
      dates.forEach((date) => {
        actualDate = DateTime.fromMillis(date.start * 60000).setZone(timezone);
        const tempDate = actualDate.set({ hour: 0, minute: 0, second: 0 });
        const { days } = tempDate.diff(lastDate, 'days').toObject();
        eventDayTimes.push({
          hour: actualDate.hour,
          minute: actualDate.minute,
          diff: days,
        });
        lastDate = tempDate;
      });
      var rRule = rrulestr(recurrance);
      const dateTimeLookup = [];
      const generatedEventDates = [];
      rRule
        .between(rRule.options.dtstart, endTime.toJSDate(), true)
        .forEach((ruleDate) => {
          const dt1 = DateTime.fromJSDate(ruleDate).setZone('utc');
          let favDate = dt1.setZone(timezone, {
            keepLocalTime: true,
          });
          let tempDate = dt1.setZone(timezone, {
            keepLocalTime: true,
          });
          const recureException = recurranceExceptions
            ? recurranceExceptions[Math.round(tempDate.toMillis() / 60000)]
            : undefined;
          if (recureException) {
            tempDate = DateTime.fromMillis(recureException * 60000).setZone(timezone);
            favDate = tempDate;
          }
          tempDate = tempDate.setZone(defaultTimezone);
          eventDayTimes.forEach((dateTime) => {
            favDate = favDate.plus({ day: dateTime.diff });
            favDate = favDate.set({
              hour: dateTime.hour,
              minute: dateTime.minute,
              second: 0,
            });
            let favDateInMins = Math.round(favDate.toMillis() / 60000);
            tempDate = tempDate.plus({ day: dateTime.diff });
            tempDate = tempDate.set({
              hour: dateTime.hour,
              minute: dateTime.minute,
              second: 0,
            });
            generatedEventDates.push({
              color: favoriteDates.includes(favDateInMins)
                ? '#ff8a80'
                : '#4dd0e1',
              start: moment(tempDate.toISO()),
              end: moment(tempDate.toISO()),
            });
            const keyDate = tempDate.set({ hour: 0, minute: 0, second: 0 });
            dateTimeLookup.push({
              keyDate: Math.round(keyDate.toMillis() / 60000),
              startDate: favDateInMins,
            });
          });
        });
      setEventDates(generatedEventDates);
      setAvailableDateTimeLookup(dateTimeLookup);
      setOpen(true);
    };
    const onClose = () => setOpen(false);

    const handleChange = (momentDate) => {
      const newTimeInMins = Math.round(momentDate.valueOf() / 60000);
      const availableDateTime = availableDateTimeLookup.find(
        (ele) => ele.keyDate === newTimeInMins
      );
      onChange(availableDateTime.startDate);
      setOpen(false);
    };

    return (
      <>
        {/* <Button
          id={id}
          ref={ref}
          variant='outlined'
          onClick={onOpen}
          color='primary'
          className={classes.openBtn}
        >
          See all available dates for this event
        </Button> */}
        <Button
          id={id}
          ref={ref}
          color='primary'
          onClick={onOpen}
          className={classes.linkBtn}
        >
          See all available dates for this event
        </Button>
        <Dialog
          fullScreen={fullScreen}
          maxWidth='lg'
          open={open}
          onClose={onClose}
          aria-labelledby='recurring event dates'
        >
          <DialogTitle
            id='recurring-event-dates'
            onClose={onClose}
            style={{ paddingBottom: 0 }}
          >
            All available dates {fullScreen ? '' : 'for this event'}
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <div className={classes.legend}>
            <span
              style={{
                width: 40,
                height: 40,
                backgroundColor: '#ff8a80',
                display: 'inline-block',
                borderRadius: 5,
                padding: 9,
                textAlign: 'center',
                border: '1px solid #fff',
              }}
            >
              1
            </span>
            <span style={{ paddingLeft: 6 }}>
              <strong>= a date you marked as favorite</strong>
            </span>
            <FavoriteIcon
              style={{ color: '#e53935', verticalAlign: 'text-bottom' }}
            />
          </div>
          <div
            className={classes.legend}
            style={{ paddingTop: 4, paddingBottom: 16 }}
          >
            <span
              style={{
                width: 40,
                height: 40,
                backgroundColor: '#4dd0e1',
                display: 'inline-block',
                borderRadius: 5,
                padding: 9,
                textAlign: 'center',
                border: '1px solid #fff',
              }}
            >
              1
            </span>
            <span style={{ paddingLeft: 6 }}>
              <strong>= additional event dates</strong>
            </span>
          </div>
          <DialogContent dividers className={classes.content}>
            {open && (
              <Calendar
                showTodayButton={false}
                ranges={eventDates}
                min={moment().subtract(1, 'day')}
                max={moment().add(1, 'year')}
                value={moment(currEventDate.toISO({ includeOffset: false }))}
                selectedDay={moment(
                  currEventDate.toISO({ includeOffset: false })
                )}
                onSelect={dashboard ? null : handleChange}
                className={classes.calendar}
              />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
);

AltDatesDialog.propTypes = {
  currDate: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  dashboard: PropTypes.bool,
  id: PropTypes.string,
  favoriteDates: PropTypes.arrayOf(PropTypes.number),
};

AltDatesDialog.defaultProps = {
  dashboard: false,
  id: '',
  favoriteDates: [],
};

export default AltDatesDialog;
