/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
// TODO: Move membership definitions including Stripe price IDs into DB so
//       that they are shared across Client, Admin, and Cloud Functions.
export const MEMBER_PLANS = {
  NONE: 0,
  USER: 10,
  PER_EVENT: 20, // $4.99 per event
  BUNDLE_5: 2005, // 5+ events. $19.99 per month
  BUNDLE_10: 2010, // 10+ events. $29.99 per month
  BUNDLE_20: 2020, // 20+ events. $39.99 per month
  MANAGED_1: 7001, // 1 Managed event. $7.99 per event
  MANAGED_20: 7020, // 20 Managed events. $139.99 per event
};

export const memberPlanDetails = (plan) => {
  let details;
  switch (plan) {
    case MEMBER_PLANS.USER:
      details = {
        name: 'User',
        pricePerMonth: 'free',
        discountedPricePerMonth: 'free',
        pricePerAddEvent: '$4.99',
        discountedPricePerAddEvent: '$3.99',
        eventsIncluded: 0,
        percentSavings: 0,
        upsell:
          'Have more than 5 events per month? Save up to 60% with Bundle Plans.',
      };
      break;
    case MEMBER_PLANS.PER_EVENT:
      details = {
        name: 'Basic',
        pricePerMonth: '$4.99 per event',
        discountedPricePerMonth: '$3.99 per event',
        pricePerAddEvent: '$4.99',
        discountedPricePerAddEvent: '$3.99',
        eventsIncluded: 0,
        percentSavings: 0,
        upsell:
          'Have more than 5 events per month? Save up to 60% with Bundle Plans.',
      };
      break;
    case MEMBER_PLANS.BUNDLE_5:
      details = {
        name: 'Bundle 5',
        pricePerMonth: '$19.99',
        discountedPricePerMonth: '$15.99',
        pricePerAddEvent: '$3.99',
        discountedPricePerAddEvent: '$3.19',
        eventsIncluded: 5,
        percentSavings: 20,
        upsell:
          'Have more than 8 events? Save up to 60% by upgrading your Bundle plan.',
      };
      break;
    case MEMBER_PLANS.BUNDLE_10:
      details = {
        name: 'Bundle 10',
        pricePerMonth: '$29.99',
        discountedPricePerMonth: '$23.99',
        pricePerAddEvent: '$2.99',
        discountedPricePerAddEvent: '$2.39',
        eventsIncluded: 10,
        percentSavings: 40,
        upsell:
          'Have more than 13 events? Save up to 60% by upgrading your Bundle plan.',
      };
      break;
    case MEMBER_PLANS.BUNDLE_20:
      details = {
        name: 'Bundle 20',
        pricePerMonth: '$39.99',
        discountedPricePerMonth: '$31.99',
        pricePerAddEvent: '$1.99',
        discountedPricePerAddEvent: '$1.59',
        eventsIncluded: 20,
        percentSavings: 60,
        upsell:
          'Would you like help with managing your events? We offer Event Management services.',
      };
      break;
    case MEMBER_PLANS.MANAGED_1:
      details = {
        name: 'Event management plan',
        pricePerMonth: '$ Negotiated',
        discountedPricePerMonth: '$ Negotiated',
        pricePerAddEvent: '$ Negotiated',
        discountedPricePerAddEvent: '$ Negotiated',
        eventsIncluded: 20,
      };
      break;
    default:
      details = {
        name: 'UNKNOWN',
        pricePerMonth: 'UNKNOWN',
        discountedPricePerMonth: 'UNKNOWN',
        pricePerAddEvent: 'UNKNOWN',
        discountedPricePerAddEvent: 'UNKNOWN',
        eventsIncluded: 0,
      };
  }
  return details;
};

export const memberCouponDetails = (coupon) => {
  let details;
  switch (coupon) {
    case 'free-month':
      details = {
        name: 'First month FREE',
        percentOff: 100,
        id: 'free-month',
      };
      break;
    default:
      details = {
        name: 'UNKNOWN',
        percentOff: 0,
        id: 'UNKNOWN',
      };
  }
  return details;
};
