/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Grid as Loader } from 'react-loader-spinner';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  searching: {
    position: 'absolute',
    width: 'calc(100% - 12px)',
    zIndex: '2',
    textAlign: 'center',
  },
  overlay: {
    backgroundColor: 'rgba(250, 250, 250, 1);',
    height: '100%',
    borderRadius: 20,
  },
  searchLoading: {
    display: 'flex',
    paddingTop: '70px',
    paddingBottom: '15px',
    alignItems: 'baseline',
    width: 248,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  text: {
    textShadow: '-5px 5px 10px #fff, 5px -5px 10px #fff',
  },
}));

const SearchLoading = React.forwardRef(
  ({ id, message, show, overlay }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
      <Fade in={show} timeout={500}>
        <div
          id={id}
          ref={ref}
          className={ClassNames(classes.searching, {
            [classes.overlay]: overlay,
          })}
        >
          <div className={classes.searchLoading}>
            <Loader
              arialLabel='searching'
              color={theme.palette.companyPrimary.main}
              height={30}
              width={60}
              wrapperStyle={{ display: 'block', transform: 'rotate(45deg)' }}
            />
            <Loader
              arialLabel='searching'
              color={theme.palette.companyPrimary.main}
              height={60}
              width={120}
              wrapperStyle={{ display: 'block', transform: 'rotate(45deg)' }}
            />
            <Loader
              arialLabel='searching'
              color={theme.palette.companyPrimary.main}
              height={30}
              width={60}
              wrapperStyle={{ display: 'block', transform: 'rotate(45deg)' }}
            />
          </div>
          <Typography
            variant='h3'
            align='center'
            className={ClassNames(classes.text, {
              [classes.noPaddingBottom]: !navigator.onLine,
            })}
          >
            {message}
          </Typography>
          {!navigator.onLine && (
            <Typography variant='body1' align='center' className={classes.text}>
              There appears to be network issues, but we'll keep trying...
            </Typography>
          )}
        </div>
      </Fade>
    );
  }
);

SearchLoading.propTypes = {
  show: PropTypes.bool.isRequired,
  id: PropTypes.string,
  message: PropTypes.string,
  overlay: PropTypes.bool,
};

SearchLoading.defaultProps = {
  id: '',
  message: 'Searching...',
  overlay: true,
};

export default SearchLoading;
