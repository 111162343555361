/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { sweetAlert, sweetError, sweetProgress } from 'utilities/sweetAlert';
import { Helmet } from 'react-helmet';
import { animateScroll as scroll } from 'react-scroll';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { UserContext } from 'UserContext';
import { doLoginAndVerifyEmail } from 'vendors/firebase/fireauth';

import Checkbox from '@material-ui/core/Checkbox';
import Footer from 'components/Footer';
import Header from 'components/Header';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NamedDivider from 'components/NamedDivider';
import {
  FORGOT_URI,
  LOGIN_URI,
  PLANUP_URI,
  SIGNUP_URI,
} from 'constants/routes';
import { CLOUDINARY, SITEURL } from 'constants/general';

const logoSvg = `${CLOUDINARY}/sona/svg/amgt_logo_sm_v1.svg`;
const logoIcon = (
  <img src={logoSvg} alt='A Mighty Good Time logo small' width={60} />
);

const parseParams = (searchString) => {
  const { n, c } = queryString.parse(searchString);
  return {
    emailAddr: n,
    continueUrl: c,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#F2F5F4',
  },
  content: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
    marginTop: 10,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
      marginTop: '6vh',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: 0,
    width: '100%',
  },
  buttonDiv: {
    width: '100%',
    padding: `${theme.spacing(1)} ${theme.spacing(5)} ${theme.spacing(4)}`,
  },
  button: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
  },
  paper: {
    maxWidth: 400,
    padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(
      2
    )} ${theme.spacing(5)}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  createButton: {
    width: '100%',
    textAlign: 'center',
  },
  link: {
    color: theme.palette.companyPrimary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '& strong': {
      fontWeight: 'bold',
    },
  },
  trouble: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.company.danger,
    paddingTop: theme.spacing(1),
  },
  errorIcon: {
    color: theme.palette.company.danger,
    maxWidth: 36,
    paddingTop: 10,
  },
  iconError: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    verticalAlign: 'text-bottom',
  },
  checkbox: {
    padding: 8,
    alignSelf: 'flex-start',
  },
  icon: {
    color: theme.palette.companySecondary.main,
  },
  label: {
    paddingLeft: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    display: 'inline-flex',
    transition: '0.3s ease all',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const INITIAL_ERROR = {
  email: false,
  password: false,
  message: null,
};

const LoginPage = React.forwardRef(({ id, history, location }, ref) => {
  const classes = useStyles();
  const { onLogin } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [toUrl, setToUrl] = useState('');
  const [error, setError] = useState(INITIAL_ERROR);

  const scrollToTop = (duration) =>
    scroll.scrollToTop({
      duration,
      delay: 0,
      smooth: true,
    });

  useEffect(() => {
    scrollToTop(0);
    const { emailAddr, continueUrl } = parseParams(location.search);
    if (emailAddr) {
      setEmail(emailAddr);
      document.getElementById('password').focus();
    }
    if (continueUrl) {
      setToUrl(continueUrl);
    } else if (location.state && location.state.from) {
      const from = location.state.from;
      const REGEXP = RegExp('(.+)\\?plan=(\\d+)$', 'gi');
      const parts = REGEXP.exec(from);
      if (parts && parts.length > 1) {
        setToUrl(PLANUP_URI);
      }
    }
    // eslint-disable-next-line
  }, []);

  const onSendVerification = (email, password) => {
    const { from } = location.state || { from: '/' };
    const continueUrl = window.location.origin
      .concat(LOGIN_URI)
      .concat('?n=')
      .concat(encodeURIComponent(email))
      .concat('&c=')
      .concat(encodeURIComponent(from));
    sweetProgress();
    doLoginAndVerifyEmail(email, password, continueUrl)
      .then(() => {
        sweetAlert({
          icon: 'success',
          title: 'Success!',
          content: (
            <div>
              <p>
                We&#39;ve just sent you a confirmation email with a verification
                link. Don&#39;t forget to check your junk mail folder if you
                don&#39;t see the email in your inbox!
              </p>
            </div>
          ),
          button: 'OK',
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      })
      .catch((err) => {
        sweetError({
          title: 'A problem occurred while sending email verification',
          subject: 'Resend email verification failed',
          context: `doLoginAndVerifyEmail: ${email}`,
          message: err.message,
        }).then((email) => {
          if (email) {
            const win = window.open(email, '_blank');
            win.focus();
          }
        });
      });
  };

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    const emailCleaned = email.trim().toLowerCase();
    if (emailCleaned === '') {
      setError({
        ...INITIAL_ERROR,
        email: true,
        message: 'Please enter your email address.',
      });
    } else if (password === '') {
      setError({
        ...INITIAL_ERROR,
        password: true,
        message: 'Please enter your password.',
      });
    } else {
      onLogin(emailCleaned, password)
        .then(() => {
          const { from, search } = location.state || {
            from: '/',
            search: '',
          };
          history.push({
            pathname: toUrl || from,
            search,
            state: location.state,
          });
        })
        .catch((err) => {
          if (err.code === 'auth/invalid-email') {
            setError({
              ...INITIAL_ERROR,
              message: 'Not a valid email address',
            });
          } else if (err.code === 'auth/unverified-email') {
            sweetAlert({
              icon: 'info',
              title: 'Please Verify Your Email',
              content: (
                <div>
                  <p style={{ textAlign: 'left' }}>
                    We previously sent you a confirmation email with a
                    verification link.{' '}
                    <strong>
                      You will need to click that link in-order to verify your
                      email and complete the account creation process.
                    </strong>{' '}
                    Don&#39;t forget to check your junk mail folder if you
                    don&#39;t see the email in your inbox!
                  </p>
                  <p style={{ textAlign: 'left' }}>
                    If you believe you did not receive the confirmation email,
                    you may submit a request for a new verification email using
                    the button below.
                  </p>
                  <p style={{ textAlign: 'left' }}>
                    If you continue to have trouble with email verification,
                    then please contact customer support at{' '}
                    <a
                      href='mailto:support@amightygoodtime.com?Subject=Request%20email%20verification%20assistance.'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      support@amightygoodtime.com
                    </a>{' '}
                    for assistance, and we will do our best to have your issue
                    resolved as soon as possible.
                  </p>
                </div>
              ),
              buttons: {
                cancel: 'Resend Verification Email',
                confirm: 'OK',
              },
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then((value) => {
              if (!value) {
                onSendVerification(emailCleaned, password);
              }
            });
          } else {
            setError({
              ...INITIAL_ERROR,
              message: 'Not a valid e-mail and password combination',
            });
          }
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>A Mighty Good Time: Login to account.</title>
        <link rel='canonical' href={SITEURL.concat('/login')} />
        <meta name='robots' content='noindex' />
      </Helmet>
      <main id={id} ref={ref} className={classes.root}>
        <Header />
        <div className={classes.content}>
          <Paper className={classes.paper} elevation={6}>
            <form
              className={classes.container}
              onSubmit={onSubmit}
              noValidate
              autoComplete='off'
            >
              <Typography variant='h2' style={{ fontWeight: 600 }}>
                Log in
              </Typography>
              <TextField
                id='email'
                label='Email'
                className={classes.textField}
                value={email}
                required
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                error={error.email}
                margin='normal'
                autoComplete='email'
                autoFocus={email.length === 0}
              />
              <TextField
                id='password'
                label='Password'
                className={classes.textField}
                value={password}
                required
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                error={error.password}
                autoComplete='off'
                margin='normal'
                autoFocus={email.length > 0}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword}
                    onClick={() =>
                      setShowPassword((prevShowPassword) => !prevShowPassword)
                    }
                    value='showPassword'
                    className={classes.checkbox}
                  />
                }
                label='Show password'
                classes={{
                  label: ClassNames(classes.label, classes.pointer),
                }}
              />
              {error && error.message && (
                <Grid container>
                  <Grid item xs={2} className={classes.errorIcon}>
                    <ErrorIcon />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant='body1'
                      color='error'
                      className={classes.error}
                      gutterBottom
                    >
                      {error.message}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <span className={classes.buttonDiv}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  size='large'
                  fullWidth
                  className={classes.button}
                >
                  LOG IN
                </Button>
              </span>
            </form>
            <NamedDivider color='primary' center showIcon icon={logoIcon} />
            <Typography
              variant='body1'
              align='center'
              style={{ paddingTop: 5, paddingBottom: 5 }}
            >
              New to A Mighty Good Time?
            </Typography>
            <div className={classes.createButton}>
              <Button
                variant='outlined'
                color='primary'
                size='small'
                className={classes.button}
                component={Link}
                to={{
                  pathname: SIGNUP_URI,
                  state: location.state, // eslint-disable-line react/prop-types
                }}
                replace
              >
                CREATE A FREE ACCOUNT
              </Button>
            </div>
            <Typography
              variant='body2'
              align='center'
              color='inherit'
              className={classes.trouble}
            >
              <Link to={FORGOT_URI} className={classes.link}>
                Trouble logging in?
              </Link>
            </Typography>
          </Paper>
        </div>
        <Footer />
      </main>
    </>
  );
});

LoginPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
};

LoginPage.defaultProps = {
  id: '',
};

export default withRouter(LoginPage);
