/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import CheckedIcon from '@material-ui/icons/RadioButtonChecked';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: '2px 0',
    [theme.breakpoints.up('md')]: {
      borderRadius: 25,
    },
  },
  selected: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.companyPrimary.main} !important`,
    [theme.breakpoints.up('md')]: {
      borderRadius: 25,
    },
  },
  bold: {
    fontWeight: '800',
  },
  icon: {
    marginRight: 0,
    color: `${theme.palette.common.white} !important`,
    minWidth: 38,
  },
  gutters: {
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));

const RadioList = React.forwardRef(
  ({ id, className, listItems, initKey, onChange }, ref) => {
    const classes = useStyles();
    const [currKey, setCurrKey] = useState(initKey.key);

    const handleChange = (key, name) => () => {
      if (currKey !== key) {
        setCurrKey(key);
        if (onChange) {
          onChange(key);
        }
      }
    };

    return (
      <List id={id} ref={ref} style={{ width: '100%' }} dense disablePadding>
        {listItems.map((pr) => (
          <ListItem
            button
            key={pr.key}
            selected={currKey === pr.key}
            onClick={handleChange(pr.key, pr.name)}
            classes={{
              root: classes.listItem,
              selected: classes.selected,
            }}
            disableGutters
            className={classes.gutters}
            style={{ width: '100%' }}
          >
            <ListItemIcon
              classes={{
                root: classes.icon,
              }}
            >
              {currKey === pr.key ? (
                <CheckedIcon />
              ) : (
                <UncheckedIcon style={{ color: '#ddd' }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={pr.name}
              classes={{
                dense: ClassNames({
                  [classes.selected]: currKey === pr.key,
                  [classes.bold]: currKey === pr.key,
                }),
              }}
            />
          </ListItem>
        ))}
      </List>
    );
  }
);

RadioList.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  initKey: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

RadioList.defaultProps = {
  id: '',
  className: '',
  initKey: { key: '' },
  onChange: null,
  allowCustom: false,
  value: { key: '' },
};

export default RadioList;
