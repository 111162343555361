/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { logAnalyticsEvent } from 'vendors/firebase/main';
import { CLOUDINARY, getEmailRegex } from 'constants/general';
import { doSubscribeToMailchimp } from 'vendors/firebase/fireauth';
import EmailSentAlert from 'components/EmailSentAlert';

const promoImg = `${CLOUDINARY}/sona/img/10_marketing_tips_v1.jpg`;
const emailSvg = `${CLOUDINARY}/sona/svg/open_email_blue_v1.svg`;

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.companyPrimary.light,
    minHeight: 300,
    width: 'fit-content',
    marginLeft: -10,
    marginRight: -10,
    maxWidth: '100vw',
    marginTop: theme.spacing(9),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(9),
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: 20,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(5),
    },
  },
  gridColumnContainer: {
    display: 'grid',
    minHeight: 300,
    maxWidth: 950,
    gridTemplateColumns: 'auto auto',
    gridGap: '30px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'unset',
      gridGap: 'unset',
      gridTemplateRows: 'auto',
    },
  },
  gridColumn: {
    display: 'flex',
    height: '100%',
    width: '100vw',
    padding: '30px 10px',
    [theme.breakpoints.up('sm')]: {
      padding: 30,
      width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px 0',
    },
  },
  card: {
    textAlign: 'center',
    background: theme.palette.common.white,
    borderRadius: 15,
    padding: 15,
    boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
    [theme.breakpoints.up('sm')]: {
      padding: '15px 30px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 30,
    },
  },
  promoImg: {
    marginTop: -100,
    borderRadius: 10,
    boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
    [theme.breakpoints.up('md')]: {
      width: 218.24,
      height: 280,
      marginTop: 0,
      marginRight: 30,
    },
  },
  text: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.65rem',
    },
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingBottom: 20,
    },
  },
  emailDiv: {
    display: 'block',
    justifyContent: 'center',
    paddingTop: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      paddingBottom: 8,
    },
  },
  emailInputDiv: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 200,
      maxWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 430,
    },
  },
  emailInput: {
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 8,
    minHeight: 40,
    width: '100%',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  emailBtn: {
    textTransform: 'inherit',
    width: '100%',
    height: 40,
    marginTop: 5,
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 125,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 5,
    },
    '&:disabled': {
      color: `${theme.palette.common.white} !important`,
      backgroundColor: `${theme.palette.companyPrimary.dark} !important`,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.company.danger,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    fontWeight: '800',
    marginBottom: 5,
  },
  error: {
    backgroundColor: `${theme.palette.company.danger} !important`,
  },
  helperText: {
    fontSize: theme.typography.overline.fontSize,
  },
}));

const OrganizerFreeTips = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [processStep, setProcessStep] = useState('input');
  const [missingData, setMissingData] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(false);

  const emailRef = useRef();

  const onSubmit = (e) => {
    setError(false);
    if (emailRef.current) {
      const email = emailRef.current.value;
      const regex = /\s+/gi;
      const emailCleaned = email.trim().replace(regex, ' ').toLowerCase();
      const REGEX_EMAIL = getEmailRegex();
      const emailIsValid = REGEX_EMAIL.test(emailCleaned);
      if (emailCleaned === '') {
        setMissingData(true);
      } else if (!emailIsValid) {
        setInvalidData(true);
      } else {
        setInvalidData(false);
        setMissingData(false);
        setSubmitting(true);
        doSubscribeToMailchimp(emailCleaned, '10 Marketing Tips')
          .then(() => {
            setProcessStep('success');
            setSubmitting(false);
            setShowSuccess(true);
          })
          .catch((e) => {
            console.log('ERROR:', e);
            setProcessStep('input');
            setError(true);
            setSubmitting(false);
          });
        logAnalyticsEvent('promo', {
          type: 'marketing_tips_download',
        });
      }
    }
  };

  const handleTooltipClose = () => {
    setMissingData(false);
    setInvalidData(false);
  };

  const handleSuccessAlertClose = () => {
    setShowSuccess(false);
  };

  return (
    <div id={id} ref={ref} className={classes.root}>
      <div className={classes.gridColumnContainer}>
        <div className={ClassNames(classes.gridColumn, classes.text)}>
          <div className={classes.card}>
            <Hidden mdUp>
              <img
                src={promoImg}
                alt='promo'
                width={150}
                className={classes.promoImg}
              />
            </Hidden>
            <Hidden smDown>
              <img src={emailSvg} alt='promo' width={50} />
            </Hidden>
            <Typography
              variant='h3'
              align='center'
              color='inherit'
              className={classes.title}
            >
              <strong>Want some free tips?</strong>
            </Typography>
            <Typography
              variant='body2'
              align='left'
              color='inherit'
              style={{ paddingLeft: 10, maxWidth: 552 }}
            >
              Older adults are the fastest growing demographic, yet are
              traditionally neglected in marketing and misrepresented in media.
              Here are some tips on how to{' '}
              <strong>make your marketing inclusive.</strong>
            </Typography>
            <div className={classes.emailDiv}>
              <div className={classes.emailInputDiv}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={missingData || invalidData}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement='top'
                  title={`Please enter ${
                    invalidData ? 'a valid' : 'your'
                  } email`}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <input
                    ref={emailRef}
                    type='email'
                    id='email'
                    name='email'
                    required
                    placeholder='Enter your email'
                    className={classes.emailInput}
                  />
                </Tooltip>
                <Hidden xsDown>
                  {error && (
                    <Typography
                      variant='body1'
                      align='center'
                      color='error'
                      style={{ marginTop: 5, whiteSpace: 'nowrap' }}
                    >
                      We&#39;re sorry, there was an error submitting your
                      request.
                    </Typography>
                  )}
                  <Typography
                    variant='caption'
                    align='center'
                    color='inherit'
                    className={classes.helperText}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    We hate junk. We hate spam. Your email is always safe in our
                    hands.
                  </Typography>
                </Hidden>
              </div>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Button
                  variant='contained'
                  color='primary'
                  className={ClassNames(classes.emailBtn, {
                    [classes.error]: error,
                  })}
                  onClick={onSubmit}
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress
                      color='inherit'
                      size={20}
                      className={classes.progress}
                    />
                  ) : processStep === 'input' ? (
                    error ? (
                      'Try again'
                    ) : (
                      'Yes please!'
                    )
                  ) : (
                    'Success!'
                  )}
                </Button>
              </ClickAwayListener>
              <Hidden smUp>
                {error && (
                  <Typography
                    variant='body1'
                    align='center'
                    color='error'
                    style={{ marginBottom: 5 }}
                  >
                    We&#39;re sorry, there was an error submitting your request.
                  </Typography>
                )}
                <Typography
                  variant='caption'
                  align='center'
                  color='inherit'
                  className={classes.helperText}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>
                    We hate junk. We hate spam.
                  </span>{' '}
                  <span style={{ whiteSpace: 'nowrap' }}>
                    Your email is always safe in our hands.
                  </span>
                </Typography>
              </Hidden>
            </div>
          </div>
        </div>
        <Hidden smDown>
          <div
            className={classes.gridColumn}
            style={{ justifyContent: 'flex-start' }}
          >
            <img src={promoImg} alt='promo' className={classes.promoImg} />
          </div>
        </Hidden>
      </div>
      <EmailSentAlert open={showSuccess} onClose={handleSuccessAlertClose} />
    </div>
  );
});

OrganizerFreeTips.propTypes = {
  id: PropTypes.string,
};

OrganizerFreeTips.defaultProps = {
  id: '',
};

export default OrganizerFreeTips;
