/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';

import { makeStyles } from '@material-ui/core/styles';

import { logAnalyticsEvent } from 'vendors/firebase/main';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    paddingBottom: 2,
    paddingTop: 5,
    '& > p': {
      fontWeight: theme.typography.body1.fontWeight,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      marginBlockStart: '0em',
      marginBlockEnd: '0em',
      minHeight: 27,
    },
    '& > h1': {
      fontWeight: theme.typography.h2.fontWeight,
      fontSize: theme.typography.h2.fontSize,
      lineHeight: theme.typography.h2.lineHeight,
      marginBlockStart: '0.67em',
      marginBlockEnd: '0.67em',
    },
    '& > h2': {
      fontWeight: theme.typography.h4.fontWeight,
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      color: '#616161',
      marginBlockStart: '0.67em',
      marginBlockEnd: '0.67em',
    },
    '& > h3': {
      fontWeight: theme.typography.caption.fontWeight,
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
      marginBlockStart: '0em',
      marginBlockEnd: '0em',
    },
    '& > ol': {
      fontWeight: theme.typography.body1.fontWeight,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
    '& > ul': {
      fontWeight: theme.typography.body1.fontWeight,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
    '& * a': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#098076',
      '&:hover': {
        color: '#f57c00',
      },
    },
  },
  blockquote: {
    borderLeft: '5px solid #eee',
    color: '#666',
    fontFamily: "'Hoefler Text', 'Georgia', serif",
    fontStyle: 'italic',
    margin: '16px 0',
    padding: '10px 20px',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#098076',
    '&:hover': {
      color: '#f57c00',
    },
  },
}));

const DEFAULT = {
  raw: '',
  length: '',
};

const RichTextViewer = React.forwardRef(
  ({ id, value, orgId, context, analytics }, ref) => {
    const classes = useStyles();
    const descRef = useRef();

    const [html, setHtml] = useState(() => {
      const parsedValue = value || DEFAULT;
      if (parsedValue.length > 0) {
        return ReactHtmlParser(draftToHtml(parsedValue.raw));
      } else {
        return <div></div>;
      }
    });

    useEffect(() => {
      if (analytics) {
        const onClick = (e) => {
          if (orgId) {
            e.path.forEach((ele) => {
              if (ele.tagName === 'A') {
                logAnalyticsEvent('event_link', {
                  ...context,
                  type: 'link',
                  placement: 'description',
                  href: ele.href,
                });
              }
            });
          }
        };
        let currRef;
        if (descRef.current) {
          currRef = descRef.current;
          currRef.addEventListener('click', onClick);
        }
        return () => {
          if (currRef) {
            currRef.removeEventListener('click', onClick);
          }
        };
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const parsedValue = value || DEFAULT;
      if (parsedValue.length > 0) {
        setHtml(ReactHtmlParser(draftToHtml(parsedValue.raw)));
      } else {
        setHtml(<div></div>);
      }
      // eslint-disable-next-line
    }, [value]);

    return (
      <div id={id} ref={descRef} className={classes.root}>
        {html}
      </div>
    );
  }
);

RichTextViewer.propTypes = {
  value2: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  orgId: PropTypes.string,
  analytics: PropTypes.bool,
};

RichTextViewer.defaultProps = {
  value2: DEFAULT,
  id: '',
  orgId: null,
  analytics: true,
};

export default RichTextViewer;
