/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import { DateTime } from 'luxon';
import { getTimeZones } from '@vvo/tzdb';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import {
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const checkIfOnline = async () => {
  let trys = 6;
  while (!navigator.onLine && trys > 0) {
    trys--;
    await delay(5000);
  }
  if (!navigator.onLine) {
    return false;
  }
  return true;
};

const timeZoneObjects = getTimeZones();
const currLocalDateTime = DateTime.local();
const defaultTimezone = currLocalDateTime.zoneName;
const defaultTimezoneObject = timeZoneObjects.find((timeZone) => {
  return (
    defaultTimezone === timeZone.name ||
    timeZone.group.includes(defaultTimezone)
  );
});
const defaultTimezoneAbr = defaultTimezoneObject.abbreviation;
const defaultCountry = defaultTimezoneObject.countryName;

const defaultCountryTimezones = timeZoneObjects.filter(
  (ele) => ele.countryName === defaultCountry
);
const allOtherCountryTimezones = timeZoneObjects.filter(
  (ele) => ele.countryName !== defaultCountry
);

const timezones = [];
timezones.push({
  name: '',
  currentTimeFormat: `${defaultCountry} timezones`,
});
timezones.push(
  ...defaultCountryTimezones.sort((a, b) => {
    const aInt = parseInt(a.currentTimeFormat.substring(0, 6).replace(':', ''));
    const aAltName = a.alternativeName;
    const bInt = parseInt(b.currentTimeFormat.substring(0, 6).replace(':', ''));
    const bAltName = b.alternativeName;
    if (aInt === bInt) {
      return aAltName.toLowerCase() > bAltName.toLowerCase() ? -1 : 1;
    }
    return aInt - bInt;
  })
);
timezones.push({
  name: '',
  currentTimeFormat: 'All other timezones',
});
timezones.push(
  ...allOtherCountryTimezones.sort((a, b) => {
    const aInt = parseInt(a.currentTimeFormat.substring(0, 6).replace(':', ''));
    const bInt = parseInt(b.currentTimeFormat.substring(0, 6).replace(':', ''));
    return aInt - bInt;
  })
);

const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthShortNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const tzDstAbbr = {
  ACST: 'ACDT',
  AEST: 'AEDT',
  AKST: 'AKDT',
  AST: 'ADT',
  AWST: 'AWDT',
  CST: 'CDT',
  CHAST: 'CHADT',
  CIST: 'CIDST',
  EST: 'EDT',
  HST: 'HDT',
  IST: 'IDT',
  IRST: 'IRDT',
  LHST: 'LHDT',
  MST: 'MDT',
  MSK: 'MSD',
  NST: 'NDT',
  NFT: 'NFDT',
  NZST: 'NZDT',
  PST: 'PDT',
  PMST: 'PMDT',
};

const getTimeZoneAbr = (tz, isDST) => {
  const tzObject = timeZoneObjects.find((timeZone) => {
    return tz === timeZone.name || timeZone.group.includes(tz);
  });
  let abbr;
  if (isDST) {
    abbr = tzDstAbbr[tzObject.abbreviation] || tzObject.abbreviation;
  } else {
    abbr = tzObject.abbreviation;
  }
  return abbr;
};

const formatDate = (date, shortName = false, includeWeekday = false) => {
  let weekday = '';
  if (includeWeekday) {
    const fullDayName = dayNames[date.getDay()];
    weekday = shortName ? fullDayName.substring(0, 3) : fullDayName;
    weekday = weekday.concat(', ');
  }
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${weekday}${
    shortName ? month.substring(0, 3) : month
  } ${day}, ${year}`;
};

const getOrdinalSuffix = (num) => {
  var j = num % 10,
    k = num % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

const formatSelectDate = (date) => {
  const today = new Date();
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year =
    date.getFullYear() !== today.getFullYear()
      ? `, ${date.getFullYear()}`
      : getOrdinalSuffix(day);
  return `${month} ${day}${year}`;
};

const REGEX = /,\s\d\d\d\d$/gi;
const formatEventDates = (eventDate) => {
  const currDt = DateTime.local();
  const { startDate, dates, timezone } = eventDate;
  const currDataTime = DateTime.fromMillis(startDate * 60000).setZone(timezone);
  let dayOfEvent = currDataTime.toLocaleString(DateTime.DATE_HUGE);
  const dayOfEventLocalYear = currDataTime.toLocal().year;
  if (dayOfEventLocalYear === currDt.year) {
    dayOfEvent = dayOfEvent.replace(`, ${dayOfEventLocalYear}`, '');
  }
  let daysOfEvent = `${currDataTime.weekdayShort}, ${currDataTime.monthShort} ${currDataTime.day}`;
  if (currDataTime.year !== currDt.year) {
    daysOfEvent = `${daysOfEvent}, ${currDataTime.year}`;
  }
  const allTimes = [];
  let hoursOfEvent;
  let defaultHoursOfEvent;
  let count = dates.length - 1;
  dates.forEach(({ day, start, end }) => {
    const isGood = startDate === start;
    const startDateTime = DateTime.fromMillis(start * 60000).setZone(timezone);
    const endDateTime = DateTime.fromMillis(end * 60000).setZone(timezone);
    const timezoneAbr = getTimeZoneAbr(timezone, startDateTime.isInDST);

    const eDay = DateTime.fromMillis(start * 60000).setZone(timezone);
    const weekDay = eDay.weekdayShort;
    const dayStr = eDay.toLocaleString(DateTime.DATE_MED);
    const timeStr = `${startDateTime.toLocaleString(
      DateTime.TIME_SIMPLE
    )} - ${endDateTime.toLocaleString(DateTime.TIME_SIMPLE)} ${timezoneAbr}`;
    if (defaultHoursOfEvent === undefined) {
      defaultHoursOfEvent = timeStr;
    }
    allTimes.push({
      day: `${weekDay}, ${dayStr.replace(REGEX, '')}`,
      time: timeStr,
    });
    if (hoursOfEvent === undefined && (isGood || count === 0)) {
      hoursOfEvent = timeStr;
    }
  });

  return {
    dayOfEvent,
    daysOfEvent,
    hoursOfEvent: hoursOfEvent || defaultHoursOfEvent,
    allTimes,
  };
};

const isValidTimezone = (tz) => {
  const result = timeZoneObjects.filter(
    (ele) => tz === ele.name || ele.group.includes(tz)
  );
  return result.length > 0;
};

const getDayInMinutes = (d) => {
  const utc = Date.UTC(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds()
  );
  // Minutes since EPOC
  return utc / 60000;
};

const dayTypes = [
  'custom',
  'today',
  'tomorrow',
  'thisweek',
  'thisweekend',
  'any',
];
const getDayTypeId = (name) => {
  return dayTypes.indexOf(name);
};
const getDayType = (id) => {
  const name = dayTypes[id];
  if (name) {
    if (name === 'today' || name === 'tomorrow') {
      return {
        name,
        days: 1,
      };
    } else if (name === 'thisweekend') {
      return {
        name,
        days: 2,
      };
    } else if (name === 'thisweek') {
      return {
        name,
        days: 7,
      };
    }
    return {
      name,
      days: -1,
    };
  }
  return {
    name: 'custom',
    days: 1,
  };
};
const verifyDayType = (date, type) => {
  if (type === 'custom' || type === 'any') {
    return type;
  }
  const currLocal = DateTime.local();
  const today = DateTime.local(
    currLocal.year,
    currLocal.month,
    currLocal.day,
    0,
    0,
    0
  );
  const todayInMins = Math.round(today.toMillis() / 60000);
  const tomorrow = today.plus({ days: 1 });
  const tomorrowInMins = Math.round(tomorrow.toMillis() / 60000);
  if (type === 'today') {
    if (date >= todayInMins && date < tomorrowInMins) {
      return 'today';
    }
  } else if (type === 'tomorrow') {
    const dayAfterTomorrow = tomorrow.plus({ days: 1 });
    const dayAfterTomorrowInMins = Math.round(
      dayAfterTomorrow.toMillis() / 60000
    );
    if (date >= tomorrowInMins && date < dayAfterTomorrowInMins) {
      return 'tomorrow';
    }
  } else if (type === 'thisweekend') {
    let weekendStart = today;
    while (weekendStart.weekday < 6) {
      weekendStart = weekendStart.plus({ days: 1 });
    }
    const weekendEnd = weekendStart.plus({ days: 2 });
    const weekendStartInMins = Math.round(weekendStart.toMillis() / 60000);
    const weekendEndInMins = Math.round(weekendEnd.toMillis() / 60000);
    if (date >= weekendStartInMins && date < weekendEndInMins) {
      return 'thisweekend';
    }
  } else if (type === 'thisweek') {
    let weekStart = today;
    while (weekStart.weekday > 5) {
      weekStart = weekStart.plus({ days: 1 });
    }
    let weekEnd = weekStart.plus({ days: 7 });
    const weekStartInMins = Math.round(weekStart.toMillis() / 60000);
    const weekEndInMins = Math.round(weekEnd.toMillis() / 60000);
    if (date >= weekStartInMins && date < weekEndInMins) {
      return 'thisweek';
    }
  }
  return 'custom';
};

const formatDateRange = (startDate, endDate, shortName = false) => {
  const startDay = startDate.getDate();
  const startMonth = shortName
    ? monthShortNames[startDate.getMonth()]
    : monthNames[startDate.getMonth()];
  const startYear = startDate.getFullYear();
  const endDay = endDate.getDate();
  const endMonth = shortName
    ? monthShortNames[endDate.getMonth()]
    : monthNames[endDate.getMonth()];
  const endYear = endDate.getFullYear();
  if (startYear === endYear) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
  }
  return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
};

const formatDateMonthYear = (date, shortName = false) => {
  const dateMonth = shortName
    ? monthShortNames[date.getMonth()]
    : monthNames[date.getMonth()];
  const dateYear = date.getFullYear();
  return `${dateMonth} ${dateYear}`;
};

const formatDateMonthDayYear = (date, shortName = false) => {
  const dateMonth = shortName
    ? monthShortNames[date.getMonth()]
    : monthNames[date.getMonth()];
  const dateDay = date.getDate();
  const dateYear = date.getFullYear();
  return `${dateMonth} ${dateDay}, ${dateYear}`;
};

const capitalize = (str) => {
  return str.replace(
    // eslint-disable-next-line no-useless-escape
    /[\w\.]+/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1)
  );
};

// ^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\\:\\d+)?(\/[-a-z\d%_.,~+]*)*(\?[:;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*
var URL_PATTERN = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.,~+]*)*' + // port and path
    '(\\?[:;&a-z\\d%_.*~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);
const validURL = (str) => !!URL_PATTERN.test(str);

const cleanUrl = (url) => {
  let cleanedUrl = url
    ? url
        .trim()
        .replaceAll('!', '%21')
        .replaceAll('(', '%28')
        .replaceAll(')', '%29')
        .replaceAll('[', '%5B')
        .replaceAll(']', '%5D')
        .replaceAll("'", '%27')
    : '';
  if (
    cleanedUrl.length > 0 &&
    !(cleanedUrl.startsWith('https://') || cleanedUrl.startsWith('http://'))
  ) {
    return `https://${cleanedUrl}`;
  }
  return cleanedUrl;
};

const toURL = (src) => (src instanceof Blob ? URL.createObjectURL(src) : src);

const checkLocalGeoCache = (placeId) => {
  try {
    const geoLocsStr = window.localStorage.getItem('geo');
    if (geoLocsStr) {
      const geoLocs = JSON.parse(geoLocsStr);
      const foundGeo = geoLocs.filter((ele) => ele.placeId === placeId);
      return foundGeo ? foundGeo[0] : null;
    }
  } catch (err) {
    return null;
  }
  return null;
};
const updateLocalGeoCache = (geoLoc) => {
  try {
    const geoLocsStr = window.localStorage.getItem('geo');
    if (geoLocsStr) {
      const geoLocs = JSON.parse(geoLocsStr);
      geoLocs.push({
        ...geoLoc,
        timestamp: new Date().getTime(),
      });
      // Limit to 10 MRU
      const geoTimes = [];
      for (var i = 0; i < geoLocs.length; i++) {
        geoTimes.push(geoLocs[i].timestamp);
      }
      const lruTimes = geoTimes.sort((a, b) => b - a).slice(0, 10);
      window.localStorage.setItem(
        'geo',
        JSON.stringify(
          geoLocs.filter((ele) => lruTimes.includes(ele.timestamp))
        )
      );
    } else {
      window.localStorage.setItem('geo', JSON.stringify([geoLoc]));
    }
  } catch (err) {
    return null;
  }
};
const getGeoLocation = async (placeId, fullAddress = false) => {
  let geoLoc = checkLocalGeoCache(placeId);
  if (geoLoc) {
    return geoLoc;
  }
  const location = await getGeocode({ placeId })
    .then(async (geoResults) => {
      const { lat, lng } = await getLatLng(geoResults[0]);
      const { address_components, formatted_address } = geoResults[0];
      let streetNumber;
      let street;
      let city;
      let state;
      let stateAbbr;
      let country;
      address_components.forEach(({ types, short_name, long_name }) => {
        if (types.includes('locality') && types.includes('political')) {
          city = long_name.length > 20 ? short_name : long_name;
        } else if (
          types.includes('administrative_area_level_1') &&
          types.includes('political')
        ) {
          stateAbbr = short_name;
          state = long_name;
        } else if (types.includes('country') && types.includes('political')) {
          country = long_name;
        } else if (types.includes('street_number')) {
          streetNumber = short_name;
        } else if (types.includes('route')) {
          street = short_name;
        }
      });
      const newLocation = {
        formattedAddress: fullAddress
          ? formatted_address
          : `${city}, ${stateAbbr || state}`,
        city,
        state,
        country,
        lat,
        lng,
        placeId,
      };
      if (streetNumber && street) {
        newLocation.street = `${streetNumber} ${street}`;
      }
      updateLocalGeoCache(newLocation);
      return newLocation;
    })
    .catch((error) => {
      console.log('getGeoLocation Error: ', error);
      return null;
    });
  return location;
};

const GOOGLE_MAP_URL =
  'https://www.google.com/maps/search/?api=1&query=__LAT__,__LNG__';
const GOOGLE_MAP_ADD_PLACE_ID = '&query_place_id=__PLACE_ID__';

const generateMapUrl = (lat, lng, placeId) => {
  let url = GOOGLE_MAP_URL.replace('__LAT__', lat).replace('__LNG__', lng);
  if (placeId) {
    url = `${url}${GOOGLE_MAP_ADD_PLACE_ID.replace('__PLACE_ID__', placeId)}`;
  }
  return url;
};

const minimizeOrganizer = (org) => {
  const o = {
    name: org.name,
    status: org.status,
  };
  if (org.pid) {
    o.pid = org.pid;
  }
  if (org.url) {
    o.url = org.url;
  }
  if (org.email) {
    o.email = org.email;
  }
  if (org.phone) {
    o.phone = org.phone;
  }
  if (org.desc) {
    o.desc = org.desc;
  }
  if (org.pid) {
    o.pid = org.pid;
  }
  if (org.social.length > 0) {
    o.social = org.social;
  }
  return o;
};

const minimizeEvent = (event) => {
  const e = {
    name: event.name,
    date: event.date,
    orgId: event.orgId,
    location: {},
    status: event.status,
  };
  if (event.featuredPhoto && event.featuredPhoto.pid) {
    event.pid = event.featuredPhoto.pid;
  }
  if (event.location) {
    if (event.location.inperson) {
      e.location.inperson = event.location.inperson;
    }
    if (event.location.online) {
      e.location.online = event.location.online;
    }
    if (event.location.phone) {
      e.location.phone = event.location.phone;
    }
  }
  if (event.priceType !== 'free') {
    e.price = event.price;
  } else {
    e.price = 'Free';
  }
  if (event.register) {
    e.register = event.register;
  }
  if (event.desc && event.desc.search) {
    e.desc = event.desc.search;
  }
  const eTags = event.tags.concat(event.otherFilters);
  if (eTags.length > 0) {
    e.tags = eTags;
  }
  if (event.social.length > 0) {
    e.social = event.social;
  }
  return e;
};

function parsePhoneNumber(phoneValue) {
  let phoneCountry = '';
  let phoneNumberIntl = '';
  let phoneUpdated = false;
  if (phoneValue) {
    const REGEX = /([A-Z][A-Z])\s([+\d\s]+)/gi;
    const regResult = REGEX.exec(phoneValue);
    if (regResult && regResult.length > 2) {
      phoneCountry = regResult[1];
      phoneNumberIntl = regResult[2];
    } else {
      phoneCountry = 'US';
      phoneNumberIntl = formatPhoneNumberIntl(`+1${phoneValue}`);
      phoneUpdated = true;
    }
  }
  return {
    phoneCountry,
    phoneNumberIntl,
    phoneUpdated,
  };
}

function formatIntlPhoneNumber(phoneValue) {
  if (!phoneValue) {
    return {
      teleNumberIntl: '',
      phoneNumberIntl: '',
      regionName: '',
    };
  }
  const REGEX = /\s+/gi;
  const { phoneCountry, phoneNumberIntl } = parsePhoneNumber(phoneValue);
  const leadNumbers = getCountryCallingCode(phoneCountry);
  const localNumber = formatPhoneNumber(phoneNumberIntl);
  let regionName;
  if (Intl && Intl.DisplayNames) {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    regionName = regionNames.of(phoneCountry);
  } else {
    regionName = phoneCountry.toUpperCase();
  }
  return {
    teleNumberIntl: phoneNumberIntl.replace(REGEX, ''),
    phoneNumberIntl: `+${leadNumbers} ${localNumber}`,
    regionName,
  };
}

export {
  checkIfOnline,
  capitalize,
  formatDate,
  formatDateRange,
  formatDateMonthYear,
  formatDateMonthDayYear,
  formatSelectDate,
  formatEventDates,
  timezones,
  isValidTimezone,
  defaultTimezone,
  defaultTimezoneAbr,
  defaultCountry,
  getDayInMinutes,
  getDayTypeId,
  getDayType,
  verifyDayType,
  validURL,
  cleanUrl,
  toURL,
  getGeoLocation,
  minimizeOrganizer,
  minimizeEvent,
  generateMapUrl,
  parsePhoneNumber,
  formatIntlPhoneNumber,
};
