/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
  engineName,
  engineVersion,
} from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { loggerError, getLogId } from 'vendors/firebase/fireauth';
import Footer from 'components/Footer';
import Header from 'components/Header';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    zIndex: 0,
    display: 'inline',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: 'calc(100vh - 70px - 204px)',
    padding: `${theme.spacing(3)} ${theme.spacing(0.5)}`,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 90px - 155px)',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 90px - 118px)',
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
      minHeight: 'calc(100vh - 90px - 76px)',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 50,
  },
  button: {
    textTransform: 'inherit',
    fontSize: '1.25rem',
    padding: '9px 30px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
      float: 'right',
      margin: '0 10px',
      minWidth: 166,
    },
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(err, errorInfo) {
    const logId = getLogId();
    console.log(
      `Browser: ${browserName} ${fullBrowserVersion}, OS: ${osName} ${osVersion}`
    );
    loggerError(
      logId,
      `ErrorBoundary: ${err.message}
      Browser: ${browserName} ${fullBrowserVersion}
      OS: ${osName} ${osVersion}
      Engine: ${engineName} ${engineVersion}

      Error Details:${JSON.stringify(errorInfo)}`
    );
  }

  render() {
    const { classes, history } = this.props;
    if (this.state.hasError) {
      return (
        <main className={classes.root}>
          <Header />
          <div
            className={ClassNames(classes.content, 'page-transition fade-in')}
          >
            <div>
              <Typography variant='h3' align='center'>
                <strong>Oh no! Something went wrong.</strong>
              </Typography>
              <Typography variant='h6' align='center'>
                Our team has been notified of the problem and will look into a
                fix right away.
              </Typography>
              <div className={classes.buttons}>
                <Button
                  variant='contained'
                  onClick={history.goBack}
                  color='primary'
                  size='small'
                  className={classes.button}
                >
                  Go Back
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => window.location.reload()}
                  color='primary'
                  size='small'
                  className={classes.button}
                >
                  Try Again
                </Button>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(withStyles(styles)(ErrorBoundary));
