/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import usePlacesAutocomplete from 'use-places-autocomplete';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

import CustomTextField from 'components/Inputs/CustomTextField';
import { getGeoLocation } from 'utilities/utils';
import { localStateAvailable } from 'hooks/useStorageState';

const renderInputComponent = (inputProps) => {
  const {
    classes,
    inputRef = () => {},
    onChange = () => {},
    onCancel = () => {},
    tabIndex,
    ref,
    variant,
    error,
    ...other
  } = inputProps;

  if (variant === 'outlined') {
    return (
      <CustomTextField
        variant='outlined'
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
            error: classes.error,
          },
        }}
        fullWidth
        error={error}
        onChange={onChange}
        onCancel={onCancel}
        style={{ paddingBottom: 10 }}
        {...other}
      />
    );
  } else {
    delete other.showLoading;
    delete other.places;
    return (
      <TextField
        variant={variant}
        onChange={onChange}
        onCancel={onCancel}
        error={error}
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
            error: classes.error,
          },
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.label,
            focused: classes.focused,
            error: classes.error,
          },
        }}
        inputProps={{
          tabIndex,
          autoComplete: 'off',
          autoCorrect: 'off',
          autoCapitalize: 'none',
          spellCheck: 'false',
        }}
        {...other}
      />
    );
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    '& > div': {
      font: '600 1.29rem sans-serif',
      color: theme.palette.companyPrimary.main,
      '&::before': {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid !important',
        borderBottomColor: `${theme.palette.companyPrimary.dark} !important`,
      },
    },
    '& > p': {
      backgroundColor: 'white',
      zIndex: 99,
      border: '1px solid',
      padding: 4,
      marginTop: 0,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  popper: {
    marginTop: -11,
  },
  label: {
    width: 'max-content',
    color: theme.palette.companyPrimary.main,
    '&$focused': {
      color: theme.palette.companyPrimary.dark,
    },
  },
  error: {
    color: `${theme.palette.company.danger} !important`,
  },
  focused: {
    outline: 'none',
    borderColor: theme.palette.companyPrimary.main,
  },
}));

const LocationInput = React.forwardRef(
  (
    {
      className,
      disabled,
      error,
      fullAddress,
      fullWidth,
      helperText,
      label,
      id,
      initValue,
      onChange,
      onClick,
      onEnter,
      placeholder,
      required,
      tabIndex,
      variant,
    },
    ref
  ) => {
    const classes = useStyles();
    const requestOptions = fullAddress ? {} : { types: ['(regions)'] };
    const {
      ready,
      suggestions: { loading, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions,
      debounce: 300,
      cache: localStateAvailable() ? 86400 : false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState(
      initValue.formattedAddress ? initValue.formattedAddress : ''
    );
    const [address, setAddress] = useState(initValue);
    const filteredData = fullAddress
      ? data
      : data.filter((ele) => {
          const { types } = ele;
          return types.includes('locality') || types.includes('postal_code');
        });

    const onSuggestionsFetchRequested = () => {};

    const onSuggestionSelected = (e, { suggestion }) => {
      let addr;
      if (fullAddress) {
        addr = suggestion.description;
      } else {
        addr = `${suggestion.terms[0].value}, ${suggestion.terms[1].value}`;
      }
      setInputValue(addr);
      getGeoLocation(suggestion.place_id, fullAddress)
        .then((geoLoc) => {
          if (geoLoc) {
            const newAddress = {
              street: geoLoc.street || '',
              formattedAddress: geoLoc.formattedAddress,
              city: geoLoc.city,
              state: geoLoc.state,
              country: geoLoc.country,
              lat: geoLoc.lat,
              lng: geoLoc.lng,
              placeId: geoLoc.placeId,
            };
            setAddress(newAddress);
            if (onChange) onChange(newAddress);
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
        });
    };

    const clearAddress = () => {
      const newAddress = {
        street: '',
        formattedAddress: '',
        city: '',
        state: '',
        country: '',
        lat: '',
        lng: '',
        placeId: '',
      };
      setAddress(newAddress);
      if (onChange) onChange(newAddress);
    };

    const handleCancel = () => {
      setInputValue('');
      clearAddress();
    };

    const handleChange = (e, { newValue, method }) => {
      if (method === 'type') {
        setValue(e.target.value);
        setInputValue(newValue);
        clearAddress();
      }
    };

    const getSuggestionValue = (suggestion) => {
      if (fullAddress) {
        return suggestion.description;
      } else {
        return `${suggestion.terms[0].value}, ${suggestion.terms[1].value}`;
      }
    };

    const renderSuggestion = (suggestion, { isHighlighted }) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <MenuItem
          selected={isHighlighted}
          component='div'
          style={{ verticalAlign: 'bottom' }}
        >
          <strong>{main_text}</strong>
          <small style={{ marginLeft: 8 }}>{secondary_text}</small>
        </MenuItem>
      );
    };

    const autosuggestProps = {
      renderInputComponent,
      suggestions: filteredData,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested: clearSuggestions,
      getSuggestionValue,
      renderSuggestion,
      onSuggestionSelected,
      highlightFirstSuggestion: true,
    };

    const handleClick = () => {
      if (onClick) {
        onClick();
        setTimeout(() => {
          anchorEl.focus();
        }, 500);
      }
    };

    const handleKeyDown = (e) => {
      const key = e.which ? e.which : e.keyCode;
      if (key === 9 || key === 13) {
        if (
          filteredData.length === 0 &&
          (inputValue === '' || inputValue !== address.city)
        ) {
          clearAddress();
          if (onEnter) onEnter();
        }
      }
    };

    return (
      <div
        ref={ref}
        className={ClassNames(classes.root, className)}
        onClick={handleClick}
      >
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            disabled: disabled || !ready,
            fullWidth,
            id,
            label,
            places: true,
            showLoading: loading,
            variant,
            placeholder,
            value: inputValue,
            error,
            helperText,
            required,
            onChange: handleChange,
            onCancel: handleCancel,
            onKeyDown: handleKeyDown,
            inputRef: (node) => {
              setAnchorEl(node);
            },
            tabIndex,
          }}
          theme={{
            input: ClassNames({
              [classes.input]: variant === 'standard',
            }),
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Popper
              anchorEl={anchorEl}
              open={Boolean(options.children)}
              placement='bottom'
              disablePortal={false}
              modifiers={{
                flip: {
                  enabled: false,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent',
                },
              }}
              className={ClassNames({
                [classes.popper]: variant === 'outlined',
              })}
              style={{ zIndex: 9999 }}
            >
              <Paper
                square
                {...options.containerProps}
                style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
              >
                {options.children}
              </Paper>
            </Popper>
          )}
        />
      </div>
    );
  }
);

LocationInput.propTypes = {
  initValue: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullAddress: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  tabIndex: PropTypes.number,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};

LocationInput.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  fullAddress: false,
  fullWidth: false,
  helperText: '',
  label: '',
  id: 'location',
  onChange: null,
  onClick: null,
  onEnter: null,
  placeholder: 'Enter city or zip code',
  required: false,
  tabIndex: 0,
  variant: 'outlined',
};

export default LocationInput;
