/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { SITEURL, CLOUDINARY } from 'constants/general';

const poweredBy = `${CLOUDINARY}v1581102248/sona/svg/powered_by_AMGT_v1.png`;

const useStyles = makeStyles((theme) => ({
  poweredBy: {
    position: 'fixed',
    zIndex: '999',
    top: 0,
    width: '100%',
    height: 25,
    backgroundColor: '#fafafa',
    textAlign: 'center',
    boxShadow: 'rgb(50 50 50 / 75%) 0px 8px 20px 0px',
  },
  navLink: {
    color: theme.palette.companyPrimary.main,
    fontSize: 14,
    fontWeight: '800',
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  amgtIcon: {
    verticalAlign: 'bottom',
  },
}));

const PoweredBy = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  return (
    <div id={id} ref={ref} className={classes.poweredBy}>
      <a
        href={SITEURL}
        className={classes.navLink}
        target='_blank'
        rel='noopener noreferrer'
      >
        <span style={{ padding: '3px 10px' }}>Powered by</span>
        <img
          src={poweredBy}
          alt='powered by A Mighty Good Time'
          className={classes.amgtIcon}
        />
      </a>
    </div>
  );
});

PoweredBy.propTypes = {
  id: PropTypes.string,
};

PoweredBy.defaultProps = {
  id: '',
};

export default PoweredBy;
