/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  fireauth,
  getUserMetadata,
  disableAnalytics,
  logAnalyticsEvent,
} from 'vendors/firebase/main';
import {
  doCreateUser,
  doUserVerified,
  doUpdateEmail,
  doUpdateFavorites,
  doUpdateSubscriber,
  doUpdateUser,
  doSignInWithEmailAndPassword,
  isTestEmails,
} from 'vendors/firebase/fireauth';
import { doGetUserRef } from 'vendors/firebase/firestore';
import { capitalize } from 'utilities/utils';
import isBannedEmail from 'utilities/bandedDomains';
import { NON_PROFIT, SITEURL } from 'constants/general';
import { MEMBER_PLANS } from './constants/memberships';

const UserContext = React.createContext();
const { Provider, Consumer } = UserContext;

const generateInitials = (firstName, lastName) => {
  const fName = firstName ? firstName.trim() : '';
  const lName = lastName ? lastName.trim() : '';
  const firstInitial = fName.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/) || [];
  const lastInitial = lName.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/) || [];
  return firstInitial.join('').concat(lastInitial.join('')).toUpperCase();
};
const REGEX = /\s+/gi;
const generateDisplayNames = (firstName, lastName) => {
  const fName = firstName
    ? capitalize(firstName.trim().replace(REGEX, ' '))
    : '';
  const lName = lastName ? capitalize(lastName.trim().replace(REGEX, ' ')) : '';
  const lastInitial = lName.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/) || [];
  const displayName = `${fName} ${lName}`.trim();
  const publicName = `${fName} ${lastInitial.join('').toUpperCase()}`.trim();
  return {
    firstName: fName,
    lastName: lName,
    displayName,
    publicName,
  };
};
const arrayRemove = (arr, value) => arr.filter((ele) => ele !== value);

const CreateUser = (
  email,
  password,
  firstName,
  lastName,
  paymentCustomerId,
  paymentOptions,
  memberPlan,
  nonProfitStatus,
  recaptchaToken,
  continueUrl
) => {
  if (isBannedEmail(email)) {
    return Promise.reject(new Error('Email domain is prohibited'));
  }
  return doCreateUser(
    email,
    password,
    generateDisplayNames(firstName, lastName),
    generateInitials(firstName, lastName),
    firstName,
    lastName,
    paymentCustomerId,
    paymentOptions,
    memberPlan,
    nonProfitStatus,
    recaptchaToken,
    continueUrl
  );
};

const UserProvider = ({ children }) => {
  const [initializing, setInitializing] = useState(true);
  const [isAuth, setIsAuth] = useState(fireauth.currentUser);
  const [uid, setUid] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [initials, setInitials] = useState('');
  const [imgSrc, setImgSrc] = useState('');
  const [thumbSrc, setThumbSrc] = useState('');
  const [pid, setPid] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailVerifySent, setEmailVerifySent] = useState(false);
  const [memberPlan, setMemberPlan] = useState(MEMBER_PLANS.NONE);
  const [nonProfitStatus, setNonProfitStatus] = useState(NON_PROFIT.NONE);
  const [paymentCustomerId, setPaymentCustomerId] = useState('');
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentIgnore, setPaymentIgnore] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [applicationId, setApplicationId] = useState('');

  const onLogout = () =>
    fireauth.signOut().then(() => {
      setIsAuth(false);
      setInitializing(false);
      window.location.href = SITEURL.concat('?p=logout');
    });

  const loadMetaData = async () => {
    await getUserMetadata(uid).then((metadata) => {
      const { initials, imgSrc, thumbSrc } = metadata;
      setInitials(initials);
      setImgSrc(imgSrc);
      setThumbSrc(thumbSrc);
    });
  };

  const completeAuthorization = async (currAuth) => {
    if (currAuth) {
      setUid(currAuth.uid);
      setDisplayName(currAuth.displayName);
      setEmail(currAuth.email);
      setEmailVerified(currAuth.emailVerified);
      setIsAuth(true);
      isTestEmails(currAuth.email);
      setInitializing(false);
    } else {
      setIsAuth(false);
      setUid('');
      setDisplayName('');
      setEmail('');
      setInitials('');
      setImgSrc('');
      setThumbSrc('');
      setPid('');
      setFirstName('');
      setLastName('');
      setMemberPlan(MEMBER_PLANS.NONE);
      setNonProfitStatus(NON_PROFIT.NONE);
      setPaymentCustomerId('');
      setPaymentOptions([]);
      setPaymentIgnore(true);
      setFavorites([]);
      setApplicationId('');
      setInitializing(false);
    }
  };

  useEffect(() => {
    const authUnsubscribe = fireauth.onAuthStateChanged((currAuth) =>
      completeAuthorization(currAuth)
    );
    return () => {
      authUnsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let unsub;
    let watchFirstName = firstName;
    let watchLastName = lastName;
    let watchPid = pid;
    let watchMemberPlan = memberPlan;
    let wathcNonProfitStatus = nonProfitStatus;
    let watchPaymentCustomerId = paymentCustomerId;
    let watchPaymentOptionsLength = paymentOptions.length;
    let watchPaymentOptions = JSON.stringify(paymentOptions.sort());
    let watchPaymentIgnore = paymentIgnore;
    let watchFavoritesLength = favorites.length;
    let watchFavorites = JSON.stringify(favorites.sort());
    let watchApplicationId = applicationId;

    if (uid) {
      doGetUserRef(uid).then((ref) => {
        unsub = ref.onSnapshot(
          (snapshot) => {
            if (snapshot && snapshot.exists) {
              const currUser = snapshot.data();
              if (currUser.pending) {
                doUserVerified();
              }
              if (currUser.internal) {
                disableAnalytics();
              }
              if (
                watchFirstName !== currUser.firstName ||
                watchLastName !== currUser.lastName ||
                watchPid !== currUser.pid
              ) {
                fireauth.currentUser.reload().then(async () => {
                  await loadMetaData();
                  setDisplayName(fireauth.currentUser.displayName);
                  setEmail(fireauth.currentUser.email);
                  setEmailVerified(fireauth.currentUser.emailVerified);
                  if (watchFirstName !== currUser.firstName) {
                    setFirstName(currUser.firstName);
                    watchFirstName = currUser.firstName;
                  }
                  if (watchLastName !== currUser.lastName) {
                    setLastName(currUser.lastName);
                    watchLastName = currUser.lastName;
                  }
                  if (watchPid !== currUser.pid) {
                    setPid(currUser.pid);
                    watchPid = currUser.pid;
                  }
                });
              }
              if (watchMemberPlan !== currUser.memberPlan) {
                setMemberPlan(currUser.memberPlan);
                watchMemberPlan = memberPlan;
              }
              if (wathcNonProfitStatus !== currUser.nonProfitStatus) {
                setNonProfitStatus(currUser.nonProfitStatus);
                wathcNonProfitStatus = currUser.nonProfitStatus;
              }
              if (watchPaymentCustomerId !== currUser.paymentCustomerId) {
                setPaymentCustomerId(currUser.paymentCustomerId);
                watchPaymentCustomerId = currUser.paymentCustomerId;
              }
              if (
                watchPaymentOptionsLength !== currUser.paymentOptions.length ||
                watchPaymentOptions !==
                  JSON.stringify(currUser.paymentOptions.sort())
              ) {
                setPaymentOptions(currUser.paymentOptions);
                watchPaymentOptionsLength = currUser.paymentOptions.length;
                watchPaymentOptions = JSON.stringify(
                  currUser.paymentOptions.sort()
                );
              }
              if (watchPaymentIgnore !== currUser.paymentIgnore) {
                setPaymentIgnore(currUser.paymentIgnore);
                watchPaymentIgnore = currUser.paymentIgnore;
              }
              if (!currUser.favorites) {
                setFavorites([]);
              } else if (
                watchFavoritesLength !== currUser.favorites.length ||
                watchFavorites !== JSON.stringify(currUser.favorites.sort())
              ) {
                setFavorites(currUser.favorites);
                watchFavoritesLength = currUser.favorites.length;
                watchFavorites = JSON.stringify(currUser.favorites.sort());
              }
              if (watchApplicationId !== currUser.applicationId) {
                setApplicationId(currUser.applicationId);
                watchApplicationId = currUser.applicationId;
              }
            }
          },
          (err) => {
            console.log(`Encountered error: ${err}`);
          }
        );
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
    // eslint-disable-next-line
  }, [uid]);

  const pauseEmailVerify = (continueUrl) => {
    setEmailVerifySent(true);
  }

  const refreshUser = async () => {
    return await fireauth.currentUser
      .reload()
      .then(async () => {
        if (fireauth.currentUser.emailVerified) {
          console.log('Refresh: Email verified. Clear interval.');
          setEmailVerified(true);
          return true;
        }
        return false;
      })
      .catch((err) => false);
  };

  const onLogin = (email, password) =>
    doSignInWithEmailAndPassword(email, password).then(async (currAuth) => {
      await completeAuthorization(currAuth);
      return Promise.resolve(currAuth);
    });

  const handleUpdateUser = (photoURL, pid, firstName, lastName) => {
    const { email } = fireauth.currentUser;
    const displayName = `${firstName} ${lastName}`;
    return doUpdateUser(
      displayName.trim(),
      photoURL,
      pid,
      firstName,
      lastName
    ).then(() => {
      return doUpdateSubscriber(email, firstName, lastName);
    });
  };

  const handleUpdateEmail = (password, newEmail) => {
    const { email } = fireauth.currentUser;
    return doUpdateEmail(password, newEmail).then(() => {
      setEmail(newEmail);
      return doUpdateSubscriber(newEmail, firstName, lastName, email);
    });
  };

  const onFavorite = (eid, add, context) => {
    if (isAuth) {
      let newFavorites;
      if (add) {
        newFavorites = favorites.concat([eid]);
        logAnalyticsEvent('favorite', context);
      } else {
        newFavorites = arrayRemove(favorites, eid);
      }
      return doUpdateFavorites(newFavorites).then(() => {
        setFavorites(newFavorites);
      });
    }
    return Promise.resolve('ok');
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Provider
        value={{
          init: initializing,
          isAuth,
          uid,
          displayName,
          initials,
          imgSrc,
          thumbSrc,
          pid,
          firstName,
          lastName,
          email,
          emailVerified,
          emailVerifySent,
          memberPlan,
          nonProfitStatus,
          paymentCustomerId,
          paymentOptions,
          paymentIgnore,
          favorites,
          applicationId,
          onLogin,
          onLogout,
          doUpdateUser: handleUpdateUser,
          doUpdateEmail: handleUpdateEmail,
          onFavorite,
          pauseEmailVerify,
          refreshUser,
        }}
      >
        {children}
      </Provider>
    </Suspense>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  UserContext,
  UserProvider,
  Consumer as UserConsumer,
  CreateUser,
  generateInitials,
  generateDisplayNames,
};
