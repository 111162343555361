/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Blurhash } from 'react-blurhash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

import Divider from 'components/Divider';
import Footer from 'components/Footer';
import Header from 'components/Header';
import HowItWorksFAQ from 'components/HowItWorksFAQ';
import HowToEvents from 'components/HowToEvents';
import LoadImage from 'components/LoadImage';
import SubscribeBanner from 'components/SubscribeBanner';
import { CLOUDINARY, SITEURL } from 'constants/general';
import {
  CONTACTUS_URI,
  EVENT_ORGANIZERS_URI,
  SIGNUP_URI,
} from 'constants/routes';

const FOR_PARTICIPANTS_ANCHOR = 'for-participants';

const devices = `${CLOUDINARY}/sona/img/how-it-works-devices-v2.png`;
const groupDiverseSeniorsParkBlurhash =
  '|FN^C+H;.mD500r_P9NGI9^lMcRk9Zxp%#xvRoV@caRkRj%zxaNaE1nP-p%4InMyeoSwjEx]kUV@rEw4%f-;xukpoax]S$R6nhS2tlpIMxxatRNG%1xZV[tR-;RPt7tRnlw[i]$Nxbr_%3S~Rixa.SaKadS1V?bIt8x^s:';
const blackCoupleIpadBlurhash =
  '|QKwOpuh?w9aR:i^Z~IBMxtiQ-Ionjt6xus.NHIoPS-WRixGRiaexHXRof%NtRjYoKRQNat7RiVsMxRORiozofNHS0rsWBspbvxbSgRjs9tRo}W=ozsoR*ofxaofoejZRjt6tRt7xbS2WBa#oMWBSesTV@R%jsjZoMW.of';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
  },
  content: {
    flex: 1,
    maxWidth: 1280,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `${theme.spacing(2)} 0`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    },
  },
  link: {
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  contactUs: {
    paddingTop: theme.spacing(3),
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  banner: {
    position: 'relative',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: 100,
  },
  banner1: {
    width: '100%',
    paddingRight: 3,
  },
  banner2: {
    width: '100%',
    paddingLeft: 3,
  },
  searchBtn: {
    position: 'absolute',
    left: '50%',
    bottom: -30,
    borderRadius: 40,
    cursor: 'default',
    textTransform: 'inherit',
    fontSize: 26,
    border: '6px solid white',
    width: 160,
    height: 68,
    transform: 'translate(-50%, 0)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: 200,
      height: 72,
      bottom: -30,
      fontSize: 28,
    },
    [theme.breakpoints.up('md')]: {
      width: 220,
      height: 80,
      bottom: -22,
      fontSize: 32,
    },
  },
  icon: {
    fontSize: 30,
    marginRight: 3,
    [theme.breakpoints.up('md')]: {
      fontSize: 34,
    },
  },

  bannerText: {
    position: 'relative',
    display: 'flex',
  },
  upcomming: {
    width: '100%',
    textAlign: 'center',
    top: 2,
    left: -8,
    '& > img': {
      maxWidth: 280,
    },
    [theme.breakpoints.up('sm')]: {
      '& > img': {
        maxWidth: 400,
        top: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc((100vw * 0.44) - 20px)',
      textAlign: 'end',
      top: -12,
      left: 0,
      '& > img': {
        maxWidth: 350,
      },
    },
  },
  promote: {
    width: '100%',
    padding: '0 10px 20px 10px',
    fontSize: 32,
    [theme.breakpoints.up('sm')]: {
      padding: '0px 10px 20px 10px',
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc((100vw * 0.56) - 20px)',
      padding: '40px 60px 20px 0',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc((100vw * 0.56) - 20px)',
      padding: '61px 0 20px 0',
    },
  },
  promoteBody: {
    padding: '0 10px 30px 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 10px 40px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '20px 10px 50px',
    },
  },
  gridColumnContainer: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridColumnGap: '25px',
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      gridColumnGap: '90px',
      padding: '0 20px',
    },
  },
  gridColumnLeft: {
    justifyContent: 'center',
    display: 'flex',
    height: 'fit-content',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  gridColumnRigth: {
    justifyContent: 'center',
    display: 'flex',
    height: 'fit-content',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  gridRowContainer: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
  },
  gridRow: {
    justifyContent: 'center',
    display: 'grid',
    minHeight: 68,
  },
  tile: {
    minWidth: 298,
    maxWidth: 298,
    maxHeight: 156,
    height: '100%',
    borderRadius: 10,
    textAlign: 'center',
    padding: `${theme.spacing(1)} 0 0`,
    marginBottom: 25,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
      minWidth: 360,
      maxWidth: 360,
      maxHeight: 170,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 50,
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow:
        '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
    },
  },
  tileTitle: {
    padding: '5px 10px 13px 10px',
    fontWeight: '800',
    whiteSpace: 'nowrap',
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
  },
  buttonDiv: {
    display: 'flex',
    color: theme.palette.common.white,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    fontWeight: '600',
    alignItems: 'center',
    padding: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  actionBtn: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    backgroundColor: '#fff',
    color: 'black',
    minWidth: 150,
    borderRadius: 50,
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  forParticipantsColor: {
    backgroundColor: theme.palette.companySecondary.main,
  },
  forOrganizersColor: {
    backgroundColor: theme.palette.companyPrimary.main,
  },
  largestOnlineCalendar: {
    position: 'relative',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: 100,
    [theme.breakpoints.up('sm')]: {
      marginTop: 30,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },
  },
  largestOnlineCalendarText: {
    padding: '5px 10px 0',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(100% - 290px)',
      padding: '5px 0 0 10px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
      padding: '5px 10px 5px',
    },
  },
  largestOnlineCalendarTitle: {
    paddingBottom: 20,
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
  },
  largestOnlineCalendarContent: {
    paddingTop: 10,
  },
  largestOnlineCalendarImg: {
    position: 'relative',
    height: 'auto',
    '& > img': {
      width: '100vw',
      marginLeft: -20,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(50vw - 10px)',
      '& > img': {
        width: '100%',
        marginLeft: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: 10,
      '& > img': {
        minHeight: 270,
        maxHeight: 270,
        minWidth: 464,
        maxWidth: 464,
        borderRadius: 10,
      },
    },
  },
  largestOnlineCalendarOverlay: {
    position: 'absolute',
    top: 0,
    left: -2,
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      background:
        'linear-gradient(90deg, rgba(250,250,250,1) 1%, rgba(250,250,250,0.75) 15%, rgba(250,250,250,0) 30%, rgba(250,250,250,0) 100%)',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 619,
    },
  },
  freeAccountTitle: {
    padding: '0 10px 20px',
  },
  freeAccountContent: {
    padding: '10px 10px 30px',
  },
  freeAccountImg: {
    position: 'relative',
    maxHeight: 290,
    width: 'auto',
    [theme.breakpoints.only('xs')]: {
      '& > img': {
        maxHeight: 290,
        width: 'auto',
        maxWidth: '80vw',
        height: 'auto',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(40vw - 10px)',
      '& > img': {
        width: '100%',
        height: 'auto',
        marginLeft: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: 480,
      maxWidth: 480,
      maxHeight: 340,
      borderRadius: 10,
    },
  },
  organizers: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  organizersImg: {
    position: 'relative',
    zIndex: 9,
    width: '100%',
    height: 'auto',
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: 250,
      maxWidth: 250,
      height: 350,
      maxHeight: 350,
    },
    [theme.breakpoints.up('md')]: {
      width: 300,
      maxWidth: 300,
      height: 300,
      maxHeight: 300,
      borderRadius: 10,
      '& > img': {
        borderRadius: 10,
      },
    },
  },
  organizersOverlay: {
    display: 'none',
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    background:
      'linear-gradient(90deg, rgba(250,250,250,0) 0%, rgba(250,250,250,0) 60%, rgba(250,250,250,1) 100%)',
    width: 400,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: 250,
    },
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
  organizersTitle: {
    padding: '0 10px 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 10px 15px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 10px 20px',
    },
  },
  organizersSubtitle: {
    padding: '10px 10px 5px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 10px 10px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 10px 15px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px 10px 25px',
    },
  },
  organizersContent: {
    padding: '17px 10px 30px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px 16px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 10px 30px',
    },
  },
  learnMoreBtn: {
    textAlign: 'center',
    padding: '0 15px',
    '& > a': {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      borderRadius: 40,
      height: 50,
      width: 200,
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      '& > a': {
        width: 225,
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight,
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > a': {
        width: 250,
        fontSize: theme.typography.h2.fontSize,
        lineHeight: theme.typography.h2.lineHeight,
      },
    },
  },
  createAcctBtn: {
    textAlign: 'center',
    '& > a': {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      borderRadius: 40,
      width: 'fit-content',
      height: 50,
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginLeft: 15,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 20,
      '& > a': {
        width: 225,
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight,
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > a': {
        width: 250,
        fontSize: theme.typography.h2.fontSize,
        lineHeight: theme.typography.h2.lineHeight,
      },
    },
  },
}));

const orgBackground = (
  <div style={{ position: 'absolute', top: 0, left: 0 }}>
    <Blurhash
      hash={groupDiverseSeniorsParkBlurhash}
      width={'100vw'}
      height={'400px'}
      resolutionX={72}
      resolutionY={72}
      punch={1}
    />
  </div>
);

const HowItWorks = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [banner1Img, setBanner1Img] = useState('');
  const [banner2Img, setBanner2Img] = useState('');
  const [upcomingEventsImg, setUpcomingEventsImg] = useState('');
  const [blackCoupleIpad, setBlackCoupleIpad] = useState('');
  const [groupDiverseSeniorsPark, setGroupDiverseSeniorsPark] = useState('');
  const [orgImgBackground, setOrgImgBackground] = useState(null);

  const scrollToTop = (duration) =>
    scroll.scrollToTop({
      duration,
      delay: 0,
      smooth: true,
    });

  useEffect(() => {
    scrollToTop(0);
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    const resize = () => {
      if (window.innerWidth < 600) {
        setOrgImgBackground(window.innerWidth > 400 ? orgBackground : null);
        setBanner1Img(
          `${CLOUDINARY}/sona/img/black-couple-riding-bicycles-xs-v1.jpg`
        );
        setBanner2Img(`${CLOUDINARY}/sona/img/white-man-with-ipad-xs-v1.jpg`);
        setUpcomingEventsImg(
          `${CLOUDINARY}/sona/img/upcoming-events-xs-v1.png`
        );
        setBlackCoupleIpad(
          `${CLOUDINARY}/sona/img/black-couple-ipad-md-v1.jpg`
        );
        setGroupDiverseSeniorsPark(
          `${CLOUDINARY}/sona/img/group-diverse-seniors-park-v1.jpg`
        );
      } else if (window.innerWidth < 960) {
        setOrgImgBackground(null);
        setBanner1Img(
          `${CLOUDINARY}/sona/img/group-diverse-seniors-yoga-sm-v1.jpg`
        );
        setBanner2Img(
          `${CLOUDINARY}/sona/img/african-woman-laptop-headphones-sm-v1.jpg`
        );
        setUpcomingEventsImg(
          `${CLOUDINARY}/sona/img/upcoming-events-xs-v1.png`
        );
        setBlackCoupleIpad(
          `${CLOUDINARY}/sona/img/black-couple-ipad-sm-v1.jpg`
        );
        setGroupDiverseSeniorsPark(
          `${CLOUDINARY}/sona/img/group-diverse-seniors-park-sm-v1.jpg`
        );
      } else {
        setOrgImgBackground(null);
        setBanner1Img(
          `${CLOUDINARY}/sona/img/group-diverse-seniors-yoga-md-v1.jpg`
        );
        setBanner2Img(
          `${CLOUDINARY}/sona/img/african-woman-laptop-headphones-md-v1.jpg`
        );
        setUpcomingEventsImg(
          `${CLOUDINARY}/sona/img/upcoming-events-sm-v1.png`
        );
        setBlackCoupleIpad(
          `${CLOUDINARY}/sona/img/black-couple-ipad-md-v2.jpg`
        );
        setGroupDiverseSeniorsPark(
          `${CLOUDINARY}/sona/img/group-diverse-seniors-park-v1.jpg`
        );
      }
    };
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  });

  return (
    <>
      <Helmet>
        <title>
          Find older adult classes- senior activities: A Mighty Good Time
        </title>
        <link rel='canonical' href={SITEURL.concat('/howitworks')} />
        <meta
          name='description'
          content='How to find or list events, senior activities and interactive experiences for older adults in your area.'
        />
      </Helmet>
      <main id={id} ref={ref} className={classes.root}>
        <Header />
        <SubscribeBanner />
        <div className={ClassNames(classes.content, 'page-transition fade-in')}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant='h2'
                align='center'
                color='secondary'
                style={{ paddingBottom: 20 }}
              >
                <strong>How it works</strong>
              </Typography>
              <div className={classes.banner}>
                <LoadImage
                  src={banner1Img}
                  alt='Elderly people outdoors carrying yoga mats'
                  className={classes.banner1}
                />
                <LoadImage
                  src={banner2Img}
                  alt='senior african woman using laptop computer while wearing headphones'
                  className={classes.banner2}
                />
                <Button
                  variant='contained'
                  color='secondary'
                  fullWidth
                  className={classes.searchBtn}
                  disableRipple
                >
                  <SearchIcon className={classes.icon} />
                  Search
                </Button>
              </div>
              <div className={classes.bannerText}>
                <LoadImage
                  src={upcomingEventsImg}
                  alt='upcoming events for adults 50+'
                  className={classes.upcomming}
                />
                <Hidden smDown>
                  <Typography
                    variant='h1'
                    color='inherit'
                    className={classes.promote}
                  >
                    The easiest way to find and promote{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>life-enriching</span>{' '}
                    experiences for{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>older adults.</span>
                  </Typography>
                </Hidden>
              </div>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={12}>
                <Typography
                  variant='h1'
                  color='inherit'
                  className={classes.promote}
                >
                  The easiest way to find and promote{' '}
                  <span style={{ whiteSpace: 'nowrap' }}>life-enriching</span>{' '}
                  experiences for{' '}
                  <span style={{ whiteSpace: 'nowrap' }}>older adults.</span>
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={12} className={classes.promoteBody}>
              <Typography variant='body1' color='inherit'>
                There are so many great activities and programs for adults over
                50, organized by top-quality event hosts around the world, but
                they can be challenging to find. We take the stress out of the
                search by curating them for you, making it easier than ever to
                find and promote experiences that help us stay social, active
                and engaged as we age.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.gridColumnContainer}>
                <div className={classes.gridColumnLeft}>
                  <ScrollLink
                    to={FOR_PARTICIPANTS_ANCHOR}
                    offset={fullScreen ? -60 : -90}
                    spy={true}
                    smooth={true}
                    duration={500}
                    ignoreCancelEvents={true}
                  >
                    <Paper className={classes.tile} elevation={2}>
                      <div className={classes.gridRowContainer}>
                        <div className={classes.gridRow}>
                          <div style={{ maxHeight: 85 }}>
                            <Typography
                              variant='h4'
                              align='center'
                              className={classes.tileTitle}
                            >
                              For Event Participants
                            </Typography>
                            <Divider
                              className={ClassNames(
                                classes.divider,
                                classes.forParticipantsColor
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className={ClassNames(
                            classes.gridRow,
                            classes.buttonDiv,
                            classes.forParticipantsColor
                          )}
                        >
                          <Button
                            variant='contained'
                            size='large'
                            className={classes.actionBtn}
                          >
                            <strong>Learn More</strong>
                          </Button>
                        </div>
                      </div>
                    </Paper>
                  </ScrollLink>
                </div>
                <div className={classes.gridColumnRigth}>
                  <Link
                    to={EVENT_ORGANIZERS_URI}
                    style={{ textDecoration: 'none' }}
                  >
                    <Paper className={classes.tile} elevation={2}>
                      <div className={classes.gridRowContainer}>
                        <div className={classes.gridRow}>
                          <div style={{ maxHeight: 85 }}>
                            <Typography
                              variant='h4'
                              align='center'
                              className={classes.tileTitle}
                            >
                              For Event Organizers
                            </Typography>
                            <Divider
                              className={ClassNames(
                                classes.divider,
                                classes.forOrganizersColor
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className={ClassNames(
                            classes.gridRow,
                            classes.buttonDiv,
                            classes.forOrganizersColor
                          )}
                        >
                          <Button
                            variant='contained'
                            size='large'
                            className={classes.actionBtn}
                          >
                            <strong>Learn More</strong>
                          </Button>
                        </div>
                      </div>
                    </Paper>
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} id={FOR_PARTICIPANTS_ANCHOR}>
              <Hidden smUp>
                <div
                  className={classes.largestOnlineCalendarImg}
                  style={{ marginTop: 30 }}
                >
                  <LoadImage
                    src={blackCoupleIpad}
                    blurhash={blackCoupleIpadBlurhash}
                    alt='black couple with ipad'
                    className={classes.largestOnlineCalendarImg}
                  />
                  <div className={classes.largestOnlineCalendarOverlay} />
                </div>
              </Hidden>
              <div className={classes.largestOnlineCalendar}>
                <div className={classes.largestOnlineCalendarText}>
                  <Typography
                    variant='h4'
                    color='inherit'
                    className={classes.largestOnlineCalendarTitle}
                  >
                    Events for adults over 50, hosted by recreation specialists
                    world-wide.
                  </Typography>
                  <Typography
                    variant='body1'
                    color='inherit'
                    className={classes.largestOnlineCalendarContent}
                  >
                    With a variety of{' '}
                    <strong>
                      in-person, virtual, and phone-accessible events
                    </strong>{' '}
                    on the calendar, you can quickly and easily browse a wide
                    range of activities provided by organizers across the world!
                  </Typography>
                </div>
                <Hidden xsDown>
                  <div className={classes.largestOnlineCalendarImg}>
                    <LoadImage
                      src={blackCoupleIpad}
                      blurhash={blackCoupleIpadBlurhash}
                      alt='black couple with ipad'
                      className={classes.largestOnlineCalendarImg}
                    />
                    <div className={classes.largestOnlineCalendarOverlay} />
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12}>
              <HowToEvents />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: 50, paddingBottom: fullScreen ? 30 : 50 }}
            >
              <Typography
                variant='h2'
                align={fullScreen ? 'left' : 'center'}
                style={{ padding: '0 10px' }}
              >
                <strong>Is this website free to use?</strong>
              </Typography>
              <Typography
                variant='h2'
                color='secondary'
                align={fullScreen ? 'left' : 'center'}
                style={{ padding: '5px 10px 30px' }}
              >
                <strong>You bet it is!</strong>
              </Typography>
              <Typography
                variant='body1'
                color='inherit'
                style={{ padding: '0 10px' }}
              >
                <strong>
                  It is always free to search for events and create an account.
                </strong>{' '}
                Since the events are hosted by different organizers, the cost to
                attend will vary in price. The event price will always be
                displayed at the top of the event profile and will be marked
                FREE if there is no ticket fee.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Hidden smUp>
                <div
                  className={classes.freeAccountImg}
                  style={{ marginBottom: 20 }}
                >
                  <LoadImage
                    src={devices}
                    alt='desktop iPad smartphone'
                    className={classes.freeAccountImg}
                  />
                </div>
              </Hidden>
              <div className={classes.banner}>
                <div>
                  <Typography
                    variant='h2'
                    color='inherit'
                    className={classes.freeAccountTitle}
                  >
                    Create your free account.
                  </Typography>
                  <Typography
                    variant='body1'
                    color='inherit'
                    className={classes.freeAccountContent}
                  >
                    While you don&#39;t need an account to browse the calendar,
                    having one lets you save your favorite activities all in one
                    place. You can also sign up for email notifications so that
                    you will be the first to hear about upcoming highlighted
                    events!
                  </Typography>
                  <div className={classes.createAcctBtn}>
                    <Button
                      component={Link}
                      to={{
                        pathname: SIGNUP_URI,
                      }}
                      variant='contained'
                      color='secondary'
                      size='large'
                    >
                      Create Account
                    </Button>
                  </div>
                </div>
                <Hidden xsDown>
                  <div className={classes.freeAccountImg}>
                    <LoadImage
                      src={devices}
                      alt='desktop iPad smartphone'
                      className={classes.freeAccountImg}
                    />
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: fullScreen ? 30 : 50, paddingBottom: 15 }}
            >
              <div className={classes.organizers}>
                <Hidden smUp>
                  <Typography
                    variant='h2'
                    color='secondary'
                    className={classes.organizersTitle}
                  >
                    <strong>Are you an event organizer?</strong>
                  </Typography>
                </Hidden>
                <div style={{ position: 'relative' }}>
                  <div className={classes.organizersImg}>
                    <LoadImage
                      src={groupDiverseSeniorsPark}
                      blurhash={groupDiverseSeniorsParkBlurhash}
                      alt='group diverse seniors in the park'
                      className={classes.organizersImg}
                    />
                    <div className={classes.organizersOverlay} />
                  </div>
                  {orgImgBackground}
                </div>
                <div>
                  <Hidden xsDown>
                    <Typography
                      variant='h2'
                      color='secondary'
                      className={classes.organizersTitle}
                    >
                      <strong>Are you an event organizer?</strong>
                    </Typography>
                  </Hidden>
                  <Typography
                    variant='h3'
                    color='inherit'
                    className={classes.organizersSubtitle}
                  >
                    We'll help you promote them to the 50+ community!
                  </Typography>
                  <Typography
                    variant='body1'
                    color='inherit'
                    className={classes.organizersContent}
                  >
                    We know how challenging it can be to find an effective way
                    to get the word out about your events. That's why we created
                    a free, simple to use online tool to help you reach your
                    desired audience.
                  </Typography>
                  <div className={classes.learnMoreBtn}>
                    <Button
                      component={Link}
                      to={{
                        pathname: EVENT_ORGANIZERS_URI,
                      }}
                      variant='contained'
                      color='secondary'
                      size='large'
                      fullWidth
                    >
                      Learn More
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <HowItWorksFAQ />
            </Grid>
            <Grid item xs={12} className={classes.contactUs}>
              <Typography
                variant='h6'
                align='center'
                color='inherit'
                style={{ paddingBottom: 0 }}
              >
                Questions?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' align='center' color='inherit'>
                Please{' '}
                <Link to={`${CONTACTUS_URI}`} className={classes.link}>
                  Contact us!
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </main>
    </>
  );
});

HowItWorks.propTypes = {
  id: PropTypes.string,
};

HowItWorks.defaultProps = {
  id: '',
};

export default HowItWorks;
