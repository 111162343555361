/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Done';

import { OTHERFILTERSLIST } from 'constants/general';

const useStyles = makeStyles((theme) => ({
  add: {
    margin: '2px 2px 2px 20px',
  },
  alignLeft: {
    justifyContent: 'left',
  },
  readOnly: {
    margin: 5,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  fullWidth: {
    width: '100%',
  },
  selected: {
    fontWeight: '800',
  },
  hidden: {
    display: 'none',
  },
  icon: {
    fontSize: '1.5rem',
  },
  grid: {
    padding: 5,
  },
  chipText: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
}));

const arrayRemove = (arr, value) => arr.filter((ele) => ele !== value);

const CategoryChips = React.forwardRef(
  (
    {
      id,
      className,
      color,
      fullWidth,
      hasChange,
      hideIcon,
      maxColumns,
      onChange,
      readOnly,
      size,
      tabIndex,
      tagList,
      initTags,
    },
    ref
  ) => {
    const classes = useStyles();
    const [currTags, setCurrTags] = useState(initTags);

    useEffect(() => {
      if (onChange) {
        onChange(currTags);
      }
      // eslint-disable-next-line
    }, [currTags]);

    const handleChange = (item, unselectItem) => () => {
      if (unselectItem) {
        setCurrTags((prevTags) => arrayRemove(prevTags, item));
      } else {
        setCurrTags((prevTags) => prevTags.concat(item).sort());
      }
      if (hasChange) hasChange();
    };

    let tabCount = tabIndex;
    const gridLg = 12 / maxColumns;

    return (
      <Grid container id={id} className={className}>
        <input
          ref={ref}
          readOnly
          value={currTags}
          style={{ display: 'none' }}
        />
        {readOnly
          ? currTags.map((cat) => {
              const isOtherFilter = OTHERFILTERSLIST.includes(cat);
              return (
                <Chip
                  key={cat}
                  color={isOtherFilter ? 'primary' : color}
                  variant={isOtherFilter ? 'default' : 'outlined'}
                  label={cat}
                  className={classes.readOnly}
                />
              );
            })
          : tagList.map((cat) => {
              const isSelected = currTags.includes(cat);
              return (
                <Grid
                  item
                  key={cat}
                  xs={12}
                  sm={6}
                  lg={gridLg}
                  className={classes.grid}
                >
                  <Chip
                    color={isSelected ? color : 'default'}
                    variant={isSelected ? 'default' : 'outlined'}
                    icon={
                      // eslint-disable-next-line no-nested-ternary
                      !hideIcon ? (
                        isSelected ? (
                          <RemoveIcon className={classes.icon} />
                        ) : (
                          <AddIcon className={classes.icon} />
                        )
                      ) : null
                    }
                    label={cat}
                    className={ClassNames(classes.chipText, {
                      [classes.alignLeft]: !hideIcon,
                      [classes.selected]: isSelected,
                      [classes.fullWidth]: fullWidth,
                    })}
                    onClick={handleChange(cat, isSelected)}
                    tabIndex={tabCount++}
                  />
                </Grid>
              );
            })}
      </Grid>
    );
  }
);

CategoryChips.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  fullWidth: PropTypes.bool,
  hasChange: PropTypes.func,
  hideIcon: PropTypes.bool,
  id: PropTypes.string,
  maxColumns: PropTypes.oneOf([3, 4]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  tabIndex: PropTypes.number,
  tagList: PropTypes.arrayOf(PropTypes.string),
  initTags: PropTypes.arrayOf(PropTypes.string),
};

CategoryChips.defaultProps = {
  className: '',
  color: 'primary',
  fullWidth: false,
  hasChange: null,
  hideIcon: false,
  id: 'category',
  onChange: null,
  maxColumns: 4,
  readOnly: false,
  size: 'lg',
  tabIndex: 0,
  tagList: [],
  initTags: [],
};

export default CategoryChips;
