/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import moment from 'moment';
import { Calendar } from 'react-datepicker2';

import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import { formatSelectDate } from 'utilities/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 41,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: `${theme.palette.companyPrimary.dark} !important`,
    '&:hover': {
      borderBottomWidth: 2,
    },
    '& * input': {
      font: '600 1.29rem sans-serif',
    },
  },
  gridColumnKeywords: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    position: 'relative',
    cursor: 'pointer',
    marginTop: 5,
  },
  gridColumn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: 40,
  },
  input: {
    color: theme.palette.companyPrimary.main,
    margin: 0,
    width: '100%',
    minWidth: 202,
    '& > input': {
      cursor: 'pointer',
    },
  },
  iconButton: {
    color: theme.palette.companyPrimary.main,
    width: 46,
    height: 46,
    padding: 0,
  },
  popper: {
    zIndex: 999,
    width: '100%',
    maxWidth: 320,
  },
  paper: {
    transition: 'height 500ms ease-in',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTop: `solid 2px ${theme.palette.companyPrimary.main}`,
    marginTop: 0,
    boxShadow:
      '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
  },
  placementTop: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTop: 'none',
    borderBottom: `solid 2px ${theme.palette.companyPrimary.main}`,
  },
  calendar: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    '& * .today button': {
      borderColor: `${theme.palette.companyPrimary.main} !important`,
    },
    '& * .selected button': {
      backgroundColor: `${theme.palette.companyPrimary.main} !important`,
      borderColor: `${theme.palette.companyPrimary.main} !important`,
    },
    '& * .selected button:active': {
      backgroundColor: `${theme.palette.companyPrimary.main} !important`,
      borderColor: `${theme.palette.companyPrimary.main} !important`,
    },
  },
  selectorIcon: {
    color: theme.palette.companyPrimary.dark,
    height: 32,
    width: 32,
    transition: 'all 150ms ease-in',
  },
  activeIcon: {
    transform: 'rotate(180deg)',
  },
}));

export const DATEOPTIONS = {
  today: 'Today',
  tomorrow: 'Tomorrow',
  thisweek: 'This week',
  thisweekend: 'This weekend',
  custom: 'Custom date...',
  any: 'Show me everything',
};

const DateSelector = React.forwardRef(
  ({ id, className, onChange, value }, ref) => {
    const classes = useStyles();
    const today = new Date();
    const [open, setOpen] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    const [momentValue, setMomentValue] = useState(moment(value.date));
    const [selectedDate, setSelectedDate] = useState('today');
    const [customDate, setCustomDate] = useState(formatSelectDate(today));

    const anchorRef = React.useRef(null);

    useEffect(() => {
      setSelectedDate(value.type);
      setCustomDate(formatSelectDate(value.date));
      setMomentValue(moment(value.date));
    }, [value]);

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
      setShowMenu(true);
    };

    const handleChange = (momentDate) => {
      setMomentValue(momentDate);
      const d = new Date(
        momentDate.year(),
        momentDate.month(),
        momentDate.date(),
        0,
        0,
        0,
        0
      );
      onChange({
        type: 'custom',
        date: d,
      });
      setCustomDate(formatSelectDate(d));
      setOpen(false);
      setShowMenu(true);
    };

    const renderValue = (v) => {
      if (v === 'custom') {
        return customDate;
      }
      return DATEOPTIONS[v];
    };

    const getMenuDate = (v) => {
      const d = new Date();
      if (v === 'tomorrow') {
        d.setDate(d.getDate() + 1);
      } else if (v === 'thisweekend') {
        while (d.getDay() !== 0 && d.getDay() !== 6) {
          d.setDate(d.getDate() + 1);
        }
      }
      const startOfDay = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        0,
        0,
        0,
        0
      );
      return startOfDay;
    };

    const onMenuChange = async (v) => {
      if (v === 'custom') {
        setShowMenu(false);
      } else {
        const startOfDay = await getMenuDate(v);
        onChange({
          type: v,
          date: startOfDay,
        });
        setOpen(false);
      }
      setSelectedDate(v);
    };

    return (
      <ClickAwayListener onClickAway={handleClose}>
        <div
          id={id}
          ref={ref}
          className={ClassNames(
            classes.gridColumnKeywords,
            classes.root,
            className
          )}
        >
          <div className={classes.gridColumn}>
            <Input
              ref={anchorRef}
              placeholder='Day of event'
              readOnly
              value={selectedDate && renderValue(selectedDate)}
              onClick={handleToggle}
              disableUnderline
              className={classes.input}
            />
          </div>
          <div className={classes.gridColumn}>
            <IconButton
              aria-label='show date options'
              onClick={handleToggle}
              classes={{
                root: classes.iconButton,
              }}
            >
              <ExpandMoreIcon
                className={ClassNames(classes.selectorIcon, {
                  [classes.activeIcon]: open,
                })}
              />
            </IconButton>
          </div>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal={true}
            placement='bottom-start'
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start'
                      ? 'center top'
                      : 'center bottom',
                }}
                {...(open ? { timeout: 500 } : {})}
              >
                <Paper
                  className={ClassNames(classes.paper, {
                    [classes.placementTop]: placement !== 'bottom-start',
                  })}
                >
                  {showMenu ? (
                    <MenuList autoFocusItem={open} id='menu-list-grow'>
                      <MenuItem
                        value='today'
                        onClick={() => onMenuChange('today')}
                      >
                        {DATEOPTIONS['today']}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        value='tomorrow'
                        onClick={() => onMenuChange('tomorrow')}
                      >
                        {DATEOPTIONS['tomorrow']}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        value='thisweek'
                        onClick={() => onMenuChange('thisweek')}
                      >
                        {DATEOPTIONS['thisweek']}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        value='thisweekend'
                        onClick={() => onMenuChange('thisweekend')}
                      >
                        {DATEOPTIONS['thisweekend']}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        value='custom'
                        onClick={() => onMenuChange('custom')}
                      >
                        {DATEOPTIONS['custom']}
                      </MenuItem>
                      <Divider />
                      <MenuItem value='any' onClick={() => onMenuChange('any')}>
                        {DATEOPTIONS['any']}
                      </MenuItem>
                    </MenuList>
                  ) : (
                    <Calendar
                      showTodayButton={false}
                      timePicker={false}
                      min={moment().subtract(1, 'day')}
                      selectedDay={momentValue}
                      onSelect={handleChange}
                      className={classes.calendar}
                    />
                  )}
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }
);

DateSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.instanceOf(Object),
};

DateSelector.defaultProps = {
  className: '',
  id: 'dateSelector',
  value: {
    type: 'today',
    date: new Date(),
  },
};

export default DateSelector;
