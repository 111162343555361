/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { animateScroll as scroll } from 'react-scroll';
import {
  sweetAlert,
  sweetError,
  sweetNetworkError,
} from 'utilities/sweetAlert';
import { DateTime } from 'luxon';

import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';

import { indexEvents } from 'vendors/algolia/main';

import Loading from 'components/Loading';
import Header from 'components/Header';
import Footer from 'components/Footer';
import PoweredBy from 'components/PoweredBy';
import ViewEvent from 'components/events/ViewEvent';

import { UserContext } from 'UserContext';
import { useLocalState } from 'hooks/useStorageState';
import { logAnalyticsEvent } from 'vendors/firebase/main';
import { doGetEvent, doGetOrganizer } from 'vendors/firebase/firestore';
import {
  COMMON_SEARCH_PARAMS,
  DEFAULT_EVENT_ERRORS,
  SITEURL,
  getTagShortList,
} from 'constants/general';
import { EVENT_URI, SEARCH_URI } from 'constants/routes';

const MAX_PAGE_WIDTH = 994;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#F2F5F4',
  },
  content: {
    flex: 1,
    width: '100%',
    maxWidth: '1090px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 15px',
    },
  },
  topSpacing: {
    marginTop: 20,
  },
  csidSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  toolbar: {
    width: '100%',
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  backButton: {
    textTransform: 'inherit',
    width: '100%',
    margin: '4px 0px',
    padding: '5px 30px',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
      margin: '5px 0px',
      padding: '10px 42px',
    },
  },
}));

const RETRY_MAX = 1;

const ViewEventPage = React.forwardRef(
  ({ id, history, location, match }, ref) => {
    const classes = useStyles();
    const { onFavorite } = useContext(UserContext);
    const [showBackBtn, setShowBackBtn] = useState(false);
    const [currViewDate, setCurrViewDate] = useState(null);
    const [event, setEvent] = useState(null);
    const [eventTitle, setEventTitle] = useState(null);
    const [eventUrl, setEventUrl] = useState(null);
    const [customSearchId, setCustomSearchId] = useState('');
    const [searchedFromAddr] = useLocalState(
      {
        formattedAddress: '',
        city: '',
        state: '',
        country: '',
        lat: '',
        lng: '',
        placeId: '',
      },
      'location'
    );

    useEffect(() => {
      scroll.scrollToTop({
        duration: 0,
        delay: 0,
        smooth: true,
      });
    }, []);

    const updateHelmet = (e) => {
      setEventTitle(`A Mighty Good Time: ${e.name}`);
      setEventUrl(SITEURL.concat(location.pathname));
      // let price;
      // if (e.priceType === 'free') {
      //   price = 'Free';
      // } else {
      //   price = e.price;
      // }
      // setEventDescription(`${e.name}. Price: ${price}.`.replace('..', '.'));
    };

    const getEventFromIndex = (objectID) => {
      const params = COMMON_SEARCH_PARAMS;
      params.filters = `objectID:${objectID}`;
      return indexEvents.search('', params).then(async (responses) => {
        const { hits } = responses;
        if (hits.length > 0) {
          const { days, endDate, startDate, orgId } = hits[0];
          return doGetOrganizer(orgId, false).then((orgDetails) => {
            return { days, endDate, startDate, orgDetails };
          });
        }
        throw Error('Event not found in index');
      });
    };

    // DUPLICATE ListFavorites
    const getReplacementForExpiredEventId = async (eid) => {
      const currLocal = DateTime.local();
      const currLocalInMins = Math.round(currLocal.toMillis() / 60000);
      const searchParams = {
        filters: `startDate > ${currLocalInMins} AND id:${eid}`,
        getRankingInfo: true,
        page: 0,
        hitsPerPage: 1,
        restrictSearchableAttributes: ['id', 'startDate'],
        distinct: 1,
      };
      return await indexEvents
        .search('', searchParams)
        .then(async (responses) => {
          const { nbHits, hits } = responses;
          if (nbHits === 0) {
            return null;
          }
          return hits[0];
        });
    };

    const handleNotFound = async (eid) => {
      const found = await getReplacementForExpiredEventId(eid);
      let title;
      let content;
      let buttons;
      if (found) {
        const currViewDateTime = DateTime.fromMillis(
          found.startDate * 60000
        ).setZone(found.timezone);
        const tZone = currViewDateTime.offsetNameShort;
        const currViewDateStr = `${currViewDateTime.toLocaleString(
          DateTime.DATETIME_MED
        )} ${tZone}`;
        title = "We're sorry, that event has already ended.";
        content = (
          <div>
            <p style={{ textAlign: 'center' }}>But it is occurring again on</p>
            <h4 style={{ textAlign: 'center' }}>{currViewDateStr}.</h4>
          </div>
        );
        buttons = {
          cancel: 'No thanks',
          confirm: 'Take me to it',
        };
      } else {
        title = "We're sorry, that event is no longer on the calendar.";
        content = (
          <div>
            <p style={{ textAlign: 'center' }}>
              It either ended or was canceled by the organizer. But have a look
              at what else is coming up!
            </p>
          </div>
        );
        buttons = {
          cancel: 'Ok',
        };
      }
      sweetAlert({
        icon: 'info',
        title,
        content,
        buttons,
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(async (value) => {
        if (value) {
          const promises = [];
          // DUPLICATE local
          promises.push(doGetOrganizer(found.orgId, false));
          promises.push(doGetEvent(found.id));
          Promise.all(promises)
            .then((responses) => {
              if (responses[0] && responses[1]) {
                const orgDetails = responses[0];
                const eventDetails = responses[1];
                updateHelmet(eventDetails);
                setEvent({
                  ...eventDetails,
                  eid,
                  date: {
                    ...eventDetails.date,
                    dates: found.days,
                    startDate: found.startDate,
                    endDate: found.endDate,
                  },
                  organizer: orgDetails,
                });
                const venue = [eventDetails.priceType];
                if (eventDetails.location.inperson) {
                  venue.push('inperson');
                }
                if (eventDetails.location.online) {
                  venue.push('online');
                }
                if (eventDetails.location.phone) {
                  venue.push('phone');
                }
                const context = {
                  eventId: eid,
                  eventName: eventDetails.name,
                  orgId: orgDetails.id,
                  orgName: orgDetails.name,
                  type: 'replacement',
                  venue,
                  tags: getTagShortList(
                    eventDetails.tags.concat(eventDetails.otherFilters)
                  ).join(),
                };
                if (eventDetails.location.inperson) {
                  const { address } = eventDetails.location.inperson;
                  if (address && address.lat && address.lng) {
                    context.latitude = address.lat;
                    context.longitude = address.lng;
                  }
                }
                logAnalyticsEvent('view_item', context);
                const event_path = `${EVENT_URI}/${eid}/${found.startDate}`;
                logAnalyticsEvent('view_page', {
                  page_location: SITEURL.concat(event_path.substring(1)),
                  page_path: event_path,
                  page_title: `Event: ${eventDetails.name}`,
                });
                history.replace({
                  pathname: event_path,
                  state: {
                    ...location.state,
                  },
                });
              }
            })
            .catch((err) => {
              if (err.message.startsWith('Event not found')) {
                handleNotFound(eid);
              } else {
                sweetError({
                  title: 'Error retrieving event details!',
                  subject: 'Failure retrieving event details',
                  context: `Eventdate: ${found.startDate}, EventID: ${found.id}`,
                  message: err.message,
                }).then((email) => {
                  if (email) {
                    const win = window.open(email, '_blank');
                    win.focus();
                  }
                  history.push({
                    pathname: SEARCH_URI,
                  });
                });
              }
            });
        } else {
          history.push({
            pathname: SEARCH_URI,
          });
        }
      });
    };

    const doGetEventDetails = (currDate, evt, analyticsType, rc) => {
      doGetEvent(evt.eid, evt.orgId)
        .then((eventDetails) => {
          if (eventDetails) {
            setEvent({
              ...eventDetails,
              eid: evt.eid,
              date: {
                ...evt.date,
                recurrance: eventDetails.date.recurrance,
                recurranceExceptions: eventDetails.date.recurranceExceptions,
              },
              favorite: evt.favorite,
            });
            let venue;
            if (!evt.venue) {
              venue = [eventDetails.priceType];
              if (eventDetails.location.inperson) {
                venue.push('inperson');
              }
              if (eventDetails.location.online) {
                venue.push('online');
              }
              if (eventDetails.location.phone) {
                venue.push('phone');
              }
            } else {
              venue = evt.venue;
            }
            const context = {
              eventId: evt.eid,
              eventName: evt.name,
              orgId: evt.orgId,
              orgName: evt.orgName,
              type: analyticsType || 'search',
              venue,
              tags: getTagShortList(
                eventDetails.tags.concat(eventDetails.otherFilters)
              ).join(),
            };
            if (eventDetails.location.inperson) {
              const { address } = eventDetails.location.inperson;
              if (address && address.lat && address.lng) {
                context.latitude = address.lat;
                context.longitude = address.lng;
              }
            }
            if (
              venue.includes('inperson') &&
              searchedFromAddr &&
              searchedFromAddr.lat
            ) {
              context.search_from_latitude = searchedFromAddr.lat;
              context.search_from_longitude = searchedFromAddr.lng;
            }
            logAnalyticsEvent('view_item', context);
            const event_path = `${EVENT_URI}/${evt.eid}/${currDate}`;
            logAnalyticsEvent('view_page', {
              page_location: SITEURL.concat(event_path.substring(1)),
              page_path: event_path,
              page_title: `Event: ${evt.name}`,
            });
          }
        })
        .catch((err) => {
          if (err.message.startsWith('Event not found')) {
            handleNotFound(evt.eid);
          } else if (err.message === 'offline') {
            if (rc < RETRY_MAX) {
              sweetAlert({
                icon: 'info',
                title: "We're sorry!",
                content: (
                  <div>
                    <p style={{ textAlign: 'center' }}>
                      We are unable to retrieve the event details.
                    </p>
                    <p style={{ textAlign: 'center' }}>
                      Your internet connection may be offline.
                    </p>
                  </div>
                ),
                buttons: {
                  cancel: 'Cancel',
                  confirm: 'Try again?',
                },
                closeOnClickOutside: false,
                closeOnEsc: false,
              }).then((value) => {
                if (value) {
                  doGetEventDetails(currDate, evt, analyticsType, rc + 1);
                } else {
                  history.goBack();
                }
              });
            } else {
              sweetNetworkError({
                title: 'We are unable to retrieve the event details.',
                subject: 'Failure retrieving event details (offline)',
                context: `Eventdate: ${currDate}, EventID: ${evt.eid}, OrgID: ${evt.orgId}`,
                message: 'Retry limit reached. Client is offline.',
              }).then((email) => {
                if (email) {
                  const win = window.open(email, '_blank');
                  win.focus();
                }
                history.goBack();
              });
            }
          } else {
            sweetError({
              title: 'Error retrieving event details!',
              subject: 'Failure retrieving event details',
              context: `Eventdate: ${currDate}, EventID: ${evt.eid}, OrgID: ${evt.orgId}`,
              message: err.message,
            }).then((email) => {
              if (email) {
                const win = window.open(email, '_blank');
                win.focus();
              }
              history.goBack();
            });
          }
        });
    };

    useEffect(() => {
      const {
        event,
        currViewDate: currDate,
        csid,
        analyticsType,
      } = location.state ? location.state : {};
      setCurrViewDate(currDate);
      setCustomSearchId(csid || '');
      if (event) {
        updateHelmet(event);
        doGetEventDetails(currDate, event, analyticsType, 0);
        setShowBackBtn(true);
      } else {
        const REGEXP = RegExp('/event/([^/]+)/(\\d+)(\\?favorite=true)*', 'gi');
        const uriFileParts = REGEXP.exec(location.pathname);
        if (uriFileParts && uriFileParts.length > 1) {
          const eid = uriFileParts[1];
          const startDate = parseInt(uriFileParts[2]);
          const isFav =
            uriFileParts.length > 2 && uriFileParts[3] === '?favorite=true'
              ? true
              : false;
          setCurrViewDate(startDate);
          const promises = [];
          promises.push(getEventFromIndex(`${eid}-${startDate}`));
          // DUPLICATE local
          promises.push(doGetEvent(eid));
          Promise.all(promises)
            .then((responses) => {
              if (responses[0] && responses[1]) {
                const { days, endDate, startDate, orgDetails } = responses[0];
                const eventDetails = responses[1];
                updateHelmet(eventDetails);
                setEvent({
                  ...eventDetails,
                  eid,
                  date: {
                    ...eventDetails.date,
                    dates: days,
                    startDate,
                    endDate,
                  },
                  organizer: orgDetails,
                });
                const venue = [eventDetails.priceType];
                if (eventDetails.location.inperson) {
                  venue.push('inperson');
                }
                if (eventDetails.location.online) {
                  venue.push('online');
                }
                if (eventDetails.location.phone) {
                  venue.push('phone');
                }
                const context = {
                  eventId: eid,
                  eventName: eventDetails.name,
                  orgId: orgDetails.id,
                  orgName: orgDetails.name,
                  type: 'link',
                  venue,
                  tags: getTagShortList(
                    eventDetails.tags.concat(eventDetails.otherFilters)
                  ).join(),
                };
                if (eventDetails.location.inperson) {
                  const { address } = eventDetails.location.inperson;
                  if (address && address.lat && address.lng) {
                    context.latitude = address.lat;
                    context.longitude = address.lng;
                  }
                }
                logAnalyticsEvent('view_item', context);
                const event_path = `${EVENT_URI}/${eid}/${startDate}`;
                logAnalyticsEvent('view_page', {
                  page_location: SITEURL.concat(event_path.substring(1)),
                  page_path: event_path,
                  page_title: `Event: ${eventDetails.name}`,
                });
                if (isFav) {
                  onFavorite(`${eid}-${startDate}`, true, {
                    eventId: eid,
                    eventName: eventDetails.name,
                    orgId: orgDetails.id,
                    orgName: orgDetails.name,
                  });
                }
              }
            })
            .catch((err) => {
              if (err.message.startsWith('Event not found')) {
                handleNotFound(eid);
              } else {
                sweetError({
                  title: 'Error retrieving event details!',
                  subject: 'Failure retrieving event details',
                  context: `Eventdate: ${startDate}, EventID: ${eid}`,
                  message: err.message,
                }).then((email) => {
                  if (email) {
                    const win = window.open(email, '_blank');
                    win.focus();
                  }
                  history.push({
                    pathname: SEARCH_URI,
                  });
                });
              }
            });
        }
      }
      // eslint-disable-next-line
    }, [match.isExact, location.search]);

    let metaDesc = '';
    if (event && event.organizer && currViewDate) {
      const currViewDateTime = DateTime.fromMillis(
        currViewDate * 60000
      ).setZone(event.date.timezone);
      const tZone = currViewDateTime.offsetNameShort;
      const currViewDateStr = `${currViewDateTime.toLocaleString(
        DateTime.DATETIME_MED
      )} ${tZone}`;
      metaDesc = `Organized by ${event.organizer.name} on ${currViewDateStr}.`;
    }
    return (
      <>
        {event ? (
          <Helmet>
            <title>{eventTitle}</title>
            <link rel='canonical' href={eventUrl} />
            <meta name='robots' content='noindex' />
            <meta property='og:type' content='article' />
            <meta property='og:title' content={event.name} />
            <meta property='og:description' content={metaDesc} />
            <meta property='og:image' content={event.img} />
            <meta property='og:url' content={eventUrl} />
            <meta property='og:site_name' content='A Mighty Good Time' />
            <meta name='twitter:title' content={event.name} />
            <meta name='twitter:description' content={metaDesc} />
            <meta name='twitter:image' content={event.img} />
          </Helmet>
        ) : (
          <Helmet>
            <title>{eventTitle}</title>
            <link rel='canonical' href={eventUrl} />
            <meta name='robots' content='noindex' />
            {/* <meta name='description' content={eventDescription} /> */}
          </Helmet>
        )}
        <main id={id} ref={ref} className={classes.root}>
          {customSearchId === '' && <Header showBackBtn={showBackBtn} />}
          <div
            className={ClassNames(classes.content, 'page-transition fade-in', {
              [classes.contentDetail]: showBackBtn,
              [classes.topSpacing]: customSearchId === '',
              [classes.csidSpacing]: customSearchId !== '',
            })}
          >
            {customSearchId && (
              <div className={classes.toolbar}>
                <Button
                  variant='outlined'
                  onClick={() => history.goBack()}
                  color='primary'
                  className={ClassNames(classes.backButton, {
                    [classes.hide]: !showBackBtn,
                  })}
                >
                  <BackIcon style={{ marginLeft: -8 }} />
                  Back to search results
                </Button>
              </div>
            )}
            {event ? (
              <ViewEvent
                event={{
                  eid: event.eid,
                  img: event.img,
                  date: event.date,
                  name: event.name,
                  organizer: event.organizer,
                  location: event.location,
                  favorite: event.favorite,
                  price: event.priceType === 'free' ? 'Free' : event.price,
                  register: event.register,
                  desc: event.desc,
                  tags: event.tags.concat(event.otherFilters).filter(Boolean),
                  social: event.social,
                }}
                errors={DEFAULT_EVENT_ERRORS}
                initViewDate={currViewDate}
                customSearchId={customSearchId}
                readOnly
              />
            ) : (
              <Loading className={classes.root} />
            )}
          </div>
          {/* {customSearchId === '' ? <Footer /> : <PoweredBy />} */}
          {customSearchId === '' && <Footer />}
          {customSearchId !== '' &&
            customSearchId !== 'amgt255bos' &&
            customSearchId !== 'atxdemo' && <PoweredBy />}
        </main>
      </>
    );
  }
);

ViewEventPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
  id: PropTypes.string,
};

ViewEventPage.defaultProps = {
  id: '',
};

export default withRouter(ViewEventPage);
