/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { CLOUDINARY_EDIT, MAX_PAGE_WIDTH, MODE } from 'constants/general';
import { CONTACTUS_URI, EVENTS_URI } from 'constants/routes';

const one = `${CLOUDINARY_EDIT}/v1613857780/sona/svg/one.svg`;
const two = `${CLOUDINARY_EDIT}/v1613857784/sona/svg/two.svg`;
const three = `${CLOUDINARY_EDIT}/v1613857789/sona/svg/three.svg`;
const apply = `${CLOUDINARY_EDIT}/v1613858018/sona/svg/apply.original.svg`;
const addEvent = `${CLOUDINARY_EDIT}/v1613858030/sona/svg/add-event.svg`;
const bullhorn = `${CLOUDINARY_EDIT}/v1613858037/sona/svg/bullhorn.svg`;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
    // [theme.breakpoints.up('md')]: {
    //   paddingTop: 80,
    // },
  },
  gridColumnContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '50px',
    padding: '0 20px',
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'unset',
      gridColumnGap: 'unset',
      gridTemplateRows: 'repeat(3, auto)',
    },
  },
  gridColumn: {
    justifyContent: 'center',
    display: 'flex',
    height: 'fit-content',
  },
  gridRowContainer: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    maxWidth: 476,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 576,
    },
  },
  gridRow: {
    justifyContent: 'center',
    display: 'flex',
  },
  companyPrimary: {
    color: theme.palette.companyPrimary.main,
  },
  images: {
    position: 'relative',
    maxWidth: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  numbers: {
    width: 50,
    height: 50,
    maxWidth: '100%',
    position: 'absolute',
    top: 10,
    left: 10,
  },
  link: {
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  createEventBtn: {
    textAlign: 'center',
    marginTop: 25,
    '& > a': {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      borderRadius: 40,
      width: 'fit-content',
      height: 50,
    },
    [theme.breakpoints.up('md')]: {
      '& > a': {
        width: 225,
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight,
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > a': {
        width: 250,
        fontSize: theme.typography.h2.fontSize,
        lineHeight: theme.typography.h2.lineHeight,
      },
    },
  },
}));

const HowToAddEvent = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  return (
    <div id={id} ref={ref} className={classes.root}>
      <Typography
        variant='h2'
        align='center'
        color='secondary'
        style={{ paddingBottom: 10 }}
      >
        <strong>Adding events is easy.</strong>
      </Typography>
      <Typography
        variant='h6'
        align='center'
        fullwidth='true'
        style={{
          maxWidth: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingBottom: 20,
        }}
      >
        Create. Promote. Grow.
      </Typography>
      <div className={classes.gridColumnContainer}>
        <div className={classes.gridColumn}>
          <div className={classes.gridRowContainer}>
            <div className={ClassNames(classes.gridRow, classes.images)}>
              <img
                src={apply}
                alt='create account'
                style={{
                  width: 350,
                  height: 100,
                  maxWidth: '100%',
                  margin: 40,
                }}
              />
              <img src={one} alt='one' className={classes.numbers} />
            </div>
            <div className={classes.gridRow}>
              <Typography
                variant='body1'
                className={classes.text}
                align='left'
                color='inherit'
              >
                <strong>
                  Create your account and add your event organizer profile.
                </strong>{' '}
                You can include all your social media links, business
                information, and logo.
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.gridColumn}>
          <div className={classes.gridRowContainer}>
            <div className={ClassNames(classes.gridRow, classes.images)}>
              <img
                src={addEvent}
                alt='add event'
                style={{
                  width: 350,
                  height: 100,
                  maxWidth: '100%',
                  margin: 40,
                }}
              />
              <img src={two} alt='two' className={classes.numbers} />
            </div>
            <div className={classes.gridRow} style={{ display: 'block' }}>
              <Typography
                variant='body1'
                className={classes.text}
                align='left'
                color='inherit'
                gutterBottom
              >
                <strong>Create your event.</strong> Make it one time, recurring,
                or multi-day. Does your event require registration? You can add
                those details too!
              </Typography>
              <Typography
                variant='body1'
                className={classes.text}
                align='left'
                color='inherit'
              >
                Do you have a lot of events to add? We can help.{' '}
                <Link
                  to={{
                    pathname: CONTACTUS_URI,
                    state: {
                      message: "I'd like help with adding my events.",
                    },
                  }}
                  className={classes.link}
                >
                  Contact us.
                </Link>
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.gridColumn}>
          <div className={classes.gridRowContainer}>
            <div className={ClassNames(classes.gridRow, classes.images)}>
              <img
                src={bullhorn}
                alt='post event'
                style={{
                  width: 350,
                  height: 100,
                  maxWidth: '100%',
                  margin: 40,
                }}
              />
              <img src={three} alt='three' className={classes.numbers} />
            </div>
            <div className={classes.gridRow}>
              <Typography
                variant='body1'
                className={classes.text}
                align='left'
                color='inherit'
              >
                <strong>Post the event to the calendar.</strong> You&#39;re all
                ready to go! We&#39;ll start getting the word out and promote it
                for you!
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.createEventBtn}>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          component={Link}
          to={{
            pathname: EVENTS_URI,
            state: {
              mode: MODE.CREATE,
              eventType: 'Event',
            },
          }}
        >
          <strong>Add an event</strong>
        </Button>
      </div>
    </div>
  );
});

HowToAddEvent.propTypes = {
  id: PropTypes.string,
};

HowToAddEvent.defaultProps = {
  id: '',
};

export default HowToAddEvent;
