/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: '2px 0',
    [theme.breakpoints.up('md')]: {
      borderRadius: 25,
    },
  },
  selected: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.companyPrimary.main} !important`,
    [theme.breakpoints.up('md')]: {
      borderRadius: 25,
    },
  },
  bold: {
    fontWeight: '800',
  },
  icon: {
    marginRight: 0,
    color: `${theme.palette.common.white} !important`,
    minWidth: 38,
  },
  gutters: {
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));

const arrayRemove = (arr, value) => arr.filter((ele) => ele !== value);

const CheckboxList = React.forwardRef(
  ({ id, className, listItems, initSelectedItems, onChange }, ref) => {
    const classes = useStyles();
    const [checkedItems, setCheckedItems] = useState(initSelectedItems);

    const handleChange = (key, name) => () => {
      console.log('handleChange:', key, name);
      let checked = false;
      if (checkedItems.includes(key)) {
        setCheckedItems((prevCheckedItems) =>
          arrayRemove(prevCheckedItems, key)
        );
      } else {
        checked = true;
        setCheckedItems((prevCheckedItems) => prevCheckedItems.concat(key));
      }
      if (onChange) {
        onChange(key, checked);
      }
    };

    return (
      <List
        id={id}
        ref={ref}
        dense
        disablePadding
        className={className}
        style={{ width: '100%' }}
      >
        {listItems.map((pr) => {
          const checked = checkedItems.includes(pr.key);
          return (
            <ListItem
              button
              key={pr.key}
              selected={checked}
              onClick={handleChange(pr.key, pr.name)}
              classes={{
                root: classes.listItem,
                selected: classes.selected,
              }}
              disableGutters
              className={classes.gutters}
              style={{ width: '100%' }}
            >
              <ListItemIcon
                classes={{
                  root: classes.icon,
                }}
              >
                {checked ? (
                  <CheckCircleIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon style={{ color: '#ddd' }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={pr.name}
                classes={{
                  dense: ClassNames({
                    [classes.selected]: checked,
                    [classes.bold]: checked,
                  }),
                }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
);

CheckboxList.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  initSelectedItems: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

CheckboxList.defaultProps = {
  id: '',
  className: '',
  onChange: null,
  allowCustom: false,
  initSelectedItems: [],
};

export default CheckboxList;
