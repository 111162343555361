/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

import DateSelector from 'components/Selects/DateSelector';
import LocationInput from 'components/Inputs/LocationInput';

import { SEARCH_URI } from 'constants/routes';
import { CATEGORYLIST } from 'constants/general';
import { getDayInMinutes, getDayTypeId } from 'utilities/utils';
import { sessionStateAvailable, useLocalState } from 'hooks/useStorageState';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 99,
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(
      1.5
    )} ${theme.spacing(1)}`,
    [theme.breakpoints.only('xs')]: {
      borderRadius: 0,
    },
    [theme.breakpoints.up('sm')]: {
      width: 360,
      padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
        2.5
      )} ${theme.spacing(2)}`,
    },
  },
  gridContent: {
    maxWidth: '100%',
    flexBasis: '100%',
  },
  header: {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.57)',
    paddingBottom: 0,
  },
  input: {
    margin: 0,
    width: '100%',
  },
  selectorIcon: {
    color: theme.palette.companyPrimary.main,
    height: 32,
    width: 32,
  },
  category: {
    width: '100%',
    font: '600 1.29rem sans-serif',
    color: theme.palette.companyPrimary.main,
    paddingRight: '62px !important',
  },
  select: {
    margin: '-4px 12px',
  },
  navLink: {
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    textDecoration: 'underline',
    textTransform: 'none',
    width: 'fit-content',
    margin: 0,
    padding: '6px 0',
    justifyContent: 'flex-start',
    color: 'rgba(0, 0, 0, 0.57)',
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '14px 0 -10px -5px',
      padding: '6px 8px',
    },
  },
  searchBtn: {
    borderRadius: 35,
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    border: '1px solid white',
    padding: '14px 16px',
  },
  icon: {
    marginRight: 5,
  },
  formRootInperson: {
    minWidth: 87,
    marginRight: 0,
    marginLeft: -8,
    paddingRight: 6,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 10,
    },
  },
  formRootOnline: {
    minWidth: 87,
    marginRight: 0,
    marginLeft: 0,
    paddingRight: 18,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 10,
    },
  },
  formRootPhone: {
    minWidth: 87,
    marginRight: 0,
    marginLeft: 0,
  },
  formControlLabel: {
    fontWeight: '700',
    color: 'rgba(0, 0, 0, 0.57)',
    fontSize: 14,
    lineHeight: '1',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    }
  },
  radioBtn: {
    padding: 5,
  },
  advanced: {
    padding: 0,
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
      alignSelf: 'flex-end',
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
  },
}));

const SearchView = React.forwardRef(
  (
    { id, className, history, fullScreen, keywords: searchKeywords, venue },
    ref
  ) => {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const currDate = new Date();
    const [, setErrorChange] = useState(false);
    const [locInperson, setLocInperson] = useState(false);
    const [locOnline, setLocOnline] = useState(true);
    const [locPhone, setLocPhone] = useState(true);
    const [searchTypes, setSearchTypes] = useLocalState(
      {
        inperson: false,
        online: true,
        phone: true,
      },
      'searchType'
    );
    const [address, setAddress] = useLocalState(
      {
        formattedAddress: '',
        city: '',
        state: '',
        country: '',
        lat: '',
        lng: '',
        placeId: '',
      },
      'location'
    );
    const [errors, setErrors] = useState({
      address: '',
    });
    const [disableInput, setDisableInput] = useState(false);
    const [selectedDay, setSelectedDay] = useState({
      type: 'today',
      date: new Date(
        currDate.getFullYear(),
        currDate.getMonth(),
        currDate.getDate(),
        0,
        0,
        0
      ),
    });
    const [category, setCategory] = useState('Anything');

    const onCategoryChange = (e) => {
      setCategory(e.target.value);
    };

    const validateLocation = (location) => {
      const newErrors = {};
      newErrors.address =
        // eslint-disable-next-line no-nested-ternary
        location === null || location.city === ''
          ? 'Begin entering city or zip code and then select your city, state from provided list once it appears.'
          : !location.lat || !location.lng
          ? 'Please check your city or zip code and try again.'
          : '';

      const hasErrors = Object.values(newErrors).some(
        (error) => error.length > 0
      );
      setErrors(newErrors);
      return !hasErrors;
    };

    const onSearch = () => {
      if (locInperson && !validateLocation(address)) {
        return;
      } else {
        setErrors({ address: '' });
      }
      const selectedDayInMins = getDayInMinutes(
        selectedDay && selectedDay.date ? selectedDay.date : new Date()
      );
      let queryString = `?l=${locInperson ? 'i' : ''}${locOnline ? 'o' : ''}${
        locPhone ? 'p' : ''
      }&d=${selectedDayInMins}`;
      const type = getDayTypeId(selectedDay.type);
      if (type) {
        queryString = `${queryString}&n=${type}`;
      }
      if (locInperson) {
        queryString = `${queryString}&g=${encodeURIComponent(address.placeId)}`;
      }
      if (category !== 'Anything') {
        queryString = `${queryString}&t=${encodeURIComponent(category)}`;
      }
      history.push({
        pathname: SEARCH_URI,
        search: queryString,
      });
    };

    useEffect(() => {
      if (searchTypes.inperson && !sessionStateAvailable()) {
        setDisableInput(true);
        setErrors({ address: 'Session cookies required for location lookup.' });
      } else {
        setDisableInput(false);
        setErrors({ address: '' });
      }
      // eslint-disable-next-line
    }, [searchTypes]);

    useEffect(() => {
      if (venue && venue.placeId && venue.placeId !== address.placeId) {
        setAddress({
          formattedAddress: venue.formattedAddress,
          city: venue.city,
          state: venue.state,
          country: venue.country,
          lat: venue.lat,
          lng: venue.lng,
          placeId: venue.placeId,
        });
      }
      if (venue.inperson || venue.online || venue.phone) {
        setSearchTypes({
          inperson: venue.inperson,
          online: venue.online,
          phone: venue.phone,
        });
        setLocInperson(venue.inperson);
        setLocOnline(venue.online);
        setLocPhone(venue.phone);
      } else if (searchTypes) {
        setLocInperson(searchTypes.inperson || false);
        setLocOnline(searchTypes.online || false);
        setLocPhone(searchTypes.phone || false);
      } else {
        setLocInperson(false);
        setLocOnline(true);
        setLocPhone(true);
      }
      // eslint-disable-next-line
    }, [
      venue,
      venue.inperson,
      venue.online,
      venue.phone,
      venue.formattedAddress,
      venue.city,
      venue.state,
      venue.country,
      venue.lat,
      venue.lng,
      venue.placeId,
    ]);

    const onLocationTypeChange = (newType) => {
      let newLocInperson;
      let newLocOnline;
      let newLocPhone;
      if (newType === 'inperson') {
        newLocInperson = true;
        newLocOnline = false;
        newLocPhone = false;
      } else if (newType === 'online') {
        newLocInperson = false;
        newLocOnline = true;
        if (locInperson) {
          newLocPhone = false;
        } else if (locOnline) {
          newLocPhone = false;
        } else {
          newLocPhone = true;
        }
      } else if (newType === 'phone') {
        newLocInperson = false;
        if (locInperson) {
          newLocOnline = false;
        } else if (locPhone) {
          newLocOnline = false;
        } else {
          newLocOnline = true;
        }
        newLocPhone = true;
      }
      setLocInperson(newLocInperson);
      setLocOnline(newLocOnline);
      setLocPhone(newLocPhone);
      setSearchTypes({
        inperson: newLocInperson,
        online: newLocOnline,
        phone: newLocPhone,
      });
      if (newType !== 'inperson') {
        if (address.city === '' || address.lat === '' || address.lng === '') {
          setErrors({ address: '' });
          setErrorChange((prev) => !prev);
          setAddress({
            formattedAddress: '',
            city: '',
            state: '',
            country: '',
            lat: '',
            lng: '',
            placeId: '',
          });
        }
      }
    };

    const onLocationChange = (
      location = {
        formattedAddress: '',
        city: '',
        state: '',
        country: '',
        lat: '',
        lng: '',
        placeId: '',
      },
      search = false
    ) => {
      const newLocation = {
        formattedAddress: location.formattedAddress,
        city: location.city,
        state: location.state,
        country: location.country,
        lat: location.lat,
        lng: location.lng,
        placeId: location.placeId,
      };
      setAddress(newLocation);
      if (newLocation.lat && newLocation.lng) {
        validateLocation(newLocation);
      }
    };

    const handleDateChange = (date) => setSelectedDay(date);

    const enableLocationType = () => onLocationTypeChange('inperson');

    return (
      <Paper
        id={id}
        ref={ref}
        className={ClassNames(classes.root, className)}
        elevation={fullScreen ? 4 : 12}
      >
        <Grid container spacing={2} justifyContent='center'>
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.gridContent}
            style={{ paddingBottom: 0 }}
          >
            <Typography
              variant='body1'
              display='block'
              align='center'
              className={classes.header}
            >
              <strong>I want to do something</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.gridContent}>
            <DateSelector value={selectedDay} onChange={handleDateChange} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.gridContent}
            style={{ paddingBottom: 0 }}
          >
            <FormControl component='fieldset'>
              <FormGroup
                row
                aria-label='location type'
                name='locationType'
                style={{ flexWrap: 'unset', flexDirection: 'row' }}
                onChange={(e) => onLocationTypeChange(e.target.value)}
              >
                <FormControlLabel
                  value='inperson'
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      color='primary'
                      checked={locInperson}
                      classes={{
                        root: classes.radioBtn,
                      }}
                    />
                  }
                  label='In Person'
                  classes={{
                    root: classes.formRootInperson,
                    label: classes.formControlLabel,
                  }}
                />
                <FormControlLabel
                  value='online'
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      color='primary'
                      checked={locOnline}
                      classes={{
                        root: classes.radioBtn,
                      }}
                    />
                  }
                  label='Online'
                  classes={{
                    root: classes.formRootOnline,
                    label: classes.formControlLabel,
                  }}
                />
                <FormControlLabel
                  value='phone'
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      color='primary'
                      checked={locPhone}
                      classes={{
                        root: classes.radioBtn,
                      }}
                    />
                  }
                  label='By Phone'
                  classes={{
                    root: classes.formRootPhone,
                    label: classes.formControlLabel,
                  }}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.gridContent}>
            {address && (
              <LocationInput
                id='search-location'
                variant='standard'
                required
                disabled={!locInperson || disableInput}
                initValue={{
                  street: '',
                  formattedAddress: address.formattedAddress,
                  city: address.city,
                  state: address.state,
                  country: address.country,
                  lat: address.lat,
                  lng: address.lng,
                  placeId: address.placeId,
                }}
                helperText={`${errors.address !== '' ? errors.address : ''}`}
                error={errors.address.length > 0}
                className={classes.input}
                onChange={onLocationChange}
                onCancel={onLocationChange}
                onClick={enableLocationType}
                onEnter={onSearch}
                fullWidth
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.gridContent}
            style={{ paddingBottom: 0 }}
          >
            <Typography
              variant='body1'
              display='block'
              align='center'
              className={classes.header}
            >
              <strong>I&#39;m in the mood for</strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.gridContent}
            style={{ paddingBottom: 0 }}
          >
            <Select
              id='standard-select-currency'
              // select
              value={category}
              onChange={onCategoryChange}
              IconComponent={ExpandMoreIcon}
              classes={{
                root: classes.category,
                icon: ClassNames(classes.selectorIcon, classes.select),
              }}
              style={{ width: '100%' }}
            >
              <MenuItem key='Anything' value='Anything'>
                Anything
              </MenuItem>
              {CATEGORYLIST.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={5} sm={6} className={classes.advanced}>
            <Button
              component={Link}
              to={SEARCH_URI}
              color='default'
              className={classes.navLink}
            >
              Advanced Search
            </Button>
          </Grid>
          <Grid item xs={7} sm={6} align='right' style={{ paddingBottom: 0 }}>
            <Button
              variant='contained'
              color='secondary'
              onClick={onSearch}
              fullWidth
              className={classes.searchBtn}
            >
              <SearchIcon className={classes.icon} />
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);

SearchView.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  id: PropTypes.string,
  keywords: PropTypes.string,
  venue: PropTypes.instanceOf(Object),
};

SearchView.defaultProps = {
  className: '',
  fullScreen: false,
  id: '',
  keywords: '',
  venue: {
    inperson: undefined,
    online: undefined,
    phone: undefined,
    formattedAddress: '',
    city: '',
    state: '',
    country: '',
    lat: '',
    lng: '',
    placeId: '',
  },
};

export default withRouter(SearchView);
