/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { logAnalyticsEvent } from 'vendors/firebase/main';
import { HOWITWORKS_URI } from 'constants/routes';

import Footer from 'components/Footer';
import Header from 'components/Header';
import SubscribeBanner from 'components/SubscribeBanner';
import AboutUsSummary from 'components/AboutUsSummary';
import LoadImage from 'components/LoadImage';
import SearchView from 'views/search/SearchView';
import UpComingEvents from 'components/UpComingEvents';
import { CLOUDINARY, SITEURL } from 'constants/general';
import { LANDING_URI } from 'constants/routes';

let searchBoxMarginTop = -275;
if (window.innerWidth < 820) {
  searchBoxMarginTop = -180;
} else if (window.innerWidth >= 960 && window.innerWidth < 1090) {
  searchBoxMarginTop = -230;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#F2F5F4',
  },
  bannerContainer: {
    position: 'relative',
    maxHeight: 800,
    maxWidth: '100%',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      minHeight: 300,
    },
  },
  bannerImage: {
    width: 1280,
    maxWidth: '100vw',
    height: 600,
    maxHeight: 'calc(100vw * (368/600))',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vw * (588/960))',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vw * (600/1280))',
    },
  },
  video: {
    width: '100%',
    height: '100%',
  },
  content: {
    flex: 1,
    padding: theme.spacing(8),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: -600,
    backgroundColor: '#fafafa',
  },
  main: {
    maxWidth: '1024px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
  searchBox: {
    maxWidth: 1024,
    marginTop: searchBoxMarginTop,
    marginBottom: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginTop: 'unset',
      marginBottom: 'unset',
    },
  },
  link: {
    color: 'inherit',
    '&:hover': {
      color: theme.palette.companyPrimary.dark,
    },
  },
  aboutUs: {
    maxWidth: 980,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 10px',
  },
  whatwedo: {
    paddingTop: 40,
    paddingBottom: 30,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 20,
    },
  },
  organizers: {
    fontWeight: '400',
  },
  button: {
    borderRadius: 35,
    textTransform: 'inherit',
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    width: 210,
    height: 42,
    marginTop: 15,
    marginBottom: 20,
  },
}));

const bannerSourcePre = `${CLOUDINARY}v1603743634/sona/img/bannerpre_v1.jpg`;

const PAGE_TITLE = 'Older adult classes- senior activities: A Mighty Good Time';

const Landing = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    const context = {
      page_location: `${SITEURL}${LANDING_URI.substring(1)}`,
      page_path: LANDING_URI,
      page_title: 'Landing',
    };
    logAnalyticsEvent('view_page', context);
    // eslint-disable-next-line
  }, []);

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let bannerSource;
  if (fullScreen) {
    bannerSource = `${CLOUDINARY}v1603743635/sona/img/banner_xs_v1.jpg`;
  } else if (smallScreen) {
    bannerSource = `${CLOUDINARY}v1603743635/sona/img/banner_sm_v1.jpg`;
  } else {
    bannerSource = `${CLOUDINARY}v1603743635/sona/img/banner_md_v1.jpg`;
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel='canonical' href={SITEURL} />
        <meta
          name='description'
          content='Looking for things to do or want to make new friends? A Mighty Good Time is a calendar of in-person, online, phone-based activities for 50+ adults nationwide.'
        />
      </Helmet>
      <main className={classes.root}>
        <Header />
        <SubscribeBanner />
        <div
          className={ClassNames(
            classes.bannerContainer,
            'page-transition fade-in'
          )}
        >
          <LoadImage
            src={bannerSource}
            preSrc={bannerSourcePre}
            alt='A Mighty Good Time Banner'
            className={classes.bannerImage}
          />
        </div>
        <SearchView
          className={ClassNames(classes.searchBox, 'page-transition fade-in')}
          fullScreen={fullScreen}
        />
        <Grid container>
          <Grid item xs={12} className={classes.aboutUs}>
            <Typography
              variant='h2'
              align='center'
              className={classes.whatwedo}
            >
              <strong>
                Events and experiences for adults 50 and over from{' '}
                <span style={{ whiteSpace: 'nowrap' }}> top-quality</span>{' '}
                activity providers around the world.
              </strong>
            </Typography>
            <Typography
              variant='h4'
              align='center'
              className={classes.organizers}
            >
              Have an event you'd like to promote?{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                We can help. It's free!
              </span>
            </Typography>
            <div style={{ textAlign: 'center' }}>
              <Button
                component={Link}
                to={{
                  pathname: HOWITWORKS_URI,
                }}
                variant='outlined'
                color='primary'
                className={classes.button}
              >
                Learn More
              </Button>
            </div>
          </Grid>
        </Grid>
        <UpComingEvents />
        <AboutUsSummary />
        <Footer />
      </main>
    </>
  );
});

Landing.propTypes = {
  id: PropTypes.string,
};

Landing.defaultProps = {
  id: '',
};

export default Landing;
