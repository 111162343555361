/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import Drawer from '@material-ui/core/SwipeableDrawer';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  popper: {
    top: 40,
  },
  popperClose: {
    pointerEvents: 'none',
    display: 'none !important',
  },
  dropdown: {
    borderRadius: 2,
    maxWidth: '100%',
    top: '100%',
    zIndex: '1000',
    minWidth: 200,
    padding: 5,
    margin: '2px 0 0',
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
  },
  popperResponsive: {
    zIndex: '1200',
    transform: 'translateY(46px)',
    [theme.breakpoints.down('sm')]: {
      zIndex: '1640',
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  caret: {
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginTop: '2px',
    marginLeft: '6px',
    verticalAlign: 'middle',
    borderTop: '6px solid',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  caretActive: {
    transform: 'rotate(180deg)',
  },
  caretDropup: {
    transform: 'rotate(180deg)',
  },
  caretRTL: {
    marginRight: '4px',
  },
  dropdownHeader: {
    display: 'block',
    padding: '0.1875rem 1.25rem',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: '#777',
    whiteSpace: 'nowrap',
    fontWeight: 'inherit',
    marginTop: '10px',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      cursor: 'auto',
    },
  },
  noLiPadding: {
    padding: '0',
  },
  filterBtn: {
    height: 46,
    width: '100%',
    textTransform: 'inherit',
    marginTop: 10,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
    '&:hover': {
      color: theme.palette.companyPrimary.main,
      backgroundColor: 'transparent',
    },
    '&:hover $buttonUnderline': {
      display: 'inline-flex',
    },
  },
  groupLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none',
  },
  groupMiddle: {
    borderRadius: 0,
  },
  groupRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 'none',
  },
  buttonUnderline: {
    display: 'none',
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderRadius: 'unset',
    height: 2,
    width: '100%',
    backgroundColor: theme.palette.companyPrimary.main,
  },
  buttonUnderlineActive: {
    display: 'inline-flex',
    bottom: -2,
    height: 4,
  },
  filterIcon: {
    color: '#555',
    transition: 'all 150ms ease-in',
  },
  activeIcon: {
    transform: 'rotate(180deg)',
  },
  gridColumn: {
    display: 'grid',
    width: '100%',
    padding: '5px 10px',
    gridTemplateColumns: '1fr auto',
    backgroundColor: '#e6ee9c',
  },
  gridItem: {
    justifyContent: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    zIndex: '1202',
    minWidth: 200,
  },
  doneButton: {
    marginTop: 2,
    marginRight: 2,
  },
  doneButtonMobile: {
    backgroundColor: '#fafafa',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    textTransform: 'inherit',
  },
  header: {
    backgroundColor: '#e6ee9c',
  },
  doneBtn: {
    color: theme.palette.companySecondary.dark,
    borderColor: theme.palette.companySecondary.dark,
    marginTop: 5,
    padding: '0 10px',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

const CustomDropdown = React.forwardRef(
  (
    {
      id,
      buttonText,
      dropdownHeader,
      dropdownNode,
      dropPlacement,
      groupPos,
      onApply,
      useDrawer,
      width,
    },
    ref
  ) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorRef, setAnchorRef] = useState(null);

    const arrowRef = useRef(null);

    const onToggle = () => setOpen((prevOpen) => !prevOpen);

    const onToggleDrawer = (open) => (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setOpen(open);
    };

    const handeApply = (e) => {
      onClose(e);
      onApply();
    };

    const onClose = (event) => {
      if (anchorRef && anchorRef.contains(event.target)) {
        return;
      }
      setOpen(false);
    };

    const handleKeyDown = (e) => {
      const key = e.which ? e.which : e.keyCode;
      if (key === 13) {
        handeApply(e);
        e.preventDefault();
      }
    };

    return (
      <div style={{ position: 'relative' }}>
        <Button
          variant='outlined'
          onClick={onToggle}
          className={ClassNames(classes.filterBtn, {
            [classes.groupLeft]: groupPos === 'left',
            [classes.groupMiddle]: groupPos === 'middle',
            [classes.groupRight]: groupPos === 'right',
          })}
          ref={(node) => setAnchorRef(node)}
        >
          {buttonText}{' '}
          <DownIcon
            className={ClassNames(classes.filterIcon, {
              [classes.activeIcon]: open,
            })}
          />
          <span
            className={ClassNames(classes.buttonUnderline, {
              [classes.buttonUnderlineActive]: open,
            })}
          />
        </Button>
        {useDrawer ? (
          <Drawer
            variant='temporary'
            anchor='right'
            open={open}
            onOpen={onToggleDrawer(true)}
            onClose={onToggleDrawer(false)}
            classes={{
              paper: classes.paper,
            }}
          >
            <div className={classes.gridColumn}>
              <div className={classes.gridItem}>
                <Typography variant='body1' align='left' color='textPrimary'>
                  {dropdownHeader}
                </Typography>
              </div>
              <div className={classes.gridItem}>
                <Button
                  variant='outlined'
                  onClick={handeApply}
                  color='primary'
                  className={classes.doneButtonMobile}
                >
                  {onApply ? 'Apply' : 'Done'}
                </Button>
              </div>
            </div>
            <Divider />
            {dropdownNode}
          </Drawer>
        ) : (
          <Popper
            open={open}
            anchorEl={anchorRef}
            disablePortal
            placement={dropPlacement}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            className={ClassNames(classes.popper, {
              [classes.popperClose]: !open,
              [classes.popperResponsive]: true,
            })}
            style={{ width }}
          >
            <Grow in={open} style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown} elevation={12}>
                <ClickAwayListener onClickAway={onClose}>
                  <div onKeyDown={handleKeyDown}>
                    <Grid container>{dropdownNode}</Grid>
                    <Grid
                      container
                      direction='row'
                      justifyContent='flex-end'
                      alignItems='flex-end'
                    >
                      <Button
                        variant='outlined'
                        onClick={handeApply}
                        color='secondary'
                        className={classes.doneBtn}
                      >
                        {onApply ? 'Apply' : 'Done'}
                      </Button>
                    </Grid>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          </Popper>
        )}
        <span className={classes.arrow} ref={arrowRef} />
      </div>
    );
  }
);

CustomDropdown.propTypes = {
  buttonText: PropTypes.string.isRequired,
  dropdownNode: PropTypes.instanceOf(Object).isRequired,
  groupPos: PropTypes.string,
  dropdownHeader: PropTypes.string,
  dropPlacement: PropTypes.oneOf([
    'bottom',
    'top',
    'right',
    'left',
    'bottom-start',
    'bottom-end',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
  ]),
  onApply: PropTypes.func,
  useDrawer: PropTypes.bool,
  width: PropTypes.string,
};

CustomDropdown.defaultProps = {
  groupPos: '',
  dropdownHeader: '',
  dropPlacement: 'bottom',
  onApply: null,
  useDrawer: false,
  width: 'auto',
};

export default CustomDropdown;
