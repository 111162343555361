/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid as Loader } from 'react-loader-spinner';

import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Loading = ({ className }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      className={className}
    >
      <Grid item xs={12}>
        <div style={{ textAlign: 'center', padding: 30 }}>
          <Loader
            arialLabel='loading'
            color={theme.palette.companyPrimary.main}
            height={60}
            width={120}
            wrapperStyle={{ display: 'block', transform: 'rotate(45deg)' }}
          />
          <Typography variant='h6' align='center' style={{ paddingTop: 20 }}>
            Loading...
          </Typography>
          {!navigator.onLine && (
            <Typography variant='body1' align='center'>
              There appears to be network issues, but we'll keep trying...
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
};

Loading.defaultProps = {
  className: '',
};

export default Loading;
