/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Gallery from 'react-photo-gallery';
import YouTubePlayer from 'react-player/youtube';
import VimeoPlayer from 'react-player/vimeo';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';

import TruncateText from 'components/TruncateText';
import Photo from './Photo';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  frame: {
    backgroundColor: '#fafafa',
    padding: 0,
    width: '100%',
    height: 'calc((100vw * 0.5625) + 52px)',
    maxWidth: '100%',
    maxHeight: '100%',
    outline: 'none',
    [theme.breakpoints.up('md')]: {
      padding: 10,
      width: 1000,
      height: 562.5,
    },
  },
  playerWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    outline: 'none',
  },
  player: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  icon: {
    cursor: 'pointer',
    fontSize: 32,
    '&:hover': {
      color: '#999999',
    },
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  gridColumnContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: '15px',
    alignItems: 'center',
    paddingBottom: 10,
    paddingTop: 3,
  },
  gridColumn: {
    justifyContent: 'flex-start',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.h6.fontWeight,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    paddingLeft: 5,
  },
}));

const VideoGallery = React.forwardRef(({ id, isLoading, videos }, ref) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [videoType, setVideoType] = React.useState('');
  const [videoTitle, setVideoTitle] = React.useState('');
  const [videoUrl, setVideoUrl] = React.useState('');

  const maxHeight = 200;
  let maxWidth = 0;
  videos.forEach((video) => {
    maxWidth += (maxHeight * video.thumbnail_width) / video.thumbnail_height;
  });
  const prepVideos = videos.map((v) => ({
    id: v.vid,
    type: v.type,
    src: v.thumbnail_url,
    width: v.thumbnail_width,
    height: v.thumbnail_height,
    caption: v.title,
  }));

  const onOpen = (event, video) => {
    const { type, title, vid } = videos[video.index];
    let url;
    if (type === 'youtube') {
      url = 'https://www.youtube.com/watch?v='.concat(vid);
    } else if (type === 'vimeo') {
      url = 'https://vimeo.com/'.concat(vid);
    }
    setVideoType(type);
    setVideoTitle(title);
    setVideoUrl(url);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div id={id} ref={ref}>
      {!isLoading ? (
        <div style={{ maxWidth }}>
          <Gallery
            photos={prepVideos}
            onClick={onOpen}
            targetRowHeight={200}
            renderImage={(props) => <Photo showPlayButton {...props} />}
          />
        </div>
      ) : null}
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.frame}>
            <div className={classes.gridColumnContainer}>
              <TruncateText
                lines={1}
                hideMore
                className={ClassNames(classes.gridColumn, classes.title)}
              >
                {videoTitle}
              </TruncateText>
              <div className={classes.gridColumn}>
                <CloseIcon onClick={onClose} className={classes.icon} />
              </div>
            </div>
            <div className={classes.playerWrapper}>
              {videoType === 'youtube' && (
                <YouTubePlayer
                  url={videoUrl}
                  className={classes.player}
                  playing
                  controls
                  width='100%'
                  height='calc(100% - 52px)'
                />
              )}
              {videoType === 'vimeo' && (
                <VimeoPlayer
                  url={videoUrl}
                  playing
                  controls
                  width='100%'
                  height='calc(100% - 52px)'
                />
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
});

VideoGallery.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  videos: PropTypes.arrayOf(PropTypes.object),
};

VideoGallery.defaultProps = {
  id: '',
  isLoading: false,
  videos: [],
};

export default VideoGallery;
