/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { CLOUDINARY_EDIT } from 'constants/general';

const socialGrowth = `${CLOUDINARY_EDIT}/v1613858534/sona/svg/social-growth.svg`;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1024,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 40,
    [theme.breakpoints.up('md')]: {
      paddingTop: 100,
    },
  },
  gridColumnContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridGap: '30px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'unset',
      gridColumnGap: 'unset',
      gridTemplateRows: 'auto auto',
    },
  },
  gridColumn: {
    justifyContent: 'center',
    display: 'flex',
    height: 'fit-content',
  },
  list: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    maxWidth: 900,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  companyPrimary: {
    color: theme.palette.companyPrimary.main,
  },
}));

const HowToMarketing = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  return (
    <div id={id} ref={ref} className={classes.root}>
      <Typography
        variant='h2'
        align='center'
        color='secondary'
        style={{ paddingBottom: 10 }}
      >
        <strong>Be efficient with your marketing.</strong>
      </Typography>
      <Typography
        variant='h6'
        align='center'
        fullwidth='true'
        style={{
          paddingBottom: 15,
          maxWidth: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        Maximum outreach with minimal effort.
      </Typography>
      <div className={classes.gridColumnContainer}>
        <div className={classes.gridColumn}>
          <ul className={classes.list}>
            <li>Reach your 50+ target audience more effectively</li>
            <li>
              Adding and managing events is quick and easy (we&#39;ll even do it
              for you if you&#39;d like)
            </li>
            <li>Promote your virtual events nation wide</li>
            <li>A trusted platform: all events are vetted</li>
            {/* <li>Much more affordable than traditional marketing</li> */}
            <li>
              Increase user engagement with YOUR platforms by linking your
              website and social media
            </li>
          </ul>
        </div>
        <div className={classes.gridColumn} style={{ alignSelf: 'center' }}>
          <img
            src={socialGrowth}
            alt='growth graph'
            style={{
              width: 150,
              height: 150,
              maxWidth: '100%',
            }}
          />
        </div>
      </div>
    </div>
  );
});

HowToMarketing.propTypes = {
  id: PropTypes.string,
};

HowToMarketing.defaultProps = {
  id: '',
};

export default HowToMarketing;
