/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import queryString from 'query-string';

import {
  fireauth,
  getFireStorage,
  getFirestore,
  getFireFunctions,
  logAnalyticsEvent,
} from 'vendors/firebase/main';
import { checkIfOnline } from 'utilities/utils';
import * as dbApi from 'vendors/firebase/firestore';
import { SITEURL } from 'constants/general';

let _isTestAccount = false;

// [START Logging]
export const getLogId = () =>
  performance.now().toString(36) + Math.random().toString(36).substr(2);
export const getShortId = () =>
  performance.now().toString(36).replace('.', '').substr(0, 10);

export const loggerInfo = async (logId, message) => {
  if (process.env.NODE_ENV !== 'development') {
    const ff = await getFireFunctions();
    const loggerInfo = ff.httpsCallable('loggerInfo');
    return loggerInfo({
      logId,
      message,
      uid: fireauth.currentUser ? fireauth.currentUser.uid : null,
    });
  }
};

export const loggerWarn = async (logId, message) => {
  if (process.env.NODE_ENV !== 'development') {
    const ff = await getFireFunctions();
    const loggerWarn = ff.httpsCallable('loggerWarn');
    return loggerWarn({
      logId,
      message,
      uid: fireauth.currentUser ? fireauth.currentUser.uid : null,
    });
  }
};

export const loggerError = async (logId, message) => {
  if (process.env.NODE_ENV !== 'development') {
    const ff = await getFireFunctions();
    const loggerError = ff.httpsCallable('loggerError');
    return loggerError({
      logId,
      message,
      uid: fireauth.currentUser ? fireauth.currentUser.uid : null,
    });
  }
};
// [END Logging]

export const doSendSupportMessage = async (
  fromName,
  fromEmail,
  message,
  uid
) => {
  const ff = await getFireFunctions();
  const sendSupportMessage = ff.httpsCallable('sendSupportMessage');
  return sendSupportMessage({
    fromName,
    fromEmail,
    message,
    uid,
  });
};

// [START Users]
export const isInternal = (email) => {
  const emailParts = email.split('@');
  if (emailParts.length > 1 && emailParts[1] === 'amightygoodtime.com') {
    return true;
  }
  return false;
};

export const isTestEmails = (email) => {
  if (email === 'ianp30@hotmail.com' || email === 'jelfen1114@hotmail.com') {
    _isTestAccount = true;
    return _isTestAccount;
  }
  const emailParts = email.split('@');
  if (emailParts.length > 1 && emailParts[1] === 'amightygoodtime.com') {
    _isTestAccount = true;
  } else if (
    emailParts.length > 1 &&
    emailParts[1] === 'hascare.org' &&
    emailParts[0].includes('test')
  ) {
    _isTestAccount = true;
  } else {
    _isTestAccount = false;
  }
  return _isTestAccount;
};

export const doSignOut = () => fireauth.signOut();

export const doCreateUser = async (
  email,
  password,
  displayNames,
  initials,
  firstName,
  lastName,
  paymentCustomerId,
  paymentOptions,
  memberPlan,
  nonProfitStatus,
  recaptchaToken,
  continueUrl
) => {
  let gaCleanedContinueUri;
  if (continueUrl) {
    const url = new URL(continueUrl);
    const { search } = url;
    const qss = queryString.parse(search);
    delete qss.n;
    gaCleanedContinueUri = queryString.stringify(qss);
  }

  const online = await checkIfOnline();
  if (!online) return Promise.reject(Error('offline'));
  const ff = await getFireFunctions();
  const fs = await getFirestore();
  const fr = await getFireStorage();
  const verifyRecaptchaToken = ff.httpsCallable('verifyRecaptchaToken');
  return verifyRecaptchaToken({
    recaptchaToken,
    version: 3,
  }).then(async (result) => {
    const { success, score } = result.data;
    if (success) {
      return fireauth
        .createUserWithEmailAndPassword(email, password)
        .then(async (authData) => {
          const { uid } = authData.user;
          const metadata = {
            initials,
            name: displayNames.displayName,
            publicName: displayNames.publicName,
            pid: 'none',
          };
          return fr
            .ref(`users/${uid}/avatar/metadata`)
            .put(new Blob([JSON.stringify(metadata)]), {
              contentType: 'text/plain',
              cacheControl: 'no-store',
              customMetadata: metadata,
            })
            .then((snapshot) => {
              return snapshot.ref
                .getDownloadURL()
                .then(async (photoURL) => {
                  const newUser = {
                    pending: true,
                    firstName,
                    lastName,
                    pid: 'none',
                    memberPlan: Number(memberPlan),
                    nonProfitStatus,
                    paymentCustomerId,
                    paymentOptions: paymentOptions || [],
                    paymentInvoiceId: null,
                    recaptchaScore: score,
                  };
                  if (isInternal(email)) {
                    newUser.internal = true;
                  }
                  if (isTestEmails(email)) {
                    newUser.testing = true;
                  }
                  return fireauth.currentUser
                    .updateProfile({
                      displayName: displayNames.displayName,
                      photoURL,
                      // phoneNumber: phonenum
                    })
                    .then(async () =>
                      fs
                        .collection('users')
                        .doc(uid)
                        .set(newUser)
                        .then(async () => {
                          try {
                            fireauth.currentUser.sendEmailVerification({
                              url: SITEURL,
                            });
                            logAnalyticsEvent('create_account', {
                              from: gaCleanedContinueUri,
                              recaptchaScore: score,
                            });
                          } catch (err) {
                          } finally {
                            return uid;
                          }
                        })
                        .catch((error) => {
                          throw error;
                        })
                    )
                    .catch((error) => {
                      doSignOut();
                      throw error;
                    });
                })
                .catch((error) => {
                  doSignOut();
                  throw error;
                });
            });
        })
        .catch((error) => {
          throw error;
        });
    }
    return Promise.reject();
  });
};

export const doDeleteUser = (password) =>
  fireauth
    .signInWithEmailAndPassword(fireauth.currentUser.email, password)
    .then(async () => {
      await dbApi.doDeleteUser(fireauth.currentUser.uid);
      return fireauth.currentUser.delete();
    });

export const doSignInWithEmailAndPassword = (email, password) =>
  fireauth
    .signInWithEmailAndPassword(email, password)
    .then(() => Promise.resolve(fireauth.currentUser));

export const doLoginAndVerifyEmail = (email, password, url) =>
  fireauth.signInWithEmailAndPassword(email, password).then(() =>
    fireauth.currentUser
      .sendEmailVerification({
        url,
      })
      .finally(() => doSignOut())
  );

export const doVerifyEmail = (continueUrl) =>
  dbApi.doVerifyEmail(
    fireauth.currentUser.uid,
    fireauth.currentUser.email,
    fireauth.currentUser.displayName,
    continueUrl
  );

export const doSendPasswordResetEmail = (email) =>
  fireauth.sendPasswordResetEmail(email);

const updateAuthDisplay = (displayName, photoURL) => {
  if (
    fireauth.currentUser.displayName !== displayName ||
    fireauth.currentUser.photoURL !== photoURL
  ) {
    return fireauth.currentUser.updateProfile({
      displayName,
      photoURL,
      // phoneNumber: phonenum
    });
  }
  return new Promise((resolve) => resolve('Skip'));
};

export const doUserVerified = () =>
  dbApi.doUserVerified(fireauth.currentUser.uid);

export const doUpdateUser = (displayName, photoURL, pid, firstName, lastName) =>
  updateAuthDisplay(displayName, photoURL).then(() =>
    dbApi.doUpdateUser(fireauth.currentUser.uid, pid, firstName, lastName)
  );

export const doUpdatePassword = (password, newPassword) =>
  fireauth
    .signInWithEmailAndPassword(fireauth.currentUser.email, password)
    .then(() => fireauth.currentUser.updatePassword(newPassword));

export const doForgotPassword = () =>
  fireauth.sendPasswordResetEmail(fireauth.currentUser.email);

export const doUpdateEmail = (password, email) => {
  if (fireauth.currentUser.email === email) {
    return new Promise((resolve) => resolve('Skip'));
  }
  return fireauth
    .signInWithEmailAndPassword(fireauth.currentUser.email, password)
    .then(() => fireauth.currentUser.updateEmail(email));
};

export const doUpdateFavorites = (favorites) =>
  dbApi.doUpdateFavorites(fireauth.currentUser.uid, favorites);

export const doUpdateSubscriber = async (
  email,
  firstName,
  lastName,
  previousEmail
) => {
  const ff = await getFireFunctions();
  const updateSubscriber = ff.httpsCallable('updateSubscriber');
  return updateSubscriber({
    email,
    firstName,
    lastName,
    previousEmail,
  });
};

export const doUpdateMemberPlan = (plan) =>
  dbApi.doUpdateMemberPlan(fireauth.currentUser.uid, plan);

export const doUpdateCustomerId = (customerId) =>
  dbApi.doUpdateCustomerId(fireauth.currentUser.uid, customerId);

export const doUpdatePlanInfo = (
  customerId,
  paymentOptions,
  plan,
  nonProfitStatus
) =>
  dbApi.doUpdatePlanInfo(
    fireauth.currentUser.uid,
    customerId,
    paymentOptions,
    plan,
    nonProfitStatus
  );

export const doCreateStripeCustomer = async (email, name) => {
  const ff = await getFireFunctions();
  const createStripeCustomer = ff.httpsCallable(
    isTestEmails(email)
      ? 'stripeTest-createStripeCustomer'
      : 'stripeProd-createStripeCustomer'
  );
  return createStripeCustomer({ email, name });
};

export const doGetStripeCustomerInfo = async (customerId) => {
  const ff = await getFireFunctions();
  const getStripeCustomerInfo = ff.httpsCallable(
    _isTestAccount
      ? 'stripeTest-getStripeCustomerInfo'
      : 'stripeProd-getStripeCustomerInfo'
  );
  return getStripeCustomerInfo({
    customerId,
  });
};

export const doGetStripeCustomerCards = async (customerId, limit) => {
  const ff = await getFireFunctions();
  const getStripeCustomerCards = ff.httpsCallable(
    _isTestAccount
      ? 'stripeTest-getStripeCustomerCards'
      : 'stripeProd-getStripeCustomerCards'
  );
  return getStripeCustomerCards({
    customerId,
    limit,
  });
};

export const doGetStripeCustomerInvoiceList = async (customerId) => {
  const ff = await getFireFunctions();
  const getStripeCustomerInvoiceList = ff.httpsCallable(
    _isTestAccount
      ? 'stripeTest-getStripeCustomerInvoiceList'
      : 'stripeProd-getStripeCustomerInvoiceList'
  );
  return getStripeCustomerInvoiceList({
    customerId,
  });
};

export const doGetStripeCustomerUpcomingInvoice = async (customerId) => {
  const ff = await getFireFunctions();
  const getStripeCustomerUpcomingInvoice = ff.httpsCallable(
    _isTestAccount
      ? 'stripeTest-getStripeCustomerUpcomingInvoice'
      : 'stripeProd-getStripeCustomerUpcomingInvoice'
  );
  return getStripeCustomerUpcomingInvoice({
    customerId,
  });
};

export const doAddStripeCustomerCard = async (customerId, token) => {
  const ff = await getFireFunctions();
  const addStripeCustomerCard = ff.httpsCallable(
    _isTestAccount
      ? 'stripeTest-addStripeCustomerCard'
      : 'stripeProd-addStripeCustomerCard'
  );
  return addStripeCustomerCard({
    customerId,
    token,
  }).then((result) => {
    if (fireauth.currentUser) {
      return dbApi.doAddCardOption(fireauth.currentUser.uid);
    }
    return result;
  });
};

export const doRemoveStripeCustomerCard = async (customerId, cardId) => {
  const ff = await getFireFunctions();
  const removeStripeCustomerCard = ff.httpsCallable(
    _isTestAccount
      ? 'stripeTest-removeStripeCustomerCard'
      : 'stripeProd-removeStripeCustomerCard'
  );
  return removeStripeCustomerCard({
    customerId,
    cardId,
  }).then((result) => {
    const { data } = result;
    if (data.data.length === 0) {
      return dbApi.doRemoveCardOption(fireauth.currentUser.uid);
    }
    return Promise.resolve('success');
  });
};

export const doSetStripeCustomerDefaultCard = async (customerId, cardId) => {
  const ff = await getFireFunctions();
  const setStripeCustomerDefaultCard = ff.httpsCallable(
    _isTestAccount
      ? 'stripeTest-setStripeCustomerDefaultCard'
      : 'stripeProd-setStripeCustomerDefaultCard'
  );
  return setStripeCustomerDefaultCard({
    customerId,
    cardId,
  });
};

export const doUpdateUserTag = async (tagName, tagStatus) => {
  const ff = await getFireFunctions();
  const updateUserTags = ff.httpsCallable('updateUserTags');
  return updateUserTags({
    email: fireauth.currentUser.email,
    tagName,
    tagStatus,
  });
};

export const doSubscribeToMailchimp = async (email, tagName) => {
  const ff = await getFireFunctions();
  const subscribeToMailchimp = ff.httpsCallable('updateSubscriber_v2');
  const params = {
    email,
  };
  if (tagName) {
    params.tagName = tagName;
  }
  return subscribeToMailchimp(params);
};

export const doGetSubscriberInfo = async (email) => {
  const ff = await getFireFunctions();
  const getSubscriberInfo = ff.httpsCallable('getSubscriberInfo');
  const params = {
    email,
  };
  return getSubscriberInfo(params);
};

export const doUpdateSubscribeNamesAndGroups = async (
  email,
  firstName,
  lastName,
  companyName,
  upcomingEventsGroup,
  organizerGroup
) => {
  const ff = await getFireFunctions();
  const updateSubscriberNamesAndGroups = ff.httpsCallable(
    'updateSubscriberNamesAndGroups'
  );
  const params = {
    email,
    firstName,
    lastName,
    companyName,
    upcomingEventsGroup,
    organizerGroup,
  };
  return updateSubscriberNamesAndGroups(params);
};

export const doUpdateSubscribeGroups = async (
  email,
  organizerGroup,
  mobile
) => {
  const ff = await getFireFunctions();
  const updateSubscriberGroups = ff.httpsCallable('updateSubscriberGroups');
  const params = {
    email,
    upcomingEventsGroup: true,
    organizerGroup,
    mobile,
  };
  return updateSubscriberGroups(params);
};

export const doUnsubscribeSubscriber = async (email) => {
  const ff = await getFireFunctions();
  const unsubscribeSubscriber = ff.httpsCallable('unsubscribeSubscriber');
  const params = {
    email,
  };
  return unsubscribeSubscriber(params);
};

export const doUsageLogs = async (
  table,
  actions,
  actionDesc,
  orgId,
  eventId,
  params
) => {
  const ff = await getFireFunctions();
  const data = {
    table,
    actions,
    actionDesc,
    userId: fireauth.currentUser.uid,
    orgId,
    eventId,
    params: JSON.stringify(params),
  };
  const usageLogs = ff.httpsCallable('usageLogs');
  try {
    const result = await usageLogs(data);
    return result.data;
  } catch (err) {
    const logId = getLogId();
    loggerError(logId, `Usage Logs: ${JSON.stringify(data)}`);
  }
};
// [END Users]

// [START Events]
export const doSaveEvent = (event, saveStatus) =>
  dbApi.doSaveEvent(fireauth.currentUser.uid, event, saveStatus);

export const getNumOfEventsWithDraftOrganizer = (orgId) =>
  dbApi.getNumOfEventsWithDraftOrganizer(fireauth.currentUser.uid, orgId);

export const getNumOfEventsWithPublicOrganizer = (orgId) =>
  dbApi.getNumOfEventsWithPublicOrganizer(fireauth.currentUser.uid, orgId);
// [END Events]

// [START Organizers]
export const doGetUserDraftOrganizersRef = () =>
  dbApi.doGetDraftOrganizersRef(fireauth.currentUser.uid);

export const doGetUserPublicOrganizersRef = () =>
  dbApi.doGetPublicOrganizersRef(fireauth.currentUser.uid);

export const doSaveOrganizer = (organizer, saveStatus) =>
  dbApi.doSaveOrganizer(fireauth.currentUser.uid, organizer, saveStatus);
// [END Organizers]
