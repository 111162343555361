/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Accordion from 'components/Accordion';
import VideoGallery from 'components/Media/VideoGallery';

import {
  ACCOUNT_URI,
  ACCOUNT_DELETE_URI,
  ACCOUNT_EDIT_URI,
  ACCOUNT_EMAIL_URI,
  ACCOUNT_PW_URI,
  CONTACTUS_URI,
  EVENTS_URI,
  ORGANIZERS_URI,
  SIGNUP_URI,
} from 'constants/routes';
import { MODE } from 'constants/general';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1024,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 40,
    [theme.breakpoints.up('md')]: {
      paddingTop: 60,
    },
  },
  contactus: {
    paddingTop: 30,
    paddingBottom: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: 50,
    },
  },
  oList: {
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.only('xs')]: {
      paddingInlineStart: '15px',
    },
    '& > li': {
      paddingBottom: 15,
    },
  },
  uList: {
    marginTop: 15,
    marginBottom: 0,
    paddingInlineStart: 0,
    '& > li': {
      listStyleType: 'none',
      paddingBottom: 10,
    },
  },
  link: {
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  videos: {
    paddingLeft: 10,
    paddingBottom: 15,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 30,
      paddingBottom: 20,
    },
  },
}));

const videos = [
  {
    thumbnail_height: 360,
    thumbnail_width: 480,
    thumbnail_url: 'https://i.ytimg.com/vi/cUfOo8KwBPk/hqdefault.jpg',
    title: 'AMGT: How To Add an Event',
    type: 'youtube',
    vid: 'cUfOo8KwBPk',
  },
];

const SupportTutorials = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const onChange = (panel, panelExpaned) => {
    setExpanded(panelExpaned ? panel : false);
  };

  return (
    <div id={id} ref={ref} className={classes.root}>
      <Typography
        variant='h2'
        align='center'
        color='secondary'
        style={{ paddingBottom: 30 }}
      >
        <strong>Support Tutorials</strong>
      </Typography>
      <div className={classes.videos}>
        <VideoGallery videos={videos} />
      </div>
      <Accordion
        id='adding-a-new-event'
        summary='Adding a new event'
        details={
          <span>
            Are you hosting an event that you want to promote? It&#39;s quick
            and easy to add an event to the calendar.
            <p style={{ marginTop: 10 }}>
              <i>
                If you <strong>have</strong> already created an account, then
                follow these steps to add an event:
              </i>
            </p>
            <ol type='1' className={classes.oList}>
              <li>
                On the website menu at the top of the page, click &quot;
                <strong>Add Event</strong>&quot;. Or, click{' '}
                <Link
                  to={{
                    pathname: EVENTS_URI,
                    state: {
                      mode: MODE.CREATE,
                      eventType: 'Event',
                    },
                  }}
                  className={classes.link}
                >
                  here
                </Link>{' '}
                to go there directly. Please note that if you are not currently
                logged in when you click the link, you will be prompted to do so
                before being taken to the "Add Event" page.
              </li>
              <li>
                Fill in all the required fields, and be as detailed as possible
                so that people will know how fabulous your event is! Please keep
                in mind that an event profile picture is also required. You can
                create an in-person, virtual, or phone-accessible event.
              </li>
              <li>
                Once you have finished entering all the information for your
                event, you can click the &quot;
                <strong>Preview</strong>&quot; button to see how it will be
                displayed in the calendar search results. When you are all
                finished, click the &quot;
                <strong>Submit for review</strong>&quot; button. Our team will
                then get a chance to look it over and make sure it&#39;s good to
                go. Please allow up to 24 hours for approval (but we’re pretty
                fast, so it doesn&#39;t usually take that long!).
              </li>
            </ol>
            <i>
              If you <strong>have not</strong> yet created an account, you can
              do that while adding an event. Just follow these steps:
            </i>
            <ol
              type='1'
              className={classes.oList}
              style={{ marginTop: 10, marginBottom: 5 }}
            >
              <li>
                On the website menu at the top of the page, click &quot;
                <strong>Add Event.</strong>&quot; You will be taken to a
                &quot;log in&quot; page. Click the button underneath the log-in
                section that says &quot;
                <strong>Create Free Account</strong>&quot; (it&#39;s underneath
                the fireworks logo). Or, click{' '}
                <Link to={`${SIGNUP_URI}`} className={classes.link}>
                  here
                </Link>{' '}
                to go there directly.
                <p>
                  <strong>
                    You will be prompted to create an account profile by
                    entering your name, email, and creating a password.
                  </strong>{' '}
                  We will send an email to the address you provided, asking you
                  to validate it before moving forward with creating an event.{' '}
                  <strong>
                    Make sure to check your spam folder if you don&#39;t see the
                    email from us right away.
                  </strong>{' '}
                  If you don&#39;t see an email from us, even after checking
                  your spam folder, call us and we&#39;ll get you set up right
                  away! <strong>512-222-9855</strong>
                </p>
                <p style={{ marginBottom: 5 }}>
                  <i>
                    Validating emails is one of the ways we make our website
                    secure. Thank you for helping us continue to do that!
                  </i>
                </p>
              </li>
              <li>
                When you receive an email from our team
                (support@amightygoodtime.com), you can{' '}
                <strong>validate your address by clicking on the link.</strong>{' '}
                That link will take you back to the &quot;Log in page&quot;
                where you will be prompted to log in with your email and
                password. Then you will be immediately taken to an event
                creation page, which is in your account dashboard.
              </li>
              <li>
                On the event creation page, fill in all the required fields and
                be as detailed as possible so that people will know how fabulous
                your event is! Please keep in mind that an event profile picture
                is also required. You can create an in-person, virtual, or
                phone-accessible event.
              </li>
              <li>
                Once you have finished entering all the information for your
                event, you can click the &quot;
                <strong>Preview</strong>&quot; button to see how it will be
                displayed in the calendar search results. When you are all
                finished, click the &quot;
                <strong>Submit for review</strong>&quot; button. Our team will
                then get a chance to look it over and make sure it&#39;s good to
                go. Please allow up to 24 hours for approval (but we&#39;re
                pretty fast, so it doesn&#39;t usually take that long!).
              </li>
            </ol>
            Are you getting error messages when trying to submit for review? It
            might be because there is some required information that you have
            not entered in your event. Check for the red error prompts directing
            you to what more is needed.
          </span>
        }
        expanded={expanded === 'adding-a-new-event'}
        onChange={onChange}
      />
      <Accordion
        id='editing-deleting-your-event'
        summary='Editing or deleting your event'
        details={
          <span>
            <ol type='1' className={classes.oList}>
              <li>
                Log into your account and then click on your profile icon at the
                top right corner of the webpage and select &quot;
                <strong>My Account</strong>&quot; from the drop-down list. If
                you are currently logged in, you can just 
                <Link to={`${ACCOUNT_URI}`} className={classes.link}>
                  click here
                </Link>{' '}
                which will take you directly there.
              </li>
              <li>
                On the left side of the screen, you&#39;ll see the word &quot;
                <strong>Events</strong>&quot;. Go ahead and click on that. If
                you are currently logged in, you can 
                <Link to={`${EVENTS_URI}`} className={classes.link}>
                  click here
                </Link>{' '}
                to go directly to your event list.
              </li>
              <li>
                You&#39;ll then see a list of all the events you have created.
                When you spot the one you&#39;d like to edit or delete, go ahead
                and click on it.
              </li>
              <li>
                You&#39;ll then be viewing the event profile. Click either the
                &quot;<strong>Edit</strong>&quot; or &quot;
                <strong>Delete</strong>&quot; button on the top of the profile,
                depending on what you&#39;d like to do.
              </li>
              <li>
                <strong>If you are deleting</strong>: Follow the prompts to
                confirm the deletion process.
              </li>
              <li>
                <strong>If you are editing</strong>: Once you have finished
                editing the event, go ahead and click the &quot;
                <strong>Submit for review</strong>&quot; button at the top of
                the event profile. Our team will then get a chance to look it
                over and make sure it&#39;s good to go! Please allow up to 24
                hours for approval and for it to be posted to the calendar (but
                we&#39;re pretty fast, so it doesn&#39;t usually take that
                long!).{' '}
                <i>
                  Please note that after you submit an event for review, you
                  cannot make any other changes until that version has been
                  approved.
                </i>
              </li>
            </ol>
          </span>
        }
        expanded={expanded === 'editing-deleting-your-event'}
        onChange={onChange}
      />
      <Accordion
        id='adding-an-event-organizer'
        summary='Adding an event organizer'
        details={
          <span>
            When creating a new event, you will need to tell us a little bit
            about the organizer of the event.{' '}
            <strong>
              The organizer is the organization, company, or person that is
              hosting the event.
            </strong>{' '}
            When listing information about the organizer for your event, you can
            add a logo, website address, contact information, social media
            links, and a general description. You may create multiple organizer
            profiles if you want, but only one can be designated per each
            separate event.
            <p>
              <strong>
                You can add an event organizer at the same time that you create
                your event. When you are creating your event, you will be
                prompted for organizer details.
              </strong>
            </p>
            <p>
              But if you want to add an organizer to your profile before
              creating an event, go ahead and follow these steps:
            </p>
            <ol type='1' className={classes.oList}>
              <li>
                Log into your account and then click on your profile icon at the
                top right corner of the webpage and select &quot;
                <strong>My Account</strong>&quot; from the drop-down list. If
                you are currently logged in, you can just 
                <Link to={`${ACCOUNT_URI}`} className={classes.link}>
                  click here
                </Link>{' '}
                which will take you directly to your dashboard.
              </li>
              <li>
                On the left side of the screen, you&#39;ll see the word &quot;
                <strong>Organizers</strong>&quot;. Go ahead and click on that.
                If you are currently logged in, you can 
                <Link to={`${ORGANIZERS_URI}`} className={classes.link}>
                  click here
                </Link>{' '}
                to go directly to your organizer list.
              </li>
              <li>
                You&#39;ll see a button at the top that says &quot;
                <strong>Add organizer</strong>&quot;. Go ahead and click on
                that.
              </li>
              <li>
                Now you can add information about your organizer/host. Feel free
                to add a logo, website and contact information, social media
                links, and a description. If you change you mind and decide you
                don&#39;t want to add a new organizer, click the &quot;
                <strong>Cancel changes</strong>&quot; button at the top left of
                the organizer profile.
              </li>
              <li>
                Once you&#39;re finished entering all the information about your
                organizer, you can click the &quot;
                <strong>Preview</strong>&quot; button to see how it will look
                when it&#39;s listed under your events. When you are all
                finished, click the &quot;
                <strong>Submit for review</strong>&quot; button. Our team will
                then get a chance to look it over and make sure it&#39;s good to
                go. Please allow up to 24 hours for approval (but we&#39;re
                pretty fast, so it doesn&#39;t usually take that long!).
              </li>
            </ol>
          </span>
        }
        expanded={expanded === 'adding-an-event-organizer'}
        onChange={onChange}
      />
      <Accordion
        id='editing-deleting-an-organizer'
        summary='Editing or deleting an organizer'
        details={
          <span>
            <ol type='1' className={classes.oList}>
              <li>
                Log into your account and then click on your profile icon at the
                top right corner of the webpage and select &quot;
                <strong>My Account</strong>&quot; from the drop-down list. This
                will take you to your account dashboard. If you are currently
                logged in, you can just 
                <Link to={`${ACCOUNT_URI}`} className={classes.link}>
                  click here
                </Link>{' '}
                which will take you directly to your account dashboard.
              </li>
              <li>
                On the left side of the screen, you&#39;ll see the word &quot;
                <strong>Organizers</strong>&quot;. Go ahead and click on that.
                If you are currently logged in, you can 
                <Link to={`${ORGANIZERS_URI}`} className={classes.link}>
                  click here
                </Link>{' '}
                to go directly to your organizer list.
              </li>
              <li>
                You&#39;ll then see a list of all the organizers you have
                created. When you spot the one you&#39;d like to edit or delete,
                go ahead and click on it.
              </li>
              <li>
                You&#39;ll then be viewing the organizer profile. Click either
                the &quot;<strong>Edit</strong>&quot; or &quot;
                <strong>Delete</strong>&quot; button on the top of the profile,
                depending on what you’d like to do.
              </li>
              <li>
                <strong>If you are deleting</strong>: Follow the prompts to
                confirm the deletion process. Remember, this will permanently
                delete it from your account.
              </li>
              <li>
                <strong>If you are editing</strong>: Once you have finished
                editing the organizer details, go ahead and click the &quot;
                <strong>Submit for review</strong>&quot; button. Our team will
                then get a chance to look it over and make sure it&#39;s good to
                go! Please allow up to 24 hours for approval and for it to be
                updated on your event (but we&#39;re pretty fast, so it doesn’t
                usually take that long!)
              </li>
            </ol>
          </span>
        }
        expanded={expanded === 'editing-deleting-an-organizer'}
        onChange={onChange}
      />
      <Accordion
        id='deleting-your-account'
        summary='Deleting your account'
        details={
          <span>
            We are sorry to see you go. If there is something we can do to make
            your experience better, please 
            <Link to={`${CONTACTUS_URI}`} className={classes.link}>
              contact us!
            </Link>
            <p>
              To delete your account and all your events, organizers, and
              profile information, follow the steps below. Once deleted, your
              events will no longer be listed in the calendar and you will not
              be able to retrieve any of your event or organizer information.
            </p>
            <p>
              <strong>
                To permanently delete your account, follow these steps:
              </strong>
            </p>
            <p>
              To delete your account, click{' '}
              <Link to={`${ACCOUNT_DELETE_URI}`} className={classes.link}>
                here
              </Link>{' '}
              to be taken directly to the Delete Account section on your account
              dashboard where you can complete the process. Please note that if
              you are not currently logged in when you click the link, you will
              be prompted to do so before being taken to the Delete Account
              section.
            </p>
          </span>
        }
        expanded={expanded === 'deleting-your-account'}
        onChange={onChange}
      />
      <Accordion
        id='change-your-password'
        summary='How to: change your password, edit your profile, change your email'
        details={
          <span>
            <ol type='1' className={classes.oList}>
              <li>
                Log into your account and then click on your profile icon at the
                top right corner of the webpage and select &quot;
                <strong>My Account</strong>&quot; from the drop-down list. This
                will take you to your account dashboard. If you are already
                logged into your account now, then 
                <Link to={`${ACCOUNT_URI}`} className={classes.link}>
                  click here
                </Link>
                 to be taken directly to your dashboard.
              </li>
              <li>
                You&#39;ll see your account profile, with your name at the top
                and email address underneath. Below that, you&#39;ll see the
                following buttons. Click on whichever one fits your needs and
                follow the prompts. If you are currently logged in, just click
                on the links below to go directly there
                <ul className={classes.uList}>
                  <li>
                    <Link to={`${ACCOUNT_EDIT_URI}`} className={classes.link}>
                      Edit profile
                    </Link>
                  </li>
                  <li>
                    <Link to={`${ACCOUNT_PW_URI}`} className={classes.link}>
                      Change password
                    </Link>
                  </li>
                  <li>
                    <Link to={`${ACCOUNT_EMAIL_URI}`} className={classes.link}>
                      Change email
                    </Link>
                  </li>
                  <li>
                    <Link to={`${ACCOUNT_DELETE_URI}`} className={classes.link}>
                      Delete Account
                    </Link>
                  </li>
                </ul>
              </li>
            </ol>
          </span>
        }
        expanded={expanded === 'change-your-password'}
        onChange={onChange}
      />
      <Typography
        variant='body1'
        align='center'
        color='inherit'
        className={classes.contactus}
      >
        <strong>
          Have additional questions? Please{' '}
          <Link to={`${CONTACTUS_URI}`} className={classes.link}>
            contact us
          </Link>
          !
        </strong>
      </Typography>
    </div>
  );
});

SupportTutorials.propTypes = {
  id: PropTypes.string,
};

SupportTutorials.defaultProps = {
  id: '',
};

export default SupportTutorials;
