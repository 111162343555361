/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { CLOUDINARY } from 'constants/general';

const logo = `${CLOUDINARY}/sona/img/amgt_logo_v1.png`;

const SplashPage = ({ message }) => {
  // eslint-disable-next-line no-unused-vars
  const [spMessage, setSpMessage] = useState(() => {
    if (message) return message;
    const { p } = queryString.parse(window.location.search);
    if (p === 'logout') return 'Logout...';
    return 'Loading...';
  });

  return (
    <main className='sp-main'>
      <div className='sp-div'>
        <p className='sp-p'>
          <img className='sp-img' src={logo} alt='A Mighty Good Time' />
        </p>
        <p className='sp-p'>
          <font className='sp-load'>
            <strong>{spMessage}</strong>
          </font>
        </p>
      </div>
    </main>
  );
};

SplashPage.propTypes = {
  message: PropTypes.string,
};

SplashPage.defaultProps = {
  message: null,
};

export default SplashPage;
