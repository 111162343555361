/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import Accordion from 'components/Accordion';
import VideoGallery from 'components/Media/VideoGallery';

import {
  ACCOUNT_URI,
  CONTACTUS_URI,
  ACCOUNT_DELETE_URI,
  ACCOUNT_EDIT_URI,
  ACCOUNT_PW_URI,
  ACCOUNT_EMAIL_URI,
  EVENT_ORGANIZERS_URI,
  SIGNUP_URI,
} from 'constants/routes';
import { SUPPORT_EMAIL, PHONE } from 'constants/general';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 40,
    [theme.breakpoints.up('md')]: {
      paddingTop: 60,
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  link: {
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  videos: {
    paddingBottom: 15,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 30,
      paddingBottom: 20,
    },
  },
  oList: {
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.only('xs')]: {
      paddingInlineStart: '15px',
    },
    '& > li': {
      paddingBottom: 15,
    },
  },
  uList: {
    marginTop: 15,
    marginBottom: 0,
    paddingInlineStart: 0,
    '& > li': {
      listStyleType: 'none',
      paddingBottom: 10,
    },
  },
  title: {
    padding: '0 10px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '0 10px 30px',
    },
  },
}));

const videos = [
  {
    thumbnail_height: 360,
    thumbnail_width: 480,
    thumbnail_url: 'https://i.ytimg.com/vi/cMXEDCBwMOY/hqdefault.jpg',
    title: 'AMGT: Quick Overview',
    type: 'youtube',
    vid: 'cMXEDCBwMOY',
  },
  {
    thumbnail_height: 360,
    thumbnail_width: 480,
    thumbnail_url: 'https://i.ytimg.com/vi/VIK3MsragKo/hqdefault.jpg',
    title: 'AMGT: How To Search For Events and Mark Them as a Favorite',
    type: 'youtube',
    vid: 'VIK3MsragKo',
  },
];

const HowItWorksFAQ = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);

  const onChange = (panel, panelExpaned) => {
    setExpanded(panelExpaned ? panel : false);
  };

  return (
    <div id={id} ref={ref} className={classes.root}>
      <Typography
        variant='h2'
        align={fullScreen ? 'left' : 'center'}
        color='secondary'
        className={classes.title}
      >
        <strong>Frequently Asked Questions</strong>
      </Typography>
      <Typography variant='h5' align='left' className={classes.title}>
        <strong>
          Event Organizers FAQ: See our{' '}
          <Link to={`${EVENT_ORGANIZERS_URI}`} className={classes.link}>
            Organizer's page
          </Link>
        </strong>
      </Typography>
      <Accordion
        id='question-1'
        summary='Is it free to use this website?'
        details={
          <span>
            Yes! It&#39;s free to browse the calendar and to create an account.
          </span>
        }
        expanded={expanded === 'question-1'}
        onChange={onChange}
      />
      <Accordion
        id='question-2'
        summary='Are the events free?'
        details={
          <span>
            Events are hosted by different organizers and so each event has a
            different price. The price of the event will be listed at the top of
            the event profile. If the event is FREE, it will indicate that in
            the price description.
          </span>
        }
        expanded={expanded === 'question-2'}
        onChange={onChange}
      />
      <Accordion
        id='question-3'
        summary='I need help with my account and using the website.'
        details={
          <span>
            Please see our "Support Tutorials" below. If you need additional
            assistance, please{' '}
            <Link to={`${CONTACTUS_URI}`} className={classes.link}>
              contact us
            </Link>{' '}
            .
          </span>
        }
        expanded={expanded === 'question-3'}
        onChange={onChange}
      />
      <Accordion
        id='question-4'
        summary='I need help registering for an event.'
        details={
          <span>
            Please contact the event organizer with any questions about the
            event itself or assistance with registering for the event. If you do
            not hear back from the event organizer after reaching out to them,
            then please{' '}
            <Link to={`${CONTACTUS_URI}`} className={classes.link}>
              contact us
            </Link>{' '}
            directly.
          </span>
        }
        expanded={expanded === 'question-4'}
        onChange={onChange}
      />
      <Accordion
        id='question-5'
        summary='How do I contact you if I need help or have more questions?'
        details={
          <span>
            If you have questions about your account, please view the Support
            Tutorials below. If you still need help, please contact us by
            clicking{' '}
            <Link to={`${CONTACTUS_URI}`} className={classes.link}>
              HERE
            </Link>{' '}
            .
          </span>
        }
        expanded={expanded === 'question-5'}
        onChange={onChange}
      />

      <Typography
        variant='h2'
        align={smDown ? 'left' : 'center'}
        color='secondary'
        className={classes.title}
        style={{ marginTop: 20 }}
      >
        <strong>Support Tutorials</strong>
      </Typography>
      <div className={classes.videos}>
        <VideoGallery videos={videos} />
      </div>
      <Accordion
        id='create-and-account'
        summary='Creating an account'
        details={
          <span>
            <ol type='1' className={classes.oList}>
              <li>
                On the website menu at the top of the page, click{' '}
                <strong>&quot;Log in.&quot;</strong> On the next page, click the
                button that says{' '}
                <strong>&quot;Create Free Account&quot;</strong> (it's
                underneath the fireworks logo). If you’re ready to proceed with
                this process now, click{' '}
                <Link to={`${SIGNUP_URI}`} className={classes.link}>
                  HERE
                </Link>{' '}
                to go to the create account page, or, continue reading the
                directions below before proceeding.
              </li>
              <li>
                <strong>
                  You will be prompted to enter your name, email address, and
                  create a password.
                </strong>{' '}
                We will send an email to the address you provided, asking you to
                validate it.{' '}
                <strong>Make sure to check your spam folder.</strong> If you
                don&#39;t see an email from us, even after checking your spam
                folder, call us and we&#39;ll get you set up right away!{' '}
                <strong>
                  <a
                    href={`tel:${PHONE}`}
                    className={classes.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {PHONE.replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3')}
                  </a>
                </strong>
                <p style={{ marginBottom: 5 }}>
                  <i>
                    Validating emails is one of the ways we make our website
                    secure. Thank you for helping us continue to do that!
                  </i>
                </p>
              </li>
              <li>
                Our email will come from this address:{' '}
                <a
                  href={`mailto:${SUPPORT_EMAIL}`}
                  className={classes.link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {SUPPORT_EMAIL}
                </a>
                . Please 
                <strong>
                  validate your email by clicking on the link provided to you in
                  our email
                </strong>
                . That link will take you back to the{' '}
                <strong>&quot;Log in page&quot;</strong> where you will be
                prompted to log in with your email and password.
              </li>
              <li>
                After you log in, you&#39;re good to go! Anytime you want to
                explore the features of your account dashboard, click on your
                profile icon in the top right corner of the webpage. Your
                profile icon is the round circle with your initials inside.
              </li>
            </ol>
          </span>
        }
        expanded={expanded === 'create-and-account'}
        onChange={onChange}
      />
      <Accordion
        id='deleting-your-account'
        summary='Deleting your account'
        details={
          <span>
            We are sorry to see you go. If there is something we can do to make
            your experience better, please 
            <Link to={`${CONTACTUS_URI}`} className={classes.link}>
              contact us!
            </Link>
            <p>
              To delete your account and all your events, organizers, and
              profile information, follow the steps below. Once deleted, your
              events will no longer be listed in the calendar and you will not
              be able to retrieve any of your event or organizer information.
            </p>
            <p>
              <strong>
                To permanently delete your account, follow these steps:
              </strong>
            </p>
            <p>
              To delete your account, click{' '}
              <Link to={`${ACCOUNT_DELETE_URI}`} className={classes.link}>
                here
              </Link>{' '}
              to be taken directly to the Delete Account section on your account
              dashboard where you can complete the process. Please note that if
              you are not currently logged in when you click the link, you will
              be prompted to do so before being taken to the Delete Account
              section.
            </p>
          </span>
        }
        expanded={expanded === 'deleting-your-account'}
        onChange={onChange}
      />
      <Accordion
        id='change-your-password'
        summary='How to: change your password, edit your profile, change your email'
        details={
          <span>
            <ol type='1' className={classes.oList}>
              <li>
                Log into your account and then click on your profile icon at the
                top right corner of the webpage and select &quot;
                <strong>My Account</strong>&quot; from the drop-down list. This
                will take you to your account dashboard. If you are already
                logged into your account now, then 
                <Link to={`${ACCOUNT_URI}`} className={classes.link}>
                  click here
                </Link>
                 to be taken directly to your dashboard.
              </li>
              <li>
                You&#39;ll see your account profile, with your name at the top
                and email address underneath. Below that, you&#39;ll see the
                following buttons. Click on whichever one fits your needs and
                follow the prompts. If you are currently logged in, just click
                on the links below to go directly there
                <ul className={classes.uList}>
                  <li>
                    <Link to={`${ACCOUNT_EDIT_URI}`} className={classes.link}>
                      Edit profile
                    </Link>
                  </li>
                  <li>
                    <Link to={`${ACCOUNT_PW_URI}`} className={classes.link}>
                      Change password
                    </Link>
                  </li>
                  <li>
                    <Link to={`${ACCOUNT_EMAIL_URI}`} className={classes.link}>
                      Change email
                    </Link>
                  </li>
                  <li>
                    <Link to={`${ACCOUNT_DELETE_URI}`} className={classes.link}>
                      Delete Account
                    </Link>
                  </li>
                </ul>
              </li>
            </ol>
          </span>
        }
        expanded={expanded === 'change-your-password'}
        onChange={onChange}
      />
    </div>
  );
});

HowItWorksFAQ.propTypes = {
  id: PropTypes.string,
};

HowItWorksFAQ.defaultProps = {
  id: '',
};

export default HowItWorksFAQ;
