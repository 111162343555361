/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BackIcon from '@material-ui/icons/ArrowBack';

import { CLOUDINARY } from 'constants/general';
import { LANDING_URI } from 'constants/routes';

const logo = `${CLOUDINARY}/sona/img/amgt_logo_v1.png`;

const useStyles = makeStyles((theme) => ({
  logo: {
    transition: 'all 0.4s ease-out',
    height: 45,
    [theme.breakpoints.up('sm')]: {
      height: 73,
    },
    maxWidth: 429,
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    paddingLeft: 4,
    transition: 'all 0.4s ease-out',
    height: 45,
    [theme.breakpoints.up('sm')]: {
      height: 73,
    },
  },
  tagline: {
    color: '#333435',
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    marginTop: 10,
  },
  white: {
    color: theme.palette.common.white,
  },
  backButton: {
    textTransform: 'inherit',
    marginLeft: 15,
    marginTop: 6,
    padding: '15px 42px',
    [theme.breakpoints.only('xs')]: {
      marginTop: 2,
      padding: '5px 30px',
    },
  },
  hide: {
    position: 'relative',
    fontSize: '0rem',
    opacity: 0,
  },
}));

const Logo = React.forwardRef(({ id, className, onBack, showBackBtn }, ref) => {
  const classes = useStyles();

  return (
    <div id={id} ref={ref} className={ClassNames(classes.logo, className)}>
      {showBackBtn ? (
        <Button
          variant='outlined'
          onClick={onBack}
          color='primary'
          className={ClassNames(classes.backButton, {
            [classes.hide]: !showBackBtn,
          })}
        >
          <BackIcon style={{ marginLeft: -8 }} />
          Back to search results
        </Button>
      ) : (
        <Link to={LANDING_URI}>
          <img
            className={classes.img}
            src={logo}
            alt='A Mighty Good Time Logo'
          />
        </Link>
      )}
    </div>
  );
});

Logo.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onBack: PropTypes.func,
  showBackBtn: PropTypes.bool,
};

Logo.defaultProps = {
  className: '',
  id: '',
  onBack: null,
  showBackBtn: false,
};

export default Logo;
