/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll, Link as ScrollLink } from 'react-scroll';
import { SocialIcon } from 'react-social-icons';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import InputBase from '@material-ui/core/InputBase';
import LaunchIcon from '@material-ui/icons/Launch';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import ShareIcon from '@material-ui/icons/Share';
import Typography from '@material-ui/core/Typography';

import { UserContext } from 'UserContext';
import { indexEvents } from 'vendors/algolia/main';

import AltDatesDialog from 'components/events/AltDatesDialog';
import Category from 'components/Selects/CategoryChips';
import LoadImage from 'components/LoadImage';
import NamedDivider from 'components/NamedDivider';
import RegisterDialog from 'components/events/RegisterDialog';
import Viewer from 'components/RichText/Viewer';
import ViewOrganizer from 'components/organizer/ViewOrganizer';

import { logAnalyticsEvent } from 'vendors/firebase/main';
import {
  formatEventDates,
  generateMapUrl,
  formatIntlPhoneNumber,
} from 'utilities/utils';
import {
  EVENT_URI,
  LOGIN_URI,
  LOCATION_DETAILS_ANCHOR,
  ORGANIZER_ANCHOR,
} from 'constants/routes';
import { SITEURL, COMMON_SEARCH_PARAMS } from 'constants/general';

const defaultImg = '';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  grid: {
    margin: 0,
    width: '100%',
  },
  mobileImage: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
    },
  },
  mobileItem: {
    [theme.breakpoints.down('xs')]: {
      padding: '5px !important',
    },
  },
  gridColumnContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: '15px',
    [theme.breakpoints.down('md')]: {
      gridColumnGap: '5px',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'unset',
      gridColumnGap: 'unset',
      gridTemplateRows: 'repeat(2, auto)',
    },
  },
  gridColumn: {
    justifyContent: 'left',
  },
  spacing: {
    padding: '5px 10px 5px 10px',
    marginBottom: 0,
    width: '100%',
  },
  imageError: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#3D4542',
    backgroundColor: '#FDD5CE',
  },
  imageIcon: {
    fontSize: 80,
    justifySelf: 'center',
    alignSelf: 'flex-end',
  },
  eventDate: {
    color: '#616161',
    whiteSpace: 'nowrap',
    padding: '0 10px',
  },
  eventName: {
    padding: '8px 0 8px 10px',
  },
  eventOrganizer: {
    color: '#616161',
    padding: '0 0 5px 10px',
  },
  eventLocation: {
    color: '#616161',
    padding: '0 0 5px 10px',
  },
  eventPrice: {
    color: '#424242',
    padding: '0 0 0 10px',
  },
  errorPadding: {
    padding: '3px 10px 5px',
  },
  contactBtn: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    padding: '0 6px',
    verticalAlign: 'bottom',
  },
  navLink: {
    color: theme.palette.companyPrimary.main,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word' /* Internet Explorer 5.5+ */,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  launchIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1.1rem',
  },
  image: {
    borderRadius: '5px 5px 0 0',
    width: 'calc(100vw - 20px)',
    height: 'calc((100vw - 20px) * (350/600))',
    maxWidth: 600,
    maxHeight: 350,
    objectFit: 'cover',
    overflow: 'hidden',
    boxShadow:
      '0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(50vw - 20px)',
      height: 'calc((50vw - 20px) * (350/600))',
      borderRadius: '5px 0 5px 0',
    },
  },
  dashboardImage: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(50vw - 130px)',
      height: 'calc((50vw - 130px) * (350/600))',
    },
  },
  eventDetails: {
    [theme.breakpoints.only('sm')]: {
      width: 'calc(50vw - 15px)',
    },
  },
  icon: {
    verticalAlign: 'text-top',
    marginRight: 10,
    fontSize: '1.5rem',
  },
  secondaryColor: {
    color: theme.palette.companySecondary.main,
  },
  error: {
    color: '#3D4542',
    backgroundColor: '#FDD5CE',
    borderRadius: 5,
    paddingTop: 4,
    paddingBottom: 2,
    marginBottom: 2,
  },
  category: {
    margin: 0,
  },
  contentSpacing: {
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(1)} 0 !important`,
    },
  },
  socialIcons: {
    textAlign: 'center',
    '& > a': {
      margin: '0 5px',
      verticalAlign: 'unset !important',
    },
  },
  actionButtons: {
    marginTop: 10,
    [theme.breakpoints.up('sm')]: {
      marginTop: 20,
      width: 'calc(50vw - 20px)',
      maxWidth: 600,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  dashboardActionButtons: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(50vw - 130px)',
    },
  },
  favoriteButton: {
    textTransform: 'none',
    margin: '10px 10px 0 10px',
    maxWidth: 'calc(100vw - 40px)',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(100% - 10px)',
      margin: '0 10px 0 10px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
      margin: '0 10px 0 15px',
      marginRight: 5,
    },
  },
  dashboardButton: {
    [theme.breakpoints.up('md')]: {
      margin: '0 0 0 5px',
      padding: 5,
    },
  },
  favoriteSelected: {
    color: '#e53935',
  },
  shareButton: {
    textTransform: 'none',
    margin: '10px 10px 0 10px',
    maxWidth: 'calc(100vw - 40px)',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(100% - 10px)',
      margin: '10px 10px 0 10px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
      margin: '0 10px 0 15px',
      marginRight: 5,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  registerButton: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    margin: '10px 10px 0 10px',
    maxWidth: 'calc(100vw - 40px)',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 300,
      width: 'calc(100% - 10px)',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 15,
    },
  },
  link: {
    textTransform: 'inherit',
    textDecoration: 'underline',
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    color: theme.palette.companyPrimary.light,
    '&:hover': {
      color: theme.palette.companyPrimary.main,
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  seeAllHours: {
    [theme.breakpoints.only('sm')]: {
      padding: '6px 4px',
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
  closeLink: {
    textAlign: 'right',
    paddingTop: theme.spacing(1),
  },
  gridColumnTimes: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '5px',
    [theme.breakpoints.up('sm')]: {
      gridColumnGap: '15px',
    },
  },
  endIcon: {
    margin: 0,
  },
  pre: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  preMarginTop: {
    marginTop: 10,
  },
  hidden: {
    display: 'none',
  },
  helperText: {
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    margin: 0,
  },
  gridDivider: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 15,
    },
  },
  input: {
    width: 'calc(100% - 52px)',
    maxWidth: '100%',
    paddingLeft: 12,
    [theme.breakpoints.up('sm')]: {
      width: 570,
    },
    '& > input': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  dialogTitle: {
    [theme.breakpoints.only('xs')]: {
      padding: '15px 15px 0',
    },
    paddingBottom: 0,
  },
  dialogContent: {
    [theme.breakpoints.only('xs')]: {
      padding: 15,
    },
  },
  allTimes: {
    padding: 5,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  strong: {
    fontWeight: '800',
  },
  separator: {
    fontStyle: 'italic',
    padding: '20px 0',
  },
  caption: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
  },
}));

const offset = -130; // Header height + 40 * -1
// let _scrollTop = 0;

const ViewEvent = React.forwardRef(
  (
    {
      id,
      analytics,
      dashboard,
      event,
      errors,
      readOnly,
      initViewDate,
      customSearchId,
      history,
      location: browserLoc,
    },
    ref
  ) => {
    const { isAuth, favorites, onFavorite } = useContext(UserContext);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smScreen = useMediaQuery(theme.breakpoints.only('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const [currViewDate, setCurrViewDate] = useState(initViewDate);
    const [currEvent, setCurrEvent] = useState(event);
    const [shareOpen, setShareOpen] = useState(false);
    const [anchorAllTimes, setAnchorAllTimes] = useState(null);
    const onAllTimesOpen = (e) => setAnchorAllTimes(e.currentTarget);
    const onAllTimesClose = () => setAnchorAllTimes(null);
    const allTimesOpen = Boolean(anchorAllTimes);
    const allTimesId = allTimesOpen ? 'simple-popover' : undefined;
    const [favoriteId, setFavoriteId] = useState(
      `${event.eid}-${event.date.startDate}`
    );
    const [isFavorite, setIsFavorite] = useState(false);
    const [isUpdatingFavorite, setIsUpdatingFavorite] = useState(false);
    const [favoriteDates, setFavoriteDates] = useState([]);
    let regEmailSubject;
    let regEmailBody;
    const eventUrlRef = React.useRef();

    const eventUrl = `${SITEURL}api/share/${event.eid}/${currViewDate}`;

    const copyEventToClipboard = async () => {
      if (eventUrlRef.current) {
        await navigator.clipboard.writeText(eventUrl);
        enqueueSnackbar('Event URL copied!', { variant: 'success' });
      }
    };

    // const scrollEvent = (e) => {
    //   _scrollTop = e.target.scrollingElement.scrollTop;
    // };
    useEffect(() => {
      setCurrViewDate(initViewDate);
      // eslint-disable-next-line
    }, [initViewDate]);

    useEffect(() => {
      setIsFavorite(favorites.includes(favoriteId));
      // eslint-disable-next-line
    }, [favoriteId]);

    useEffect(() => {
      setFavoriteId(`${currEvent.eid}-${currEvent.date.startDate}`);
    }, [currEvent]);

    useEffect(() => {
      if (currEvent === null) {
        setCurrEvent(event);
      }
      // eslint-disable-next-line
    }, [event]);

    useEffect(() => {
      if (favorites) {
        const { eid } = event;
        const favList = [];
        favorites.forEach((fav) => {
          if (fav.startsWith(eid)) {
            const splicePos = fav.lastIndexOf('-');
            const favDate = parseInt(fav.slice(splicePos + 1));
            favList.push(favDate);
          }
        });
        setFavoriteDates(favList);
        setIsFavorite(favorites.includes(favoriteId));
      }
      // eslint-disable-next-line
    }, [favorites]);

    useEffect(() => {
      scroll.scrollToTop({
        duration: 0,
        delay: 0,
        smooth: true,
      });
      // window.addEventListener('scroll', scrollEvent);
      // return () => window.removeEventListener('scroll', scrollEvent);
    }, []);

    const formatAddress = ({
      name,
      street,
      city,
      state,
      country,
      lat,
      lng,
      placeId,
    }) => (
      <>
        {name ? (
          <div>
            <strong>{name}</strong>
          </div>
        ) : null}
        {street ? (
          <div
            className={ClassNames({
              [classes.strong]: name.length === 0,
            })}
          >
            {street}
          </div>
        ) : null}
        {city ? (
          <div>{`${city}, ${state}, ${country}`}</div>
        ) : state ? (
          <div>{`${state}, ${country}`}</div>
        ) : null}
        {lat && lng ? (
          <a
            href={generateMapUrl(lat, lng, placeId)}
            className={classes.navLink}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => onEventClick('inperson_map')}
          >
            View Map <LaunchIcon className={classes.launchIcon} />
          </a>
        ) : null}
      </>
    );

    const toggleFavorite = () => {
      if (isAuth) {
        setIsUpdatingFavorite(true);
        onFavorite(favoriteId, !isFavorite, {
          eventId: event.eid,
          eventName: event.name,
          orgId: event.organizer.id,
          orgName: event.organizer.name,
        })
          .then(() => {
            setIsUpdatingFavorite(false);
          })
          .catch((err) => {
            setIsUpdatingFavorite(false);
          });
      } else {
        history.push({
          pathname: LOGIN_URI,
          state: {
            from: `${browserLoc.pathname}?favorite=true`,
          },
        });
      }
    };

    const onShareOpen = () => setShareOpen(true);
    const onShareClose = () => setShareOpen(false);

    const {
      // eslint-disable-next-line no-unused-vars
      dayOfEvent,
      daysOfEvent,
      hoursOfEvent,
      allTimes,
    } = formatEventDates({
      ...currEvent.date,
      timezone: readOnly ? DateTime.local().zoneName : event.date.timezone,
    });

    let location;
    let locAddress;
    let locationDetails = '';
    if (event.register && event.register !== 'unknown') {
      locationDetails = (
        <div>
          <strong>To register for this event:</strong>
        </div>
      );
      let orSep = null;
      if (event.register.link) {
        locationDetails = (
          <>
            {locationDetails}
            <div>
              Click this link:{' '}
              <a
                href={event.register.link}
                className={classes.navLink}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => onRegister('link', 'details')}
              >
                {event.register.link}
              </a>
            </div>
            <p className={classes.helperText}>
              If the above link is not clickable, try copying and pasting it
              into the address bar of your web browser.
            </p>
          </>
        );
        orSep = (
          <div>
            <i>or</i>
          </div>
        );
      }
      if (event.register.email) {
        regEmailSubject = `Registration for "${event.name}"`;
        const regDate = DateTime.fromMillis(currViewDate * 60000)
          .setZone(event.date.timezone)
          .toLocaleString(DateTime.DATETIME_FULL);
        regEmailBody = `Please sign me up for your event on ${regDate}`;
        locationDetails = (
          <>
            {locationDetails}
            {orSep}
            <div>
              Email:{' '}
              <a
                href={`mailto:${event.register.email}?subject=${regEmailSubject}&body=${regEmailBody}`}
                className={classes.navLink}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => onRegister('email', 'details')}
              >
                {event.register.email}
              </a>
            </div>
          </>
        );
        orSep = (
          <div>
            <i>or</i>
          </div>
        );
      }
      if (event.register.phone) {
        const { teleNumberIntl, phoneNumberIntl, regionName } =
          formatIntlPhoneNumber(event.register.phone);
        locationDetails = (
          <>
            {locationDetails}
            {orSep}
            <div>
              Call:{' '}
              <a
                href={`tel:${teleNumberIntl}`}
                className={classes.navLink}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => onRegister('phone', 'details')}
              >
                {phoneNumberIntl}
              </a>{' '}
              <span className={classes.caption}>({regionName})</span>
            </div>
          </>
        );
      }
      if (event.register.info) {
        locationDetails = (
          <>
            {locationDetails}
            <pre className={classes.pre} style={{ paddingTop: 5 }}>
              {event.register.info}
            </pre>
          </>
        );
      }
      locationDetails = (
        <div style={{ marginBottom: 20 }}>{locationDetails}</div>
      );
    }
    if (event.location.inperson) {
      locAddress = formatAddress(event.location.inperson.address);
    }
    if (event.location.inperson && !event.location.online) {
      location = locAddress;
    } else if (event.location.online) {
      let locType = event.location.inperson ? 'In Person / Online' : 'Online';
      locType = `${locType}${event.location.phone ? ' / By Phone' : ' event'}`;
      location = (
        <>
          <div>
            <strong>{locType}</strong>
          </div>
          {event.location.online.link &&
            (event.location.online.link.startsWith('TBA') ? (
              event.location.online.link
            ) : (
              <a
                href={event.location.online.link}
                className={classes.navLink}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => onEventClick('sum_online_link')}
              >
                Event link <LaunchIcon className={classes.launchIcon} />
              </a>
            ))}
        </>
      );
    } else if (event.location.phone) {
      location = (
        <div>
          <strong>By Phone only</strong>
        </div>
      );
    }
    const infoDivider = <div className={classes.separator}>or</div>;
    if (event.location.inperson) {
      locationDetails = (
        <>
          {locationDetails}
          {locAddress}
          {event.location.inperson.info && (
            <pre className={ClassNames(classes.pre, classes.preMarginTop)}>
              {event.location.inperson.info}
            </pre>
          )}
        </>
      );
    }
    if (event.location.online) {
      locationDetails = (
        <>
          {locationDetails}
          {event.location.inperson ? infoDivider : null}
          <div>
            <strong>Click this link to join this event online:</strong>
          </div>
          {event.location.online.link || event.location.online.info ? (
            <>
              {event.location.online.link.startsWith('TBA') &&
                event.location.online.link}
              {event.location.online.link && (
                <>
                  <a
                    href={event.location.online.link}
                    className={classes.navLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => onEventClick('loc_online_link')}
                  >
                    {event.location.online.link}
                  </a>
                  <p className={classes.helperText}>
                    If the above link is not clickable, try copying and pasting
                    it into the address bar of your web browser.
                  </p>
                </>
              )}
              {event.location.online.info && (
                <pre
                  className={ClassNames(classes.pre, classes.preMarginTop, {
                    [classes.preMarginTop]: event.location.online.link,
                  })}
                >
                  {event.location.online.info}
                </pre>
              )}
            </>
          ) : (
            <div>You will receive the event link after you register.</div>
          )}
        </>
      );
    }

    if (event.location.phone) {
      const { teleNumberIntl, phoneNumberIntl, regionName } =
        formatIntlPhoneNumber(event.location.phone.number);
      locationDetails = (
        <>
          {locationDetails}
          {event.location.inperson || event.location.online
            ? infoDivider
            : null}
          <div>
            <strong>Call in by phone:</strong>
          </div>
          {phoneNumberIntl || event.location.phone.info ? (
            <>
              <div>
                <a
                  href={`tel:${teleNumberIntl}`}
                  className={classes.navLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => onEventClick('phone')}
                >
                  {phoneNumberIntl}
                </a>{' '}
                <span className={classes.caption}>({regionName})</span>
              </div>
              {event.location.phone.info && (
                <pre className={ClassNames(classes.pre, classes.preMarginTop)}>
                  {event.location.phone.info}
                </pre>
              )}
            </>
          ) : (
            <div>
              You will receive the event call-in phone number after you
              register.
            </div>
          )}
        </>
      );
    }

    const descError =
      errors && errors.desc.length > 0 ? (
        <Typography
          paragraph
          color='error'
          className={classes.spacing}
          classes={{
            colorError: classes.error,
          }}
        >
          {event.desc.length > 0 ? errors.desc : 'Description is required'}
        </Typography>
      ) : null;
    const tagsError =
      errors && errors.tags.length > 0 ? (
        <Typography
          paragraph
          color='error'
          className={classes.spacing}
          classes={{
            colorError: classes.error,
          }}
        >
          {event.tags && event.tags.length > 0
            ? errors.tags
            : 'Minimum one category is required'}
        </Typography>
      ) : null;

    const getEventFromIndex = (objectID) => {
      const params = COMMON_SEARCH_PARAMS;
      params.filters = `objectID:${objectID}`;
      return indexEvents.search('', params).then(async (responses) => {
        const { hits } = responses;
        if (hits.length > 0) {
          const { days, endDate, startDate } = hits[0];
          return { days, endDate, startDate };
        }
        throw Error('Event not found in index');
      });
    };

    const onChangeEventDate = (newStartDate) => {
      getEventFromIndex(`${event.eid}-${newStartDate}`).then(
        ({ days, endDate, startDate }) => {
          setCurrEvent((prevEvent) => ({
            ...prevEvent,
            date: {
              ...prevEvent.date,
              startDate,
              endDate,
              dates: days,
            },
            favorite: false,
          }));
          history.replace(`${EVENT_URI}/${event.eid}/${newStartDate}`);
        }
      );
      setCurrViewDate(newStartDate);
    };

    const onRegister = (type, placement) => {
      if (analytics) {
        if (type === 'dialog') {
          logAnalyticsEvent('register_dialog', {
            type: placement,
            placement: 'summary',
            eventId: event.eid,
            eventName: event.name,
            orgId: event.organizer.id,
            orgName: event.organizer.name,
          });
        } else {
          const context = {
            type,
            placement,
            eventId: event.eid,
            eventName: event.name,
            orgId: event.organizer.id,
            orgName: event.organizer.name,
          };
          if (event.location.inperson) {
            const { address } = event.location.inperson;
            if (address && address.lat && address.lng) {
              context.latitude = address.lat;
              context.longitude = address.lng;
            }
          }
          logAnalyticsEvent('register', context);
        }
      }
    };

    const onEventClick = (type, href = undefined) => {
      if (analytics) {
        let hint;
        if (href) {
          if (href.includes('twitter.com')) {
            hint = 'twitter';
          } else if (
            href.includes('facebook.com') ||
            href.includes('/fb.me/')
          ) {
            hint = 'facebook';
          } else if (href.includes('linkedin.com')) {
            hint = 'linkedin';
          } else if (href.includes('instagram.com')) {
            hint = 'instagram';
          } else if (href.includes('youtube.com')) {
            hint = 'youtube';
          } else if (href.includes('meetup.com')) {
            hint = 'meetup';
          }
        }
        let newType;
        let placement;
        if (type === 'sum_online_link') {
          newType = 'online';
          placement = 'summary';
        } else if (type === 'loc_online_link') {
          newType = 'online';
          placement = 'details';
        } else {
          newType = type;
          placement = 'unknown';
        }
        const context = {
          type: newType,
          placement,
          eventId: event.eid,
          eventName: event.name,
          orgId: event.organizer.id,
          orgName: event.organizer.name,
        };
        if (hint) {
          context.hint = hint;
        } else if (href) {
          context.href = href;
        }
        logAnalyticsEvent('event_link', context);
      }
    };

    const onShareClick = (type) => {
      if (analytics) {
        logAnalyticsEvent('share', {
          type,
          eventId: event.eid,
          eventName: event.name,
          orgId: event.organizer.id,
          orgName: event.organizer.name,
        });
      }
    };

    return (
      <Paper id={id} ref={ref} className={classes.paper} elevation={2}>
        <Grid
          container
          spacing={3}
          direction='row'
          justifyContent='flex-start'
          alignItems='stretch'
          className={classes.grid}
        >
          <Hidden smUp>
            <Grid item xs={12} className={classes.mobileImage}>
              {event.img ? (
                <LoadImage
                  src={event.img}
                  blurhash={event.blurhash}
                  fallbackSrc={defaultImg}
                  alt={event.name}
                  className={classes.image}
                />
              ) : (
                <div className={ClassNames(classes.image, classes.imageError)}>
                  <ImageIcon className={classes.imageIcon} />
                  <Typography paragraph color='inherit'>
                    Cover Image is required
                  </Typography>
                </div>
              )}
            </Grid>
          </Hidden>
          <Grid item xs={12} style={{ padding: 0 }}>
            <div className={classes.gridColumnContainer}>
              <Hidden xsDown>
                <div className={classes.gridColumn}>
                  {event.img ? (
                    <LoadImage
                      src={event.img}
                      blurhash={event.blurhash}
                      fallbackSrc={defaultImg}
                      alt={event.name}
                      className={ClassNames(classes.image, {
                        [classes.dashboardImage]: dashboard,
                      })}
                    />
                  ) : (
                    <div
                      className={ClassNames(classes.image, classes.imageError, {
                        [classes.dashboardImage]: dashboard,
                      })}
                    >
                      <ImageIcon className={classes.imageIcon} />
                      <Typography paragraph color='inherit'>
                        Cover Image is required
                      </Typography>
                    </div>
                  )}
                  <div>
                    <div
                      className={ClassNames(classes.actionButtons, {
                        [classes.dashboardActionButtons]: dashboard,
                      })}
                    >
                      {customSearchId === '' && (
                        <Button
                          variant='outlined'
                          onClick={toggleFavorite}
                          color='primary'
                          fullWidth
                          className={ClassNames(classes.favoriteButton, {
                            [classes.favoriteSelected]: isFavorite,
                            [classes.dashboardButton]: dashboard,
                          })}
                        >
                          {isUpdatingFavorite ? (
                            <>
                              <CircularProgress
                                color='inherit'
                                size={24}
                                className={classes.icon}
                              />
                              Saving...
                            </>
                          ) : (
                            <>
                              {isFavorite ? (
                                <>
                                  <FavoriteIcon className={classes.icon} />
                                  Favorite
                                </>
                              ) : (
                                <>
                                  <FavoriteOutlinedIcon
                                    className={classes.icon}
                                  />
                                  Add to favorites
                                </>
                              )}
                            </>
                          )}
                        </Button>
                      )}
                      <Button
                        variant='outlined'
                        onClick={onShareOpen}
                        color='primary'
                        fullWidth
                        className={ClassNames(classes.shareButton, {
                          [classes.dashboardButton]: dashboard,
                        })}
                      >
                        <ShareIcon className={classes.icon} />
                        Share
                      </Button>
                    </div>
                  </div>
                </div>
              </Hidden>
              <Dialog
                fullScreen={fullScreen}
                maxWidth='lg'
                open={shareOpen}
                onClose={onShareClose}
                aria-labelledby='responsive-dialog-title'
                style={{ width: '100vw', height: '100vh' }}
              >
                <DialogTitle
                  id='responsive-dialog-title'
                  onClose={onShareClose}
                  className={classes.dialogTitle}
                >
                  {fullScreen
                    ? 'Share this event'
                    : 'Share this event with friends'}
                  <IconButton
                    aria-label='close'
                    className={classes.closeButton}
                    onClick={onShareClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers className={classes.dialogContent}>
                  <div className={classes.socialIcons}>
                    <SocialIcon
                      url={`mailto:?subject=${event.name}&body=${eventUrl}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={() => onShareClick('email')}
                    />
                    <FacebookShareButton
                      url={eventUrl}
                      style={{ padding: '0 4px' }}
                      onClick={() => onShareClick('facebook')}
                    >
                      <FacebookIcon size={52} round={true} />
                    </FacebookShareButton>
                    {/* <PinterestShareButton
                      url={eventUrl}
                      media={event.img}
                      style={{ padding: '0 4px' }}
                      onClick={() => onShareClick('pinterest')}
                    >
                      <PinterestIcon size={52} round={true} />
                    </PinterestShareButton> */}
                    <TwitterShareButton
                      url={eventUrl}
                      style={{ padding: '0 4px' }}
                      onClick={() => onShareClick('twitter')}
                    >
                      <TwitterIcon size={52} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={eventUrl}
                      style={{ padding: '0 4px' }}
                      onClick={() => onShareClick('linkedin')}
                    >
                      <LinkedinIcon size={52} round={true} />
                    </LinkedinShareButton>
                  </div>
                  <div
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      borderRadius: 10,
                      marginTop: 20,
                    }}
                  >
                    <InputBase
                      inputRef={eventUrlRef}
                      className={classes.input}
                      value={eventUrl}
                      inputProps={{
                        'aria-label': 'Event link',
                      }}
                    />
                    <IconButton
                      onClick={copyEventToClipboard}
                      className={classes.iconButton}
                      aria-label='copy'
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </div>
                </DialogContent>
              </Dialog>
              <Grid
                container
                direction='column'
                justifyContent='space-between'
                alignItems='stretch'
                className={classes.eventDetails}
              >
                <Grid item style={{ width: '100%', padding: '10px 10px 0 0' }}>
                  <Typography
                    variant='body1'
                    color={errors.date.length > 0 ? 'error' : 'inherit'}
                    className={classes.eventDate}
                    classes={{
                      colorError: classes.error,
                    }}
                  >
                    <strong>{daysOfEvent}</strong>
                  </Typography>
                  <Typography
                    variant={smScreen ? 'body2' : 'body1'}
                    color={errors.date.length > 0 ? 'error' : 'inherit'}
                    className={classes.eventDate}
                    classes={{
                      colorError: classes.error,
                    }}
                  >
                    {hoursOfEvent}
                    {allTimes.length > 1 && (
                      <Button
                        color='primary'
                        aria-describedby={allTimesId}
                        onClick={onAllTimesOpen}
                        className={ClassNames(
                          classes.link,
                          classes.seeAllHours
                        )}
                        classes={{
                          endIcon: classes.endIcon,
                        }}
                        endIcon={<ExpandMoreIcon />}
                      >
                        See {!smScreen ? 'all ' : ''}hours
                      </Button>
                    )}
                  </Typography>
                  <Popover
                    id={allTimesId}
                    open={allTimesOpen}
                    anchorEl={anchorAllTimes}
                    onClose={onAllTimesClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <div className={classes.allTimes}>
                      <div className={classes.gridColumnTimes}>
                        <div className={classes.gridColumn}>
                          {allTimes.map((t, index) => (
                            <div key={index} style={{ padding: 5 }}>
                              <strong>{t.day}</strong>
                            </div>
                          ))}
                        </div>
                        <div className={classes.gridColumn}>
                          {allTimes.map((t, index) => (
                            <div key={index} style={{ padding: 5 }}>
                              {t.time}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={classes.closeLink}>
                        <Button
                          variant='outlined'
                          color='primary'
                          aria-describedby={allTimesId}
                          onClick={onAllTimesClose}
                          className={classes.contactBtn}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Popover>
                  {currEvent.date.recurrance && (
                    <AltDatesDialog
                      currDate={currEvent.date}
                      onChange={onChangeEventDate}
                      favoriteDates={favoriteDates}
                      dashboard={dashboard}
                    />
                  )}
                </Grid>
                <Grid item style={{ width: '100%', padding: '0 10px 0 0' }}>
                  <Typography
                    variant='h4'
                    align='left'
                    color={errors.name.length > 0 ? 'error' : 'inherit'}
                    className={classes.eventName}
                    error={errors.name.length}
                    classes={{
                      colorError: classes.error,
                    }}
                  >
                    {event.name.length > 0
                      ? event.name
                      : 'Service Name is required'}
                  </Typography>
                  <Typography
                    variant='body2'
                    color={
                      event.organizer.name.length > 0 ? 'inherit' : 'error'
                    }
                    className={ClassNames(classes.eventOrganizer, {
                      [classes.errorPadding]: !location,
                    })}
                    classes={{
                      colorError: classes.error,
                    }}
                  >
                    {event.organizer.name.length > 0 ? (
                      <span style={{ marginRight: 5 }}>
                        by {event.organizer.name}
                      </span>
                    ) : (
                      'Organizer Name is required'
                    )}{' '}
                    <ScrollLink
                      to={ORGANIZER_ANCHOR}
                      spy={false}
                      smooth
                      offset={offset}
                      duration={500}
                      ignoreCancelEvents
                    >
                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.contactBtn}
                      >
                        Contact Info
                      </Button>
                    </ScrollLink>
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.gridDivider}
                  style={{
                    width: '100%',
                    marginTop: 10,
                    padding: '0 10px 0 0',
                  }}
                >
                  <NamedDivider
                    name='Location'
                    size='sm'
                    lineWidth={3}
                    style={{ width: 200 }}
                  />
                  <Typography
                    variant='body1'
                    component='div'
                    color={errors.location ? 'error' : 'inherit'}
                    className={ClassNames(classes.eventLocation, {
                      [classes.errorPadding]: errors.location,
                    })}
                    classes={{
                      colorError: classes.error,
                    }}
                  >
                    {errors.location ? 'Location information needed' : location}
                  </Typography>
                  {locationDetails && (
                    <ScrollLink
                      to={LOCATION_DETAILS_ANCHOR}
                      spy={false}
                      smooth
                      offset={offset}
                      duration={500}
                      ignoreCancelEvents
                    >
                      <Button color='primary' className={classes.link}>
                        See more location details
                      </Button>
                    </ScrollLink>
                  )}
                </Grid>
                <Grid
                  item
                  className={classes.gridDivider}
                  style={{
                    width: '100%',
                    marginTop: 10,
                    padding: '0 10px 0 0',
                  }}
                >
                  <NamedDivider
                    name='Price'
                    size='sm'
                    lineWidth={3}
                    style={{ width: 200 }}
                  />
                  <Typography
                    variant='h5'
                    color={event.price ? 'inherit' : 'error'}
                    className={classes.eventPrice}
                    classes={{
                      colorError: classes.error,
                    }}
                  >
                    {event.price ? (
                      <strong>{event.price}</strong>
                    ) : (
                      'A price is required'
                    )}
                  </Typography>
                  <div>
                    {event.register && event.register !== 'unknown' && (
                      <RegisterDialog
                        className={classes.registerButton}
                        link={event.register.link}
                        email={event.register.email}
                        emailSubject={regEmailSubject}
                        emailBody={regEmailBody}
                        phone={event.register.phone}
                        info={event.register.info}
                        onRegister={onRegister}
                      />
                    )}
                    <Hidden smUp>
                      {customSearchId === '' && (
                        <Button
                          variant='outlined'
                          onClick={toggleFavorite}
                          color='primary'
                          fullWidth
                          className={ClassNames(classes.favoriteButton, {
                            [classes.favoriteSelected]: isFavorite,
                          })}
                        >
                          {isUpdatingFavorite ? (
                            <>
                              <CircularProgress
                                color='inherit'
                                size={24}
                                className={classes.icon}
                              />
                              Saving...
                            </>
                          ) : (
                            <>
                              {isFavorite ? (
                                <>
                                  <FavoriteIcon className={classes.icon} />
                                  Favorite
                                </>
                              ) : (
                                <>
                                  <FavoriteOutlinedIcon
                                    className={classes.icon}
                                  />
                                  Add to favorites
                                </>
                              )}
                            </>
                          )}
                        </Button>
                      )}
                      <Button
                        variant='outlined'
                        onClick={onShareOpen}
                        color='primary'
                        fullWidth
                        className={classes.shareButton}
                      >
                        <ShareIcon className={classes.icon} />
                        Share
                      </Button>
                    </Hidden>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.contentSpacing}>
            <NamedDivider
              name='About this Event'
              size='md'
              noSideMargins
              lineWidth={3}
            />
            {descError}
            <Viewer
              id='event-desc'
              value={event.desc}
              orgId={event.organizer.id}
              analytics={analytics}
              context={{
                eventId: event.eid,
                eventName: event.name,
                orgId: event.organizer.id,
                orgName: event.organizer.name,
              }}
            />
            {event.social && event.social.length > 0 && (
              <div
                className={classes.socialIcons}
                style={{ textAlign: 'left', paddingTop: 20 }}
              >
                {event.social.map((url, index) => (
                  <SocialIcon
                    key={index}
                    url={url}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => onEventClick('social', url)}
                  />
                ))}
              </div>
            )}
          </Grid>
          <Grid item xs={12} className={classes.contentSpacing}>
            <NamedDivider
              name='Categories'
              size='md'
              noSideMargins
              lineWidth={3}
            />
            {tagsError}
            <Category
              initTags={event.tags}
              color='default'
              readOnly
              className={classes.category}
            />
          </Grid>
          {locationDetails && (
            <Grid item xs={12} className={classes.contentSpacing}>
              <NamedDivider
                name='Location details'
                size='md'
                noSideMargins
                lineWidth={3}
              />
              <div
                id={LOCATION_DETAILS_ANCHOR}
                style={{ marginTop: 5, marginBottom: 15 }}
              >
                {locationDetails}
              </div>
            </Grid>
          )}
          <Grid item xs={12} className={classes.contentSpacing}>
            <NamedDivider
              name='About the Organizer'
              color='tertiary'
              size='md'
              noSideMargins
              lineWidth={3}
            />
            <ViewOrganizer
              id={ORGANIZER_ANCHOR}
              organizer={event.organizer}
              event={{
                eid: event.eid,
                name: event.name,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
);

ViewEvent.propTypes = {
  initViewDate: PropTypes.number.isRequired,
  event: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  analytics: PropTypes.bool,
  dashboard: PropTypes.bool,
  id: PropTypes.string,
  customSearchId: PropTypes.string,
  readOnly: PropTypes.bool,
};

ViewEvent.defaultProps = {
  analytics: true,
  dashboard: false,
  id: '',
  customSearchId: '',
  readOnly: false,
};

export default withRouter(ViewEvent);
