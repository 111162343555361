/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Truncate from 'react-truncate';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    fontStyle: 'italic',
    color: theme.palette.companyPrimary.main,
    '&:hover,&:focus': {
      color: theme.palette.companySecondary.main,
      textDecoration: 'underline',
    },
  },
  hide: {
    display: 'none',
  },
}));

const TruncateText = React.forwardRef(
  ({ id, children, className, hideMore, less, lines, more, style }, ref) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [truncated, setTruncated] = React.useState(false);

    const handleTruncate = (newState) => {
      if (truncated !== newState) {
        setTruncated(newState);
      }
    };

    const toggleLines = (e) => {
      if (e) e.preventDefault();
      setExpanded((prevExpanded) => !prevExpanded);
    };

    const linkClass = ClassNames(classes.link, {
      [classes.hide]: hideMore,
    });

    return (
      <div id={id} ref={ref} className={className} style={style}>
        <Truncate
          lines={!expanded && lines}
          ellipsis={
            <span>
              ...{' '}
              {!hideMore && (
                <a href='#' className={linkClass} onClick={toggleLines}>
                  {more}
                </a>
              )}
            </span>
          }
          onTruncate={handleTruncate}
        >
          {children}
        </Truncate>
        {!truncated && expanded && (
          <span>
            {' '}
            {!hideMore && (
              <a href='#' className={linkClass} onClick={toggleLines}>
                {less}
              </a>
            )}
          </span>
        )}
      </div>
    );
  }
);

TruncateText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideMore: PropTypes.bool,
  id: PropTypes.string,
  less: PropTypes.string,
  lines: PropTypes.number,
  more: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

TruncateText.defaultProps = {
  className: '',
  hideMore: false,
  id: '',
  less: 'Show less',
  lines: 5,
  more: 'Read more',
  style: null,
};

export default TruncateText;
