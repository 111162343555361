/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { PRIVACY_URI, TOS_URI } from '../constants/routes';
import {
  EMAIL,
  PHONE,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
} from '../constants/general';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: '1.5em 1em',
    color: theme.palette.common.black,
    borderTop: 'rgba(0, 0, 0, 0.12) solid 1px',
    height: 242,
    [theme.breakpoints.up('md')]: {
      height: 205,
    },
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    paddingTop: 12,
    paddingBottom: 12,
    '& strong': {
      fontWeight: 'bold',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  copyright: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 15,
    },
  },
  tos: {
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    paddingBottom: 20,
  },
  privacy: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.only('sm')]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
    },
  },
  appInfo: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 30,
    height: 30,
    borderTopRightRadius: 10,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  email: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    textTransform: 'none',
    padding: 5,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
    },
  },
  icon: {
    color: theme.palette.companyPrimary.main,
    marginRight: 12,
  },
}));

const SocialIcons = (
  <div>
    <Tooltip title='Facebook'>
      <span style={{ padding: 5 }}>
        <SocialIcon
          url={FACEBOOK}
          target='_blank'
          rel='noopener noreferrer'
          style={{ height: 32, width: 32 }}
        />
      </span>
    </Tooltip>
    <Tooltip title='Instagram'>
      <span style={{ padding: 5 }}>
        <SocialIcon
          url={INSTAGRAM}
          target='_blank'
          rel='noopener noreferrer'
          style={{ height: 32, width: 32 }}
        />
      </span>
    </Tooltip>
    <Tooltip title='Twitter'>
      <span style={{ padding: 5 }}>
        <SocialIcon
          url={TWITTER}
          target='_blank'
          rel='noopener noreferrer'
          style={{ height: 32, width: 32 }}
        />
      </span>
    </Tooltip>
    <Tooltip title='LinkedIn'>
      <span style={{ padding: 5 }}>
        <SocialIcon
          url={LINKEDIN}
          target='_blank'
          rel='noopener noreferrer'
          style={{ height: 32, width: 32 }}
        />
      </span>
    </Tooltip>
  </div>
);

const Footer = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();

  return (
    <Grid
      id={id}
      ref={ref}
      container
      alignItems='center'
      direction='row'
      justifyContent='center'
      className={classes.root}
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          component='a'
          href={`mailto:${EMAIL}`}
          target='_blank'
          rel='noopener noreferrer'
          className={classes.email}
          color='inherit'
          justify='flex-end'
          fullWidth
        >
          <EmailIcon className={classes.icon} style={{ width: '1em', height: '1em'}} />
          {EMAIL}
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 15 }}>
        <Button
          component='a'
          href={`tel:+1${PHONE}`}
          target='_blank'
          rel='noopener noreferrer'
          className={classes.email}
          color='inherit'
          justify='flex-start'
          fullWidth
        >
          <PhoneIcon className={classes.icon} style={{ width: '1em', height: '1em'}} />
          {PHONE.replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 ($1) $2-$3')}
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 20 }}>
        {SocialIcons}
      </Grid>
      <Grid item xs={12} md={6} className={classes.copyright}>
        <Typography variant='body2' align='center' color='inherit'>
          &copy; {1900 + new Date().getYear()} A Mighty Good Time.{' '}
          <span style={{ whiteSpace: 'nowrap' }}>All Rights Reserved</span>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={classes.tos}>
        <Typography
          variant='body2'
          align='center'
          color='inherit'
          style={{ marginRight: 20 }}
        >
          <Link
            to={TOS_URI}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.link}
          >
            <strong style={{ whiteSpace: 'nowrap' }}>Terms of Service</strong>
          </Link>
        </Typography>
        <Typography variant='body2' align='center' color='inherit'>
          <Link
            to={PRIVACY_URI}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.link}
          >
            <strong style={{ whiteSpace: 'nowrap' }}>Privacy Statement</strong>
          </Link>
        </Typography>
      </Grid>
      <Tooltip
        title={`Web App Version: ${process.env.REACT_APP_VERSION}`}
        placement='top'
      >
        <div className={classes.appInfo}>&nbsp;</div>
      </Tooltip>
    </Grid>
  );
});

Footer.propTypes = {
  id: PropTypes.string,
};

Footer.defaultProps = {
  id: '',
};

export default Footer;
