/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { doUpdateSubscribeGroups } from 'vendors/firebase/fireauth';
import { getEmailRegex } from 'constants/general';
import {
  ABOUTUS_URI,
  CONTACTUS_URI,
  EVENT_ORGANIZERS_URI,
  HOWITWORKS_URI,
  LANDING_URI,
  SEARCH_URI,
} from 'constants/routes';
import { logAnalyticsEvent } from 'vendors/firebase/main';

const checkedIcon = 'images/checked.svg';
const uncheckedIcon = 'images/unchecked.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#FFF',
    background:
      'radial-gradient(circle, rgba(0, 77, 64, 1) 20%, rgba(9, 128, 118, 0.8) 100%)',
    marginBottom: -1,
    zIndex: 9,
  },
  main: {
    textAlign: 'center',
  },
  mainGroup: {
    display: 'inline-flex',
  },
  contents: {
    alignSelf: 'center',
    padding: '5px 5px 8px',
    [theme.breakpoints.up('md')]: {
      padding: '0 20px 0',
    },
  },
  title: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
    paddingBottom: 2,
    textShadow: '1px 1px #444',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
    },
  },
  emailDiv: {
    display: 'block',
    paddingTop: 10,
    paddingBottom: 0,
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  emailInputDiv: {
    width: '100%',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      minWidth: 200,
      maxWidth: 420,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 250,
      maxWidth: 450,
    },
  },
  emailInput: {
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 8,
    minHeight: 44,
    width: '100%',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    border: 'solid 1px rgba(9, 128, 118, 1)',
  },
  mobileBtn: {
    color: '#FFF',
    borderColor: '#FFF',
    textTransform: 'inherit',
    padding: '0 15px',
    borderRadius: 50,
  },
  emailBtn: {
    textTransform: 'inherit',
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
    width: '100%',
    height: 43,
    border: 'solid 1px #eee',
    marginTop: 5,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      maxWidth: 125,
      minWidth: 125,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 5,
    },
    '&:disabled': {
      color: `${theme.palette.common.white} !important`,
      backgroundColor: `${theme.palette.companySecondary.dark} !important`,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.company.danger,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    fontWeight: '800',
    marginBottom: 5,
  },
  tooltipBottom: {
    marginTop: -10,
  },
  error: {
    backgroundColor: `${theme.palette.company.danger} !important`,
  },
  accordian: {
    backgroundColor: 'transparent !important',
    color: '#FFF !important',
    boxShadow: 'none !important',
  },
  accordianSummaryRoot: {
    padding: '0 !important',
    transition: 'min-height 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important',
  },
  accordianSummaryContent: {
    margin: '0 !important',
    alignSelf: 'flex-start !important',
  },
  accordianSummaryExpanded: {
    minHeight: '30px !important',
    '& * button': {
      display: 'none',
    },
  },
  accordianDetailsRoot: {
    padding: '8px 5px 0 !important',
    display: 'block !important',
    [theme.breakpoints.up('md')]: {
      display: 'flex !important',
    },
  },
  helperText: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    whiteSpace: 'nowrap',
    paddingLeft: 5,
  },
}));

const SubscribeBanner = React.forwardRef(({ className, id }, ref) => {
  const classes = useStyles();
  const [missingData, setMissingData] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const [processStep, setProcessStep] = useState('input');
  const [isAnOrganizer, setIsAnOrganizer] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const emailRef = useRef();

  const title = 'Notify me about upcoming events!';

  const logAnalytics = () => {
    let page;
    if (window.location.href.endsWith(LANDING_URI)) {
      page = 'landing';
    } else if (window.location.href.endsWith(SEARCH_URI)) {
      page = 'search';
    } else if (window.location.href.endsWith(EVENT_ORGANIZERS_URI)) {
      page = 'event_organizers';
    } else if (window.location.href.endsWith(HOWITWORKS_URI)) {
      page = 'how_it_works';
    } else if (window.location.href.endsWith(ABOUTUS_URI)) {
      page = 'about_us';
    } else if (window.location.href.endsWith(CONTACTUS_URI)) {
      page = 'contact_us';
    }
    logAnalyticsEvent('promo', {
      type: 'subscribe_banner',
      page,
      organizer: isAnOrganizer ? 'yes' : 'no',
      mobile: isMobile ? 'yes' : 'no',
    });
  };

  const onSubmit = (e) => {
    setError(false);
    if (emailRef.current) {
      const email = emailRef.current.value;
      const regex = /\s+/gi;
      const emailCleaned = email.trim().replace(regex, ' ').toLowerCase();
      const REGEX_EMAIL = getEmailRegex();
      const emailIsValid = REGEX_EMAIL.test(emailCleaned);
      if (emailCleaned === '') {
        setMissingData(true);
      } else if (!emailIsValid) {
        setInvalidData(true);
      } else {
        setInvalidData(false);
        setMissingData(false);
        setSubmitting(true);
        doUpdateSubscribeGroups(emailCleaned, isAnOrganizer, isMobile)
          .then(() => {
            setProcessStep('success');
            setSubmitting(false);
            logAnalytics();
          })
          .catch((err) => {
            console.log('ERROR:', e);
            setProcessStep('input');
            setError(true);
            setSubmitting(false);
          });
      }
    }
  };

  const handleTooltipClose = () => {
    setMissingData(false);
    setInvalidData(false);
  };

  const handleKeyDown = () => {
    setMissingData(false);
    setInvalidData(false);
  };

  return (
    <div id={id} ref={ref} className={ClassNames(classes.root, className)}>
      <div className={ClassNames(classes.main)}>
        <div className={ClassNames(classes.mainGroup)}>
          <Hidden mdUp>
            <div className={ClassNames(classes.contents)}>
              <Accordion
                classes={{
                  root: classes.accordian,
                }}
              >
                <AccordionSummary
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  classes={{
                    root: classes.accordianSummaryRoot,
                    content: classes.accordianSummaryContent,
                    expanded: classes.accordianSummaryExpanded,
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <Typography
                      variant='h4'
                      color='inherit'
                      className={classes.title}
                    >
                      <strong>{title}</strong>
                    </Typography>
                    <Button
                      variant='outlined'
                      color='inherit'
                      className={classes.mobileBtn}
                    >
                      Subscribe
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  classes={{
                    root: classes.accordianDetailsRoot,
                  }}
                >
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={missingData || invalidData}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement='top'
                    title={`Please enter ${
                      invalidData ? 'a valid' : 'your'
                    } email`}
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                  >
                    <input
                      ref={emailRef}
                      type='email'
                      id='email'
                      name='email'
                      required
                      placeholder='Enter your email'
                      className={classes.emailInput}
                      onKeyDown={handleKeyDown}
                    />
                  </Tooltip>
                  <div
                    style={{
                      display: 'flex',
                      padding: 2,
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsAnOrganizer((prev) => !prev);
                      setIsMobile(true);
                    }}
                  >
                    <img
                      src={isAnOrganizer ? checkedIcon : uncheckedIcon}
                      alt={isAnOrganizer ? 'checked' : 'unchecked'}
                      style={{ width: 24, marginRight: 5 }}
                    />
                    I am also an event organizer
                  </div>
                  <Button
                    variant='contained'
                    color='secondary'
                    className={ClassNames(classes.emailBtn, {
                      [classes.error]: error,
                    })}
                    onClick={onSubmit}
                    disabled={submitting}
                  >
                    {submitting ? (
                      <CircularProgress
                        color='inherit'
                        size={20}
                        className={classes.progress}
                      />
                    ) : processStep === 'input' ? (
                      error ? (
                        'Try again'
                      ) : (
                        'Subscribe to our newsletter'
                      )
                    ) : (
                      'Success!'
                    )}
                  </Button>
                </AccordionDetails>
              </Accordion>
            </div>
          </Hidden>
          <Hidden smDown>
            <div className={ClassNames(classes.contents)}>
              <Typography
                variant='h3'
                color='inherit'
                className={classes.title}
              >
                <strong>{title}</strong>
              </Typography>
            </div>
            <div className={classes.emailDiv}>
              <div className={classes.emailInputDiv}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={missingData || invalidData}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement='bottom'
                  title={`Please enter ${
                    invalidData ? 'a valid' : 'your'
                  } email`}
                  classes={{
                    tooltip: classes.tooltip,
                    tooltipPlacementBottom: classes.tooltipBottom,
                  }}
                >
                  <input
                    ref={emailRef}
                    type='email'
                    id='email'
                    name='email'
                    required
                    placeholder='Enter your email'
                    className={classes.emailInput}
                    onKeyDown={handleKeyDown}
                  />
                </Tooltip>
                {error && (
                  <Typography
                    variant='body1'
                    align='center'
                    color='error'
                    style={{ marginTop: 5, whiteSpace: 'nowrap' }}
                  >
                    We&#39;re sorry, there was an error submitting your request.
                  </Typography>
                )}
                <div
                  style={{
                    display: 'flex',
                    padding: 2,
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setIsAnOrganizer((prev) => !prev);
                    setIsMobile(false);
                  }}
                >
                  <img
                    src={isAnOrganizer ? checkedIcon : uncheckedIcon}
                    alt={isAnOrganizer ? 'checked' : 'unchecked'}
                    style={{ width: 24, marginRight: 5 }}
                  />
                  I am also an event organizer
                </div>
              </div>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Button
                  variant='contained'
                  color='secondary'
                  className={ClassNames(classes.emailBtn, {
                    [classes.error]: error,
                  })}
                  onClick={onSubmit}
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress
                      color='inherit'
                      size={20}
                      className={classes.progress}
                    />
                  ) : processStep === 'input' ? (
                    error ? (
                      'Try again'
                    ) : (
                      'Subscribe'
                    )
                  ) : (
                    'Success!'
                  )}
                </Button>
              </ClickAwayListener>
            </div>
          </Hidden>
        </div>
      </div>
    </div>
  );
});

SubscribeBanner.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

SubscribeBanner.defaultProps = {
  className: '',
  id: '',
};

export default SubscribeBanner;
