/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import { useEffect, useState } from 'react';

const sessionStateAvailable = () => {
  try {
    window.sessionStorage.getItem('anything');
    return true;
  } catch (err) {
    return false;
  }
};

const localStateAvailable = () => {
  try {
    window.localStorage.getItem('anything');
    return true;
  } catch (err) {
    return false;
  }
};

const useSessionState = (defaultValue, key) => {
  const [value, setValue] = useState(() => {
    try {
      const stickyValue = window.sessionStorage.getItem(key);
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });
  useEffect(() => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      setValue(value);
    }
  }, [key, value]);
  return [value, setValue];
};

const useLocalState = (defaultValue, key, overrideValue) => {
  const [value, setValue] = useState(() => {
    try {
      if (overrideValue) {
        return overrideValue;
      }
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });
  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      setValue(value);
    }
  }, [key, value]);
  return [value, setValue];
};

export {
  sessionStateAvailable,
  localStateAvailable,
  useSessionState,
  useLocalState,
};
