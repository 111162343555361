/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  fade: {
    position: 'absolute',
    top: '0',
    width: 'inherit',
    height: 'inherit',
    fontSize: 'inherit',
    overflow: 'hidden',
    borderRadius: '50%',
    boxShadow:
      '0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2)',
    '& img': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      height: '100%',
      width: 'auto',
      transform: 'translate(-50%,-50%)',
    },
  },
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.companyPrimary.main,
  },
  avatarSmall: {
    width: '52px !important',
    height: '53px !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    fontSize: 26,
  },
  avatarMedium: {
    width: 64,
    height: 64,
    fontSize: 26,
  },
  avatarLarge: {
    width: 150,
    height: 150,
    fontSize: 56,
  },
  image: {
    backgroundColor: theme.palette.common.white,
  },
  img: {
    opacity: 0,
    transition: 'opacity 0.5s',
  },
  slowTransition: {
    transition: 'opacity 2.5s',
  },
}));

const LoadAvatar = React.forwardRef(
  (
    { alt, className, fallbackSrc, id, initials, src, size, transition },
    ref
  ) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [imgSrc, setImgSrc] = React.useState(src || fallbackSrc);
    const avatarSizeClass = ClassNames({
      [classes.avatarSmall]: size === 'sm',
      [classes.avatarMedium]: size === 'md',
      [classes.avatarLarge]: size === 'lg',
    });

    React.useEffect(() => {
      if (imgSrc !== src) {
        setImgSrc(src || fallbackSrc);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, fallbackSrc]);

    return (
      <div
        id={id}
        ref={ref}
        className={ClassNames(classes.root, avatarSizeClass, className)}
      >
        <Fade
          in={loading || imgSrc === null}
          timeout={500}
          className={ClassNames(classes.fade, classes.avatar)}
        >
          <Avatar alt={alt} className={classes.avatarSizeClass}>
            {initials}
          </Avatar>
        </Fade>
        {imgSrc && (
          <Avatar
            alt={alt}
            className={ClassNames(
              classes.fade,
              classes.avatar,
              classes.image,
              classes.img,
              { [classes.slowTransition]: transition === 'slow' },
              avatarSizeClass
            )}
            style={!loading ? { opacity: '1' } : {}}
          >
            <img
              alt={alt}
              src={imgSrc}
              onLoad={() => {
                setLoading(false);
              }}
              onError={() => {
                setImgSrc(fallbackSrc);
              }}
            />
          </Avatar>
        )}
      </div>
    );
  }
);

LoadAvatar.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  fallbackSrc: PropTypes.string,
  id: PropTypes.string,
  initials: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  src: PropTypes.string,
  transition: PropTypes.oneOf(['fast', 'slow']),
};

LoadAvatar.defaultProps = {
  alt: 'profile',
  className: '',
  fallbackSrc: null,
  id: '',
  initials: '',
  size: 'md',
  src: null,
  transition: 'fast',
};

export default LoadAvatar;
