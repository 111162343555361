/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Oval as Loader } from 'react-loader-spinner';
import { Blurhash } from 'react-blurhash';

import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  fade: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    fontSize: 'inherit',
    overflow: 'hidden',
    borderRadius: 'inherit',
  },
  loading: {
    margin: 0,
    position: 'absolute',
  },
  img: {
    opacity: 0,
    transition: 'opacity 1s',
  },
  loader: {
    position: 'absolute',
    width: '100%',
    minHeight: 30,
    '& > svg': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

const LoadImage = ({
  alt,
  className,
  fallbackSrc,
  id,
  onDone,
  src,
  blurhash,
  preSrc,
}) => {
  const targetRef = React.useRef();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [preLoading, setPreLoading] = React.useState(true);
  const [imgSrc, setImgSrc] = React.useState(src);
  const [loadSize, setLoadSize] = React.useState([0, 0]);

  React.useEffect(() => {
    if (imgSrc !== src) {
      setImgSrc(src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  React.useLayoutEffect(() => {
    if (targetRef.current) {
      const lw = targetRef.current.offsetWidth * 0.25;
      let lh = targetRef.current.offsetHeight * 0.25;
      if (lh > 60) {
        lh = 60;
      } else if (lh < 40) {
        lh = 40;
      }
      setLoadSize([lw > 80 ? 80 : lw, lh]);
    }
  }, [targetRef]);

  return (
    <div
      id={id}
      ref={targetRef}
      className={ClassNames(classes.root, className)}
    >
      <Fade in={loading} timeout={500} className={classes.fade}>
        <div>
          {preLoading && blurhash && (
            <Blurhash
              hash={blurhash}
              width={'100%'}
              height={'100%'}
              resolutionX={72}
              resolutionY={72}
              punch={1}
            />
          )}
          {preSrc && preSrc.length > 0 && (
            <img
              alt={alt}
              src={preSrc}
              width='100%'
              height='100%'
              className={classes.img}
              style={!preLoading ? { opacity: '0.5' } : {}}
              onLoad={() => setPreLoading(false)}
              onError={() => setPreLoading(false)}
            />
          )}
          <div className={ClassNames(classes.fade, classes.loading)}>
            <Loader
              arialLabel='loading'
              color={grey[500]}
              width={loadSize[0]}
              height={loadSize[1]}
              wrapperClass={classes.loader}
              wrapperStyle={{
                top: `calc(50% - ${loadSize[1] / 2}px)`,
              }}
            />
          </div>
        </div>
      </Fade>
      <img
        alt={alt}
        src={imgSrc}
        width='100%'
        height='100%'
        className={classes.img}
        style={!loading ? { opacity: '1' } : {}}
        onLoad={() => {
          setLoading(false);
          if (onDone) {
            onDone();
          }
        }}
        onError={() => {
          setImgSrc(fallbackSrc);
          if (onDone) {
            onDone();
          }
        }}
      />
    </div>
  );
};

LoadImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  blurhash: PropTypes.string,
  className: PropTypes.string,
  fallbackSrc: PropTypes.string,
  id: PropTypes.string,
  onDone: PropTypes.func,
  preSrc: PropTypes.string,
};

LoadImage.defaultProps = {
  alt: 'profile',
  blurhash: '',
  className: '',
  fallbackSrc: '',
  id: '',
  onDone: null,
  preSrc: null,
};

export default LoadImage;
