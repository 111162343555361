/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Helmet } from 'react-helmet';
import { animateScroll as scroll } from 'react-scroll';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import NamedDivider from 'components/NamedDivider';
import SubscribeBanner from 'components/SubscribeBanner';
import {
  CLOUDINARY,
  CLOUDINARY_CLOUDNAME_AMGT,
  SITEURL,
} from 'constants/general';

const logoSvg = `${CLOUDINARY}/sona/svg/amgt_logo_sm_v1.svg`;
const logoIcon = (
  <img src={logoSvg} alt='A Mighty Good Time logo small' width={60} />
);

const jen = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME_AMGT}/image/upload/f_auto,q_auto,r_max/v1641242757/images/jen_tlaoej.jpg`;
const ian = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME_AMGT}/image/upload/f_auto,q_auto,r_max/v1640895601/images/ian_jumi7v.jpg`;
const amy = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME_AMGT}/image/upload/f_auto,q_auto,r_max/v1640895837/images/amy_qnzz4a.jpg`;
const award = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME_AMGT}/image/upload/f_auto,q_auto,r_max/v1641241501/images/Innovators_in_Aging_Website_Badge_t4cl5j.png`;

const MAX_PAGE_WIDTH = 986;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
  },
  content: {
    flex: 1,
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    },
  },
  pageTitle: {
    fontFamily: `"Life Savers", sans-serif`,
    fontWeight: 800,
    fontSize: 34,
    textAlign: 'center',
    color: theme.palette.companyPrimary.main,
    marginBottom: 5,
  },
  colaboration: {
    padding: `0 0 ${theme.spacing(2)}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `0 0 ${theme.spacing(5)}`,
    },
  },
  aboutUs: {
    padding: `0 0 ${theme.spacing(2)}`,
    minHeight: 25,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      minHeight: 'unset',
    },
  },
  award: {
    textAlign: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(3)} 0`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)} ${theme.spacing(3)} 0`,
    },
  },
  link: {
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  aic: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 40,
    },
  },
  hascare: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
    },
  },
  gridColumnContainer: {
    display: 'grid',
    gridTemplateRows: 'repeat(3, auto)',
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridColumnGap: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      gridColumnGap: '40px',
      padding: '0 20px',
    },
  },
  gridColumn: {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'block',
    height: 'fit-content',
    cursor: 'pointer',
    transition: 'all 250ms ease-in',
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
    },
  },
  img: {
    backgroundColor: theme.palette.companyPrimary.main,
    border: `solid 4px ${theme.palette.companyPrimary.main}`,
    borderRadius: '50%',
  },
  title: {
    paddingBottom: 0,
    fontSize: 22,
  },
  subtitle: {
    paddingBottom: 0,
    fontSize: 20,
    color: theme.palette.companyPrimary.main,
  },
  expandIcon: {
    width: 50,
    height: 50,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: -7,
    transition: 'all 250ms ease-in',
  },
  activeIcon: {
    transform: 'rotate(180deg)',
  },
  disabled: {
    '-webkit-filter': 'grayscale(1)',
    opacity: '0.5',
  },
  button: {
    borderRadius: 10,
    textTransform: 'inherit',
    width: 120,
    marginTop: 10,
    padding: '0 10px',
    transition: 'all 250ms ease-in',
  },
  buttonExpanded: {
    height: 30,
    borderBottom: 'none',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      height: 40,
    },
    '&:hover': {
      borderBottom: 'none',
      backgroundColor: 'white',
      textDecoration: 'underline',
    },
  },
  learnMore: {
    border: `solid 1px ${theme.palette.companyPrimary.light}`,
    borderRadius: 10,
    padding: '15px 0',
    marginTop: -1,
    marginBottom: 25,
  },
  profile: {
    paddingLeft: 10,
    paddingRight: 6,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 20,
      paddingRight: 12,
    },
  },
}));

const AboutUs = React.forwardRef(({ id, location }, ref) => {
  const classes = useStyles();
  const [person, setPerson] = useState('');

  const scrollToTop = (duration) =>
    scroll.scrollToTop({
      duration,
      delay: 0,
      smooth: true,
    });

  useEffect(() => {
    scrollToTop(0);
    // eslint-disable-next-line
  }, []);

  const onShowProfile = (person) => {
    console.log('Show profile for', person);
    setPerson((prev) => (prev === person ? '' : person));
  };

  const ianInfo = (
    <Grid
      item
      xs={12}
      className={ClassNames(classes.aboutUs, {
        [classes.learnMore]: person === 'ian',
      })}
    >
      <Collapse in={person === 'ian'} timeout={500}>
        <div className={classes.profile}>
          <Typography variant='body1' align='left'>
            Ian is the architect and builder of A Mighty Good Time. With over 30
            years of experience as a software engineer, Ian is passionate about
            creating positive social change through software solutions.
          </Typography>
          <Typography variant='body1' align='left' style={{ paddingTop: 15 }}>
            Ian received his Bachelor&#39;s Degree in Computer Science from The
            University of British Columbia and has enjoyed a long career working
            with large scale technology corporations such as Nortel Networks and
            BlackBerry, as well as small successful startups including TeamOn
            Systems (acquired by BlackBerry) and BDEX. He is adept in all areas
            of software development, from user interface design and
            implementation, to big-data server platforms. Now Ian is using that
            experience to focus on building software solutions that support
            purpose, meaning, and better quality of life.
          </Typography>
          <Typography variant='body1' align='left' style={{ paddingTop: 15 }}>
            After experiencing his Mom&#39;s debilitating battle with
            Alzheimer&#39;s, he and his wife Jen founded Heart &amp; Soul Care,
            a technology company that builds solutions to support healthy aging
            and longevity.
          </Typography>
        </div>
      </Collapse>
    </Grid>
  );

  const amyInfo = (
    <Grid
      item
      xs={12}
      className={ClassNames(classes.aboutUs, {
        [classes.learnMore]: person === 'amy',
      })}
    >
      <Collapse in={person === 'amy'}>
        <div className={classes.profile}>
          <Typography variant='body1' align='left'>
            For over 30 years, Amy has committed her work to improving the lives
            of older adults in some form or fashion. From her time as a social
            worker in nursing homes and home health care, to Executive roles in
            older adult focused nonprofit agencies, and as a consultant and
            business owner, Amy has served caregivers, individuals with dementia
            and disabilities, and nonprofit leaders. She is passionate about
            issues related to living a full life, active aging and ageism.
          </Typography>
          <Typography variant='body1' align='left' style={{ paddingTop: 15 }}>
            Amy has a Master&#39;s Degree in Human Services from St.
            Edward&#39;s University and has been called upon time and again to
            develop relationships and create change in communities and
            organizations. She is an innovative thinker with a commitment to
            authentic leadership, problem solving and working within systems to
            implement change.
          </Typography>
          <Typography variant='body1' align='left' style={{ paddingTop: 15 }}>
            Amy and her husband, Damien, founded Aging is Cool in 2016 with the
            dream to create smart, engaging, fun activities for older adults and
            to address ageism in the world around them. In 2020, Aging is Cool
            partnered with Heart &amp; Soul Care to create and launch, A Mighty
            Good Time, a website and one-stop-shop for engaging activities for
            older adults nationally.
          </Typography>
        </div>
      </Collapse>
    </Grid>
  );

  const jenInfo = (
    <Grid
      item
      xs={12}
      className={ClassNames(classes.aboutUs, {
        [classes.learnMore]: person === 'jen',
      })}
    >
      <Collapse in={person === 'jen'}>
        <div className={classes.profile}>
          <Typography variant='body1' align='left'>
            Jen is a social entrepreneur and a lifelong creative-solution-seeker
            who loves imagining, and then bringing to life, innovative ideas
            that help improve our health and happiness. Having opened her first
            small business in college, she has learned to enjoy wearing the many
            different hats required for a small team start-up. She has a Masters
            Degree in Counseling Psychology and a background in social services,
            community mental health, and business development. She brings her
            passion for design, research, operations, and relationship building
            to the A Mighty Good Time team.
          </Typography>
          <Typography variant='body1' align='left' style={{ paddingTop: 15 }}>
            She and her husband Ian are also the founders of Heart &amp; Soul
            Care, a technology company that builds solutions to support healthy
            aging and longevity.
          </Typography>
        </div>
      </Collapse>
    </Grid>
  );

  return (
    <>
      <Helmet>
        <title>About the A Mighty Good Time team</title>
        <link rel='canonical' href={SITEURL.concat('/aboutus')} />
        <meta
          name='description'
          content='Meet the people behind A Mighty Good Time.'
        />
      </Helmet>
      <main id={id} ref={ref} className={classes.root}>
        <Header />
        <SubscribeBanner />
        <div className={ClassNames(classes.content, 'page-transition fade-in')}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.pageTitle}>Meet The Mighty Good Team</div>
              <NamedDivider
                color='primary'
                center
                showIcon
                icon={logoIcon}
                style={{
                  maxWidth: 500,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: 25,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.colaboration}>
              <Typography variant='body1' align='left'>
                We are a small, passionate, service-driven team that believes
                everyone should have access to smart, healthy, and socially
                engaging activities throughout their lifespan. We created A
                Mighty Good Time to help adults 50+ find high-quality curated
                activities hosted by recreation experts across the world. By
                providing a free platform for event organizers to more
                effectively promote their programs to the older adult community,
                we hope to make it easier than ever to find opportunities for
                continued joy, connection, meaning, and a mighty good time.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.aboutUs}>
              <div className={classes.gridColumnContainer}>
                <div
                  id='ian'
                  className={ClassNames(classes.gridColumn, {
                    [classes.disabled]: person !== '' && person !== 'ian',
                  })}
                  onClick={() => onShowProfile('ian')}
                >
                  <img
                    alt='Ian Pedersen'
                    src={ian}
                    width={180}
                    height={180}
                    className={classes.img}
                  />
                  <Typography
                    variant='h6'
                    align='center'
                    className={classes.title}
                  >
                    Ian Pedersen
                  </Typography>
                  <Typography
                    variant='body1'
                    align='center'
                    className={classes.subtitle}
                  >
                    Co-Founder
                  </Typography>
                  <Typography variant='body1' align='center'>
                    Technology
                  </Typography>
                  <Button
                    variant='outlined'
                    color='primary'
                    className={ClassNames(classes.button, {
                      [classes.buttonExpanded]: person === 'ian',
                    })}
                  >
                    {person === 'ian' ? 'Close' : 'Learn More'}
                  </Button>
                </div>
                <Hidden mdUp>{ianInfo}</Hidden>
                <div
                  id='amy'
                  className={ClassNames(classes.gridColumn, {
                    [classes.disabled]: person !== '' && person !== 'amy',
                  })}
                  onClick={() => onShowProfile('amy')}
                >
                  <img
                    alt='Amy Temperly'
                    src={amy}
                    width={180}
                    height={180}
                    className={classes.img}
                  />
                  <Typography
                    variant='h6'
                    align='center'
                    className={classes.title}
                  >
                    Amy Temperley
                  </Typography>
                  <Typography
                    variant='body1'
                    align='center'
                    className={classes.subtitle}
                  >
                    Co-Founder
                  </Typography>
                  <Typography variant='body1' align='center'>
                    Partnerships
                  </Typography>
                  <Button
                    variant='outlined'
                    color='primary'
                    className={ClassNames(classes.button, {
                      [classes.buttonExpanded]: person === 'amy',
                    })}
                  >
                    {person === 'amy' ? 'Close' : 'Learn More'}
                  </Button>
                </div>
                <Hidden mdUp>{amyInfo}</Hidden>
                <div
                  id='jen'
                  className={ClassNames(classes.gridColumn, {
                    [classes.disabled]: person !== '' && person !== 'jen',
                  })}
                  onClick={() => onShowProfile('jen')}
                >
                  <img
                    alt='Jen Emerick-Cayton'
                    src={jen}
                    width={180}
                    height={180}
                    className={classes.img}
                  />
                  <Typography
                    variant='h6'
                    align='center'
                    className={classes.title}
                  >
                    Jen Emerick-Cayton
                  </Typography>
                  <Typography
                    variant='body1'
                    align='center'
                    className={classes.subtitle}
                  >
                    Co-Founder
                  </Typography>
                  <Typography variant='body1' align='center'>
                    Operations
                  </Typography>
                  <Button
                    variant='outlined'
                    color='primary'
                    className={ClassNames(classes.button, {
                      [classes.buttonExpanded]: person === 'jen',
                    })}
                  >
                    {person === 'jen' ? 'Close' : 'Learn More'}
                  </Button>
                </div>
                <Hidden mdUp>{jenInfo}</Hidden>
              </div>
            </Grid>
            <Hidden smDown>{ianInfo}</Hidden>
            <Hidden smDown>{amyInfo}</Hidden>
            <Hidden smDown>{jenInfo}</Hidden>

            <Grid item xs={12} className={classes.award}>
              <img
                alt='Innovators In Aging Awardee 2020'
                src={award}
                width={175}
              />
              <Typography variant='body1' align='center'>
                A Mighty Good Time was honored to be a recipient of the 2020
                Texas Health and Human Services Innovators in Aging Award.
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </main>
    </>
  );
});

AboutUs.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
};

AboutUs.defaultProps = {
  id: '',
};

export default AboutUs;
