/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useContext, useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { DateTime } from 'luxon';
import { InView } from 'react-intersection-observer';
import 'intersection-observer';
import { sweetAlert, sweetNetworkError } from 'utilities/sweetAlert';
import { getGeocode } from 'use-places-autocomplete';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from '@material-ui/icons/Search';
import SubscribeBanner from 'components/SubscribeBanner';
import Typography from '@material-ui/core/Typography';

import { indexEvents } from 'vendors/algolia/main';
import { logAnalyticsEvent } from 'vendors/firebase/main';

import { UserContext } from 'UserContext';

import Header from 'components/Header';
import AdvancedSearchView from 'views/search/AdvancedSearchView';
import GatherInPersonNeeds from 'components/GatherInPersonNeeds';
import NamedDivider from 'components/NamedDivider';
import SearchResults from 'views/search/SearchResults';
import {
  COMMON_SEARCH_PARAMS,
  CLOUDINARY,
  OTHERFILTERSLIST,
  SITEURL,
  INITIAL_SEARCH_RADIUS_IN_METERS,
  getTagShortList,
} from 'constants/general';
import { SEARCH_URI } from 'constants/routes';
import {
  checkIfOnline,
  formatEventDates,
  getDayType,
  getGeoLocation,
} from 'utilities/utils';
import { useLocalState } from 'hooks/useStorageState';

const Footer = lazy(() => import('components/Footer'));
const PoweredBy = lazy(() => import('components/PoweredBy'));

const logoSvg = `${CLOUDINARY}/sona/svg/amgt_logo_sm_v1.svg`;
const logoIcon = (
  <img src={logoSvg} alt='A Mighty Good Time logo small' width={50} />
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
    zIndex: '0',
  },
  bannerContainer: {
    position: 'absolute',
    height: 600,
    width: 1280,
    maxWidth: '100%',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: 133,
    maxHeight: 'calc(100vw * (368/600))',
    [theme.breakpoints.up('sm')]: {
      top: 167,
      maxHeight: 'calc(100vw * (588/960))',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vw * (600/1280))',
    },
    [theme.breakpoints.up('lg')]: {
      left: 'calc((100vw - 1280px) / 2)',
    },
  },
  bannerImage: {
    width: 1280,
    maxWidth: '100vw',
    height: 600,
    maxHeight: 'calc(100vw * (368/600))',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vw * (588/960))',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vw * (600/1280))',
    },
  },
  bannerOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    background:
      'linear-gradient(180deg, rgba(250,250,250,0) 0%, rgba(250,250,250,0.4962359943977591) 40%, rgba(250,250,250,1) 80%)',
  },
  content: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: 'calc(100vh - 70px - 204px)',
    padding: `${theme.spacing(2)} ${theme.spacing(0.5)}`,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 90px - 155px)',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 90px - 118px)',
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(2.5)} ${theme.spacing(8)}`,
      minHeight: 'calc(100vh - 90px - 76px)',
    },
  },
  customContent: {
    flex: 1,
    padding: '40px 0 15px',
  },
  backToTopIcon: {
    width: 48,
    height: 48,
    color: 'white',
    marginLeft: 7,
    marginTop: 7,
  },
  backToTop: {
    position: 'fixed',
    zIndex: '999',
    padding: 0,
    transition: '0.5s',
    color: theme.palette.company.grey,
    '&:hover': {
      opacity: 0.7,
    },
    visibility: 'hidden',
    opacity: '0',
    right: 10,
    bottom: 10,
    [theme.breakpoints.up('sm')]: {
      right: 20,
      bottom: 20,
    },
  },
  csidBackToTop: {
    bottom: 30,
    [theme.breakpoints.up('sm')]: {
      bottom: 25,
    },
  },
  backToTopMargin: {
    marginBottom: 128,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 70,
    },
  },
  backToTopBtn: {
    borderRadius: '50%',
    width: 62,
    height: 62,
    backgroundColor: theme.palette.company.grey,
    '&:hover + div': {
      opacity: 0.7,
    },
  },
  backToTopBtnRing: {
    width: 56,
    height: 56,
    border: '1px solid white',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 3,
    left: 3,
    pointerEvents: 'none',
  },
  backToTopText: {
    marginTop: 5,
    marginLeft: -11,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  showBackToTop: {
    visibility: 'visible',
    opacity: '1',
    cursor: 'pointer',
  },
}));

const SCROLL_TO_SEARCH_RESULTS = 530;
// const INITIAL_SEARCH_RADIUS_IN_METERS = 402000; // 402 km = 250 miles
// const MAX_SEARCH_RADIUS_IN_METERS = 4824000; // 3000 miles
// const SEARCH_RESULTS_PER_PAGE = 20;
// const MIN_RESULTS_PER_PAGE = 10;

const arrayRemove = (arr, value) => arr.filter((ele) => ele !== value);

const parseParams = async (searchString) => {
  const searchLocation = {
    inperson: false,
    online: false,
    phone: false,
    formattedAddress: '',
    city: '',
    state: '',
    country: '',
    lat: '',
    lng: '',
  };
  let date = '';
  let dayType = '';
  let days = 1;
  let keywords = '';
  let tags = [];
  let price = 'any';
  let otherFilters = [];
  let hasFilters = false;
  let customSearchId = null;
  if (searchString && searchString.length > 0) {
    const { d, n, l, q, t, p, g, lat, lng, csid } =
      queryString.parse(searchString);
    if (l && (l.includes('i') || l.includes('o') || l.includes('p'))) {
      if (l.includes('i')) {
        searchLocation.inperson = true;
        if (g) {
          const geoLoc = await getGeoLocation(g);
          if (geoLoc) {
            searchLocation.formattedAddress = geoLoc.formattedAddress;
            searchLocation.city = geoLoc.city;
            searchLocation.state = geoLoc.state;
            searchLocation.country = geoLoc.country;
            searchLocation.lat = geoLoc.lat;
            searchLocation.lng = geoLoc.lng;
            searchLocation.placeId = geoLoc.placeId;
          }
        } else {
          searchLocation.lat = lat;
          searchLocation.lng = lng;
          const geoCodes = await getGeocode({
            location: {
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            },
          });
          if (geoCodes) {
            let placeId;
            geoCodes.every((geoCode) => {
              if (geoCode.types.includes('postal_code')) {
                placeId = geoCode.place_id;
                return false;
              } else if (geoCode.types.includes('plus_code')) {
                placeId = geoCode.place_id;
              }
              return true;
            });
            const geoLoc = await getGeoLocation(placeId);
            if (geoLoc) {
              searchLocation.formattedAddress = geoLoc.formattedAddress;
              searchLocation.city = geoLoc.city;
              searchLocation.state = geoLoc.state;
              searchLocation.country = geoLoc.country;
              searchLocation.lat = geoLoc.lat;
              searchLocation.lng = geoLoc.lng;
              searchLocation.placeId = geoLoc.placeId;
            }
          }
        }
      }
      searchLocation.online = l.includes('o');
      searchLocation.phone = l.includes('p');
    } else {
      searchLocation.online = true;
      searchLocation.phone = true;
    }
    let defaultDays;
    if (d && Number.isInteger(parseInt(d))) {
      date = d;
      defaultDays = 1;
    } else {
      const currDt = DateTime.local();
      const dt = DateTime.local(currDt.year, currDt.month, currDt.day, 0, 0, 0);
      const dayInMs = dt.toMillis();
      const inMins = Math.round(dayInMs / 60000);
      date = inMins.toString();
      defaultDays = -1;
    }
    if (n && Number.isInteger(parseInt(n))) {
      const dType = getDayType(n);
      dayType = dType.name;
      days = dType.days;
    } else {
      dayType = 'custom';
      days = defaultDays;
    }
    if (q) {
      hasFilters = true;
      keywords = q;
    } else {
      keywords = '';
    }
    if (t) {
      hasFilters = true;
      tags = t.split(',');
      OTHERFILTERSLIST.forEach((other) => {
        if (tags.includes(other)) {
          otherFilters.push(other);
          tags = arrayRemove(tags, other);
        }
      });
    }
    if (p) {
      hasFilters = true;
      price = p === '1' ? 'free' : 'paid';
    }
    if (csid) {
      customSearchId = csid;
    }
  }
  const params = {
    searchLocation,
    date,
    dayType,
    days,
    keywords,
    tags,
    price,
    otherFilters,
    hasFilters,
  };
  if (customSearchId) {
    params.csid = customSearchId;
  }
  return params;
};

const youHaveSeenItAll = (
  <div
    style={{
      textAlign: 'center',
      paddingTop: 20,
      maxWidth: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      textShadow:
        '0px 0px 10px white, 0px 0px 20px white, 1px -1px 8px white, -1px 1px 8px white',
    }}
  >
    <NamedDivider
      color='primary'
      center
      showIcon
      icon={logoIcon}
      style={{
        width: 500,
        maxWidth: '80vw',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    />
    <Typography
      variant='h4'
      align='center'
      style={{ paddingTop: 15, paddingBottom: 5 }}
    >
      End of search results.
    </Typography>
  </div>
);

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    paddingTop: (props) => (props.fullScreen || props.hasFound ? 20 : 30),
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  text: {
    color: theme.palette.companyPrimary.main,
    paddingBottom: 20,
  },
  searchBtn: {
    borderRadius: 35,
    textTransform: 'inherit',
    fontSize: 22,
    border: '1px solid white',
    width: 'fit-content',
    padding: '3px 12px',
    backgroundColor: (props) => {
      if (props.csid === 'j619cykdqr') {
        return '#31c800';
      }
    },
  },
  icon: {
    marginRight: 5,
  },
});

const SearchCompleteDivider = withStyles(styles)((props) => {
  const { classes, hasFound, hasOthers, newSearchString } = props;
  const nothingFoundMsg = !navigator.onLine
    ? 'Search results unavailable at this time. Please ensure you are connected to the internet.'
    : `We're sorry, there are no ${
        newSearchString ? 'in-person' : ''
      } events matching your search criteria ${
        newSearchString ? 'within a 50 mile radius' : 'currently available'
      }.`;
  return (
    <div className={classes.root}>
      <NamedDivider
        color='primary'
        center
        showIcon
        icon={logoIcon}
        style={{
          width: 500,
          maxWidth: '80vw',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <Typography
        variant='h4'
        align='center'
        style={{ paddingTop: 15, paddingBottom: 5 }}
      >
        {hasFound ? (
          <>
            That&apos;s all the events matching your search criteria currently
            available.
          </>
        ) : (
          <>{nothingFoundMsg}</>
        )}
      </Typography>
      {newSearchString && (
        <Button
          variant='contained'
          color='secondary'
          component={Link}
          to={{
            pathname: SEARCH_URI,
            search: newSearchString,
          }}
          className={classes.searchBtn}
        >
          <SearchIcon className={classes.icon} />
          See virtual events
        </Button>
      )}
      {/* {!hasFound && !newSearchString && (
        <Typography variant='h6' align='center' className={classes.text}>
          Try changing your search criteria.
        </Typography>
      )} */}
      {hasOthers && (
        <Typography
          variant='h4'
          align='center'
          style={{
            fontStyle: 'italic',
            paddingTop: 15,
            paddingBottom: 15,
            color: 'rgba(0, 0, 0, 0.67)',
          }}
        >
          Here are some other upcoming events...
        </Typography>
      )}
    </div>
  );
});

const BACK_TO_TOP_OFFSET = 1000;

let _scrollTop = 0;

const RETRY_MAX = 1;

const PAGE_TITLE = 'Find older adult classes- senior activities: A Mighty Good Time';

const Search = React.forwardRef(({ id, history, location, match }, ref) => {
  const [customSearchId, setCustomSearchId] = useState('');
  const classes = useStyles({ csid: customSearchId });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { uid, firstName, lastName, email } = useContext(UserContext);
  const displayName = `${firstName ? firstName : ''} ${
    lastName ? lastName : ''
  }`.trim();

  const [query, setQuery] = useState('');
  const [prevQuery, setPrevQuery] = useState('');
  const [queryParams, setQueryParams] = useState(null);
  const [prevQueryParamsStr, setPrevQueryParamsStr] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [searchNumDays, setSearchNumDays] = useState(1);
  const [prependDividers, setPrependDividers] = useState([]);
  const [searchTypes] = useLocalState(
    {
      inperson: false,
      online: true,
      phone: true,
    },
    'searchType'
  );
  const [searchAddress] = useLocalState(
    {
      formattedAddress: '',
      city: '',
      state: '',
      country: '',
      lat: '',
      lng: '',
      placeId: '',
    },
    'location'
  );

  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchParams, setSearchParams] = useState({});

  const [showBackToTop, setShowBackToTop] = useState(false);

  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsPage, setSearchResultsPage] = useState(0);
  const [searchResultsNumOfPages, setSearchResultsNumOfPages] = useState(0);
  const [includesUpcomingEvents, setIncludesUpcomingEvents] = useState(false);
  const [newSearchString, setNewSearchString] = useState();
  const [footerVisible, setFooterVisible] = useState(false);

  let bannerSource;
  if (fullScreen) {
    bannerSource = `${CLOUDINARY}v1596666758/sona/img/banner_xs.jpg`;
  } else if (smallScreen) {
    bannerSource = `${CLOUDINARY}v1596666758/sona/img/banner_sm.jpg`;
  } else {
    bannerSource = `${CLOUDINARY}v1596666758/sona/img/banner_md.jpg`;
  }

  const scrollToTop = (duration) =>
    scroll.scrollToTop({
      duration,
      delay: 0,
      smooth: true,
    });

  // const checkForIntersectionObserver = async () => {
  //   if (typeof window.IntersectionObserver === 'undefined') {
  //     await import('intersection-observer');
  //   }
  // };

  const scrollEvent = (event) => {
    _scrollTop = event.target.scrollingElement.scrollTop;
    setShowBackToTop(_scrollTop > BACK_TO_TOP_OFFSET);
  };

  useEffect(() => {
    scrollToTop(0);
    // checkForIntersectionObserver();
    const context = {
      page_location: `${SITEURL}${SEARCH_URI.substring(1)}`,
      page_path: SEARCH_URI,
      page_title: 'Search',
    };
    logAnalyticsEvent('view_page', context);
    window.addEventListener('scroll', scrollEvent);
    return () => window.removeEventListener('scroll', scrollEvent);
    // eslint-disable-next-line
  }, []);

  const parseHits = (currDate, days, hits, previousComplete) => {
    let lastDayOfSearch = null;
    if (days > 0) {
      const dateTime = DateTime.fromMillis(currDate * 60000);
      const startDateTime = DateTime.local(
        dateTime.year,
        dateTime.month,
        dateTime.day,
        0,
        0,
        0
      );
      const endDateTime = startDateTime.plus({ days });
      lastDayOfSearch = Math.round(endDateTime.toMillis() / 60000);
    }
    let searchComplete = previousComplete ? 2 : 0;
    const foundEventsList = [];
    let foundEventsCount = 0;
    const foundDates = prependDividers;
    hits.forEach((s, index) => {
      const {
        objectID,
        id: eid,
        name,
        startDate,
        endDate,
        timezone,
        days,
        isRecurring,
        orgId,
        orgName,
        photoId,
        blurhash,
        venue,
        location: loc,
        price,
        _tags,
        _rankingInfo,
      } = s;
      const maxTravelInMeters = s.maxTravelInKm
        ? s.maxTravelInKm * 1000
        : 80467.2; // 50 Miles
      const imgSrc = `${CLOUDINARY}v1589414485/sona/events/${photoId}`;
      let locCity = '';
      if (loc.inperson) {
        const { city, state } = loc.inperson.address;
        locCity = city || state;
      }
      const distance = (_rankingInfo.geoDistance / 1609.34).toFixed(2);
      // const eventDistance = _rankingInfo.geoDistance;
      // {s._highlightResult.name.value}
      let tags = _tags;
      let otherFilters = [];
      OTHERFILTERSLIST.forEach((other) => {
        if (_tags.includes(other)) {
          otherFilters.push(other);
          tags = arrayRemove(tags, other);
        }
      });
      const { dayOfEvent, daysOfEvent, hoursOfEvent } = formatEventDates({
        startDate,
        endDate,
        dates: days,
        timezone: DateTime.local().zoneName,
      });
      if (lastDayOfSearch && startDate > lastDayOfSearch) {
        searchComplete++;
      }
      let addDateDivider = false;
      if (!foundDates.includes(dayOfEvent)) {
        addDateDivider = true;
        foundDates.push(dayOfEvent);
      }
      const event = {
        eid,
        img: imgSrc,
        blurhash,
        name,
        dayOfEvent,
        daysOfEvent,
        hoursOfEvent,
        date: {
          startDate,
          endDate,
          dates: days,
          timezone,
        },
        venue,
        city: locCity,
        distance,
        favoriteId: objectID,
        orgId,
        orgName,
        price,
        access: {
          fifty: otherFilters.includes('Age 50+ only'),
          fiftyfive: otherFilters.includes('Age 55+ only'),
          sixty: otherFilters.includes('Age 60+ only'),
          ada: otherFilters.includes('Wheelchair accessible'),
          asl: otherFilters.includes('ASL available'),
          cc: otherFilters.includes('Closed Captioning'),
        },
        recurring: isRecurring || false,
        maxTravelDistance: (maxTravelInMeters / 1609.34).toFixed(0),
        tags,
        otherFilters,
      };
      const result = {
        objectID,
        event,
        addDateDivider,
        upcomingDivider:
          searchComplete === 1 && queryParams.hasFilters ? (
            <SearchCompleteDivider
              hasFound={foundEventsCount > 0}
              hasOthers={hits.length > foundEventsCount}
              location={location}
              newSearchString={newSearchString}
              fullScreen={fullScreen}
            />
          ) : null,
      };
      foundEventsList.push(result);
      foundEventsCount++;
      setPrependDividers(foundDates);
    });
    return { foundEventsList, includesUpcoming: searchComplete > 0 };
  };

  const executeSearch = async (
    currDate,
    currNumDays,
    currKeywords,
    currSearchParams,
    appendResults = false,
    checkOnlineIfNothingFound = false,
    applyMaxTravel = true,
    rc = 0
  ) => {
    if (searching) {
      return;
    }
    setSearching(!appendResults);
    setSearchDate(currDate);
    setSearchNumDays(currNumDays);
    setSearchKeywords(currKeywords);
    setSearchParams(currSearchParams);
    const online = await checkIfOnline();
    if (!online) {
      if (rc < RETRY_MAX) {
        sweetAlert({
          icon: 'info',
          title: "We're sorry!",
          content: (
            <div>
              <p style={{ textAlign: 'center' }}>
                We are unable to complete your search.
              </p>
              <p style={{ textAlign: 'center' }}>
                Your internet connection may be offline.
              </p>
            </div>
          ),
          buttons: {
            cancel: 'Cancel',
            confirm: 'Try again?',
          },
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then((value) => {
          if (value) {
            executeSearch(
              currDate,
              currNumDays,
              currKeywords,
              currSearchParams,
              appendResults,
              checkOnlineIfNothingFound,
              applyMaxTravel,
              rc + 1
            );
          } else {
            setSearching(false);
          }
        });
      } else {
        sweetNetworkError({
          title: 'We are unable to complete your search.',
          subject: 'Failure searching events index (offline)',
          context: `Search: keyword:${currKeywords}, params:${JSON.stringify(
            currSearchParams
          )}`,
          message: 'Retry limit reached. Client is offline.',
        }).then((email) => {
          if (email) {
            const win = window.open(email, '_blank');
            win.focus();
          }
          setSearching(false);
        });
      }
    } else {
      const promises = [];
      promises.push(indexEvents.search(currKeywords, currSearchParams));
      Promise.all(promises)
        .then(async (responses) => {
          const { page, nbPages, nbHits, hits } = responses[0];
          if (checkOnlineIfNothingFound && nbHits < 5) {
            const newSearchParams = {
              ...currSearchParams,
              filters: currSearchParams.filters.replace(
                'venue:inperson',
                'venue:online OR venue:phone'
              ),
            };
            delete newSearchParams.aroundLatLng;
            delete newSearchParams.aroundRadius;
            const virtualResults = await indexEvents.search(
              currKeywords,
              newSearchParams
            );
            if (virtualResults.nbHits > 0) {
              setNewSearchString(location.search.replace('l=i', 'l=op'));
            }
          }
          const { foundEventsList, includesUpcoming } = await parseHits(
            currDate,
            currNumDays,
            hits,
            page > 0 && includesUpcomingEvents
          );
          if (appendResults) {
            await setSearchResults((prevArr) =>
              prevArr.concat(foundEventsList)
            );
          } else {
            await setSearchResults(foundEventsList);
          }
          setIncludesUpcomingEvents(includesUpcoming);
          setSearchResultsPage(page);
          setSearchResultsNumOfPages(nbPages);
          setSearching(false);
          if (page === 0 && window.innerWidth < 600) {
            setTimeout(() => scroll.scrollTo(SCROLL_TO_SEARCH_RESULTS), 500);
          }
        })
        .catch((err) => {
          // const logId = getLogId();
          // loggerError(
          //   logId,
          //   `Search: ${
          //     err.message
          //   }, keyword:${currKeywords}, params:${JSON.stringify(
          //     currSearchParams
          //   )}`
          // );
          setSearching(false);
        });
    }
  };

  const prepareSearch = (
    { searchLocation, date, days, keywords, tags, price, otherFilters, csid },
    appendResults = false
  ) => {
    if (searching) {
      return;
    }
    const currDtInMins = Math.round(DateTime.local().toMillis() / 60000);
    const startOfDay = parseInt(date);
    setSearching(!appendResults);
    const params = COMMON_SEARCH_PARAMS;
    if (searchLocation && searchLocation.inperson) {
      // Add location filters
      if (searchLocation.lat && searchLocation.lng) {
        params.aroundLatLng = `${searchLocation.lat}, ${searchLocation.lng}`;
        params.aroundRadius = INITIAL_SEARCH_RADIUS_IN_METERS;
      }
    } else {
      delete params.aroundLatLng;
      delete params.aroundRadius;
    }

    // Add category filters
    //   [["Book", "Movie"], "SciFi"] translates as (Book OR Movie) AND SciFi"
    //   example, ["tag1", "-tag2"] translates as tag1 AND NOT tag2
    if (tags.length > 0 || otherFilters.length > 0) {
      const has50Plus = otherFilters.includes('Age 50+ only');
      const has55Plus = otherFilters.includes('Age 55+ only');
      const has60Plus = otherFilters.includes('Age 60+ only');
      let newOtherFilters = otherFilters.filter(
        (ele) =>
          ele !== 'Age 50+ only' &&
          ele !== 'Age 55+ only' &&
          ele !== 'Age 60+ only'
      );
      let ageFilters = [];
      if (has50Plus) {
        ageFilters.push('Age 50+ only');
        ageFilters.push('Age 55+ only');
        ageFilters.push('Age 60+ only');
      } else if (has55Plus) {
        ageFilters.push('Age 55+ only');
        ageFilters.push('Age 60+ only');
      } else if (has60Plus) {
        ageFilters.push('Age 60+ only');
      }
      let buildTags = ['TAGS'];
      if (ageFilters.length > 0) {
        buildTags = buildTags.concat('AGE');
      }
      buildTags = buildTags.concat(
        newOtherFilters.map((tag) => '_tags:'.concat(tag))
      );
      buildTags[0] = tags.map((tag) => '_tags:'.concat(tag));
      if (ageFilters.length > 0) {
        buildTags[1] = ageFilters.map((tag) => '_tags:'.concat(tag));
      }
      params.facetFilters = buildTags;
    } else {
      delete params.facetFilters;
    }

    // let filters = `startDate >= ${startOfDay}`;
    let venue = '';
    let searchContext = {
      inperson: false,
      online: false,
      phone: false,
      price,
      tags: getTagShortList(tags.concat(otherFilters)).join(),
    };
    if (searchLocation.inperson) {
      venue = 'venue:inperson';
      searchContext.inperson = true;
      searchContext.city = searchLocation.city;
      searchContext.state = searchLocation.state;
      searchContext.country = searchLocation.country;
      searchContext.search_from_latitude = searchLocation.lat;
      searchContext.search_from_longitude = searchLocation.lng;
    }
    if (searchLocation.online) {
      venue = `${venue}${venue.length > 0 ? ' OR ' : ''}venue:online`;
      searchContext.online = true;
    }
    if (searchLocation.phone) {
      venue = `${venue}${venue.length > 0 ? ' OR ' : ''}venue:phone`;
      searchContext.phone = true;
    }
    if (keywords) {
      searchContext.keywords = keywords;
    }
    let filters;
    if (currDtInMins > startOfDay) {
      filters = `endDate >= ${currDtInMins} AND ${venue}`;
    } else {
      filters = `startDate >= ${startOfDay} AND ${venue}`;
    }
    // if (endOfDay) {
    //   filters = filters.concat(` AND startDate < ${endOfDay}`);
    // }

    // Add price filters
    if (price !== 'any') {
      filters = filters.concat(` AND venue:${price}`);
    }
    // if (price !== 'any') {
    //   filters = filters.concat(` AND isFree = ${price === 'free' ? '1' : '0'}`);
    // }
    if (csid) {
      if (csid !== 'amgt255bos') {
        filters = filters.concat(` AND csid:${csid}`);
      }
      searchContext.csid = csid;
    }
    if (process.env.NODE_ENV !== 'development') {
      filters = filters.concat(' AND demo = 0');
    }
    if (filters.length > 0) {
      params.filters = filters;
    } else {
      delete params.filters;
    }
    logAnalyticsEvent('search', searchContext);
    executeSearch(
      date,
      days,
      keywords,
      params,
      appendResults,
      searchLocation.inperson
    );
  };

  useEffect(() => {
    if (queryParams && JSON.stringify(queryParams) !== prevQueryParamsStr) {
      setPrevQueryParamsStr(JSON.stringify(queryParams));
      prepareSearch(queryParams);
    }
    // eslint-disable-next-line
  }, [queryParams]);

  const parseNewQuery = async (newQuery) => {
    const currDt = DateTime.local();
    const dt = DateTime.local(currDt.year, currDt.month, currDt.day, 0, 0, 0);
    const dayInMs = dt.toMillis();
    const inMins = Math.round(dayInMs / 60000);
    setNewSearchString(undefined);
    if (newQuery) {
      // } && newQuery !== query) {
      await setPrependDividers([]);
      await setQuery(newQuery);
      const newQueryParams = await parseParams(newQuery);
      if (newQueryParams.csid) {
        setCustomSearchId(newQueryParams.csid);
      }
      const queryDate = parseInt(newQueryParams.date);
      setQueryParams({
        ...newQueryParams,
        date: queryDate < inMins ? inMins.toString() : newQueryParams.date,
      });
      if (window.innerWidth < 600) {
        if (location && location.state && location.state.noScroll) {
          return;
        } else {
          setTimeout(() => scroll.scrollTo(SCROLL_TO_SEARCH_RESULTS), 1000);
        }
      }
    } else {
      setQueryParams({
        searchLocation: {
          ...searchTypes,
          ...searchAddress,
        },
        date: inMins.toString(),
        dayType: 'custom',
        days: -1,
        keywords: '',
        tags: [],
        price: 'any',
        otherFilters: [],
      });
    }
  };

  useEffect(() => {
    if (match.isExact) {
      const newQuery = location.search ? location.search : null;
      if (newQuery !== prevQuery) {
        setPrevQuery(newQuery);
        parseNewQuery(newQuery);
        history.replace({
          ...location,
          state: {
            noScroll: true,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [match.isExact, location.search]);

  const fetchMoreData = () => {
    const page = searchResultsPage + 1;
    setTimeout(() => {
      executeSearch(
        searchDate,
        searchNumDays,
        searchKeywords,
        {
          ...searchParams,
          page,
        },
        true
      );
    }, 200);
  };

  const endMessage = includesUpcomingEvents ? (
    youHaveSeenItAll
  ) : (
    <>
      <SearchCompleteDivider
        hasFound={searchResults.length > 0}
        hasOthers={false}
        location={location}
        newSearchString={newSearchString}
        fullScreen={fullScreen}
      />
      {!customSearchId &&
        queryParams &&
        queryParams.searchLocation.inperson &&
        searchResults.length === 0 && (
          <div style={{ marginTop: 10 }}>
            <GatherInPersonNeeds
              id='search-gather-in-person-needs'
              displayName={displayName}
              email={email}
              uid={uid}
              queryString={JSON.stringify(queryParams)}
            />
          </div>
        )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <link rel='canonical' href={SITEURL.concat(SEARCH_URI)} />
        <meta
          name='description'
          content='Search our directory for events and interactive experiences for those 50 and over.'
        />
      </Helmet>
      <main id={id} ref={ref} className={classes.root}>
        {customSearchId === '' && (
          <>
            <Header />
            <SubscribeBanner />
            <div
              className={ClassNames(
                classes.bannerContainer,
                'page-transition fade-in'
              )}
            >
              <img
                alt='A Mighty Good Time Banner'
                src={bannerSource}
                width='1280'
                height='600'
                className={classes.bannerImage}
              />
              <div className={classes.bannerOverlay}></div>
            </div>
          </>
        )}
        <div
          style={{
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: '1',
          }}
        >
          <div
            className={ClassNames('page-transition fade-in', {
              [classes.content]: customSearchId === '',
              [classes.customContent]: customSearchId !== '',
            })}
          >
            <div className={classes.search}>
              {queryParams && (
                <AdvancedSearchView
                  date={queryParams.date}
                  dayType={queryParams.dayType}
                  venue={queryParams.searchLocation}
                  keywords={queryParams.keywords}
                  tags={queryParams.tags}
                  price={queryParams.price}
                  otherFilters={queryParams.otherFilters}
                  className={classes.bottomPadding}
                  customSearchId={customSearchId}
                />
              )}
              <SearchResults
                results={searchResults}
                searching={searching}
                hasMore={
                  searchResults > 0 ||
                  searchResultsPage + 1 < searchResultsNumOfPages
                }
                onLoadMore={fetchMoreData}
                endMessage={endMessage}
                customSearchId={customSearchId}
                searchLocation={
                  queryParams ? queryParams.searchLocation : undefined
                }
              />
            </div>
          </div>
          <div
            className={ClassNames(classes.backToTop, {
              [classes.showBackToTop]: showBackToTop,
              [classes.backToTopMargin]: footerVisible,
              [classes.csidBackToTop]: customSearchId !== '',
            })}
          >
            <div
              style={{ position: 'relative' }}
              onClick={() => scrollToTop(500)}
            >
              <div className={classes.backToTopBtn}>
                <KeyboardArrowUpIcon className={classes.backToTopIcon} />
              </div>
              <div className={classes.backToTopBtnRing}></div>
            </div>
            <div className={classes.backToTopText}>Back to Top</div>
          </div>
        </div>
        {customSearchId === '' && (
          <InView
            as='div'
            threshold={0.8}
            onChange={(inView, entry) => setFooterVisible(inView)}
          >
            <Footer />
          </InView>
        )}
        {customSearchId !== '' && customSearchId !== 'amgt255bos' && (
          <PoweredBy />
        )}
      </main>
    </>
  );
});

Search.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
  id: PropTypes.string,
};

Search.defaultProps = {
  id: '',
};

export default Search;
