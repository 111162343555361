/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
// Temporary keep
// return new Promise(resolve => {
//   setTimeout(() => {
//     resolve('Success');
//   }, 1000);
// });
// return new Promise((resolve, reject) => {
//   setTimeout(() => {
//     reject(Error('Unsuccessful response'));
//   }, 1000);
// });
// Promise.reject(new Error('Error something!'));

import {
  fireapp,
  getFirestore,
  logAnalyticsEvent,
} from 'vendors/firebase/main';
import { checkIfOnline } from 'utilities/utils';
import { CLOUDINARY, DATA_STATE } from 'constants/general';

// [START General]
export const doGetAppStats = async () => {
  const fs = await getFirestore();
  return fs.collection('stats').doc(process.env.REACT_APP_STATS_ID).get();
};

export const doGetSiteContent = async () => {
  const key = 'site-content';
  let storedContent;
  try {
    storedContent = window.localStorage.getItem(key);
  } catch (err) {
    storedContent = null;
  }
  const content = storedContent !== null ? JSON.parse(storedContent) : null;
  const currDate = new Date();
  const currDateMs = currDate.getTime();
  if (
    content &&
    content.version === process.env.REACT_APP_VERSION &&
    content.expires > currDateMs
  ) {
    return content;
  }
  const fs = await getFirestore();
  const doc = await fs
    .collection(key)
    .doc(process.env.REACT_APP_SITE_CONTENT_ID)
    .get();
  const latestContent = doc.data();
  try {
    window.localStorage.setItem(
      key,
      JSON.stringify({
        ...latestContent,
        version: process.env.REACT_APP_VERSION,
        expires: currDateMs + 86400000,
      })
    );
  } catch (err) {
    console.log('Failed to save content to local storage');
  }
  return latestContent;
};

export const doGatherInPersonNeeds = async (
  email,
  location,
  interests,
  comment,
  queryString
) => {
  const data = {
    email,
    location,
    interests,
    comment,
    submitted: fireapp.firestore.FieldValue.serverTimestamp(),
  };
  if (queryString) {
    const { searchLocation, keywords, tags, price, otherFilters } =
      JSON.parse(queryString);
    const { inperson, online, phone } = searchLocation;
    let loc;
    if (inperson) {
      loc = `${searchLocation.city}, ${searchLocation.state}, ${searchLocation.country}`;
    } else if (online) {
      loc = 'online';
    } else if (phone) {
      loc = 'phone';
    } else {
      loc = 'unknown';
    }
    data.queryDetails = {
      location: loc,
    };
    if (price !== 'any') {
      data.queryDetails.price = price;
    }
    if (keywords) {
      data.queryDetails.keywords = keywords;
    }
    if (tags && tags.length > 0) {
      data.queryDetails.tags = tags;
    }
    if (otherFilters && otherFilters.length > 0) {
      data.queryDetails.otherFilters = otherFilters;
    }
  }
  const fs = await getFirestore();
  return fs
    .collection('analytics')
    .doc(process.env.REACT_APP_ANALYTICS_INPERSON_NEEDS)
    .collection('inperson_needs')
    .add(data);
};
// [END General]

// [START Users]
export const doGetUserRef = async (uid) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid);
};

export const doUserVerified = async (uid) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid).set(
    {
      pending: fireapp.firestore.FieldValue.delete(),
    },
    { merge: true }
  );
};

export const doVerifyEmail = async (uid, email, displayName, continueUrl) => {
  const fs = await getFirestore();
  return fs.collection('email-verify').doc(uid).set({
    displayName,
    email,
    continueUrl,
  });
};

export const doUpdateUser = async (uid, pid, firstName, lastName) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid).set(
    {
      firstName,
      lastName,
      pid,
    },
    { merge: true }
  );
};

export const doDeleteUser = async (uid) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid).set(
    {
      deleted: fireapp.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  );
};

export const doUpdateFavorites = async (uid, favorites) => {
  const fs = await getFirestore();
  return fs
    .collection('users')
    .doc(uid)
    .set(
      {
        favorites:
          favorites.length > 0
            ? favorites
            : fireapp.firestore.FieldValue.delete(),
      },
      { merge: true }
    );
};

export const doUpdateMemberPlan = async (uid, plan) => {
  const fs = await getFirestore();
  return fs
    .collection('users')
    .doc(uid)
    .set(
      {
        memberPlan: Number(plan),
      },
      { merge: true }
    );
};

export const doUpdateCustomerId = async (uid, customerId) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid).set(
    {
      paymentCustomerId: customerId,
    },
    { merge: true }
  );
};

export const doAddCardOption = async (uid) => {
  const fs = await getFirestore();
  return fs
    .collection('users')
    .doc(uid)
    .update({
      paymentOptions: fireapp.firestore.FieldValue.arrayUnion('card'),
    });
};

export const doRemoveCardOption = async (uid) => {
  const fs = await getFirestore();
  return fs
    .collection('users')
    .doc(uid)
    .update({
      paymentOptions: fireapp.firestore.FieldValue.arrayRemove('card'),
    });
};

export const doUpdatePlanInfo = async (
  uid,
  customerId,
  paymentOptions,
  plan,
  nonProfitStatus
) => {
  const fs = await getFirestore();
  return fs
    .collection('users')
    .doc(uid)
    .set(
      {
        memberPlan: Number(plan),
        paymentCustomerId: customerId,
        paymentOptions,
        nonProfitStatus,
      },
      { merge: true }
    );
};

export const doGetApplicationInfo = async (appId) => {
  const fs = await getFirestore();
  return fs.collection('applications').doc(appId).get();
};
// [END Users]

// [START Events]
export const doGetDraftEventsRef = async (uid) => {
  const fs = await getFirestore();
  return fs
    .collection('pending-events')
    .where('uid', '==', uid)
    .orderBy('name');
};

export const doGetPublicEventsRef = async (uid) => {
  const fs = await getFirestore();
  return fs
    .collection('events')
    .where('uid', '==', uid)
    .where('status', '==', DATA_STATE.ACTIVE)
    .orderBy('name');
};

export const doGetEvent = async (eid, orgId) => {
  const online = await checkIfOnline();
  if (!online) return Promise.reject(Error('offline'));
  const fs = await getFirestore();
  const promises = [];
  promises.push(
    fs
      .collection('events')
      .doc(eid)
      .get()
      .then(
        (doc) =>
          new Promise((resolve, reject) => {
            if (doc && doc.exists) {
              const e = doc.data();
              if (e.status === DATA_STATE.DELETED) {
                reject(Error('Event not found'));
              }
              resolve(e);
            }
            reject(Error('Event not found'));
          })
      )
  );
  if (orgId) {
    promises.push(
      fs
        .collection('organizers')
        .doc(orgId)
        .get()
        .then(
          (doc) =>
            new Promise((resolve, reject) => {
              if (doc && doc.exists) {
                resolve(doc.data());
              }
              reject(Error('Organizer not found'));
            })
        )
    );
  }
  return Promise.all(promises).then((responses) => {
    const event = responses[0];
    const price = event.price || '';
    const register = event.register || '';
    const social = event.social || [];
    const { pid } = event.featuredPhoto;
    const imgSrc = `${CLOUDINARY}v1589414485/sona/events/${pid}`;
    const result = {
      ...event,
      price,
      register,
      social,
      img: imgSrc,
    };
    if (orgId && responses.length > 1) {
      delete result.orgId;
      result.organizer = {
        ...responses[1],
        id: orgId,
      };
    }
    return result;
  });
};

export const doSaveEvent = async (uid, event, saveStatus) => {
  const fs = await getFirestore();
  const {
    eid,
    name,
    featuredPhoto,
    date,
    orgId,
    location,
    priceType,
    price,
    register,
    desc,
    tags,
    otherFilters,
    social,
    pubStatus,
    paymentCycleEnd,
  } = event;
  logAnalyticsEvent('event_save', {
    type: saveStatus === DATA_STATE.DRAFT ? 'draft' : 'submit',
    action: eid ? 'update' : 'create',
  });
  const e = {
    uid,
    name,
    featuredPhoto,
    date,
    orgId,
    location: {},
    priceType,
    desc,
    tags,
    status: saveStatus,
    pubStatus: pubStatus || DATA_STATE.DRAFT,
    submitted: fireapp.firestore.FieldValue.serverTimestamp(),
  };
  if (location.inperson) {
    e.location.inperson = {
      address: location.inperson.address,
      info: location.inperson.info,
    };
  }
  if (location.online) {
    e.location.online = {
      link: location.online.link,
      info: location.online.info,
    };
  }
  if (location.phone) {
    e.location.phone = {
      number: location.phone.number,
      info: location.phone.info,
    };
  }
  if (priceType !== 'free') {
    e.price = price;
  } else if (eid) {
    e.price = fireapp.firestore.FieldValue.delete();
  }
  if (register) {
    e.register = {};
    if (register.link && register.link.length > 0) {
      e.register.link = register.link;
    }
    if (register.email && register.email.length > 0) {
      e.register.email = register.email;
    }
    if (register.phone && register.phone.length > 0) {
      e.register.phone = register.phone;
    }
    if (register.info && register.info.length > 0) {
      e.register.info = register.info;
    }
  } else if (eid) {
    e.register = fireapp.firestore.FieldValue.delete();
  }
  if (otherFilters && otherFilters.length > 0) {
    e.otherFilters = otherFilters;
  } else if (eid) {
    e.otherFilters = fireapp.firestore.FieldValue.delete();
  }
  if (social && social.length > 0) {
    e.social = social;
  } else if (eid) {
    e.social = fireapp.firestore.FieldValue.delete();
  }
  if (!date.recurrance && eid) {
    e.date.recurrance = fireapp.firestore.FieldValue.delete();
  }
  if (!date.recurranceExceptions && eid) {
    e.date.recurranceExceptions = fireapp.firestore.FieldValue.delete();
  }
  if (paymentCycleEnd) {
    e.paymentCycleEnd = paymentCycleEnd;
  } else if (eid) {
    e.paymentCycleEnd = fireapp.firestore.FieldValue.delete();
  }
  if (eid) {
    if (!location.inperson) {
      e.location.inperson = fireapp.firestore.FieldValue.delete();
    }
    if (!location.online) {
      e.location.online = fireapp.firestore.FieldValue.delete();
    }
    if (!location.phone) {
      e.location.phone = fireapp.firestore.FieldValue.delete();
    }
    if (register && register.link && register.link.length === 0) {
      e.register.link = fireapp.firestore.FieldValue.delete();
    }
    if (register && register.email && register.email.length === 0) {
      e.register.email = fireapp.firestore.FieldValue.delete();
    }
    if (register && register.phone && register.phone.length === 0) {
      e.register.phone = fireapp.firestore.FieldValue.delete();
    }
    if (register && register.info && register.info.length === 0) {
      e.register.info = fireapp.firestore.FieldValue.delete();
    }
    return fs.collection('pending-events').doc(eid).set(e, { merge: true });
  }
  return fs.collection('pending-events').add(e);
};

export const doRemoveEvent = async (eid, pending) => {
  const fs = await getFirestore();
  if (pending) {
    return fs.collection('pending-events').doc(eid).delete();
  }
  return fs.collection('events').doc(eid).set(
    {
      status: DATA_STATE.DELETED,
      updated: fireapp.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  );
};

export const getNumOfEventsWithDraftOrganizer = async (uid, orgId) => {
  const fs = await getFirestore();
  return await fs
    .collection('pending-events')
    .where('orgId', '==', orgId)
    .where('uid', '==', uid)
    .get()
    .then((snap) => snap.size);
};

export const getNumOfEventsWithPublicOrganizer = async (uid, orgId) => {
  const fs = await getFirestore();
  return await fs
    .collection('events')
    .where('orgId', '==', orgId)
    .where('uid', '==', uid)
    .get()
    .then((docs) => {
      let count = 0;
      docs.forEach((doc) => {
        const event = doc.data();
        if (event.status !== DATA_STATE.DELETED) {
          count++;
        }
      });
      return count;
    });
};
// [END Events]

// [START Organizers]
export const doGetDraftOrganizersRef = async (uid) => {
  const fs = await getFirestore();
  return fs
    .collection('pending-organizers')
    .where('uid', '==', uid)
    .orderBy('name');
};

export const doGetPublicOrganizersRef = async (uid) => {
  const fs = await getFirestore();
  return fs
    .collection('organizers')
    .where('uid', '==', uid)
    .where('status', '==', DATA_STATE.ACTIVE)
    .orderBy('name');
};

const doGetOrganizerFromDB = async (oid, pending) => {
  const fs = await getFirestore();
  return fs
    .collection(pending ? 'pending-organizers' : 'organizers')
    .doc(oid)
    .get()
    .then(
      (doc) =>
        new Promise((resolve, reject) => {
          if (doc && doc.exists) {
            const org = doc.data();
            resolve({
              ...org,
              id: oid,
            });
          }
          reject(Error('Organizer not found'));
        })
    );
};

export const doGetOrganizer = async (oid, checkPending) => {
  return doGetOrganizerFromDB(oid, false)
    .then((doc) => {
      return doc;
    })
    .catch((err) => {
      if (checkPending && err.message === 'Organizer not found') {
        return doGetOrganizerFromDB(oid, true);
      }
      throw Error(err);
    });
};

export const doSaveOrganizer = async (uid, organizer, saveStatus) => {
  const fs = await getFirestore();
  const { id, name, pid, url, phone, email, address, desc, social, pubStatus } =
    organizer;
  const o = {
    uid,
    name,
    status: saveStatus,
    pubStatus: pubStatus || DATA_STATE.DRAFT,
    submitted: fireapp.firestore.FieldValue.serverTimestamp(),
  };
  if (pid.length > 0) {
    o.pid = pid;
  } else if (id) {
    o.pid = fireapp.firestore.FieldValue.delete();
  }
  if (url.length > 0) {
    o.url = url;
  } else if (id) {
    o.url = fireapp.firestore.FieldValue.delete();
  }
  if (phone.length > 0) {
    o.phone = phone;
  } else if (id) {
    o.phone = fireapp.firestore.FieldValue.delete();
  }
  if (email.length > 0) {
    o.email = email;
  } else if (id) {
    o.email = fireapp.firestore.FieldValue.delete();
  }
  if (address && address.placeId) {
    o.address = address;
  } else if (id) {
    o.address = fireapp.firestore.FieldValue.delete();
  }
  if (desc.length > 0) {
    o.desc = desc;
  } else if (id) {
    o.desc = fireapp.firestore.FieldValue.delete();
  }
  if (social.length > 0) {
    o.social = social;
  } else if (id) {
    o.social = fireapp.firestore.FieldValue.delete();
  }

  if (id) {
    return fs.collection('pending-organizers').doc(id).set(o, { merge: true });
  }
  return fs.collection('pending-organizers').add(o);
};

export const doRemoveOrganizer = async (id, pending) => {
  const fs = await getFirestore();
  if (pending) {
    return fs.collection('pending-organizers').doc(id).delete();
  }
  return fs.collection('organizers').doc(id).set(
    {
      status: DATA_STATE.DELETED,
      updated: fireapp.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  );
};
// [END Organizers]
