/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Accordion from 'components/Accordion';

import { CONTACTUS_URI } from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1024,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 40,
    [theme.breakpoints.up('md')]: {
      paddingTop: 60,
    },
  },
  link: {
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
}));

const HowToFAQ = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const onChange = (panel, panelExpaned) => {
    setExpanded(panelExpaned ? panel : false);
  };

  return (
    <div id={id} ref={ref} className={classes.root}>
      <Typography
        variant='h2'
        align='center'
        color='secondary'
        style={{ paddingBottom: 30 }}
      >
        <strong>Frequently Asked Questions</strong>
      </Typography>
      <Accordion
        id='question-1'
        summary='What kind of events can I add?'
        details={
          <span>
            Organizers can post any event that is age-friendly or specifically
            for those 50 and over. Some examples of event categories are:
            <ul>
              <li>Creative and entertaining experiences</li>
              <li>Lifelong learning</li>
              <li>Health and wellness</li>
              <li>Social and community-based</li>
              <li>Support groups and aging-related education</li>
              <li>
                All experiences that help support older adults and healthy
                aging!
              </li>
            </ul>
            Event format options:
            <ul>
              <li>Virtual (video-calls, live streaming, webinars)</li>
              <li>In-person</li>
              <li>
                Telephone-access (dial-in that does not require internet access)
              </li>
            </ul>
          </span>
        }
        expanded={expanded === 'question-1'}
        onChange={onChange}
      />
      <Accordion
        id='question-2'
        summary='My event has a ticket price. Can I still add it to the calendar for free?'
        details={
          <span>
            Yes! Regardless of whether your event is free to the public or has a
            ticket price, you can post it to the calendar for free.
          </span>
        }
        expanded={expanded === 'question-2'}
        onChange={onChange}
      />
      <Accordion
        id='question-3'
        summary='Do I have to pay for an account?'
        details={
          <span>
            Nope! Your account is free and so are your event postings.
          </span>
        }
        expanded={expanded === 'question-3'}
        onChange={onChange}
      />
      <Accordion
        id='question-4'
        summary='I want to add my events to the calendar but I would like some help with that. Can you post and manage my events for me?'
        details={
          <span>
            Absolutely. Please{' '}
            <Link to={`${CONTACTUS_URI}`} className={classes.link}>
              contact us
            </Link>{' '}
            to learn more about our Event Management services.
          </span>
        }
        expanded={expanded === 'question-4'}
        onChange={onChange}
      />
      <Accordion
        id='question-5'
        summary='How many events can I post to the calendar at one time?'
        details={<span>As many as you want!</span>}
        expanded={expanded === 'question-5'}
        onChange={onChange}
      />
      <Accordion
        id='question-6'
        summary='Do you take a percentage of event ticket sales?'
        details={
          <span>
            Nope! We do not offer in-app ticket selling features so all ticket
            sales will need to be conducted outside of our website. This way, we
            can make sure all users are referred back to you and your platforms.
            This provides you with additional exposure and audience traffic.
          </span>
        }
        expanded={expanded === 'question-6'}
        onChange={onChange}
      />
      <Accordion
        id='question-7'
        summary='How many different organizers can I have under my one account?'
        details={
          <span>
            As many as you want! If you are adding and managing events for
            multiple entities, you can create multiple organizer profiles all
            within the same user account. When you add an event to the calendar,
            you can select which of your organizers is hosting the event.
          </span>
        }
        expanded={expanded === 'question-7'}
        onChange={onChange}
      />
      <Accordion
        id='question-8'
        summary='Can I cancel my event after I post it?'
        details={
          <span>
            Yes! You have full control over your event management. Under your
            account dashboard, you can cancel, reschedule, edit, or delete your
            event at any time.
          </span>
        }
        expanded={expanded === 'question-8'}
        onChange={onChange}
      />
      <Accordion
        id='question-9'
        summary='My events require a program membership in order to attend. Can I still add them to the calendar?'
        details={
          <span>
            Yes! Under your pricing description, you can identify that it is
            membership-based.
          </span>
        }
        expanded={expanded === 'question-9'}
        onChange={onChange}
      />
    </div>
  );
});

HowToFAQ.propTypes = {
  id: PropTypes.string,
};

HowToFAQ.defaultProps = {
  id: '',
};

export default HowToFAQ;
