/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import TruncateText from 'components/TruncateText';

const imgWithClick = { cursor: 'pointer' };

const useStyles = makeStyles({
  root: {
    position: 'relative',
    float: 'left',
  },
  playButton: {
    display: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.40)',
    maxHeight: '240px',
    overflow: 'hidden',
    width: 80,
    height: 40,
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 40px)',
    color: 'white',
    padding: '2px',
    textAlign: 'center',
    borderRadius: 5,
  },
  playButtonHover: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  playButtonText: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  },
  deleteHeader: {
    display: 'none',
    background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
    width: '100%',
    height: 46,
    position: 'absolute',
    borderRadius: 5,
  },
  deleteButton: {
    position: 'absolute',
    fontSize: '1em',
    width: '1.625em',
    height: '1.625em',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    margin: 10,
    padding: 0,
    border: 'none',
    outline: 'none',
    willChange: 'transform,opacity',
    cursor: 'pointer',
    zIndex: '102',
    color: '#fff',
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,.5)',
    backgroundImage: 'none',
    boxShadow: '0 0 0 0 hsla(0,0%,100%,0)',
    transition: 'box-shadow .25s ease-in',
    '&:hover': {
      boxShadow: '0 0 0 0.125em hsla(0,0%,100%,.9)',
    },
  },
  deleteSvg: {
    width: '100%',
    height: '100%',
    fontSize: 'inherit',
  },
  show: {
    display: 'block',
  },
  title: {
    position: 'absolute',
    top: 2,
    left: 36,
    textAlign: 'left',
  },
});

const Photo = ({
  direction,
  index,
  left,
  margin,
  onClick,
  onDelete,
  photo,
  showPlayButton,
  top,
}) => {
  const classes = useStyles();
  const [hover, setHover] = React.useState(false);
  const imgStyle = { margin, borderRadius: 5 };
  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handlePlay = (event) => {
    onClick(event, { photo, index });
  };

  return (
    <div
      key={photo.videoId}
      className={classes.root}
      role='presentation'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick ? handlePlay : null}
      onKeyDown={onClick ? handlePlay : null}
      style={onClick ? imgWithClick : null}
    >
      <div
        className={ClassNames(classes.playButton, {
          [classes.playButtonHover]: hover,
          [classes.show]: showPlayButton,
        })}
      >
        <div className={classes.playButtonText}>
          <PlayCircleFilledIcon style={{ marginRight: 5 }} /> Play
        </div>
      </div>
      <div
        className={ClassNames(classes.deleteHeader, {
          [classes.show]: !!onDelete,
        })}
        style={{ width: photo.width }}
      >
        <button
          className={classes.deleteButton}
          type='button'
          data-align='left'
          onClick={(e) => onDelete(e, photo)}
          style={{
            transform: 'translate3d(0px, 0px, 0) scale3d(1, 1, 1)',
            opacity: '1',
          }}
        >
          <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            xmlns='https://www.w3.org/2000/svg'
            className={classes.deleteSvg}
          >
            <path
              d='M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z'
              fill='currentColor'
            />
          </svg>
          <TruncateText
            lines={1}
            hideMore
            className={classes.title}
            style={{ width: photo.width - 56 }}
          >
            {photo.caption}
          </TruncateText>
        </button>
      </div>
      <img style={imgStyle} {...photo} alt={photo.overlay} />
    </div>
  );
};

Photo.propTypes = {
  direction: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  photo: PropTypes.instanceOf(Object).isRequired,
  left: PropTypes.number,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  showPlayButton: PropTypes.bool,
  top: PropTypes.number,
  videoId: PropTypes.string,
};

Photo.defaultProps = {
  left: undefined,
  onClick: null,
  onDelete: null,
  showPlayButton: false,
  top: undefined,
  videoId: '',
};

export default Photo;
