/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { CLOUDINARY, BRAINGAMES_URL } from 'constants/general';
const sentEmailSvg = `${CLOUDINARY}/sona/svg/sent_email_blue_v1.svg`;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px 0 15px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 15,
    },
  },
  content: {
    [theme.breakpoints.only('xs')]: {
      height: '100%',
      position: 'relative',
    },
    textAlign: 'center',
  },
  center: {
    [theme.breakpoints.only('xs')]: {
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  actions: {
    justifyContent: 'center',
  },
  icon: {
    maxWidth: 100,
    maxHeight: 100,
    minWidth: 100,
    minHeight: 100,
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  separator: {
    fontStyle: 'italic',
    padding: 5,
  },
  navLink: {
    color: theme.palette.companyPrimary.main,
    textTransform: 'inherit',
    textDecoration: 'underline',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.companyPrimary.light,
      backgroundColor: 'transparent',
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const EmailSentAlert = React.forwardRef(
  ({ id, braingames, open, onClose }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
      <MuiDialog
        onClose={onClose}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullScreen={fullScreen}
        classes={{
          paper: classes.root,
        }}
      >
        <DialogTitle id='dialog-title' onClose={onClose} />
        <DialogContent className={classes.content}>
          <div className={classes.center}>
            <img src={sentEmailSvg} alt='email sent' className={classes.icon} />
            <Typography
              variant='h4'
              align='center'
              color='primary'
              style={{
                whiteSpace: 'nowrap',
                paddingBottom: braingames ? 0 : 20,
              }}
            >
              Email sent!
            </Typography>
            {braingames && (
              <div>
                <Typography
                  variant='body1'
                  align='center'
                  color='inherit'
                  style={{ minWidth: 300, paddingBottom: 20 }}
                >
                  An email with your Brain Games has been sent.
                </Typography>
                <Typography
                  variant='body1'
                  align='center'
                  color='inherit'
                  style={{ minWidth: 300, paddingBottom: 5 }}
                >
                  <strong>Can't wait? You can view it now!</strong>
                </Typography>
                <div>
                  <Button
                    href={BRAINGAMES_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={onClose}
                    variant='contained'
                    color='primary'
                    size='large'
                    style={{
                      borderRadius: 5,
                      textTransform: 'inherit',
                      minWidth: 180,
                      marginBottom: 30,
                    }}
                  >
                    <strong>Show me!</strong>
                  </Button>
                </div>
                <div style={{ minWidth: 300, paddingBottom: 5 }}>
                  <Button onClick={onClose} className={classes.navLink}>
                    <strong>No thanks, I'll wait for the email</strong>
                  </Button>
                </div>
              </div>
            )}
            <Typography
              variant='body1'
              align='center'
              color='inherit'
              style={{ minWidth: 300, paddingBottom: 10 }}
            >
              If you don&#39;t see it in your inbox,{' '}
              <strong>
                check to see if it slipped into your junk mail folder.
              </strong>
            </Typography>
          </div>
        </DialogContent>
        {!braingames && (
          <DialogActions
            classes={{
              root: classes.actions,
            }}
          >
            <Button
              onClick={onClose}
              variant='contained'
              color='primary'
              size='large'
              style={{ borderRadius: 25, minWidth: 100 }}
            >
              Ok
            </Button>
          </DialogActions>
        )}
      </MuiDialog>
    );
  }
);

EmailSentAlert.propTypes = {
  id: PropTypes.string,
  braingames: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

EmailSentAlert.defaultProps = {
  id: '',
  braingames: false,
  open: false,
  onClose: null,
};

export default EmailSentAlert;
