/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { logAnalyticsEvent } from 'vendors/firebase/main';
import { doGetSiteContent } from 'vendors/firebase/firestore';
import { doSubscribeToMailchimp } from 'vendors/firebase/fireauth';
import { CLOUDINARY, getEmailRegex } from 'constants/general';
import EmailSentAlert from 'components/EmailSentAlert';

const img = `${CLOUDINARY}/sona/img/brain_teaser_v1.png`;
const amg = `${CLOUDINARY}/sona/img/a_mighty_good_v1.png`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    marginBottom: 30,
    maxWidth: 1020,
  },
  contentFrame: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  content: {
    borderRadius: 15,
    padding: '0 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: 20,
    },
  },
  title: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-start',
      textAlign: 'unset',
    },
  },
  titleImg: {
    width: 100,
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 150,
      marginRight: 15,
      marginBottom: -30,
    },
  },
  titleText: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  question: {
    width: 'fit-content',
    padding: '5px 10px',
    border: `1px solid ${theme.palette.companyPrimary.light}`,
    borderRadius: 20,
    margin: '10px auto 15px',
    boxShadow: `0px 0px 10px 2px ${theme.palette.companyPrimary.light}`,
  },
  answer: {
    padding: '8px 15px',
    border: `1px solid ${theme.palette.companySecondary.light}`,
    borderRadius: 20,
    margin: '5px 0',
    boxShadow: `0px 0px 10px 2px ${theme.palette.companySecondary.light}`,
  },
  reveal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: theme.palette.companyPrimary.main,
  },
  revealIcon: {
    transition: 'all 150ms ease-in',
  },
  revealUpIcon: {
    transform: 'rotate(180deg)',
  },
  emailDiv: {
    display: 'block',
    justifyContent: 'center',
    paddingTop: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      paddingBottom: 8,
    },
  },
  emailInputDiv: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 200,
      maxWidth: 420,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 430,
    },
  },
  emailInput: {
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 8,
    minHeight: 40,
    width: '100%',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  emailBtn: {
    textTransform: 'inherit',
    width: '100%',
    height: 40,
    marginTop: 5,
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 125,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 5,
    },
    '&:disabled': {
      color: `${theme.palette.common.white} !important`,
      backgroundColor: `${theme.palette.companyPrimary.dark} !important`,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.company.danger,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    fontWeight: '800',
    marginBottom: 5,
  },
  error: {
    backgroundColor: `${theme.palette.company.danger} !important`,
  },
  helperText: {
    fontSize: theme.typography.overline.fontSize,
  },
}));

const defaultQuestion = 'What has a head, a tail, is brown, and has no legs?';
const defaultAnswer = 'A penny.';

const BrainTeaser = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const [revealAnswer, setRevealAnswer] = useState(false);
  const [question, setQuestion] = useState(defaultQuestion);
  const [answer, setAnswer] = useState(defaultAnswer);
  const [answerRevealed, setAnswerRevealed] = useState(false);
  const [missingData, setMissingData] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const [processStep, setProcessStep] = useState('input');
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(false);

  const emailRef = useRef();

  useEffect(() => {
    doGetSiteContent()
      .then((siteContent) => {
        const { 'brain-teasers': brainTeasers } = siteContent;
        setQuestion(brainTeasers.question);
        setAnswer(brainTeasers.answer);
      })
      .catch((err) => {
        setQuestion('The more of this there is, the less you see. What is it?');
        setAnswer('Darkness');
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    setError(false);
    if (emailRef.current) {
      const email = emailRef.current.value;
      const regex = /\s+/gi;
      const emailCleaned = email.trim().replace(regex, ' ').toLowerCase();
      const REGEX_EMAIL = getEmailRegex();
      const emailIsValid = REGEX_EMAIL.test(emailCleaned);
      if (emailCleaned === '') {
        setMissingData(true);
      } else if (!emailIsValid) {
        setInvalidData(true);
      } else {
        setInvalidData(false);
        setMissingData(false);
        setSubmitting(true);
        doSubscribeToMailchimp(emailCleaned, 'Brain Games')
          .then(() => {
            setProcessStep('success');
            setSubmitting(false);
            setShowSuccess(true);
          })
          .catch((e) => {
            console.log('ERROR:', e);
            setProcessStep('input');
            setError(true);
            setSubmitting(false);
          });
        logAnalyticsEvent('promo', {
          type: 'brainteaser_download',
        });
      }
    }
  };

  const handleTooltipClose = () => {
    setMissingData(false);
    setInvalidData(false);
  };

  const handleSuccessAlertClose = () => {
    setShowSuccess(false);
  };

  const onRevealAnswer = () => {
    setAnswerRevealed(true);
    setRevealAnswer((prev) => !prev);
    if (!answerRevealed) {
      logAnalyticsEvent('promo', {
        type: 'brainteaser_reveal',
      });
    }
  };

  return (
    <div id={id} ref={ref} className={classes.root}>
      <div className={classes.contentFrame}>
        <div className={classes.content}>
          <div className={classes.title}>
            <img alt='brain teaser' src={img} className={classes.titleImg} />
            <div>
              <div className={classes.titleText}>
                <img
                  alt='a mighty good'
                  src={amg}
                  style={{ marginLeft: 10, marginRight: 10 }}
                  width={250}
                  height={41}
                />
                <Typography
                  variant='h4'
                  color='inherit'
                  style={{ paddingBottom: 0, fontSize: 36 }}
                >
                  <strong>Brainteaser!</strong>
                </Typography>
              </div>
              <Typography
                variant='h6'
                color='inherit'
                align='center'
                className={classes.question}
              >
                <strong>Q. {question}</strong>
              </Typography>
              <div onClick={onRevealAnswer} className={classes.reveal}>
                <ExpandMoreIcon
                  className={ClassNames(classes.revealIcon, {
                    [classes.revealUpIcon]: revealAnswer,
                  })}
                />
                <strong
                  style={{
                    marginLeft: 4,
                    marginRight: 4,
                    textDecoration: 'underline',
                  }}
                >
                  {revealAnswer ? 'Hide' : 'Reveal'} the answer
                </strong>
              </div>
              <Collapse in={revealAnswer}>
                <Typography
                  variant='body1'
                  color='inherit'
                  align='center'
                  style={{ padding: 30 }}
                >
                  <span className={classes.answer}>
                    Answer: <strong>{answer}</strong>
                  </span>
                </Typography>
                <div style={{ textAlign: 'center' }}>
                  <Typography variant='body1' color='primary' align='center'>
                    <strong>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Do you love brainteasers
                      </span>{' '}
                      <span style={{ whiteSpace: 'nowrap' }}>
                        as much as we do?
                      </span>
                    </strong>
                  </Typography>
                  <Typography variant='body1' color='inherit' align='center'>
                    <strong>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Get your free copy of the
                      </span>{' '}
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <i>Stay Smart Brain Games</i> e-book.
                      </span>{' '}
                    </strong>
                  </Typography>
                  <div className={classes.emailDiv}>
                    <div className={classes.emailInputDiv}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={missingData || invalidData}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement='top'
                        title={`Please enter ${
                          invalidData ? 'a valid' : 'your'
                        } email`}
                        classes={{
                          tooltip: classes.tooltip,
                        }}
                      >
                        <input
                          ref={emailRef}
                          type='email'
                          id='email'
                          name='email'
                          required
                          placeholder='Enter your email'
                          className={classes.emailInput}
                        />
                      </Tooltip>
                      <Hidden xsDown>
                        {error && (
                          <Typography
                            variant='body1'
                            align='center'
                            color='error'
                            style={{ marginTop: 5, whiteSpace: 'nowrap' }}
                          >
                            We&#39;re sorry, there was an error submitting your
                            request.
                          </Typography>
                        )}
                        <Typography
                          variant='caption'
                          align='center'
                          color='inherit'
                          className={classes.helperText}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          We hate junk. We hate spam. Your email is always safe
                          in our hands.
                        </Typography>
                      </Hidden>
                    </div>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Button
                        variant='contained'
                        color='primary'
                        className={ClassNames(classes.emailBtn, {
                          [classes.error]: error,
                        })}
                        onClick={onSubmit}
                        disabled={submitting}
                      >
                        {submitting ? (
                          <CircularProgress
                            color='inherit'
                            size={20}
                            className={classes.progress}
                          />
                        ) : processStep === 'input' ? (
                          error ? (
                            'Try again'
                          ) : (
                            'Send it!'
                          )
                        ) : (
                          'Success!'
                        )}
                      </Button>
                    </ClickAwayListener>
                    <Hidden smUp>
                      {error && (
                        <Typography
                          variant='body1'
                          align='center'
                          color='error'
                          style={{ marginBottom: 5 }}
                        >
                          We&#39;re sorry, there was an error submitting your
                          request.
                        </Typography>
                      )}
                      <Typography
                        variant='caption'
                        align='center'
                        color='inherit'
                        className={classes.helperText}
                      >
                        <span style={{ whiteSpace: 'nowrap' }}>
                          We hate junk. We hate spam.
                        </span>{' '}
                        <span style={{ whiteSpace: 'nowrap' }}>
                          Your email is always safe in our hands.
                        </span>
                      </Typography>
                    </Hidden>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
      <EmailSentAlert
        braingames
        open={showSuccess}
        onClose={handleSuccessAlertClose}
      />
    </div>
  );
});

BrainTeaser.propTypes = {
  id: PropTypes.string,
};

BrainTeaser.defaultProps = {
  id: '',
};

export default BrainTeaser;
