/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

/**
 * Usage
 * const styles = theme => ({
 *   divider: {
 *     backgroundColor: theme.palette.primary.main,
 *   },
 * });
 *
 * <Divider />
 * or
 * <Divider size="20" className={classes.divider} />
 * size: optional
 * className: optional
 */
const styles = (theme) => ({
  divider: {
    backgroundColor: theme.palette.companySecondary.dark,
    border: 'none',
    width: 200,
    borderRadius: 10,
    marginTop: 0,
    marginBottom: theme.spacing(3),
  },
});

const Divider = ({ size, className, classes, ...other }) => (
  <hr
    {...other}
    align='middle'
    size={size}
    className={`${className} ${classes.divider}`}
  />
);

Divider.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  classes: PropTypes.instanceOf(Object).isRequired,
};

Divider.defaultProps = {
  size: 8,
  className: '',
};

export default withStyles(styles)(Divider);
