/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import Send from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { getEmailRegex } from 'constants/general';
import { doSendSupportMessage } from 'vendors/firebase/fireauth';
import { THANKYOU_URI } from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  main: {
    color: theme.palette.companyPrimary.main,
    textAlign: 'center',
  },
  labelRoot: {
    width: '100%',
    textAlign: 'center',
    transformOrigin: '50% -10%',
    color: theme.palette.companyPrimary.dark,
  },
  buttonClass: {
    borderRadius: 35,
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    width: 'fit-content',
    border: '1px solid white',
    padding: '14px 16px',
  },
  error: {
    color: theme.palette.company.danger,
    paddingTop: theme.spacing(1),
    textAlign: 'left',
  },
  errorIcon: {
    color: theme.palette.company.danger,
    maxWidth: 36,
    paddingTop: 10,
  },
  progress: {
    color: theme.palette.common.white,
  },
}));

const ContactForm = React.forwardRef(
  ({ id, uid, initName, initEmail, initMessage, history }, ref) => {
    const classes = useStyles();
    const [fromName, setFromName] = useState(initName);
    const [fromEmail, setFromEmail] = useState(initEmail);
    const [message, setMessage] = useState(initMessage);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
      setFromName(initName);
      // eslint-disable-next-line
    }, [initName]);

    useEffect(() => {
      setFromEmail(initEmail);
      // eslint-disable-next-line
    }, [initEmail]);

    useEffect(() => {
      setMessage(initMessage);
      // eslint-disable-next-line
    }, [initMessage]);

    const validateInputs = (email, msg) => {
      const REGEX_EMAIL = getEmailRegex();
      let foundError;
      if (email === '') {
        foundError = 'Please enter your email address';
      } else if (!REGEX_EMAIL.test(email)) {
        foundError = 'Please enter a valid email address';
      } else if (msg === '') {
        foundError = 'Please enter your message';
      }
      if (foundError) {
        setError(foundError);
        return false;
      } else {
        setError('');
        return true;
      }
    };

    const onSend = () => {
      const REGEX = /\s+/gi;
      const nameCleaned = fromName.trim().replace(REGEX, ' ');
      const emailCleaned = fromEmail.trim();
      const messageCleaned = message.trim();
      if (validateInputs(emailCleaned, messageCleaned)) {
        setSending(true);
        doSendSupportMessage(nameCleaned, emailCleaned, messageCleaned, uid)
          .then(() => {
            setSending(false);
            history.push(THANKYOU_URI);
          })
          .catch(() => {
            setSending(false);
            history.push(THANKYOU_URI);
          });
      }
    };

    return (
      <div id={id} ref={ref} className={classes.main}>
        <Typography variant='h2' align='center' color='inherit'>
          Send us a message
        </Typography>
        <TextField
          type='text'
          label='Enter Your Name'
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
            },
          }}
          name='uname'
          id='uname'
          value={fromName}
          onChange={(e) => setFromName(e.target.value)}
          fullWidth
          inputProps={{
            maxLength: 200,
          }}
        />
        <p />
        <TextField
          required
          type='email'
          label='Enter Your Email'
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
            },
          }}
          name='mailfrom'
          id='mailfrom'
          value={fromEmail}
          onChange={(e) => setFromEmail(e.target.value)}
          fullWidth
          inputProps={{
            maxLength: 200,
          }}
        />
        <p />
        <TextField
          required
          type='text'
          label='Enter Your Message'
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
            },
          }}
          name='message'
          id='message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          minRows={4}
          maxRows={100}
          fullWidth
          inputProps={{
            maxLength: 2000,
          }}
        />
        {error && (
          <Grid container>
            <Grid item xs={2} className={classes.errorIcon}>
              <ErrorIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant='body1'
                color='error'
                className={classes.error}
                gutterBottom
              >
                {error}
              </Typography>
            </Grid>
          </Grid>
        )}
        <p />
        <Button
          variant='contained'
          name='sendmessage'
          id='sendmessage'
          color='primary'
          aria-label='send message'
          className={classes.buttonClass}
          endIcon={
            sending ? (
              <CircularProgress
                color='inherit'
                size={30}
                className={classes.progress}
              />
            ) : (
              <Send />
            )
          }
          onClick={onSend}
        >
          <span style={{ textAlign: 'left' }}>
            {sending ? 'Sending...' : 'Send Message'}
          </span>
        </Button>
      </div>
    );
  }
);

ContactForm.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
  initName: PropTypes.string,
  initEmail: PropTypes.string,
  initMessage: PropTypes.string,
  uid: PropTypes.string,
};

ContactForm.defaultProps = {
  id: '',
  initName: '',
  initEmail: '',
  initMessage: '',
  uid: '',
};

export default withRouter(ContactForm);
