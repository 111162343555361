/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { formatIntlPhoneNumber } from 'utilities/utils';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    textAlign: 'center',
  },
  separator: {
    fontStyle: 'italic',
    padding: 5,
  },
  navLink: {
    color: theme.palette.companyPrimary.main,
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word' /* Internet Explorer 5.5+ */,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    padding: '12px 16px',
  },
  phoneButton: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    padding: '8px 16px 2px',
    '& * span': {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
    },
  },
  caption: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
  },
}));

const RegisterDialog = React.forwardRef(
  (
    {
      id,
      className,
      disabled,
      email,
      emailBody,
      emailSubject,
      link,
      info,
      onRegister,
      phone,
    },
    ref
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = useState(false);

    const onOpen = () => {
      setOpen(true);
      if (onRegister) onRegister('dialog', 'open');
    };
    const onClose = () => setOpen(false);

    const handleRegister = (type, fromDialog) => {
      if (onRegister) {
        onRegister(type, 'summary');
        if (fromDialog) {
          onRegister('dialog', type);
        }
      }
    };

    const { teleNumberIntl, phoneNumberIntl, regionName } =
      formatIntlPhoneNumber(phone);

    let regButton;
    let regInfo;
    if (link && !email && !phone && !info) {
      regButton = (
        <Button
          id={id}
          ref={ref}
          variant='contained'
          component='a'
          href={link}
          target='_blank'
          color='secondary'
          fullWidth
          className={className}
          disabled={disabled}
          onClick={() => handleRegister('link', false)}
        >
          Register
        </Button>
      );
    } else if (email && !link && !phone && !info) {
      regInfo = (
        <span>
          Please send an email to{' '}
          <b
            style={{ marginLeft: 3, marginRight: 5, overflowWrap: 'anywhere' }}
          >
            <i>{email}</i>
          </b>{' '}
          to register.
        </span>
      );
      regButton = (
        <Button
          id={id}
          ref={ref}
          variant='contained'
          color='secondary'
          fullWidth
          className={className}
          disabled={disabled}
          onClick={onOpen}
        >
          Register
        </Button>
      );
    } else if (phone && !link && !email && !info) {
      regInfo = (
        <span>
          Please call{' '}
          <b
            style={{ marginLeft: 3, marginRight: 5, overflowWrap: 'anywhere' }}
          >
            {phoneNumberIntl}
          </b>
          <span className={classes.caption}>({regionName})</span> to register.
        </span>
      );
      regButton = (
        <Button
          id={id}
          ref={ref}
          variant='contained'
          color='secondary'
          fullWidth
          className={className}
          disabled={disabled}
          onClick={onOpen}
        >
          Register
        </Button>
      );
    } else {
      regInfo = info;
      regButton = (
        <Button
          id={id}
          ref={ref}
          variant='contained'
          color='secondary'
          fullWidth
          className={className}
          disabled={disabled}
          onClick={onOpen}
        >
          Register
        </Button>
      );
    }
    return (
      <>
        {regButton}
        <Dialog
          fullScreen={fullScreen}
          maxWidth='sm'
          open={open}
          onClose={onClose}
          aria-labelledby='event registration'
        >
          <DialogTitle
            id='recurring-event-dates'
            onClose={onClose}
            style={{ paddingBottom: 10 }}
          >
            Event registration
          </DialogTitle>
          <DialogContent dividers className={classes.content}>
            {link && (
              <div>
                <Button
                  variant='contained'
                  component='a'
                  href={link}
                  target='_blank'
                  rel='noopener noreferrer'
                  color='secondary'
                  fullWidth
                  onClick={() => handleRegister('link', true)}
                  className={classes.button}
                >
                  Register online
                </Button>
              </div>
            )}
            {email && (
              <>
                {link && <div className={classes.separator}>or</div>}
                <div>
                  <Button
                    variant='contained'
                    component='a'
                    href={`mailto:${email}?subject=${emailSubject}&body=${emailBody}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    color='secondary'
                    fullWidth
                    className={classes.button}
                    style={{ padding: '0px 16px' }}
                    onClick={() => handleRegister('email', true)}
                  >
                    <div style={{ lineHeight: 1.5, textTransform: 'inherit' }}>
                      Register{' '}
                      <span style={{ textTransform: 'lowercase' }}>via</span>{' '}
                      email
                      <div
                        style={{
                          fontSize: theme.typography.body1.fontSize,
                          lineHeight: theme.typography.body1.lineHeight,
                          fontStyle: 'italic',
                          textTransform: 'lowercase',
                          overflowWrap: 'anywhere',
                        }}
                      >
                        {email}
                      </div>
                    </div>
                  </Button>
                </div>
              </>
            )}
            {phoneNumberIntl && (
              <>
                {(link || email) && <div className={classes.separator}>or</div>}
                <div>
                  <Button
                    variant='contained'
                    component='a'
                    href={`tel:${teleNumberIntl}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    color='secondary'
                    fullWidth
                    onClick={() => handleRegister('phone', true)}
                    className={classes.phoneButton}
                  >
                    <div>
                      <div>Call: {phoneNumberIntl}</div>
                      <div className={classes.caption}>({regionName})</div>
                    </div>
                  </Button>
                </div>
              </>
            )}
            {regInfo && <div style={{ paddingTop: 15 }}>{regInfo}</div>}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant='outlined' color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

RegisterDialog.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  email: PropTypes.string,
  emailBody: PropTypes.string,
  emailSubject: PropTypes.string,
  id: PropTypes.string,
  info: PropTypes.string,
  link: PropTypes.string,
  onRegister: PropTypes.func,
  phone: PropTypes.string,
};

RegisterDialog.defaultProps = {
  className: '',
  disabled: false,
  email: '',
  emailBody: '',
  emailSubject: '',
  id: '',
  info: '',
  link: '',
  onRegister: null,
  phone: '',
};

export default RegisterDialog;
