/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import { SnackbarProvider } from 'notistack';

import { UserProvider } from 'UserContext';
import withTheme from './withTheme';
import App from './App';

const Root = () => {
  const [initialLoad, setInitialLoad] = React.useState(false);

  return (
    <UserProvider>
      <SnackbarProvider maxSnack={3}>
        <App
          initialLoadProp={initialLoad}
          setInitialLoadProp={() => setInitialLoad(true)}
        />
      </SnackbarProvider>
    </UserProvider>
  );
};

export default withTheme(Root);
