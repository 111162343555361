/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BrainTeaser from 'views/search/BrainTeaser';
import SearchLoading from 'components/SearchLoading';
import SearchResult from 'views/search/SearchResult';
import { EVENT_URI } from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  searchResults: {
    position: 'relative',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

const styles = (theme) => ({
  divider: {
    zIndex: 1,
    color: 'white',
    background: (props) => {
      if (props.csid === 'j619cykdqr') {
        return '#3a3a3a';
      }
      return 'linear-gradient(180deg, rgba(9,128,118,1) 0%, rgba(38,166,154,1) 100%)';
    },
    padding: (props) => (props.fullScreen ? '10px 15px 10px' : '7px 25px 5px'),
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    fontSize: (props) => (props.fullScreen ? 18 : 22),
    margin: '20px auto -10px',
    maxWidth: (props) => (props.fullScreen ? 'calc(100vw - 60px)' : 400),
    textAlign: 'center',
  },
});

const DateResultsDivider = withStyles(styles)((props) => {
  const { classes, newDay } = props;
  return <div className={classes.divider}>{newDay}</div>;
});

const Search = React.forwardRef(
  (
    {
      id,
      results,
      searching,
      hasMore,
      onLoadMore,
      endMessage,
      customSearchId,
      searchLocation,
    },
    ref
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const { ref: scrollRef, inView } = useInView({
      threshold: 1,
    });

    useEffect(() => {
      if (inView && hasMore) {
        onLoadMore();
      }
      // eslint-disable-next-line
    }, [inView]);

    const triggerPoint = results.length - 5;
    const brainTeaserPos = results.length > 6 ? 6 : results.length - 1;
    return (
      <div className={classes.searchResults}>
        <SearchLoading show={searching} message='Searching...' />
        {results.map((result, index) => (
          <div key={result.objectID}>
            {result.upcomingDivider && result.upcomingDivider}
            {result.addDateDivider && (
              <DateResultsDivider
                newDay={result.event.dayOfEvent}
                fullScreen={fullScreen}
                csid={customSearchId}
              />
            )}
            <Link
              className={classes.link}
              to={{
                pathname: `${EVENT_URI}/${result.event.eid}/${result.event.date.startDate}`,
                state: {
                  currViewDate: result.event.date.startDate,
                  event: result.event,
                  csid: customSearchId,
                },
              }}
            >
              <div ref={index === triggerPoint ? scrollRef : null}>
                <SearchResult
                  eid={result.event.eid}
                  img={result.event.img}
                  blurhash={result.event.blurhash}
                  displayName={result.event.name}
                  eventDate={result.event.daysOfEvent}
                  eventTime={result.event.hoursOfEvent}
                  eventEndTime={result.event.date.endDate}
                  venue={result.event.venue}
                  city={result.event.city}
                  distance={result.event.distance}
                  favoriteId={result.event.favoriteId}
                  orgName={result.event.orgName}
                  price={result.event.price}
                  access={result.event.access}
                  recurring={result.event.recurring}
                />
              </div>
            </Link>
            {customSearchId === '' && index === brainTeaserPos && (
              <BrainTeaser />
            )}
          </div>
        ))}
        {hasMore && (
          <div style={{ height: 220 }}>
            <SearchLoading
              show={true}
              overlay={false}
              message='Loading more results...'
            />
          </div>
        )}
        {endMessage && !hasMore && <div>{endMessage}</div>}
        {customSearchId === '' &&
          !hasMore &&
          results.length === 0 &&
          !searchLocation.inperson && <BrainTeaser />}
      </div>
    );
  }
);

Search.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  searching: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  id: PropTypes.string,
  customSearchId: PropTypes.string,
  endMessage: PropTypes.instanceOf(Object),
  searchLocation: PropTypes.instanceOf(Object),
};

Search.defaultProps = {
  id: '',
  customSearchId: '',
  endMessage: null,
  searchLocation: { inperson: false },
};

export default Search;
