/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SearchLoading from 'components/SearchLoading';
import TruncateText from 'components/TruncateText';
import { indexEvents } from 'vendors/algolia/main';

import { formatEventDates } from 'utilities/utils';
import { CLOUDINARY, COMMON_SEARCH_PARAMS } from 'constants/general';
import { EVENT_URI, SEARCH_URI } from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1280,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 30,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 20,
    },
  },
  contents: {
    width: 1280,
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
    },
  },
  tagline: {
    paddingLeft: 15,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 22,
      paddingBottom: 10,
    },
  },
  shortcuts: {
    [theme.breakpoints.only('xs')]: {
      padding: '10px 10px 0 10px',
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: 5,
      paddingLeft: 10,
    },
  },
  paper: {
    zIndex: 99,
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      borderRadius: 5,
    },
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    '&:hover': {
      zIndex: 99,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      marginTop: -2,
      marginLeft: -2,
      marginBottom: 1,
      '& * img': {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
      },
    },
  },
  eventDate: {
    whiteSpace: 'nowrap',
    color: theme.palette.companySecondary.dark,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
  },
  eventLocation: {
    color: '#757575',
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  seeMoreBtn: {
    width: 400,
    height: 50,
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
    marginTop: 30,
    marginBottom: 50,
    maxWidth: 'calc(100% - 30px)',
    marginLeft: 15,
    marginRight: 15,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  filterHeaders: {
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle2.lineHeight,
    textTransform: 'none',
    color: '#757575',
    [theme.breakpoints.only('xs')]: {
      minWidth: 30,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: 40,
    },
  },
  activeHeader: {
    color: '#424242',
    borderBottom: '2px solid #757575',
  },
  comingEvents: {
    position: 'relative',
    minHeight: 220,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    [theme.breakpoints.up('md')]: {
      padding: 10,
    },
  },
  comingEvent: {
    padding: '10px 10px',
    [theme.breakpoints.up('md')]: {
      padding: 10,
    },
    [theme.breakpoints.up('lg')]: {
      padding: 15,
    },
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    fontWeight: '600',
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
    paddingTop: 12,
    [theme.breakpoints.up('sm')]: {
      height: 102,
    },
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    fontWeight: '500',
  },
}));

const featuredTypes = [
  {
    type: 'all',
    name: 'All',
    date: 'today',
    price: 'any',
  },
  {
    type: 'weekend',
    name: 'This Weekend',
    date: 'thisweekend',
    price: 'any',
  },
  {
    type: 'free',
    name: 'Free',
    date: 'today',
    price: 'free',
  },
  {
    type: 'entertainment',
    name: 'Entertainment',
    date: 'today',
    price: 'any',
  },
  {
    type: 'creative',
    name: 'Creative',
    date: 'today',
    price: 'any',
  },
  {
    type: 'wellness',
    name: 'Health & Wellness',
    date: 'today',
    price: 'any',
  },
  {
    type: 'learning',
    name: 'Lifelong Learning',
    date: 'today',
    price: 'any',
  },
];

let hitsPerPage = 8;
if (window.innerWidth < 1280) {
  hitsPerPage = 6;
}
const smCardCols = window.innerWidth < 750 ? 6 : 4;

const alreadyFound = (arr, eid) => arr.find((ele) => ele.eid === eid);

const UpComingEvents = React.forwardRef(({ id, className, history }, ref) => {
  const classes = useStyles();
  const currDateTime = DateTime.local();
  const startOfDay = Math.round(currDateTime.toMillis() / 60000);

  const [init, setInit] = useState(true);

  const [weekendStart, setWeekendStart] = useState(0);
  const [featuredType, setFeaturedType] = useState(featuredTypes[0].type);
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [_errorChange, setErrorChange] = useState(false);

  const parseHits = (hits) => {
    const foundEventList = [];
    const BreakException = {};
    try {
      hits.forEach((s, index) => {
        const {
          id: eid,
          name,
          startDate,
          endDate,
          timezone,
          days,
          recurrance,
          orgId,
          orgName,
          photoId,
          venue,
          desc,
          price,
          _tags,
        } = s;
        if (!alreadyFound(foundEventList, eid)) {
          const imgSrc = `${CLOUDINARY}v1589414485/sona/events/${photoId}`;
          // eslint-disable-next-line no-unused-vars
          const { dayOfEvent, daysOfEvent, hoursOfEvent } = formatEventDates({
            startDate,
            endDate,
            dates: days,
            timezone: DateTime.local().zoneName,
          });
          foundEventList.push({
            eid,
            name,
            startDate,
            endDate,
            days,
            recurrance,
            timezone,
            img: imgSrc,
            daysOfEvent,
            hoursOfEvent,
            venue,
            desc,
            tags: _tags,
            price,
            orgId,
            orgName,
            width: '32%',
          });
          if (foundEventList.length >= hitsPerPage) {
            throw BreakException;
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
    return foundEventList;
  };

  const executeSearch = async (startDate, endDate, price, tags, label) => {
    setSearching(true);
    if (searching) {
      return;
    }
    const params = COMMON_SEARCH_PARAMS;
    let filters = `startDate >= ${startDate} AND venue:online OR venue:phone`;
    if (endDate) {
      filters = filters.concat(` AND startDate < ${endDate}`);
    }
    delete params.aroundLatLng;
    delete params.aroundRadius;
    if (price !== 'any') {
      filters = filters.concat(` AND venue:${price}`);
    }
    if (process.env.NODE_ENV !== 'development') {
      filters = filters.concat(' AND demo = 0');
    }
    if (filters.length > 0) {
      params.filters = filters;
    } else {
      delete params.filters;
    }
    if (tags.length > 0) {
      const filterTags = [];
      tags.map((tag) => filterTags.push('_tags:'.concat(tag)));
      params.facetFilters = [filterTags];
    } else {
      delete params.facetFilters;
    }
    indexEvents.search('', params).then(async (responses) => {
      const { hits } = responses;
      const foundEventsList = await parseHits(hits);
      await setSearchResults(foundEventsList);
      setSearching(false);
    });
  };

  useEffect(() => {
    setInit(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!init) {
      const fObj = featuredTypes.find((ele) => ele.type === featuredType);
      doTypeSearch(featuredType, fObj.name);
    }
    // eslint-disable-next-line
  }, [init]);

  const findTheWeekend = () => {
    let weekend = DateTime.local(
      currDateTime.year,
      currDateTime.month,
      currDateTime.day,
      0,
      0,
      0
    );
    while (weekend.weekday < 6) {
      weekend = weekend.plus({ day: 1 });
    }
    return Math.round(weekend.toMillis() / 60000);
  };

  const searchThisWeekend = () => {
    let wkEnd = weekendStart;
    if (wkEnd === 0) {
      wkEnd = findTheWeekend();
      setWeekendStart(wkEnd);
    }
    executeSearch(wkEnd, wkEnd + 2880, 'any', [], 'searchThisWeekend');
  };

  const doTypeSearch = (type, name) => {
    if (type === 'weekend') {
      searchThisWeekend();
    } else if (type === 'free') {
      executeSearch(startOfDay, null, 'free', [], 'doTypeSearch 1');
    } else if (
      type === 'entertainment' ||
      type === 'creative' ||
      type === 'wellness' ||
      type === 'learning'
    ) {
      executeSearch(startOfDay, null, 'any', [name], 'doTypeSearch 2');
    } else {
      executeSearch(startOfDay, null, 'any', [], 'doTypeSearch 3');
    }
  };

  const onFeaturedTypeChange = (fType) => {
    setFeaturedType(fType.type);
    doTypeSearch(fType.type, fType.name);
  };

  const viewMoreEvents = () => {
    let queryString = `?l=op`;
    if (featuredType === 'weekend') {
      queryString = `${queryString}&d=${weekendStart}&n=4`;
    } else {
      queryString = `${queryString}&d=${startOfDay}&n=5`;
    }
    if (featuredType === 'free') {
      queryString = `${queryString}&p=1`;
    } else if (
      featuredType === 'entertainment' ||
      featuredType === 'creative' ||
      featuredType === 'wellness' ||
      featuredType === 'learning'
    ) {
      const menuTypeObj = featuredTypes.find(
        (ele) => ele.type === featuredType
      );
      queryString = `${queryString}&t=${encodeURIComponent(menuTypeObj.name)}`;
    }
    history.push({
      pathname: SEARCH_URI,
      search: queryString,
    });
  };

  return (
    <div id={id} ref={ref} className={ClassNames(classes.root, className)}>
      <div className={classes.contents}>
        <Typography variant='h4' className={classes.tagline}>
          <strong>Upcoming events</strong>
        </Typography>
      </div>
      <div className={ClassNames(classes.contents, classes.shortcuts)}>
        {featuredTypes.map((fType, index) => (
          <Button
            key={index}
            className={classes.filterHeaders}
            onClick={() => onFeaturedTypeChange(fType)}
          >
            <span
              className={ClassNames({
                [classes.activeHeader]: featuredType === fType.type,
              })}
            >
              {fType.name}
            </span>
          </Button>
        ))}
        <Button
          component={Link}
          to={{
            pathname: SEARCH_URI,
            search: `?n=5`,
          }}
          className={classes.filterHeaders}
        >
          See more...
        </Button>
      </div>
      <div className={classes.contents}>
        <Grid container className={classes.comingEvents}>
          <SearchLoading
            show={searching}
            message='Loading featured events...'
          />
          {searchResults.map((event, index) => {
            let venueLocation;
            if (event.venue.includes('inperson')) {
              venueLocation = 'Austin, Texas';
            } else if (event.venue.includes('online')) {
              venueLocation = 'Online Event';
            } else if (event.venue.includes('phone')) {
              venueLocation = 'By Phone';
            } else {
              venueLocation = '';
            }
            return (
              <Grid
                item
                key={index}
                xs={12}
                sm={smCardCols}
                md={4}
                lg={3}
                className={classes.comingEvent}
              >
                <Card
                  className={classes.paper}
                  onClick={() => {
                    history.push({
                      pathname: `${EVENT_URI}/${event.eid}/${event.startDate}`,
                      state: {
                        analyticsType: 'upcoming',
                        currViewDate: event.startDate,
                        event: {
                          eid: event.eid,
                          name: event.name,
                          date: {
                            startDate: event.startDate,
                            endDate: event.endDate,
                            dates: event.days,
                            recurrance: event.recurrance,
                            timezone: event.timezone,
                          },
                          img: event.img,
                          orgId: event.orgId,
                          orgName: event.orgName,
                          price: event.price,
                          tags: event.tags,
                        },
                      },
                    });
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      image={event.img}
                      title={event.name}
                    />
                    <CardContent style={{ paddingBottom: 5 }}>
                      <Typography variant='body1' className={classes.eventDate}>
                        <strong>{event.daysOfEvent}</strong>
                      </Typography>
                      <Typography
                        gutterBottom
                        variant='body1'
                        className={classes.eventDate}
                      >
                        {event.hoursOfEvent}
                      </Typography>
                      <Typography
                        variant='body2'
                        className={classes.eventLocation}
                      >
                        {venueLocation}
                      </Typography>
                      <TruncateText
                        lines={3}
                        hideMore
                        className={classes.title}
                      >
                        {event.name}
                      </TruncateText>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button color='primary' className={classes.button}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className={classes.contents} style={{ textAlign: 'center' }}>
        <Button
          variant='outlined'
          color='primary'
          className={classes.seeMoreBtn}
          onClick={viewMoreEvents}
        >
          View More Events
        </Button>
      </div>
    </div>
  );
});

UpComingEvents.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
};

UpComingEvents.defaultProps = {
  className: '',
  id: '',
};

export default withRouter(UpComingEvents);
