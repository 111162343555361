/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AdvancedImage } from '@cloudinary/react';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { SocialIcon } from 'react-social-icons';

import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import Typography from '@material-ui/core/Typography';

import {
  CLOUDINARY_CLOUDNAME_HASC,
  CLOUDINARY_ORGANIZERS,
} from 'constants/general';
import { logAnalyticsEvent } from 'vendors/firebase/main';
import { generateMapUrl, formatIntlPhoneNumber } from 'utilities/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    paddingBottom: 20,
  },
  image: {
    height: 100,
    width: 'auto',
  },
  organizerLogo: {
    maxHeight: 100,
  },
  organizerName: {
    paddingBottom: 5,
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  organizerDesc: {
    paddingTop: 20,
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    whiteSpace: 'break-spaces',
  },
  navLink: {
    color: theme.palette.companyPrimary.main,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word' /* Internet Explorer 5.5+ */,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  launchIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1.1rem',
  },
  socialIcons: {
    textAlign: 'center',
    paddingTop: 20,
    '& > a': {
      margin: '0 5px',
    },
  },
  error: {
    color: '#3D4542',
    backgroundColor: '#FDD5CE',
    borderRadius: 5,
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '2px 10px',
    margin: '10px auto',
  },
  img: {
    maxWidth: 'calc(100vw - 60px)',
  },
  address: {
    color: '#616161',
    padding: '10px 0',
  },
  caption: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
  },
}));

const ViewOrganizer = React.forwardRef(
  ({ id, organizer, event, errors }, ref) => {
    const classes = useStyles();

    const onLinkClick = (type, href = undefined) => {
      let hint;
      if (href) {
        if (href.includes('twitter.com')) {
          hint = 'twitter';
        } else if (href.includes('facebook.com')) {
          hint = 'facebook';
        } else if (href.includes('linkedin.com')) {
          hint = 'linkedin';
        } else if (href.includes('instagram.com')) {
          hint = 'instagram';
        } else if (href.includes('youtube.com')) {
          hint = 'youtube';
        } else if (href.includes('meetup.com')) {
          hint = 'meetup';
        }
      }
      const context = {
        type,
        orgId: organizer.id,
        orgName: organizer.name,
      };
      if (event) {
        context.eventId = event.eid;
        context.eventName = event.name;
      }
      if (hint) {
        context.hint = hint;
      } else if (href) {
        context.href = href;
      }
      logAnalyticsEvent('organizer', context);
    };

    const formatAddress = ({ street, city, state, lat, lng, placeId }) => (
      <>
        {street}
        {city ? <div>{`${city}, ${state}`}</div> : null}
        {lat && lng ? (
          <a
            href={generateMapUrl(lat, lng, placeId)}
            className={classes.navLink}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => onLinkClick('address_map')}
          >
            View Map <LaunchIcon className={classes.launchIcon} />
          </a>
        ) : null}
      </>
    );

    const myImage = new CloudinaryImage(
      CLOUDINARY_ORGANIZERS.concat(organizer.pid),
      {
        cloudName: CLOUDINARY_CLOUDNAME_HASC,
      }
    );

    const { teleNumberIntl, phoneNumberIntl, regionName } =
      formatIntlPhoneNumber(organizer.phone);

    return (
      <div id={id} className={classes.root}>
        {errors.nonProfit && (
          <Typography
            variant='body1'
            align='center'
            className={classes.error}
            color='error'
            classes={{
              colorError: classes.error,
            }}
          >
            {errors.nonProfit}
          </Typography>
        )}
        {organizer.pid && (
          <div className={classes.img}>
            <AdvancedImage
              cldImg={myImage.format('png').resize(scale().height(100))}
            />
          </div>
        )}
        <Typography
          variant='h4'
          align='center'
          className={classes.organizerName}
          color={organizer.name.length === 0 ? 'error' : 'inherit'}
          classes={{
            colorError: classes.error,
          }}
        >
          {organizer.name.length > 0
            ? organizer.name
            : 'An organizer name is required'}
        </Typography>
        {errors.phone && !errors.phone.startsWith('Please enter at least one') && (
          <Typography
            variant='body1'
            align='center'
            className={classes.error}
            color='error'
            classes={{
              colorError: classes.error,
            }}
          >
            Phone number error
          </Typography>
        )}
        {errors.phone.length === 0 && phoneNumberIntl && (
          <div>
            <a
              href={`tel:${teleNumberIntl}`}
              className={classes.navLink}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => onLinkClick('phone')}
            >
              {phoneNumberIntl}
            </a>{' '}
            <span className={classes.caption}>({regionName})</span>
          </div>
        )}
        {errors.email && !errors.email.startsWith('Please enter at least one') && (
          <Typography
            variant='body1'
            align='center'
            className={classes.error}
            color='error'
            classes={{
              colorError: classes.error,
            }}
          >
            Email address error
          </Typography>
        )}
        {errors.email.length === 0 && organizer.email && (
          <div style={{ paddingTop: 5 }}>
            <a
              href={`mailto:${organizer.email}`}
              className={classes.navLink}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => onLinkClick('email')}
            >
              {organizer.email}
            </a>
          </div>
        )}
        {errors.url && (
          <Typography
            variant='body1'
            align='center'
            className={classes.error}
            color='error'
            classes={{
              colorError: classes.error,
            }}
          >
            {errors.url.startsWith('Please enter at least one')
              ? 'At least one form of contact required'
              : 'Website url error'}
          </Typography>
        )}
        {errors.url.length === 0 && organizer.url && (
          <div style={{ paddingTop: 5 }}>
            <a
              href={organizer.url}
              className={classes.navLink}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => onLinkClick('link')}
            >
              Website
            </a>
          </div>
        )}
        {organizer.address && organizer.address.lat && organizer.address.lng && (
          <Typography
            variant='body1'
            component='div'
            color='inherit'
            className={classes.address}
          >
            {formatAddress(organizer.address)}
          </Typography>
        )}
        {organizer.desc && (
          <Typography
            variant='body1'
            align='left'
            className={classes.organizerDesc}
          >
            {organizer.desc}
          </Typography>
        )}
        {organizer.social && organizer.social.length > 0 && (
          <div className={classes.socialIcons}>
            {organizer.email && (
              <SocialIcon
                url={`mailto:${organizer.email}`}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => onLinkClick('email')}
              />
            )}
            {organizer.social.map((url, index) => (
              <SocialIcon
                key={index}
                url={url}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => onLinkClick('social', url)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
);

ViewOrganizer.propTypes = {
  organizer: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object),
  event: PropTypes.instanceOf(Object),
  id: PropTypes.string,
};

ViewOrganizer.defaultProps = {
  errors: {
    name: '',
    pid: '',
    url: '',
    phone: '',
    email: '',
    desc: '',
    social: '',
  },
  event: null,
  id: '',
};

export default ViewOrganizer;
