/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import Chip from '@material-ui/core/Chip';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteBorder';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TvIcon from '@material-ui/icons/Tv';
import Paper from '@material-ui/core/Paper';
import PhoneIcon from '@material-ui/icons/Phone';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { UserContext } from 'UserContext';

import LoadImage from 'components/LoadImage';
import TruncateText from 'components/TruncateText';
import { CLOUDINARY } from 'constants/general';

const defaultImg = `${CLOUDINARY}v1581036706/hascare/img/unknown_service.jpg`;

const useStyles = makeStyles((theme) => ({
  paper: {
    zIndex: 99,
    borderRadius: 5,
    maxWidth: 1020,
    margin: '10px 12px 15px',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    '&:hover': {
      zIndex: 99,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      marginLeft: 10,
      marginRight: 14,
    },
  },
  gridMobile: {
    width: '100%',
    margin: 0,
  },
  gridColumnContainer: {
    position: 'relative',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: '10px',
    [theme.breakpoints.up('md')]: {
      gridColumnGap: '15px',
    },
  },
  gridColumnDetails: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: '24px',
  },
  gridColumn: {
    display: 'flex',
  },
  gridStart: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
  },
  gridEnd: {
    justifyContent: 'flex-end',
  },
  gridMiddle: {
    alignItems: 'center',
  },
  gridRowContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
  },
  gridRowStart: {
    justifyContent: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridRowEnd: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  taglineContainer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  taglineItem: {
    flexGrow: '1',
  },
  spacingEnd: {
    paddingRight: 10,
  },
  imageMobile: {
    width: '100%',
    height: 'calc((100vw - 30px) * (350/600))',
    maxWidth: 600,
    maxHeight: 350,
    '& > img': {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      [theme.breakpoints.up('sm')]: {
        borderRadius: 5,
      },
    },
  },
  imageDesktop: {
    width: 232,
    height: 135.33,
    maxWidth: '36vw',
    [theme.breakpoints.up('md')]: {
      width: 257.14,
      height: 150,
    },
    '& > img': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
  },
  icon: {
    marginLeft: 2,
    marginRight: 2,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 5,
    },
  },
  favorite: {
    color: '#e53935',
  },
  venue: {
    color: theme.palette.companyPrimary.main,
  },
  spacingTopRow: {
    paddingTop: 5,
  },
  spacingTop: {
    paddingTop: 4,
  },
  noBottomSpacing: {
    paddingBottom: 0,
  },
  eventDate: {
    whiteSpace: 'nowrap',
    paddingRight: 10,
    color: theme.palette.companySecondary.dark,
    [theme.breakpoints.only('sm')]: {
      paddingRight: 5,
    },
  },
  eventName: {
    padding: '0px 10px 0 0',
  },
  eventOrganizer: {
    color: '#616161',
    padding: '0 10px 5px 0',
  },
  eventVenue: {
    color: '#616161',
    padding: '0 10px 5px 0',
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
  },

  eventPrice: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    marginRight: 10,
    width: '100%',
    maxWidth: '25vw',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '20vw',
    },
  },
  eventPriceText: {
    color: '#616161',
    padding: '0 0 5px 0',
    textAlign: 'start',
    fontFamily: theme.typography.fontFamily,
    fontWeight: '600',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'end',
    },
  },
  navLink: {
    color: theme.palette.companyPrimary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  launchIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1.1rem',
  },
  recurring: {
    height: 27,
    cursor: 'pointer',
    '& > span': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  venueDistance: {
    marginLeft: 10,
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
  },
  access: {
    borderRadius: 8,
    fontSize: 16,
    fontWeight: '800',
    height: 22,
    verticalAlign: 'super',
    alignSelf: 'center',
    [theme.breakpoints.only('xs')]: {
      marginLeft: 6,
    },
    '& > span': {
      paddingLeft: 3,
      paddingRight: 3,
    },
    [theme.breakpoints.up('sm')]: {
      height: 21,
    },
  },
}));

const eventEnded = (
  <>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
      }}
    ></div>
    <span
      style={{
        color: '#eee',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        fontSize: 20,
        fontWeight: '800',
        fontStyle: 'italic',
        position: 'absolute',
        right: -10,
        bottom: 10,
        borderRadius: 10,
        padding: '5px 15px',
      }}
    >
      Ended
    </span>
  </>
);

const SearchResult = React.forwardRef(
  (
    {
      displayName,
      city,
      distance,
      eventDate,
      eventTime,
      eventEndTime,
      recurring,
      orgName,
      id,
      img,
      blurhash,
      price,
      favoriteId,
      access,
      venue,
    },
    ref
  ) => {
    const classes = useStyles();
    const { favorites } = useContext(UserContext);

    const currTime = new Date();
    const currTimeInMins = currTime.getTime() / 60000;

    let venueLocation;
    let venueDistance;
    if (venue.includes('inperson')) {
      venueLocation = city;
      venueDistance = `${distance} miles`;
    } else if (venue.includes('online')) {
      venueLocation = 'Online Event';
    } else if (venue.includes('phone')) {
      venueLocation = 'By Phone';
    } else {
      venueLocation = '';
    }

    const isFavorite = favorites && favorites.includes(favoriteId);

    return (
      <Paper id={id} ref={ref} className={classes.paper} elevation={2}>
        <Hidden smUp>
          <Grid
            container
            spacing={1}
            direction='row'
            className={classes.gridMobile}
          >
            <Grid item xs={12} style={{ position: 'relative', padding: 0 }}>
              <LoadImage
                src={img}
                blurhash={blurhash}
                fallbackSrc={defaultImg}
                alt='Featured'
                className={classes.imageMobile}
              />
              {currTimeInMins > eventEndTime && eventEnded}
            </Grid>
            <Grid item xs={12} style={{ padding: '5px 10px' }}>
              <Typography
                variant='h6'
                color='inherit'
                className={classes.eventDate}
              >
                <strong>{eventDate}</strong>
              </Typography>
              <Typography
                variant='body1'
                color='inherit'
                className={classes.eventDate}
              >
                {eventTime}
              </Typography>
              {recurring && (
                <Chip
                  label='Recurring'
                  variant='outlined'
                  className={classes.recurring}
                />
              )}
            </Grid>
            <Grid item xs={12} style={{ padding: '5px 10px' }}>
              <Typography variant='h4' align='left' color='inherit'>
                {displayName}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: '0 10px' }}>
              <Typography
                variant='body1'
                color='inherit'
                className={classes.eventVenue}
              >
                <strong>{venueLocation}</strong>
                {venueDistance && (
                  <span className={classes.venueDistance}>
                    <em>&#8226; {venueDistance}</em>
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ height: 34, padding: '0 10px' }}>
              {isFavorite && (
                <FavoriteIcon
                  className={ClassNames(classes.icon, classes.favorite)}
                />
              )}
              {venue.includes('inperson') && (
                <LocationOnIcon
                  className={ClassNames(classes.icon, classes.venue)}
                />
              )}
              {venue.includes('online') && (
                <TvIcon className={ClassNames(classes.icon, classes.venue)} />
              )}
              {venue.includes('phone') && (
                <PhoneIcon
                  className={ClassNames(classes.icon, classes.venue)}
                />
              )}
              {access.ada && (
                <AccessibleForwardIcon
                  className={ClassNames(classes.icon, classes.venue)}
                />
              )}
              {access.asl && (
                <Chip
                  size='small'
                  label='ASL'
                  color='primary'
                  className={ClassNames(classes.icon, classes.access)}
                />
              )}
              {access.cc && (
                <Chip
                  size='small'
                  label='CC'
                  color='primary'
                  className={ClassNames(classes.icon, classes.access)}
                />
              )}
              {access.fifty && (
                <Chip
                  variant='outlined'
                  size='small'
                  label='50+'
                  color='primary'
                  className={ClassNames(classes.icon, classes.access)}
                />
              )}
              {access.fiftyfive && (
                <Chip
                  variant='outlined'
                  size='small'
                  label='55+'
                  color='primary'
                  className={ClassNames(classes.icon, classes.access)}
                />
              )}
              {access.sixty && (
                <Chip
                  variant='outlined'
                  size='small'
                  label='60+'
                  color='primary'
                  className={ClassNames(classes.icon, classes.access)}
                />
              )}
            </Grid>
            <Grid item xs={12} style={{ height: 34, padding: '0 10px' }}>
              <TruncateText
                lines={1}
                hideMore
                className={classes.eventPriceText}
              >
                {price}
              </TruncateText>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <div className={classes.gridColumnContainer}>
            <div
              className={classes.gridColumn}
              style={{ position: 'relative' }}
            >
              <LoadImage
                src={img}
                blurhash={blurhash}
                fallbackSrc={defaultImg}
                alt='Featured'
                className={classes.imageDesktop}
              />
              {currTimeInMins > eventEndTime && eventEnded}
            </div>
            <div className={classes.gridColumn}>
              <div className={classes.gridRowContainer}>
                <div
                  className={ClassNames(
                    classes.gridRowStart,
                    classes.spacingTopRow
                  )}
                >
                  <Typography
                    variant='body1'
                    color='inherit'
                    className={classes.eventDate}
                  >
                    <strong>{eventDate}</strong>
                  </Typography>
                  <Typography
                    variant='body1'
                    color='inherit'
                    className={classes.eventDate}
                  >
                    {eventTime}
                  </Typography>
                  {recurring && (
                    <Chip
                      label='Recurring'
                      variant='outlined'
                      className={classes.recurring}
                    />
                  )}
                </div>
                <div
                  className={ClassNames(classes.gridRowStart)}
                  style={{ display: 'block' }}
                >
                  <Typography
                    variant='h6'
                    align='left'
                    color='inherit'
                    className={classes.eventName}
                  >
                    {displayName}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='inherit'
                    className={classes.eventOrganizer}
                  >
                    by {orgName}
                  </Typography>
                </div>
                <div
                  className={ClassNames(classes.gridRowStart)}
                  style={{ display: 'block' }}
                >
                  <Typography
                    variant='body1'
                    color='inherit'
                    className={classes.eventVenue}
                  >
                    <strong>{venueLocation}</strong>
                    {venueDistance && (
                      <span className={classes.venueDistance}>
                        <em>&#8226; {venueDistance}</em>
                      </span>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={ClassNames(classes.gridColumn, classes.spacingEnd)}>
              <div className={classes.gridRowContainer}>
                <div
                  className={ClassNames(
                    classes.gridRowEnd,
                    classes.spacingTopRow
                  )}
                >
                  {isFavorite ? (
                    <FavoriteIcon
                      className={ClassNames(classes.icon, classes.favorite)}
                    />
                  ) : (
                    <FavoriteOutlinedIcon className={classes.icon} />
                  )}
                </div>
                <div
                  className={ClassNames(classes.gridRowEnd, classes.spacingTop)}
                  style={{ alignItems: 'flex-end' }}
                >
                  {venue.includes('inperson') ? (
                    <Tooltip title='In person event' placement='top'>
                      <LocationOnIcon
                        className={ClassNames(classes.icon, classes.venue)}
                      />
                    </Tooltip>
                  ) : null}
                  {venue.includes('online') ? (
                    <Tooltip title='Online event' placement='top'>
                      <TvIcon
                        className={ClassNames(classes.icon, classes.venue)}
                      />
                    </Tooltip>
                  ) : null}
                  {venue.includes('phone') ? (
                    <Tooltip title='Telephone event' placement='top'>
                      <PhoneIcon
                        className={ClassNames(classes.icon, classes.venue)}
                      />
                    </Tooltip>
                  ) : null}
                  {access.ada ? (
                    <Tooltip title='Wheelchair accessible' placement='top'>
                      <AccessibleForwardIcon
                        className={ClassNames(classes.icon, classes.venue)}
                      />
                    </Tooltip>
                  ) : null}
                </div>
                <div
                  className={ClassNames(classes.gridRowEnd, classes.spacingTop)}
                >
                  {access.asl ? (
                    <Tooltip title='ASL available' placement='top'>
                      <Chip
                        size='small'
                        label='ASL'
                        color='primary'
                        className={ClassNames(classes.icon, classes.access)}
                      />
                    </Tooltip>
                  ) : null}
                  {access.cc ? (
                    <Tooltip title='Closed Captioning' placement='top'>
                      <Chip
                        size='small'
                        label='CC'
                        color='primary'
                        className={ClassNames(classes.icon, classes.access)}
                      />
                    </Tooltip>
                  ) : null}
                  {access.fifty ? (
                    <Tooltip title='Age 50+ only' placement='top'>
                      <Chip
                        variant='outlined'
                        size='small'
                        label='50+'
                        color='primary'
                        className={ClassNames(classes.icon, classes.access)}
                      />
                    </Tooltip>
                  ) : null}
                  {access.fiftyfive ? (
                    <Tooltip title='Age 55+ only' placement='top'>
                      <Chip
                        variant='outlined'
                        size='small'
                        label='55+'
                        color='primary'
                        className={ClassNames(classes.icon, classes.access)}
                      />
                    </Tooltip>
                  ) : null}
                  {access.sixty ? (
                    <Tooltip title='Age 60+ only' placement='top'>
                      <Chip
                        variant='outlined'
                        size='small'
                        label='60+'
                        color='primary'
                        className={ClassNames(classes.icon, classes.access)}
                      />
                    </Tooltip>
                  ) : null}
                </div>
                <div
                  className={ClassNames(classes.gridRowEnd, classes.spacingTop)}
                  style={{ height: 30 }}
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div className={classes.eventPrice}>
              <TruncateText
                lines={1}
                hideMore
                className={classes.eventPriceText}
              >
                {price}
              </TruncateText>
            </div>
          </div>
        </Hidden>
      </Paper>
    );
  }
);

SearchResult.propTypes = {
  displayName: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired,
  eventTime: PropTypes.string.isRequired,
  eventEndTime: PropTypes.number.isRequired,
  eid: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  access: PropTypes.instanceOf(Object),
  blurhash: PropTypes.string,
  city: PropTypes.string,
  distance: PropTypes.string,
  favoriteId: PropTypes.string,
  id: PropTypes.string,
  recurring: PropTypes.bool,
  venue: PropTypes.instanceOf(Object),
};

SearchResult.defaultProps = {
  id: '',
  blurhash: '',
  city: '',
  distance: '',
  favoriteId: '',
  access: {
    fifty: false,
    ada: false,
    asl: false,
    cc: false,
  },
  recurring: true,
  venue: {},
};

export default SearchResult;
