/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { CLOUDINARY, MAX_PAGE_WIDTH } from 'constants/general';
import { EVENT_ORGANIZERS_URI } from 'constants/routes';

const oneStopShopSvg = `${CLOUDINARY}/v1621967336/sona/svg/search.location.svg`;
const easyToUseSvg = `${CLOUDINARY}/v1621967336/sona/svg/snap.fingers.svg`;
const liveOnlineSvg = `${CLOUDINARY}/v1621967336/sona/svg/live.online.svg`;
const telephoneSvg = `${CLOUDINARY}/v1621967336/sona/svg/telephone.svg`;
const addEventSvg = `${CLOUDINARY}/v1621967336/sona/svg/add-event.green.svg`;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    maxWidth: 1280,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'visible',
    padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(3)}`,
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(4)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `0 ${theme.spacing(6)} ${theme.spacing(6)}`,
    },
  },
  gridColumnContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '50px',
    padding: '0 20px',
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      gridGap: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      gridGap: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'unset',
      gridColumnGap: 'unset',
      gridTemplateRows: 'repeat(3, auto)',
    },
    [theme.breakpoints.only('sm')]: {
      padding: 0,
    },
  },
  gridColumn: {
    justifyContent: 'center',
    display: 'flex',
    height: 'fit-content',
  },
  gridRowContainer: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    maxWidth: 476,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 576,
    },
  },
  gridRow: {
    justifyContent: 'center',
    display: 'flex',
  },
  gridColumnContainer2: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '20px',
    padding: '50px 20px 0',
    maxWidth: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'unset',
      gridGap: 'unset',
      gridTemplateRows: 'repeat(2, auto)',
    },
  },
  button: {
    borderRadius: 35,
    textTransform: 'inherit',
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    width: 250,
    height: 47,
    marginTop: 10,
  },
  text: {
    maxWidth: 300,
  },
  addYourEvent: {
    maxWidth: 470,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingTop: 15,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'unset',
    },
  },
  addYourEventIcon: {
    position: 'absolute',
    top: -5,
    left: -90,
  },
  title: {
    paddingBottom: 20,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h1.fontSize,
      lineHeight: theme.typography.h1.lineHeight,
    },
  },
  oneStopImg: {
    width: 100,
    height: 100,
    margin: 40,
    [theme.breakpoints.only('sm')]: {
      width: 100 * 0.75,
      height: 100 * 0.75,
    },
  },
  easyToUseImg: {
    width: 100,
    height: 100,
    margin: 40,
    [theme.breakpoints.only('sm')]: {
      width: 100 * 0.75,
      height: 100 * 0.75,
    },
  },
  liveOnlineImg: {
    width: 114,
    height: 114,
    margin: '41px 5px 25px 40px',
    [theme.breakpoints.only('sm')]: {
      width: 114 * 0.75,
      height: 114 * 0.75,
      margin: '41px 5px 28px 20px',
    },
  },
  phoneImg: {
    width: 58,
    height: 58,
    margin: '72px 40px 50px 5px',
    [theme.breakpoints.only('sm')]: {
      width: 58 * 0.75,
      height: 58 * 0.75,
      margin: '72px 20px 40px 5px',
    },
  },
}));

const AboutUsSummary = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();

  return (
    <div id={id} ref={ref} className={classes.root}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            maxWidth: 1080,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography variant='h5' align='center' className={classes.title}>
            <strong>
              Finding life-enriching experiences for older adults has never been
              easier.
            </strong>
          </Typography>
          <Typography
            variant='body1'
            align='left'
            style={{
              paddingBottom: 5,
            }}
          >
            A Mighty Good Time is a curated calendar of events for active aging
            adults. Featuring in-person, virtual, and phone-accessible
            activities, our mission is to make it easier to find, create, and
            promote experiences that help us stay social, active, and engaged as
            we age.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.gridColumnContainer}>
            <div className={classes.gridColumn}>
              <div className={classes.gridRowContainer}>
                <div className={ClassNames(classes.gridRow, classes.images)}>
                  <img
                    src={oneStopShopSvg}
                    alt='one stop shop'
                    className={classes.oneStopImg}
                  />
                </div>
                <div className={classes.gridRow}>
                  <Typography
                    variant='body1'
                    className={classes.text}
                    align='left'
                    color='inherit'
                  >
                    One-stop-shop: browse through a variety of events from a
                    wide range of organizers.
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.gridColumn}>
              <div className={classes.gridRowContainer}>
                <div className={ClassNames(classes.gridRow, classes.images)}>
                  <img
                    src={easyToUseSvg}
                    alt='easy to use'
                    className={classes.easyToUseImg}
                  />
                </div>
                <div className={classes.gridRow}>
                  <Typography
                    variant='body1'
                    className={classes.text}
                    align='left'
                    color='inherit'
                  >
                    Quick and easy search tool to find just what you're looking
                    for.
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.gridColumn}>
              <div className={classes.gridRowContainer}>
                <div className={ClassNames(classes.gridRow, classes.images)}>
                  <img
                    src={liveOnlineSvg}
                    alt='online'
                    className={classes.liveOnlineImg}
                  />
                  <img
                    src={telephoneSvg}
                    alt='telephone'
                    className={classes.phoneImg}
                  />
                </div>
                <div className={classes.gridRow}>
                  <Typography
                    variant='body1'
                    className={classes.text}
                    align='left'
                    color='inherit'
                  >
                    Experiences that are accessible to everyone: in-person,
                    virtual and{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>by-phone.</span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 50 }}>
          <div className={classes.addYourEvent}>
            <div>
              <Typography
                variant='h6'
                align='center'
                style={{ position: 'relative', paddingBottom: 10 }}
              >
                <div
                  style={{
                    position: 'relative',
                    width: 'fit-content',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <strong>
                    Do you have an event you want to promote to the{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>50+ community?</span>
                  </strong>
                  <Hidden smDown>
                    <div className={classes.addYourEventIcon}>
                      <img
                        src={addEventSvg}
                        alt='add an event'
                        style={{
                          width: 80,
                          height: 80,
                        }}
                      />
                    </div>
                  </Hidden>
                </div>
              </Typography>
            </div>
            <div>
              <Typography
                variant='h6'
                align='center'
                style={{ paddingBottom: 10 }}
              >
                It&#39;s free to add it to our calendar!
              </Typography>
            </div>
            <Hidden mdUp>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={addEventSvg}
                  alt='add an event'
                  style={{
                    width: 80,
                    height: 80,
                    margin: 10,
                  }}
                />
              </div>
            </Hidden>
            <div style={{ textAlign: 'center' }}>
              <Button
                component={Link}
                to={{
                  pathname: EVENT_ORGANIZERS_URI,
                }}
                variant='contained'
                color='secondary'
                className={classes.button}
              >
                Learn More
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

AboutUsSummary.propTypes = {
  id: PropTypes.string,
};

AboutUsSummary.defaultProps = {
  id: '',
};

export default AboutUsSummary;
