/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import LoadAvatar from 'components/LoadAvatar';
import { ACCOUNT_URI, CONTACTUS_URI, LANDING_URI } from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    padding: '0.9375rem 0.9375rem 4px',
  },
  subMenuText: {
    fontSize: 14,
    fontWeight: '700',
    color: '#333435',
    '&:hover': {
      color: theme.palette.companyPrimary.main,
    },
  },
  desktopIcon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  expandIcon: {
    width: 26,
    height: 26,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: -4,
  },
  navLink: {
    backgroundColor: 'transparent',
    color: '#333435',
    position: 'relative',
    fontSize: 14,
    textTransform: 'uppercase',
    lineHeight: '1.6rem',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: theme.palette.companyPrimary.main,
    },
  },
  popper: {
    textTransform: 'initial',
    borderTop: `solid 4px ${theme.palette.companyPrimary.main}`,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: -4,
    boxShadow:
      '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
  },
}));

const AccountMenu = React.forwardRef(
  ({ id, className, imgSrc, thumbSrc, initials, onLogout }, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };

    const handleListKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    };

    const handleLogout = (event) => {
      handleClose(event);
      onLogout();
    };

    return (
      <div id={id} ref={ref} className={className} style={{ padding: 0 }}>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          className={ClassNames(classes.button, classes.navLink)}
          disableRipple
        >
          <LoadAvatar
            src={thumbSrc}
            fallbackSrc={imgSrc}
            initials={initials}
            size='sm'
          />
          <ExpandMoreIcon className={classes.expandIcon} />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement='bottom'
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={classes.popper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      component={Link}
                      classes={{
                        root: classes.subMenuText,
                      }}
                      to={{
                        pathname: ACCOUNT_URI,
                      }}
                      onClick={handleClose}
                    >
                      MY ACCOUNT
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      classes={{
                        root: classes.subMenuText,
                      }}
                      to={{
                        pathname: CONTACTUS_URI,
                      }}
                      onClick={handleClose}
                    >
                      HELP
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      classes={{
                        root: classes.subMenuText,
                      }}
                      to={{
                        pathname: LANDING_URI,
                      }}
                      onClick={handleLogout}
                    >
                      LOGOUT
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
);

AccountMenu.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  imgSrc: PropTypes.string,
  initials: PropTypes.string,
  onLogout: PropTypes.func,
  thumbSrc: PropTypes.string,
};

AccountMenu.defaultProps = {
  className: '',
  id: '',
  imgSrc: null,
  initials: '',
  onLogout: null,
  thumbSrc: null,
};

export default AccountMenu;
