/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Helmet } from 'react-helmet';
import { animateScroll as scroll } from 'react-scroll';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { UserContext } from 'UserContext';

import ContactForm from 'components/ContactForm';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import NamedDivider from 'components/NamedDivider';
import SubscribeBanner from 'components/SubscribeBanner';
import {
  CLOUDINARY,
  EMAIL,
  PARTNERS_EMAIL,
  MARKETING_EMAIL,
  PHONE,
  SITEURL,
  MAX_PAGE_WIDTH,
} from 'constants/general';

const logoSvg = `${CLOUDINARY}/sona/svg/amgt_logo_sm_v1.svg`;
const logoIcon = (
  <img src={logoSvg} alt='A Mighty Good Time logo small' width={60} />
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
  },
  content: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    },
  },
  contactUs: {
    padding: `0 ${theme.spacing(1)} ${theme.spacing(3)}`,
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(3)} 0`,
    },
  },
  link: {
    color: theme.palette.companyPrimary.dark,
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  paper: {
    padding: 15,
    [theme.breakpoints.up('sm')]: {
      padding: 30,
    },
  },
  sendMessage: {
    paddingBottom: theme.spacing(1),
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  gridColumnContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '50px',
    padding: '0 20px',
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'unset',
      gridColumnGap: 'unset',
      gridTemplateRows: 'repeat(2, auto)',
    },
  },
  gridColumn: {
    justifyContent: 'center',
    display: 'flex',
    height: 'fit-content',
  },
  email: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    textTransform: 'none',
    color: theme.palette.companyPrimary.main,
    justifyContent: 'flex-start',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px',
      marginRight: 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
  },
  icon: {
    width: '1.5em',
    height: '1.5em',
    paddingRight: theme.spacing(1),
    color: theme.palette.companySecondary.main,
  },
}));

const ContractUs = React.forwardRef(({ id, location }, ref) => {
  const classes = useStyles();
  const { uid, firstName, lastName, email } = useContext(UserContext);
  const displayName = `${firstName ? firstName : ''} ${
    lastName ? lastName : ''
  }`.trim();

  const scrollToTop = (duration) =>
    scroll.scrollToTop({
      duration,
      delay: 0,
      smooth: true,
    });

  useEffect(() => {
    scrollToTop(0);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Questions about older adult classes- senior activities: A Mighty Good Time
        </title>
        <link rel='canonical' href={SITEURL.concat('/contractus')} />
        <meta
          name='description'
          content='Provide feedback and offer suggestions on how we can help bring joy to older adults near you.'
        />
      </Helmet>
      <main id={id} ref={ref} className={classes.root}>
        <Header />
        <SubscribeBanner />
        <div className={ClassNames(classes.content, 'page-transition fade-in')}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h4' align='center'>
                <strong>Contact Us</strong>
              </Typography>
              <NamedDivider
                color='primary'
                center
                showIcon
                icon={logoIcon}
                style={{
                  maxWidth: 500,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: 25,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.contactUs}>
              <Typography
                variant='h2'
                color='primary'
                align='left'
                style={{ paddingBottom: 20 }}
              >
                <strong>Let&#39;s connect!</strong>
              </Typography>
              <Typography
                variant='h5'
                align='left'
                style={{ paddingBottom: 15, fontWeight: '400' }}
              >
                We&#39;d love to start a conversation.
              </Typography>
              <Typography
                variant='h6'
                align='left'
                style={{ paddingBottom: 10 }}
              >
                General Questions and Support
              </Typography>
              <Button
                component='a'
                href={`mailto:${EMAIL}`}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.email}
                color='inherit'
                justify='flex-start'
                fullWidth
              >
                <EmailIcon className={classes.icon} />
                {EMAIL}
              </Button>
              <Button
                component='a'
                href={`tel:+1${PHONE}`}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.email}
                color='inherit'
                justify='flex-start'
                fullWidth
              >
                <PhoneIcon className={classes.icon} />
                {PHONE.replace(/^(\d{3})(\d{3})(\d{4})$/g, '+1 ($1) $2-$3')}
              </Button>
              <Typography
                variant='body1'
                color='inherit'
                align='left'
                style={{ paddingTop: 20 }}
              >
                <strong>
                  Event Management, API and White Label partnership inquiries:
                </strong>
              </Typography>
              <Button
                component='a'
                href={`mailto:${PARTNERS_EMAIL}`}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.email}
                color='inherit'
                justify='flex-start'
                fullWidth
              >
                {PARTNERS_EMAIL}
              </Button>
              <Typography
                variant='body1'
                color='inherit'
                align='left'
                style={{ paddingTop: 20 }}
              >
                <strong>Press and media inquiries:</strong>
              </Typography>
              <Button
                component='a'
                href={`mailto:${MARKETING_EMAIL}`}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.email}
                color='inherit'
                justify='flex-start'
                fullWidth
              >
                {MARKETING_EMAIL}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} className={classes.sendMessage}>
              <Paper className={classes.paper} elevation={6}>
                <ContactForm
                  uid={uid}
                  initName={displayName || ''}
                  initEmail={email || ''}
                  initMessage={
                    location.state && location.state.message
                      ? location.state.message
                      : ''
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </main>
    </>
  );
});

ContractUs.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
};

ContractUs.defaultProps = {
  id: '',
};

export default ContractUs;
