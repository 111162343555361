/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useContext, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './page-transitions.css';

import { UserContext, UserConsumer } from 'UserContext';
import * as routes from 'constants/routes';
import { SEARCH_CACHE_KEY } from 'constants/general';
import ScrollToTop from './ScrollToTop';
import ErrorBoundary from './ErrorBoundary';

import AboutUsPage from 'views/pages/AboutUs';
import ContactUsPage from 'views/pages/ContactUs';
import HowItWorksPage from 'views/pages/HowItWorks';
import EventOrganizersPage from 'views/pages/EventOrganizers';
import EventPage from 'views/events/ViewEvent';
import LandingPage from 'views/pages/Landing';
import SearchPage from 'views/pages/Search';
import LoginPage from 'views/pages/Login';
import SplashPage from 'views/pages/Splash';
// Public Lazy Loaded Pages
const ForgotPage = lazy(() => import('views/pages/Forgot'));
const NotFoundPage = lazy(() => import('views/pages/NotFound'));
const SignUpPage = lazy(() => import('views/pages/SignUp'));
const PrivacyPage = lazy(() => import('views/pages/Privacy'));
const TosPage = lazy(() => import('views/pages/Tos'));
const ThankYouPage = lazy(() => import('views/pages/ThankYou'));
const InPersonNeedsPage = lazy(() => import('views/pages/InPersonNeeds'));
const PreferencesPage = lazy(() => import('views/pages/Unsubscribe'));
const PluginsPage = lazy(() => import('views/pages/Plugins'));
// Secure Lazy Loaded Pages
const Dashboard = lazy(() => import('layouts/Dashboard'));

const PrivateRoute = ({ render: Component, location, ...rest }) => {
  const { init } = useContext(UserContext);

  if (init) {
    return <SplashPage />;
  }
  return (
    <Route
      {...rest}
      location={location}
      render={(props) => (
        <UserConsumer>
          {({ isAuth }) =>
            isAuth ? (
              <ErrorBoundary>
                <Component {...props} />
              </ErrorBoundary>
            ) : (
              <Redirect
                to={{
                  pathname: routes.LOGIN_URI,
                  state: {
                    ...location.state,
                    from: location.pathname,
                    search: location.search,
                  },
                }}
              />
            )
          }
        </UserConsumer>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  render: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
};

PrivateRoute.defaultProps = {
  location: { pathname: '/', state: {} },
};

const PublicRoute = ({ render: Component, location, ...rest }) => {
  return (
    <ErrorBoundary>
      <Route {...rest} location={location} render={Component} />
    </ErrorBoundary>
  );
};

PublicRoute.propTypes = {
  render: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
};

PublicRoute.defaultProps = {
  location: { pathname: '/', state: {} },
};

const PublicRedirect = ({ from, ...rest }) => {
  return <Redirect from={from} {...rest} />;
};

PublicRedirect.propTypes = {
  from: PropTypes.string,
};

PublicRedirect.defaultProps = {
  from: '/',
};

const whenToCache = ({ location }) => {
  if (location.pathname.startsWith('/event/')) {
    return true;
  }
  return false;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<SplashPage />}>
          <CacheSwitch>
            <Route
              path='/brain_games_signup'
              component={() => {
                window.location.href =
                  'https://mailchi.mp/171d89ed3d74/stay-smart-brain-games-sign-up';
                return null;
              }}
            />
            <PublicRoute
              exact
              path={routes.LANDING_URI}
              render={(props) => (
                <ScrollToTop>
                  <LandingPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.SIGNUP_URI}
              render={(props) => (
                <ScrollToTop>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
                  >
                    <SignUpPage {...props} />
                  </GoogleReCaptchaProvider>
                </ScrollToTop>
              )}
            />
            <CacheRoute
              cacheKey={SEARCH_CACHE_KEY}
              when={whenToCache}
              path={routes.SEARCH_URI}
              render={(props) => (
                <ErrorBoundary>
                  <SearchPage {...props} />
                </ErrorBoundary>
              )}
            />
            <PublicRoute
              path={routes.LOGIN_URI}
              render={(props) => (
                <ScrollToTop>
                  <LoginPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              path={routes.EVENT_URI}
              render={(props) => <EventPage {...props} />}
            />
            <PublicRoute
              exact
              path={routes.ABOUTUS_URI}
              render={(props) => (
                <ScrollToTop>
                  <AboutUsPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.CONTACTUS_URI}
              render={(props) => (
                <ScrollToTop>
                  <ContactUsPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.HOWITWORKS_URI}
              render={(props) => (
                <ScrollToTop>
                  <HowItWorksPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.EVENT_ORGANIZERS_URI}
              render={(props) => (
                <ScrollToTop>
                  <EventOrganizersPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.FORGOT_URI}
              render={(props) => (
                <ScrollToTop>
                  <ForgotPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.TOS_URI}
              render={(props) => (
                <ScrollToTop>
                  <TosPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.PRIVACY_URI}
              render={(props) => (
                <ScrollToTop>
                  <PrivacyPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.THANKYOU_URI}
              render={(props) => (
                <ScrollToTop>
                  <ThankYouPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.PREFERENCES_URI}
              render={(props) => (
                <ScrollToTop>
                  <PreferencesPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.INPERSONNEEDS_URI}
              render={(props) => (
                <ScrollToTop>
                  <InPersonNeedsPage {...props} />
                </ScrollToTop>
              )}
            />
            <PublicRoute
              exact
              path={routes.PLUGINS_URI}
              render={(props) => (
                <ScrollToTop>
                  <PluginsPage {...props} />
                </ScrollToTop>
              )}
            />

            <PrivateRoute
              path={routes.DASHBOARD_URI}
              render={(props) => (
                <ScrollToTop>
                  <Dashboard {...props} />
                </ScrollToTop>
              )}
            />

            <Redirect to={routes.LANDING_URI} />

            <PublicRoute render={(props) => <NotFoundPage {...props} />} />
          </CacheSwitch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
