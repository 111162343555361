/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = {
  itemLink: {
    textDecoration: 'none',
    transition: 'all 300ms linear',
    position: 'relative',
    display: 'block',
    backgroundColor: 'transparent',
    fontFamily: '"Nunito Sans", sans-serif',
    fontWeight: '300',
    lineHeight: '1.5em',
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.5)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  subItem: {
    marginLeft: 40,
    width: 'calc(100% - 40px)',
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
  },
  activeSubLink: {
    '&,&:hover,&:focus': {
      backgroundColor: 'rgba(156, 39, 176, 0.2)',
    },
  },
  lastSubLink: {
    '&,&:hover,&:focus': {
      boxShadow:
        '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 5px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
    },
  },
  activeLink: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: '#f57c00',
      boxShadow:
        '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
    },
  },
  itemIcon: {
    color: 'inherit',
    minWidth: 'unset',
    width: '30px',
    height: '24px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    '& svg': {
      fontSize: '1.5rem',
    },
  },
  itemText: {
    color: 'inherit',
    fontFamily: '"Nunito Sans", sans-serif',
    fontWeight: '300',
    lineHeight: '1.5em',
    margin: '0',
    // lineHeight: '30px',
    fontSize: '20px',
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
  },
};

const LinkType = withStyles(styles)(
  ({ classes, children, href, to, isGroupActive, isLastGroupItem }) =>
    href ? (
      <a
        href={href}
        className={ClassNames(classes.itemLink, {
          [classes.activeSubLink]: isGroupActive,
          [classes.lastSubLink]: isGroupActive && isLastGroupItem,
        })}
      >
        {children}
      </a>
    ) : (
      <NavLink
        to={to}
        className={ClassNames(classes.itemLink, {
          [classes.activeSubLink]: isGroupActive,
          [classes.lastSubLink]: isGroupActive && isLastGroupItem,
        })}
      >
        {children}
      </NavLink>
    )
);

const MenuItemLink = ({
  classes,
  href,
  isActive,
  isGroupActive,
  isLastGroupItem,
  icon,
  label,
  onClick,
  subItem,
  to,
}) => (
  <LinkType
    href={href}
    to={to}
    isGroupActive={isGroupActive}
    isLastGroupItem={isLastGroupItem}
  >
    <ListItem
      button
      className={ClassNames({
        [classes.activeLink]: isActive,
        [classes.subItem]: subItem,
      })}
      onClick={onClick}
    >
      {icon && <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>}
      <ListItemText
        primary={label}
        disableTypography
        className={classes.itemText}
      />
    </ListItem>
  </LinkType>
);

MenuItemLink.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.node,
  isGroupActive: PropTypes.bool,
  isLastGroupItem: PropTypes.bool,
  onClick: PropTypes.instanceOf(Object),
  subItem: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

MenuItemLink.defaultProps = {
  href: null,
  icon: null,
  isGroupActive: false,
  isLastGroupItem: false,
  onClick: null,
  subItem: false,
  to: null,
};

export default withStyles(styles)(MenuItemLink);
