/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  gridContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'auto auto 1fr',
  },
  noSideMargins: {
    width: 'calc(100% + 20px)',
    marginLeft: -10,
  },
  gridCenterContainer: {
    gridTemplateColumns: '1fr auto 1fr',
  },
  gridColumn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100% !important',
  },
  font: {
    fontFamily: theme.typography.fontFamily,
  },
  fontLarge: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
  },
  fontMedium: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  fontSmall: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },
  fontBold: {
    fontWeight: 800,
  },
  divider: {
    borderStyle: 'none',
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    width: 15,
  },
  dividerSM: {
    width: 20,
  },
  dividerMd: {
    width: 30,
  },
  dividerLg: {
    width: 50,
  },
  colorTextDefault: {
    color: '#757575',
  },
  colorDefault: {
    color: '#e0e0e0',
    fill: '#e0e0e0',
  },
  backgroundColorDefault: {
    backgroundColor: '#e0e0e0',
  },
  colorPrimary: {
    color: theme.palette.companyPrimary.main,
    fill: theme.palette.companyPrimary.main,
    boxShadow: 'white 0px 0px 10px 1px',
  },
  backgroundColorPrimary: {
    backgroundColor: theme.palette.companyPrimary.main,
  },
  colorSecondary: {
    color: theme.palette.companySecondary.main,
    fill: theme.palette.companySecondary.main,
    boxShadow: 'white 0px 0px 10px 1px',
  },
  backgroundColorSecondary: {
    backgroundColor: theme.palette.companySecondary.main,
  },
  colorWhite: {
    color: theme.palette.common.white,
    fill: theme.palette.common.white,
  },
  backgroundColorWhite: {
    backgroundColor: theme.palette.common.white,
  },
  colorTextTertiary: {
    color: theme.palette.companyTertiary.dark,
  },
  colorTertiary: {
    color: theme.palette.companyTertiary.main,
    fill: theme.palette.companyTertiary.main,
  },
  backgroundColorTertiary: {
    backgroundColor: theme.palette.companyTertiary.main,
  },
});

const NamedDivider = ({
  bold,
  center,
  className,
  classes,
  color,
  noSideMargins,
  icon,
  lineWidth,
  name,
  showIcon,
  size,
  ...other
}) => (
  <div
    {...other}
    className={ClassNames(
      classes.gridContainer,
      {
        [classes.gridCenterContainer]: center,
        [classes.noSideMargins]: noSideMargins,
      },
      className
    )}
  >
    <div className={classes.gridColumn}>
      <hr
        size={lineWidth}
        className={ClassNames(classes.divider, {
          [classes.dividerSm]: size === 'sm',
          [classes.dividerMd]: size === 'md',
          [classes.dividerLg]: size === 'lg',
          [classes.colorDefault]: color === 'default',
          [classes.backgroundColorDefault]: color === 'default',
          [classes.colorPrimary]: color === 'primary',
          [classes.backgroundColorPrimary]: color === 'primary',
          [classes.colorSecondary]: color === 'secondary',
          [classes.backgroundColorSecondary]: color === 'secondary',
          [classes.colorWhite]: color === 'white',
          [classes.backgroundColorWhite]: color === 'white',
          [classes.colorTertiary]: color === 'tertiary',
          [classes.backgroundColorTertiary]: color === 'tertiary',
          [classes.fullWidth]: center,
        })}
      />
    </div>
    <div className={classes.gridColumn}>
      {showIcon ? (
        icon || (
          <svg
            width='60'
            height='27.42'
            viewBox='0 0 140 64'
            xmlns='https://www.w3.org/2000/svg'
            className={ClassNames({
              [classes.colorDefault]: color === 'default',
              [classes.colorPrimary]: color === 'primary',
              [classes.colorSecondary]: color === 'secondary',
              [classes.colorWhite]: color === 'white',
              [classes.colorTertiary]: color === 'tertiary',
            })}
          >
            <path
              style={{ opacity: 0.5 }}
              d='M30.262 57.02L7.195 40.723c-5.84-3.976-7.56-12.06-3.842-18.063 3.715-6 11.467-7.65 17.306-3.68l4.52 3.76 2.6-5.274c3.717-6.002 11.47-7.65 17.305-3.68 5.84 3.97 7.56 12.054 3.842 18.062L34.49 56.118c-.897 1.512-2.793 1.915-4.228.9z'
            />
            <path
              style={{ opacity: 0.5 }}
              d='M105.512 56.12l-14.44-24.272c-3.716-6.008-1.996-14.093 3.843-18.062 5.835-3.97 13.588-2.322 17.306 3.68l2.6 5.274 4.52-3.76c5.84-3.97 13.592-2.32 17.307 3.68 3.718 6.003 1.998 14.088-3.842 18.064L109.74 57.02c-1.434 1.014-3.33.61-4.228-.9z'
            />
            <path
              style={{ opacity: 0.8 }}
              d='M67.408 57.834l-23.01-24.98c-5.864-6.15-5.864-16.108 0-22.248 5.86-6.14 15.37-6.14 21.234 0L70 16.168l4.368-5.562c5.863-6.14 15.375-6.14 21.235 0 5.863 6.14 5.863 16.098 0 22.247l-23.007 24.98c-1.43 1.556-3.757 1.556-5.188 0z'
            />
          </svg>
        )
      ) : (
        <Typography
          variant='caption'
          display='block'
          align='center'
          className={ClassNames(classes.text, {
            [classes.fontSmall]: size === 'sm',
            [classes.fontMedium]: size === 'md',
            [classes.fontLarge]: size === 'lg',
            [classes.fontBold]: bold,
            [classes.colorTextDefault]: color === 'default',
            [classes.colorPrimary]: color === 'primary',
            [classes.colorSecondary]: color === 'secondary',
            [classes.colorWhite]: color === 'white',
            [classes.colorTextTertiary]: color === 'tertiary',
          })}
        >
          {name}
        </Typography>
      )}
    </div>
    <div className={classes.gridColumn}>
      <hr
        size={lineWidth}
        className={ClassNames(classes.fullWidth, classes.divider, {
          [classes.colorDefault]: color === 'default',
          [classes.backgroundColorDefault]: color === 'default',
          [classes.colorPrimary]: color === 'primary',
          [classes.backgroundColorPrimary]: color === 'primary',
          [classes.colorSecondary]: color === 'secondary',
          [classes.backgroundColorSecondary]: color === 'secondary',
          [classes.colorWhite]: color === 'white',
          [classes.backgroundColorWhite]: color === 'white',
          [classes.colorTertiary]: color === 'tertiary',
          [classes.backgroundColorTertiary]: color === 'tertiary',
        })}
      />
    </div>
  </div>
);

NamedDivider.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  bold: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'white',
    'primary',
    'secondary',
    'tertiary',
  ]),
  noSideMargins: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  lineWidth: PropTypes.number,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  showIcon: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

NamedDivider.defaultProps = {
  bold: false,
  center: false,
  className: '',
  color: 'default',
  noSideMargins: false,
  icon: null,
  lineWidth: 4,
  name: '',
  showIcon: false,
  size: 'lg',
};

export default withStyles(styles)(NamedDivider);
