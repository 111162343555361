/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import { makeStyles } from '@material-ui/core/styles';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Drawer from '@material-ui/core/SwipeableDrawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GroupIcon from '@material-ui/icons/Group';
import Grow from '@material-ui/core/Grow';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import PhoneIcon from '@material-ui/icons/Phone';
import Popper from '@material-ui/core/Popper';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import SvgIcon from '@material-ui/core/SvgIcon';
import Toolbar from '@material-ui/core/Toolbar';

import { UserContext } from 'UserContext';

import { logAnalyticsEvent } from 'vendors/firebase/main';
import { doGetAppStats } from 'vendors/firebase/firestore';
import AccountMenu from 'components/AccountMenu';
import Logo from 'components/Logo';
import MenuItemLink from 'components/MenuItemLink';
import {
  ABOUTUS_URI,
  EVENTS_URI,
  FAVORITES_URI,
  LANDING_URI,
  SEARCH_URI,
  EVENT_ORGANIZERS_URI,
  CONTACTUS_URI,
  HOWITWORKS_URI,
  LOGIN_URI,
  PRIVACY_URI,
} from 'constants/routes';
import { MODE } from 'constants/general';

const DashboardMenu = lazy(() => import('components/Dashboard/DashboardMenu'));

const MAX_PAGE_WIDTH = 1280;
const HEADER_HEIGHT_MOBILE = 60;
const HEADER_HEIGHT = 90;

const useStyles = makeStyles((theme) => ({
  root: {
    left: 0,
    color: 'black',
    background: '#fafafa',
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 1,
    minHeight: HEADER_HEIGHT_MOBILE,
    [theme.breakpoints.up('sm')]: {
      minHeight: HEADER_HEIGHT,
    },
    maxHeight: HEADER_HEIGHT,
  },
  headerControls: {
    height: HEADER_HEIGHT_MOBILE,
    [theme.breakpoints.up('sm')]: {
      height: HEADER_HEIGHT,
    },
    [theme.breakpoints.up('md')]: {
      width: 516,
    },
    display: 'inline-flex',
    float: 'right',
  },
  headerControlsWithAuth: {
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  drawer: {
    width: 225,
  },
  listItem: {
    height: HEADER_HEIGHT,
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'flex',
    width: 'fit-content',
    margin: 0,
    padding: 0,
    // height: 50.28,
    alignItems: 'flex-end',
  },
  leadingSpace: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
  },
  trailingSpace: {
    marginRight: theme.spacing(0.8),
  },
  menuBtn: {
    marginTop: 5,
  },
  menuStackBtn: {
    textAlign: 'center',
  },
  activeHeader: {
    color: `${theme.palette.companySecondary.dark} !important`,
  },
  activeHeaderMarker: {
    borderBottom: 'solid 3px',
  },
  navLink: {
    backgroundColor: 'transparent',
    color: '#333435',
    position: 'relative',
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    fontWeight: '700',
    textTransform: 'uppercase',
    textDecoration: 'none',
    display: 'inline-flex',
    // borderRadius: 50,
    '&:hover,&:focus': {
      color: theme.palette.companyPrimary.main,
    },
  },
  subMenuText: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    fontWeight: '700',
    color: '#333435',
    '&:hover': {
      color: theme.palette.companyPrimary.main,
    },
  },
  buttonSize: {
    width: 'fit-content',
    height: HEADER_HEIGHT,
    padding: '0.8rem 0.9rem',
    display: 'block',
  },
  outlinedLink: {
    borderColor: theme.palette.companyPrimary.dark,
    color: theme.palette.companyPrimary.dark,
    borderStyle: 'solid',
    borderWidth: '1px',
    width: HEADER_HEIGHT,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.companyPrimary.main,
    },
  },
  actionLink: {
    background: `linear-gradient(45deg, ${theme.palette.companyPrimary.main} 30%, ${theme.palette.companyPrimary.light} 90%)`,
    color: theme.palette.common.white,
    boxShadow: '0 3px 5px 2px rgba(170, 0, 255, .30)',
    width: HEADER_HEIGHT,
    '&:hover,&:focus': {
      background: `linear-gradient(45deg, ${theme.palette.companyPrimary.dark} 30%, ${theme.palette.companyPrimary.main} 90%)`,
      color: theme.palette.common.white,
    },
  },
  list: {
    width: 'inherit',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    color: 'inherit',
    [theme.breakpoints.up('md')]: {
      height: HEADER_HEIGHT,
      float: 'right',
    },
  },
  toolbar: {
    width: '100vw',
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: HEADER_HEIGHT_MOBILE,
    [theme.breakpoints.up('sm')]: {
      height: HEADER_HEIGHT,
    },
  },
  toolbarGutters: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  backButton: {
    marginLeft: 15,
    padding: '5px 42px',
    [theme.breakpoints.only('xs')]: {
      padding: '5px 30px',
    },
  },
  hide: {
    opacity: 0,
    display: 'none',
    transition: 'all 0.4s ease-out',
  },
  snackbar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.company.info,
    maxWidth: 432,
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  versionTitle: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    fontWeight: '800',
    paddingBottom: theme.spacing(1),
  },
  versionText: {
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle2.lineHeight,
  },
  desktopIcon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  icon: {
    alignSelf: 'flex-start',
    marginRight: 10,
    width: '1.25em',
    height: '1.25em',
  },
  refreshBtn: {
    padding: '5px 30px',
    margin: '0 10px 10px',
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.company.info,
      backgroundColor: theme.palette.common.white,
    },
  },
  popper: {
    borderTop: `solid 4px ${theme.palette.companyPrimary.main}`,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: -4,
    boxShadow:
      '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
  },
  expandIcon: {
    width: 26,
    height: 26,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: -7,
  },
  menuButton: {
    padding: '0 8px 0 4px',
    marginTop: 4,
    marginBottom: 4,
    [theme.breakpoints.up('sm')]: {
      marginTop: 14,
      marginBottom: 14,
    },
  },
  cookieConsent: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    boxShadow: 'rgb(50 50 50 / 75%) 0px 8px 20px 0px',
    '& * span': {
      color: theme.palette.companyPrimary.main,
      '&:hover': {
        color: theme.palette.companySecondary.dark,
      },
    },
  },
  cookieConsentContent: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: '0px !important',
    },
  },
  cookieConsentButtonWrapper: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      textAlign: 'end',
    },
  },
  cookieConsentButton: {
    background: '#f57c00',
    color: '#ffffff',
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    borderRadius: 45,
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    margin: '10px 15px',
    [theme.breakpoints.only('xs')]: {
      marginTop: '0px !important',
    },
  },
}));

const Header = React.forwardRef(({ history, location, showBackBtn }, ref) => {
  const classes = useStyles();
  const { isAuth, initials, imgSrc, thumbSrc, firstName, onLogout } =
    useContext(UserContext);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [browserRefresh, setBrowserRefresh] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const anchorInfoRef = useRef(null);

  const headerLink = ClassNames(classes.buttonSize, classes.navLink);

  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);
  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const isActiveRoute = (pathname, mode = MODE.INIT) => {
    const locMode =
      location && location.state && location.state.mode
        ? location.state.mode
        : MODE.INIT;
    const locPathname = location ? location.pathname : '';
    if (mode > MODE.INIT) {
      return locPathname === pathname && locMode === mode;
    }
    return locPathname === pathname;
  };

  useEffect(() => {
    const checkAppVersion = () =>
      doGetAppStats().then((doc) => {
        const currStat = doc.data();
        setBrowserRefresh(
          currStat.pauseVersionCheck
            ? false
            : currStat.version !== process.env.REACT_APP_VERSION
        );
      });
    checkAppVersion();
    const interval = setInterval(() => {
      checkAppVersion();
    }, 3600000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const handleInfoToggle = () => {
    setOpenInfo((prevOpen) => !prevOpen);
  };

  const handleInfoClose = (event) => {
    if (anchorInfoRef.current && anchorInfoRef.current.contains(event.target)) {
      return;
    }
    setOpenInfo(false);
  };

  const handleInfoListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenInfo(false);
    }
  };

  const onBrowserRefresh = () => {
    logAnalyticsEvent('browser_refresh');
    window.location.reload();
  };

  return (
    <div className={classes.root} ref={ref}>
      <AppBar className={classes.root} position='fixed'>
        <Toolbar
          className={classes.toolbar}
          classes={{
            gutters: classes.toolbarGutters,
          }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={browserRefresh}
          >
            <SnackbarContent
              className={classes.snackbar}
              message={
                <>
                  <div className={classes.snackbarMessage}>
                    <InfoIcon className={classes.icon} />
                    <div>
                      <div className={classes.versionTitle}>
                        New version available
                      </div>
                      <div className={classes.versionText}>
                        Please refresh the page to ensure the best experience
                        with A Mighty Good Time.
                      </div>
                    </div>
                  </div>
                </>
              }
              action={[
                <Button
                  variant='outlined'
                  key='refresh'
                  onClick={onBrowserRefresh}
                  size='large'
                  className={classes.refreshBtn}
                >
                  Refresh
                </Button>,
              ]}
            />
          </Snackbar>
          <div style={{ flex: 1 }}>
            <Logo showBackBtn={showBackBtn} onBack={() => history.goBack()} />
          </div>
          <div
            className={ClassNames(classes.headerControls, {
              [classes.headerControlsWithAuth]: isAuth,
            })}
          >
            <Hidden smDown>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <Button
                    component={Link}
                    to={{
                      pathname: LANDING_URI,
                    }}
                    aria-controls='home'
                    aria-haspopup='false'
                    className={headerLink}
                    classes={{
                      label: ClassNames({
                        [classes.activeHeader]: isActiveRoute(LANDING_URI),
                      }),
                    }}
                    disableRipple
                  >
                    <HomeIcon className={classes.desktopIcon} />
                    <div
                      className={ClassNames(classes.menuBtn, {
                        [classes.activeHeaderMarker]:
                          isActiveRoute(LANDING_URI),
                      })}
                    >
                      HOME
                    </div>
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                    component={Link}
                    to={{
                      pathname: SEARCH_URI,
                    }}
                    aria-controls='search'
                    aria-haspopup='false'
                    className={headerLink}
                    classes={{
                      label: ClassNames({
                        [classes.activeHeader]: isActiveRoute(SEARCH_URI),
                      }),
                    }}
                    disableRipple
                  >
                    <SearchIcon className={classes.desktopIcon} />
                    <div
                      className={ClassNames(classes.menuBtn, {
                        [classes.activeHeaderMarker]: isActiveRoute(SEARCH_URI),
                      })}
                    >
                      SEARCH
                    </div>
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                    component={Link}
                    to={{
                      pathname: EVENTS_URI,
                      state: {
                        mode: MODE.CREATE,
                        eventType: 'Event',
                      },
                    }}
                    aria-controls='create event'
                    aria-haspopup='false'
                    className={headerLink}
                    classes={{
                      label: ClassNames({
                        [classes.activeHeader]: isActiveRoute(
                          EVENTS_URI,
                          MODE.CREATE
                        ),
                      }),
                    }}
                    disableRipple
                  >
                    <AddAlarmIcon className={classes.desktopIcon} />
                    <div
                      className={ClassNames(classes.menuStackBtn, {
                        [classes.activeHeaderMarker]: isActiveRoute(
                          EVENTS_URI,
                          MODE.CREATE
                        ),
                      })}
                    >
                      <div>ADD</div>
                      <div style={{ marginTop: -10, marginBottom: -4 }}>
                        EVENT
                      </div>
                    </div>
                  </Button>
                </ListItem>
                {!isAuth && (
                  <ListItem className={classes.listItem}>
                    <Button
                      component={Link}
                      to={{
                        pathname: EVENT_ORGANIZERS_URI,
                      }}
                      className={headerLink}
                      classes={{
                        label: ClassNames({
                          [classes.activeHeader]:
                            isActiveRoute(EVENT_ORGANIZERS_URI),
                        }),
                      }}
                      disableRipple
                    >
                      <BusinessCenterIcon className={classes.desktopIcon} />
                      <div
                        className={ClassNames(classes.menuStackBtn, {
                          [classes.activeHeaderMarker]:
                            isActiveRoute(EVENT_ORGANIZERS_URI),
                        })}
                      >
                        <div>EVENT</div>
                        <div style={{ marginTop: -10, marginBottom: -4 }}>
                          ORGANIZERS
                        </div>
                      </div>
                    </Button>
                  </ListItem>
                )}
                <ListItem className={classes.listItem}>
                  <Button
                    ref={anchorInfoRef}
                    aria-controls={openInfo ? 'info-menu' : undefined}
                    aria-haspopup='true'
                    onClick={handleInfoToggle}
                    className={headerLink}
                    classes={{
                      label: ClassNames({
                        [classes.activeHeader]:
                          (isAuth && isActiveRoute(EVENT_ORGANIZERS_URI)) ||
                          isActiveRoute(HOWITWORKS_URI) ||
                          isActiveRoute(ABOUTUS_URI) ||
                          isActiveRoute(CONTACTUS_URI),
                      }),
                    }}
                    disableRipple
                  >
                    <HelpOutlineIcon className={classes.desktopIcon} />
                    <div className={classes.menuBtn}>ABOUT</div>
                    <ExpandMoreIcon className={classes.expandIcon} />
                  </Button>
                  <Popper
                    open={openInfo}
                    anchorEl={anchorInfoRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    placement='bottom'
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom'
                              ? 'center top'
                              : 'center bottom',
                        }}
                      >
                        <Paper className={classes.popper}>
                          <ClickAwayListener onClickAway={handleInfoClose}>
                            <MenuList
                              autoFocusItem={openInfo}
                              id='open-menu'
                              onKeyDown={handleInfoListKeyDown}
                            >
                              {isAuth && (
                                <MenuItem
                                  component={Link}
                                  classes={{
                                    root: ClassNames(classes.subMenuText, {
                                      [classes.activeHeader]:
                                        isActiveRoute(EVENT_ORGANIZERS_URI),
                                    }),
                                  }}
                                  to={{
                                    pathname: EVENT_ORGANIZERS_URI,
                                  }}
                                  onClick={handleInfoClose}
                                >
                                  EVENT ORGANIZERS
                                </MenuItem>
                              )}
                              <MenuItem
                                component={Link}
                                classes={{
                                  root: ClassNames(classes.subMenuText, {
                                    [classes.activeHeader]:
                                      isActiveRoute(HOWITWORKS_URI),
                                  }),
                                }}
                                to={{
                                  pathname: HOWITWORKS_URI,
                                }}
                                onClick={handleInfoClose}
                              >
                                HOW IT WORKS
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                classes={{
                                  root: ClassNames(classes.subMenuText, {
                                    [classes.activeHeader]:
                                      isActiveRoute(ABOUTUS_URI),
                                  }),
                                }}
                                to={{
                                  pathname: ABOUTUS_URI,
                                }}
                                onClick={handleInfoClose}
                              >
                                ABOUT US
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                classes={{
                                  root: ClassNames(classes.subMenuText, {
                                    [classes.activeHeader]:
                                      isActiveRoute(CONTACTUS_URI),
                                  }),
                                }}
                                to={{
                                  pathname: CONTACTUS_URI,
                                }}
                                onClick={handleInfoClose}
                              >
                                CONTACT US
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </ListItem>
                {isAuth && (
                  <ListItem className={classes.listItem}>
                    <Button
                      component={Link}
                      to={{
                        pathname: FAVORITES_URI,
                      }}
                      className={headerLink}
                      style={{
                        marginLeft: 6,
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                      classes={{
                        label: ClassNames({
                          [classes.activeHeader]: isActiveRoute(FAVORITES_URI),
                        }),
                      }}
                      disableRipple
                    >
                      <FavoriteIcon
                        className={classes.desktopIcon}
                        style={{ color: '#e53935' }}
                      />
                      <span
                        className={ClassNames({
                          [classes.activeHeaderMarker]:
                            isActiveRoute(FAVORITES_URI),
                        })}
                      >
                        FAVORITES
                      </span>
                    </Button>
                  </ListItem>
                )}
                <ListItem className={classes.listItem}>
                  {isAuth ? (
                    <>
                      <AccountMenu
                        location={location}
                        initials={initials}
                        imgSrc={imgSrc}
                        thumbSrc={thumbSrc}
                        onLogout={onLogout}
                        className={headerLink}
                      />
                    </>
                  ) : (
                    <Button
                      component={Link}
                      to={{
                        pathname: LOGIN_URI,
                      }}
                      className={headerLink}
                      classes={{
                        label: ClassNames({
                          [classes.activeHeader]: isActiveRoute(LOGIN_URI),
                        }),
                      }}
                      disableRipple
                    >
                      <AccountCircleIcon className={classes.desktopIcon} />
                      <div
                        className={ClassNames(classes.menuBtn, {
                          [classes.activeHeaderMarker]:
                            isActiveRoute(LOGIN_URI),
                        })}
                      >
                        LOG IN
                      </div>
                    </Button>
                  )}
                </ListItem>
              </List>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                aria-haspopup='true'
                aria-label='Menu'
                onClick={toggleDrawer}
                color='inherit'
                className={classes.menuButton}
              >
                <MenuIcon style={{ fontSize: 42 }} />
              </IconButton>
              <Drawer
                variant='temporary'
                anchor='right'
                open={isDrawerOpen}
                onOpen={openDrawer}
                onClose={closeDrawer}
                classes={{
                  paper: classes.drawer,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <div
                  className={classes.list}
                  role='presentation'
                  onClick={toggleDrawer}
                  onKeyDown={toggleDrawer}
                >
                  <List className={classes.list}>
                    <MenuItemLink
                      to={LANDING_URI}
                      isActive={isActiveRoute(LANDING_URI)}
                      icon={<HomeIcon />}
                      label='Home'
                    />
                    <MenuItemLink
                      to={SEARCH_URI}
                      isActive={isActiveRoute(SEARCH_URI)}
                      icon={<SearchIcon />}
                      label='Search'
                    />
                    <MenuItemLink
                      to={{
                        pathname: EVENTS_URI,
                        state: {
                          mode: MODE.CREATE,
                          eventType: 'Event',
                        },
                      }}
                      isActive={isActiveRoute(EVENTS_URI, MODE.CREATE)}
                      icon={<AddAlarmIcon />}
                      label='Add Event'
                    />
                    <MenuItemLink
                      to={EVENT_ORGANIZERS_URI}
                      isActive={isActiveRoute(EVENT_ORGANIZERS_URI)}
                      icon={<BusinessCenterIcon />}
                      label='Event Organizers'
                    />
                    <MenuItemLink
                      to={HOWITWORKS_URI}
                      isActive={isActiveRoute(HOWITWORKS_URI)}
                      icon={<HelpOutlineIcon />}
                      label='How It Works'
                    />
                    <MenuItemLink
                      to={ABOUTUS_URI}
                      isActive={isActiveRoute(ABOUTUS_URI)}
                      icon={<GroupIcon />}
                      label='About Us'
                    />
                    <MenuItemLink
                      to={CONTACTUS_URI}
                      isActive={isActiveRoute(CONTACTUS_URI)}
                      icon={<PhoneIcon />}
                      label='Contact Us'
                    />
                    {isAuth ? (
                      <MenuItemLink
                        to={LANDING_URI}
                        isActive={false}
                        onClick={onLogout}
                        icon={
                          <SvgIcon>
                            <path d='M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z' />
                          </SvgIcon>
                        }
                        label='Log Out'
                      />
                    ) : (
                      <MenuItemLink
                        to={{
                          pathname: LOGIN_URI,
                          state: {
                            from: location.pathname,
                          },
                        }}
                        isActive={isActiveRoute(LOGIN_URI)}
                        icon={
                          <SvgIcon>
                            <path d='M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z' />
                          </SvgIcon>
                        }
                        label='Log In'
                      />
                    )}
                  </List>
                  {isAuth && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <DashboardMenu firstName={firstName} />
                    </Suspense>
                  )}
                </div>
              </Drawer>
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>
      {isActiveRoute(SEARCH_URI) && (
        <CookieConsent
          location='bottom'
          buttonText='Dismiss'
          cookieName='cookie-consent-v1'
          style={{
            background: '#f5f5f5',
            color: '#111111',
          }}
          containerClasses={classes.cookieConsent}
          contentClasses={classes.cookieConsentContent}
          buttonWrapperClasses={classes.cookieConsentButtonWrapper}
          buttonStyle={{
            background: '#f57c00',
            color: '#ffffff',
            borderRadius: 45,
            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
            margin: '10px 15px',
            marginTop: 5,
          }}
          buttonClasses={classes.cookieConsentButton}
        >
          A Mighty Good Time uses cookies to improve our website and provide the
          best user experience. By dismissing this notice or continuing to use
          this site you accept our{' '}
          <Link
            to={PRIVACY_URI}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.link}
          >
            <span style={{ whiteSpace: 'nowrap' }}>Cookie Policy</span>
          </Link>
          .
        </CookieConsent>
      )}
    </div>
  );
});

Header.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  showBackBtn: PropTypes.bool,
};

Header.defaultProps = {
  showBackBtn: false,
};

export default withRouter(Header);
