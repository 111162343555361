/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import CheckedIcon from '@material-ui/icons/Done';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { CATEGORYLIST } from 'constants/general';

const styles = (theme) => ({
  add: {
    margin: '2px 2px 2px 20px',
  },
  chip: {
    margin: 5,
  },
  fullWidth: {
    width: '100%',
  },
  chipSm: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  itemSpacing: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  selected: {
    fontWeight: '800',
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.companyPrimary.main} !important`,
  },
  icon: {
    marginRight: 0,
    color: `${theme.palette.common.white} !important`,
    minWidth: 38,
  },
  hidden: {
    display: 'none',
  },
});

const arrayRemove = (arr, value) => arr.filter((ele) => ele !== value);

class CategoryList extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;
    this.state = {
      value,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (item, unselectItem) => () => {
    const { onChange } = this.props;
    if (unselectItem) {
      this.setState(
        (prevState) => ({
          value: arrayRemove(prevState.value, item),
        }),
        () => {
          if (onChange) {
            onChange(this.state.value);
          }
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          value: prevState.value.concat(item),
        }),
        () => {
          if (onChange) {
            onChange(this.state.value);
          }
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <List dense>
        {CATEGORYLIST.map((cat) => {
          const isSelected = value.includes(cat);
          return (
            <ListItem
              button
              key={cat}
              selected={isSelected}
              onClick={this.handleChange(cat, isSelected)}
              className={classes.itemSpacing}
              classes={{
                selected: classes.selected,
              }}
            >
              <ListItemIcon
                classes={{
                  root: classes.icon,
                }}
              >
                {isSelected ? (
                  <CheckedIcon />
                ) : (
                  <UncheckedIcon style={{ color: '#ddd' }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={cat}
                classes={{
                  dense: ClassNames({
                    [classes.selected]: isSelected,
                  }),
                }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

CategoryList.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};

CategoryList.defaultProps = {
  onChange: null,
  value: [],
};

export default withStyles(styles)(CategoryList);
