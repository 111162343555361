/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 'calc(100vw - 20px)',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.companyPrimary.main,
    '&:before': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:hover': {
      color: theme.palette.companySecondary.dark,
    },
  },
  content: {
    transition: 'unset',
    margin: '0 !important',
    color: 'inherit',
  },
  expanded: {
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    backgroundColor: '#edeff0',
    margin: '0 !important',
    borderRadius: '10px !important',
    [theme.breakpoints.only('sm')]: {
      maxWidth: 'calc(100vw - 80px)',
    },
  },
  question: {
    color: 'inherit',
    marginLeft: 40,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  questionExpanded: {
    color: theme.palette.companySecondary.dark,
    textDecoration: 'underline',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'unset',
      width: 'unset',
    },
  },
  answer: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    textAlign: 'left',
  },
  marginLeft: {
    margin: '0 0 0 25px !important',
    [theme.breakpoints.up('sm')]: {
      margin: '0 20px !important',
    },
  },
  panelIcon: {
    color: 'inherit',
    padding: 5,
    right: 'calc(100% - 25px)',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 12px',
      right: 'calc(100% - 30px)',
    },
  },
  panelIconExpanded: {
    color: 'inherit',
    padding: 5,
    right: 'calc(100vw - 65px)',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 12px',
      right: 'calc(100% - 42px)',
    },
  },
  summary: {
    minHeight: '38px !important',
    '&:hover,&:focus': {
      color: theme.palette.companySecondary.dark,
    },
    padding: '5px 0',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px 0 24px',
    },
  },
  details: {
    color: '#333',
  },
}));

const MyAccordion = React.forwardRef(
  ({ id, expanded, summary, details, onChange }, ref) => {
    const classes = useStyles();

    const handleChange = (panel) => (event, isExpanded) => {
      onChange(panel, isExpanded);
    };

    return (
      <Accordion
        id={id}
        ref={ref}
        expanded={expanded}
        onChange={handleChange(id)}
        classes={{
          root: classes.root,
          expanded: classes.expanded,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classes.summary,
            content: classes.content,
            expandIcon: ClassNames({
              [classes.panelIcon]: !expanded,
              [classes.panelIconExpanded]: expanded,
            }),
            expanded: classes.marginLeft,
          }}
        >
          <Typography
            variant='body1'
            align='left'
            className={ClassNames({
              [classes.question]: !expanded,
              [classes.questionExpanded]: expanded,
            })}
          >
            {summary}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.details,
          }}
        >
          <div className={classes.answer}>{details}</div>
        </AccordionDetails>
      </Accordion>
    );
  }
);

MyAccordion.propTypes = {
  id: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MyAccordion;
