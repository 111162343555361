/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import swal from '@sweetalert/with-react';
import './sweetAlert.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

import { loggerError, getLogId } from 'vendors/firebase/fireauth';

const sweetAlert = (params) => swal(params);

const sweetClose = () => swal.close();

const sweetError = (error) => {
  const logId = getLogId();
  const email = `mailto:support@amightygoodtime.com?Subject=${error.subject.replace(
    /\s+/gi,
    '%20'
  )}.%20Please%20provide%20assistance.&body=Error ID = ${logId}`;
  loggerError(logId, `${error.message}, ${error.context}`);

  return sweetAlert({
    icon: 'error',
    title: 'Error!',
    content: (
      <div>
        <h2>{error.title}</h2>
        <p style={{ textAlign: 'left' }}>
          We are very sorry for the inconvenience. Please contact customer
          support at{' '}
          <a href={email} target='_blank' rel='noopener noreferrer'>
            support@amightygoodtime.com
          </a>{' '}
          for assistance, and we will do our best to have your issue resolved as
          soon as possible.
        </p>
        <p style={{ textAlign: 'left' }}>
          Please include the <strong>Error ID</strong> below within your email.
        </p>
        <code>
          {error.message}
          <br />
          <br />
          Error ID: {logId}
        </code>
      </div>
    ),
    buttons: {
      cancel: {
        text: "I'm ok",
        value: false,
        visible: true,
      },
      done: {
        text: 'Send Email',
        value: true,
        visible: true,
      },
    },
    closeOnClickOutside: false,
    closeOnEsc: false,
  }).then((value) => {
    if (value) {
      return email;
    } else {
      return null;
    }
  });
};

const sweetNetworkError = (error) => {
  const logId = getLogId();
  const email = `mailto:support@amightygoodtime.com?Subject=${error.subject.replace(
    /\s+/gi,
    '%20'
  )}.%20Please%20provide%20assistance.&body=Error ID = ${logId}`;
  loggerError(logId, `${error.message}, ${error.context}`);

  return sweetAlert({
    icon: error.icon || 'info',
    title: error.title,
    content: (
      <div>
        <h4>Please ensure you are connected to the internet.</h4>
        <p style={{ textAlign: 'left' }}>
          If you have checked your internet connection and are still having
          trouble, please send a message to our customer support at{' '}
          <a href={email} target='_blank' rel='noopener noreferrer'>
            support@amightygoodtime.com
          </a>{' '}
          . We will do our best to promptly assist you.
        </p>
        <p style={{ textAlign: 'left' }}>
          Please include the <strong>Error ID</strong> below within your email.
        </p>
        <code>Error ID: {logId}</code>
      </div>
    ),
    buttons: {
      cancel: {
        text: "I'm ok",
        value: false,
        visible: true,
      },
      done: {
        text: 'Send Email',
        value: true,
        visible: true,
      },
    },
    closeOnClickOutside: false,
    closeOnEsc: false,
  }).then((value) => {
    if (value) {
      return email;
    } else {
      return null;
    }
  });
};

const sweetProgress = (title) =>
  sweetAlert({
    content: (
      <div>
        <div className='progress-icon'>
          <CircularProgress size={88} thickness={2.4} color='inherit' />
          <SaveIcon className='save-icon' />
        </div>
        <h3>{title}</h3>
      </div>
    ),
    button: false,
    closeOnClickOutside: false,
    closeOnEsc: false,
    // timer: 2000,
  });

const sweetChange = (oldPlan, newPlan) =>
  sweetAlert({
    icon: 'info',
    title: 'Change Membership Plan',
    content: (
      <div>
        <h4 style={{ textAlign: 'left' }}>
          In order to change your membership plan from {oldPlan} to {newPlan},
          please email us at{' '}
          <a
            href={`mailto:support@amightygoodtime.com?Subject=Change plan to ${newPlan} membership.`}
            target='_blank'
            rel='noopener noreferrer'
          >
            support@amightygoodtime.com
          </a>
        </h4>
        <p style={{ textAlign: 'left' }}>
          We need verification to ensure data is not removed incorrectly. Once
          verified we will accomidate your request quickly.
        </p>
      </div>
    ),
    button: 'OK',
    closeOnClickOutside: false,
    closeOnEsc: false,
  });

export {
  sweetAlert,
  sweetClose,
  sweetChange,
  sweetError,
  sweetNetworkError,
  sweetProgress,
};

// Usage Example
// -------------
// sweetAlert({
//   title: 'Success!',
//   text: 'Thank you for creating an account!',
//   icon: 'success', // warning, error, success, info
//   // button: 'OK Man',
//   buttons: {
//     cancel: {
//       text: 'Cancel',
//       value: false,
//       visible: true,
//     },
//     danger: {
//       text: 'Danger',
//       value: true,
//       visible: true,
//     },
//     confirm: {
//       text: 'Confirm',
//       value: true,
//       visible: true,
//     },
//     done: {
//       text: 'Done',
//       value: true,
//       visible: true,
//     },
//   },
//   closeOnClickOutside: false,
//   closeOnEsc: false,
//   dangerMode: true, // Confirm red, Cancel has focus
//   // timer: 3000, // Will disappear in 3 sec
// }).then((value) => {
//   if (value) {
//     console.log('True value returned');
//   } else {
//     console.log('False value returned');
//   }
// });

// sweetError({
//   title: 'A problem occurred while deleting your account!',
//   subject: 'Failure deleting account',
//   message: err.message
// });
